import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import classNames from 'classnames'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { MULTIPLE_VALUES_OPTION, DATE_AND_TIME_SELECT_VALUES } from '../formDefinitions'
import { ReactComponent as SelectChevron } from 'images/select-chevron.svg'
import errorIcon from 'images/overdue.svg'

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    position: 'relative',
    transform: 'none !important',
    color: '#1C1C1C',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: '0 0 7px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10.5px 14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#1C1C1C !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#1C1C1C !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    border: '1px solid rgba(0, 0, 0, 0.43)',
    '& > div': {
      color: '#1C1C1C',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      paddingRight: '60px !important',
    },
    '&.Mui-error': {
      borderColor: `${theme.palette.error.main} !important`,
      '& > svg': {
        fill: `${theme.palette.error.main} !important`,
      },
    },
  },
  '& .MuiSelect-icon': {
    width: '15px',
    height: '10px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fill: theme.palette.primary.main,
  },
  '& .MuiFormHelperText-root': {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: '24px',
    '&.Mui-error': {
      color: `${theme.palette.error.main} !important`,
      '&::before': {
        content: "''",
        backgroundImage: `url(${errorIcon})`,
        transform: 'translate(-20px, 3px)',
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '0',
      },
    },
  },
}))

const Select = ({
  theme,
  required,
  onChange,
  timezone,
  dateTimeSelectOptions,
  editedField,
  ...props
}) => {
  const { t } = useTranslation()
  const { setFieldValue, initialValues, setFieldError } = useFormikContext()
  const [field, meta] = useField(props)

  const renderOptions = useCallback(() => {
    return props.options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  }, [props.options])

  const renderOptionsLabel = useCallback(
    (value) => {
      const currentOption = props.options.find((option) => option.value === value)
      return currentOption ? currentOption.label : t(MULTIPLE_VALUES_OPTION.i18n)
    },
    [props.options, t],
  )

  const onSelectChange = useCallback(
    (option) => {
      field.onChange(option) // Formik logic to update selection
      onChange(option.target) // Callback for parent component

      /**
       * This is logic is exclusive to Select fields that are part of
       * DateAndTimeField Modules.
       */
      if (dateTimeSelectOptions?.mode) {
        let datePickerValue = null
        let timePickerValue = null

        /**
         * DateField selects should reset pickers to null if not definedDate
         * and set a specific time if definedDate.
         */
        if (option.target.value === DATE_AND_TIME_SELECT_VALUES.definedDate) {
          // Pick initial value if exists, create localized moment date object if not.
          datePickerValue = initialValues[dateTimeSelectOptions.dateField] || moment().tz(timezone)
          timePickerValue = initialValues[dateTimeSelectOptions.timeField] || moment().tz(timezone)
        } else {
          // Reset errors on date - time pickers
          setFieldError(dateTimeSelectOptions.dateField, null)
          setFieldError(dateTimeSelectOptions.timeField, null)
        }
        setFieldValue(dateTimeSelectOptions.dateField, datePickerValue)
        setFieldValue(dateTimeSelectOptions.timeField, timePickerValue)
      }
    },
    [onChange, field, initialValues, dateTimeSelectOptions, setFieldError, setFieldValue, timezone],
  )

  return (
    <div className="select">
      <CustomTextField
        id={props.name}
        select
        defaultValue={props.defaultValue}
        label={props.label}
        value={field.value}
        onBlur={field.onBlur}
        onChange={onSelectChange}
        error={meta.touched && meta.error ? true : false}
        helperText={meta.touched && meta.error ? meta.error : null}
        inputProps={{ name: props.name }}
        required={required}
        InputProps={{
          classes: {
            // eslint-disable-next-line
            root: classNames({ ['edited-highlight']: editedField }),
          },
        }}
        SelectProps={{
          IconComponent: SelectChevron,
          displayEmpty: true,
          renderValue: renderOptionsLabel,
        }}
        fullWidth
        margin="dense"
        variant="outlined"
      >
        {renderOptions()}
      </CustomTextField>
    </div>
  )
}

export default Select

Select.propTypes = {
  dateTimeSelectOptions: PropTypes.object,
  defaultValue: PropTypes.string,
  editedField: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  theme: PropTypes.object,
  timezone: PropTypes.string,
}

Select.defaultProps = {
  dateTimeSelectOptions: {},
  defaultValue: '',
  editedField: false,
  label: '',
  name: '',
  onChange: () => {},
  options: [],
  required: false,
  theme: {},
  timezone: 'America/New_York',
}
