import platformApi from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getEnrollments = ({ dispatch, courseKey, returnRejectedPromise = false }) =>
  platformApi({
    dispatch,
    types: [
      actions.ENROLLMENTS_GET_REQUEST,
      actions.ENROLLMENTS_GET_SUCCESS,
      actions.ENROLLMENTS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().enrollments(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
    returnRejectedPromise,
  })

export const unsetEnrollments = ({ dispatch }) => dispatch({ type: actions.ENROLLMENTS_UNSET })
