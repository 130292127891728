import { useCallback, useContext, useEffect } from 'react'
import { StoreContext } from 'store'
import { apiStatus } from 'store/api'
import { actions, getCustomer } from 'store/actions'
import { actionHasErrors } from 'store/selectors'
import { CONTACT_SUPPORT_URL, DEFAULT_CUSTOMER, DEFAULT_THEME } from 'utils/constants'
import { isEmptyObject } from 'utils/functional'
import { useStateWithLocalStorage } from 'utils/customHooks'
import DefaultLogo from 'images/logo-knockout.svg'

const useCustomerData = ({ customerSlug }) => {
  const { api, brandedSigninCustomerData, customer, dispatch, theme } = useContext(StoreContext)
  const [brandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')
  const customerStatus = apiStatus({
    action: actions.CUSTOMER_GET_REQUEST,
    isEmpty: !customer,
    isLoaded: customer,
  })
  const customerToGet = brandedSignInCustomer || customerSlug || DEFAULT_CUSTOMER
  const hasCustomerErrors = actionHasErrors(api, 'CUSTOMER_GET')
  const hasCustomerData = !isEmptyObject(brandedSigninCustomerData)

  const isVitalSourceSignIn = customerSlug && customerSlug === 'vitalsource'

  const currentThemeMatchesCustomerTheme =
    hasCustomerData &&
    brandedSigninCustomerData.customerTheme.primaryColor === theme.primaryColor &&
    brandedSigninCustomerData.customerTheme.secondaryColor === theme.secondaryColor

  const shouldDispatchCustomerData =
    !hasCustomerData || (!isVitalSourceSignIn && !currentThemeMatchesCustomerTheme)

  const isCustomerDataReady = isVitalSourceSignIn
    ? hasCustomerData
    : hasCustomerData && currentThemeMatchesCustomerTheme

  const dispatchCustomerData = useCallback(
    (customerData) =>
      dispatch({
        type: actions.BRANDED_SIGNIN_CUSTOMER_DATA_SET,
        payload: customerData,
      }),
    [dispatch],
  )

  // Retrieve customer data
  useEffect(() => {
    if (customerStatus.shouldLoad) {
      getCustomer({ dispatch, customerSlug: customerToGet })
    }
  }, [customerToGet, customerStatus.shouldLoad, dispatch])

  // If retrieving customer data fails, use Acrobatiq logo
  useEffect(() => {
    if (hasCustomerErrors && !hasCustomerData) {
      dispatchCustomerData({
        customerImage: DefaultLogo,
        customerName: 'Acrobatiq',
        customerTheme: DEFAULT_THEME,
        customerSupportUrl: CONTACT_SUPPORT_URL,
        enableLogoutLink: true,
        enableCourseCopy: false,
      })
    }
  }, [hasCustomerData, hasCustomerErrors, dispatchCustomerData])

  // Use customer data - fallback to default
  useEffect(() => {
    if (customer && shouldDispatchCustomerData) {
      const {
        customerImage,
        brandName,
        name,
        theme,
        studentHelpUrl,
        enableLogoutLink,
        enableCourseCopy,
      } = customer
      dispatchCustomerData({
        customerImage: customerImage || DefaultLogo,
        customerName: brandName || name || 'Acrobatiq',
        customerTheme: theme || DEFAULT_THEME,
        customerSupportUrl: studentHelpUrl || CONTACT_SUPPORT_URL,
        enableLogoutLink: enableLogoutLink,
        enableCourseCopy: enableCourseCopy,
      })
    }
  }, [customer, shouldDispatchCustomerData, dispatchCustomerData])

  return [isCustomerDataReady]
}

export default useCustomerData
