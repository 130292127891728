import { error, log } from 'utils/log'

export const isFunction = (possibleFunction) =>
  possibleFunction && possibleFunction.constructor === Function

export const isObject = (possibleObject) => possibleObject && possibleObject.constructor === Object

export const isEmptyObject = (obj) => !isObject(obj) || !(Object.keys(obj).length > 0)

export const isEmptyArray = (array) => !Array.isArray(array) || array.length === 0

const fallbackCopyTextToClipboard = (str) => {
  function listener(e) {
    e.clipboardData.setData('text/html', str)
    e.clipboardData.setData('text/plain', str)
    e.preventDefault()
  }
  document.addEventListener('copy', listener)
  document.execCommand('copy')
  document.removeEventListener('copy', listener)
}
export const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      log('Async: Copying to clipboard was successful!')
    },
    function (err) {
      error('Async: Could not copy text: ', err)
    },
  )
}

export async function copyRichText(id) {
  const richTextWithFallback = document.getElementById(id)

  if (!navigator.clipboard || typeof ClipboardItem === 'undefined') {
    fallbackCopyTextToClipboard(richTextWithFallback.innerText)
    return
  }

  const clipboardItem = new ClipboardItem({
    'text/plain': new Blob([richTextWithFallback.innerText], { type: 'text/plain' }),
    'text/html': new Blob([richTextWithFallback.outerHTML], { type: 'text/html' }),
  })

  navigator.clipboard.write([clipboardItem])
}

export const completeUrl = (s) => {
  return !/^https?:\/\//i.test(s) ? 'https://' + s : s
}
