import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const {
  TERMS_GET_REQUEST,
  TERMS_GET_SUCCESS,
  TERMS_GET_FAILURE,
  TERMS_ACCEPT_POST_FAILURE,
  TERMS_ACCEPT_POST_REQUEST,
  TERMS_ACCEPT_POST_SUCCESS
} = actions

export const getTerms = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [TERMS_GET_REQUEST, TERMS_GET_SUCCESS, TERMS_GET_FAILURE],
    fetchParams: {
      url: apiEndpoints().configuration(),
      params: withCredentials({ method: 'get', credentials: 'include' })
    }
  })

export const acceptTerms = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [TERMS_ACCEPT_POST_REQUEST, TERMS_ACCEPT_POST_SUCCESS, TERMS_ACCEPT_POST_FAILURE],
    fetchParams: {
      url: apiEndpoints().acceptTerms(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  })
