export const lowerCharRegex = /.*[a-z]/

export const upperCharRegex = /.*[A-Z]/

export const numberCharRegex = /.*[0-9]/

export const specialCharRegex = /.*[^A-Za-z0-9]/

// Source: https://formik.org/docs/guides/validation
export const validEmailRegex = new RegExp('^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$', 'i')
