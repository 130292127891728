import { actions } from 'store/actions'
import { initialState } from 'store'

const currentLocationReducer = (state = initialState.currentLocation, action) => {
  switch (action.type) {
    case actions.CURRENT_LOCATION_SET:
      return {
        ...state,
        ...action.payload
      }
    case actions.CURRENT_LOCATION_UNSET:
      return initialState.currentLocation
    default:
      return state
  }
}

export default currentLocationReducer
