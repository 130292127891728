import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getAssignmentsExceptions = ({ dispatch, courseKey, returnRejectedPromise = false }) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_EXCEPTIONS_GET_REQUEST,
      actions.ASSIGNMENTS_EXCEPTIONS_GET_SUCCESS,
      actions.ASSIGNMENTS_EXCEPTIONS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentsExceptions(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
    returnRejectedPromise,
  })

export const createAssignmentException = ({
  dispatch,
  courseKey,
  data,
  returnRejectedPromise = false,
}) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_EXCEPTIONS_POST_REQUEST,
      actions.ASSIGNMENTS_EXCEPTIONS_POST_SUCCESS,
      actions.ASSIGNMENTS_EXCEPTIONS_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentsExceptions(courseKey),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })

export const updateAssignmentException = ({
  dispatch,
  courseKey,
  data,
  returnRejectedPromise = false,
}) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_EXCEPTIONS_PATCH_REQUEST,
      actions.ASSIGNMENTS_EXCEPTIONS_PATCH_SUCCESS,
      actions.ASSIGNMENTS_EXCEPTIONS_PATCH_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentsExceptions(courseKey, data.uuid),
      params: withCredentials({
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })

export const createOrUpdateAssignmentException = ({
  dispatch,
  courseKey,
  data,
  returnRejectedPromise = false,
}) =>
  data.uuid
    ? updateAssignmentException({ dispatch, courseKey, data, returnRejectedPromise })
    : createAssignmentException({ dispatch, courseKey, data, returnRejectedPromise })

export const unsetAssignmentsExceptions = ({ dispatch }) =>
  dispatch({ type: actions.ASSIGNMENTS_EXCEPTIONS_UNSET })

export const deleteAssignmentException = ({ dispatch, courseKey, data, returnRejectedPromise }) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_EXCEPTIONS_DELETE_REQUEST,
      actions.ASSIGNMENTS_EXCEPTIONS_DELETE_SUCCESS,
      actions.ASSIGNMENTS_EXCEPTIONS_DELETE_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentsExceptions(courseKey, data.uuid),
      params: withCredentials({
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })
