import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { assignmentSettingsReducer } from './settingsReducer'
import { settingsInitialState } from './settingsInitialState'

const SettingsContext = React.createContext()

export const SettingsContextProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(assignmentSettingsReducer, {
    ...settingsInitialState,
    ...initialState,
  })

  return (
    <SettingsContext.Provider value={{ ...state, dispatchSettingsAction: dispatch }}>
      {children}
    </SettingsContext.Provider>
  )
}

SettingsContextProvider.propTypes = {
  initialState: PropTypes.shape({}),
  children: PropTypes.element,
}

SettingsContextProvider.defaultProps = { initialState: settingsInitialState }

export const useSettingsContext = () => React.useContext(SettingsContext) || {}
