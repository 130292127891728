import React, { useCallback, memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { styled } from '@mui/material/styles'

const Div = styled('div')(({ theme }) => ({
  '& .clear-selected-types': {
    color: theme.palette.primary.main,
  },
  '& .selector': {
    backgroundColor: theme.palette.legacy.primary.getShade(1),
    border: `1px solid ${theme.palette.primary.light}`,
  },
  '& .active-selector': {
    backgroundColor: theme.palette.primary.main,
    '& > label': {
      color: '#FFFFFF !important',
    },
  },
}))

const AssignmentTypeSelector = () => {
  const { t } = useTranslation()
  const { assignmentTagsState, assignmentTagsActions, assignmentTypes } = useSettingsContext()

  const onSelectorClick = useCallback(
    (assignmentKey) => {
      const payload = {
        key: assignmentKey,
        value: !assignmentTagsState[assignmentKey].value,
        propagate: true,
      }
      assignmentTagsActions.setTagValue(payload)
    },
    [assignmentTagsState, assignmentTagsActions],
  )

  const onSelectorKeyPress = useCallback(
    (e, assignmentKey) => {
      if (e.key === ' ') {
        onSelectorClick(assignmentKey)
      }
    },
    [onSelectorClick],
  )

  const onClearSelected = useCallback(() => {
    assignmentTagsActions.clearTags({ propagate: true })
  }, [assignmentTagsActions])

  const renderOptions = useCallback(
    (types) => {
      const options = Object.keys(types).map((assignmentKey) => (
        <div
          className={classNames('selector', {
            // eslint-disable-next-line
            ['active-selector']: assignmentTagsState[assignmentKey].value,
          })}
          key={assignmentKey}
          role="button"
          tabIndex="0"
          onClick={() => onSelectorClick.apply(onSelectorClick, [assignmentKey])}
          onKeyPress={(e) => onSelectorKeyPress.apply(onSelectorKeyPress, [e, assignmentKey])}
        >
          <input
            type="checkbox"
            id={`assignmentType-${assignmentKey}`}
            checked={assignmentTagsState[assignmentKey].value}
            readOnly
            tabIndex="-1"
          />
          <label htmlFor={`assignmentType-${assignmentKey}`}>
            {types[assignmentKey] || t(`assignmentTypes.${assignmentKey}`)}
          </label>
        </div>
      ))
      return (
        <div className="type-selectors">
          {options}
          {Object.values(assignmentTagsState).some((selector) => selector.value === true) ? (
            <button className="clear-selected-types" onClick={onClearSelected}>
              {t('assignmentTypeSelector.clear')}
            </button>
          ) : null}
        </div>
      )
    },
    [assignmentTagsState, onSelectorClick, onSelectorKeyPress, onClearSelected, t],
  )

  return assignmentTagsState && assignmentTypes && Object.keys(assignmentTypes).length > 0 ? (
    <Div className="assignment-type-selector">
      <span className="title">{t('assignmentTypeSelector.selectOnly')}</span>
      {renderOptions(assignmentTypes)}
    </Div>
  ) : (
    <div className="assignment-type-selector" />
  )
}

export default memo(AssignmentTypeSelector)

AssignmentTypeSelector.propTypes = {
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.shape({}),
}

AssignmentTypeSelector.defaultProps = {
  theme: {},
}
