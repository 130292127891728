/* eslint-disable react/no-danger */
import React, { useState, useCallback, useContext, useEffect, useRef } from 'react'
import { Button, CustomInput, Form } from 'reactstrap'
import { useTranslation, Trans } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { StoreContext } from 'store'
import { acceptTerms, actions, getAqUser, getTerms } from 'store/actions'
import { apiStatus } from 'store/api'
import { isLoading } from 'store/selectors'
import Spinner from 'components/Spinner'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { isEmptyObject } from 'utils/functional'

const Terms = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { api, terms, userAq, dispatch } = useContext(StoreContext)
  const { t, i18n } = useTranslation()
  const [didAgree, setDidAgree] = useState(false)
  const { coursesPageUrl } = buildPagesEndpoints(i18n)
  const isRedirecting = useRef(false)
  const isApiLoading = isLoading(api)

  const { search: querySearch } = location
  const { next: nextPage } = queryString.parse(querySearch)

  const termsStatus = apiStatus({
    action: actions.TERMS_GET_REQUEST,
    isEmpty: isEmptyObject(terms) || !terms.terms,
    isLoaded: !isEmptyObject(terms) && terms.terms,
  })

  const termsLoaded = !isEmptyObject(terms)
  const userAccepted = userAq && userAq.tos_accepted

  function createMarkup(htmlString) {
    return { __html: htmlString }
  }

  const handleInputChange = () => setDidAgree((state) => !state)

  const getCheckboxLabel = () => (
    <Trans i18nKey="terms.agreement">
      I agree to the{' '}
      <a target="_blank" rel="noopener noreferrer" href={terms.terms.intlUrl}>
        Terms of Use
      </a>
      ,
      <a target="_blank" rel="noopener noreferrer" href={terms.privacy.intlUrl}>
        Privacy Policy
      </a>{' '}
      and
      <a target="_blank" rel="noopener noreferrer" href={terms.cookie.intlUrl}>
        Cookie Policy
      </a>
    </Trans>
  )

  const handleSubmit = (event) => {
    event.preventDefault()
    acceptTerms({ dispatch, data: { accept: true } })
  }

  const redirectUserToPage = useCallback(() => {
    if (!isRedirecting.current) {
      isRedirecting.current = true
      navigate(nextPage || coursesPageUrl())
    }
  }, [coursesPageUrl, navigate, nextPage])

  useEffect(() => {
    document.title = t('terms.termsAndPrivacy')
  }, [t])

  useEffect(() => {
    if (termsStatus.shouldLoad) {
      getTerms({ dispatch })
    }
  }, [termsStatus.shouldLoad, dispatch])

  useEffect(() => {
    if (termsLoaded && terms.userAccepted && !userAccepted) {
      getAqUser({ dispatch })
    }
  }, [dispatch, terms, termsLoaded, userAccepted])

  useEffect(() => {
    if (userAccepted) {
      redirectUserToPage()
    }
  }, [redirectUserToPage, userAccepted])

  return (
    <main
      id="main"
      role="main"
      aria-hidden="false"
      aria-label={t('nav.mainContent')}
      tabIndex="-1"
      className="full-page tos"
    >
      <div className="tos__container">
        <h1 className="tos__main-title">{t('terms.termsAndPrivacy')}</h1>
        {!termsLoaded ? (
          <Spinner />
        ) : (
          <>
            <div className="tos__document">
              <div dangerouslySetInnerHTML={createMarkup(terms.terms.article.body)} />
              <div dangerouslySetInnerHTML={createMarkup(terms.privacy.article.body)} />
              <div dangerouslySetInnerHTML={createMarkup(terms.cookie.article.body)} />
            </div>
            <Form className="tos__form" onSubmit={handleSubmit}>
              <CustomInput
                type="checkbox"
                id="exampleCustomCheckbox"
                checked={didAgree}
                onChange={handleInputChange}
                label={getCheckboxLabel()}
              />

              <p className="text-right">
                <Button
                  type="submit"
                  color="primary"
                  disabled={!didAgree || isApiLoading}
                  className="tos__button"
                >
                  {t('terms.accept')}
                </Button>
              </p>
            </Form>
          </>
        )}
      </div>
    </main>
  )
}

export default Terms
