import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'
import { resetAction } from './apiActions'
import { log } from 'utils/log'

export const getAssignmentsUnitsAndModules = ({
  dispatch,
  courseKey,
  purposeTypes,
  returnRejectedPromise = false,
}) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_GET_REQUEST,
      actions.ASSIGNMENTS_GET_SUCCESS,
      actions.ASSIGNMENTS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().allSegments(courseKey, purposeTypes),
      params: { method: 'get', credentials: 'include' },
    },
    returnRejectedPromise,
  })

export const getAssignmentById = ({ dispatch, courseKey, id }) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_GET_REQUEST,
      actions.ASSIGNMENTS_GET_SUCCESS,
      actions.ASSIGNMENTS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentById(courseKey, id),
      params: { method: 'get', credentials: 'include' },
    },
  })

export const unsetAssignments = ({ dispatch }) => dispatch({ type: actions.ASSIGNMENTS_UNSET })

export const updateAssignmentsSchedules = ({
  dispatch,
  courseKey,
  data,
  returnRejectedPromise = false,
}) => {
  log('data', data)
  return platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENTS_SCHEDULES_PATCH_REQUEST,
      actions.ASSIGNMENTS_SCHEDULES_PATCH_SUCCESS,
      actions.ASSIGNMENTS_SCHEDULES_PATCH_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentsSchedules(courseKey),
      params: withCredentials({
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })
}

export const updateSegmentSchedules = ({
  dispatch,
  courseKey,
  data,
  returnRejectedPromise = false,
}) => {
  log('data', data)
  return platformApi({
    dispatch,
    types: [
      actions.SEGMENT_SCHEDULES_PATCH_REQUEST,
      actions.SEGMENT_SCHEDULES_PATCH_SUCCESS,
      actions.SEGMENT_SCHEDULES_PATCH_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().segmentSchedules(courseKey),
      params: withCredentials({
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })
}

export const getAssignmentTypes = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENT_TYPES_GET_REQUEST,
      actions.ASSIGNMENT_TYPES_GET_SUCCESS,
      actions.ASSIGNMENT_TYPES_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentTypes(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
  })

export const unsetAssignmentTypes = ({ dispatch }) =>
  dispatch({ type: actions.ASSIGNMENT_TYPES_UNSET })

export const getAssignmentPurposeTypes = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.ASSIGNMENT_PURPOSE_TYPES_GET_REQUEST,
      actions.ASSIGNMENT_PURPOSE_TYPES_GET_SUCCESS,
      actions.ASSIGNMENT_PURPOSE_TYPES_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().assignmentPurposeTypes(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
  })

export const unsetAssignmentPurposeTypes = ({ dispatch }) =>
  dispatch({ type: actions.ASSIGNMENT_PURPOSE_TYPES_UNSET })

export const unsetAllAssignmentsData = ({ dispatch }) =>
  Promise.all([
    dispatch({ type: actions.ASSIGNMENTS_UNSET }),
    dispatch({ type: actions.ASSIGNMENT_TYPES_UNSET }),
  ])

export const resetAllAssignmentsActions = ({ dispatch }) =>
  Promise.all([
    resetAction({ dispatch, action: 'ASSIGNMENTS_GET' }),
    resetAction({ dispatch, action: 'ASSIGNMENT_TYPES_GET' }),
  ])
