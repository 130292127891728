import { useContext, useState, useEffect, useCallback } from 'react'
import { StoreContext } from 'store'
import { isLoading } from 'store/selectors'
import { useTranslation } from 'react-i18next'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { apiStatus } from 'store/api'
import { actions } from 'store/actions'

const useAuthentication = ({ getAqUser, setUserIsAuthenticated, pathname, customerSlug, vbid }) => {
  const { api, userAq, auth, dispatch } = useContext(StoreContext)

  // flags to decide what to do
  const [isCurrentUserTriggered, setIsCurrentUserTriggered] = useState(false)
  const [shouldRedirectToLogin, setShouldRedirectToLogin] = useState(false)
  const [shouldShowAnError, setShouldShowAnError] = useState(false)
  const [shouldRedirectToTerms, setShouldRedirectToTerms] = useState(false)
  const [canShowComponent, setCanShowComponent] = useState(false)
  const { i18n } = useTranslation()
  const { chooseCourseSectionPageUrl, coursesPageUrl } = buildPagesEndpoints(i18n)

  const isTermsPage = pathname.includes('/tos')
  const isProtectedPath = !!pathname
  const isStorePurchaseFlow = !!customerSlug && !!vbid

  const userAqStatus = apiStatus({
    action: actions.USER_AQ_POST_REQUEST,
    isEmpty: !userAq,
    isLoaded: userAq,
  })

  // helper checks
  /**
   * this looks kinda terrible and duplicated, but
   * as it is using the hook `useCallback`, it can't be changed to a builder function like
   * `const buildCheck (fn) => useCallback(fn, [...dependencies])` -> react complains that useCallback is been used wrongly.
   * Also, without the useCallback hook, react starts to complain that the function will trigger the render multiple times.
   * Also also, the dependencies MUST be written every time, otherwise react will complaim as well.
   * So, for now this shall be this way
   */
  const shouldGetAqUser = () =>
    !userAq && !auth.isAuthenticated && !isCurrentUserTriggered && !isLoading(api)
  const shouldSetRedirectToLogin = () =>
    (!userAq || (userAqStatus.hasError && userAq.alohomoraUrl)) &&
    !auth.isAuthenticated &&
    isCurrentUserTriggered &&
    !isLoading(api)
  const shouldSetShowAnError = () =>
    !!userAq &&
    userAqStatus.hasError &&
    !userAq.alohomoraUrl &&
    isCurrentUserTriggered &&
    !isLoading(api)
  const setAsAuthenticated = () =>
    !!userAq && !userAqStatus.hasError && !auth.isAuthenticated && !isLoading(api)
  const shouldSetRedirectToTerms = () =>
    !isTermsPage &&
    !!userAq &&
    !userAqStatus.hasError &&
    userAq.tos_accepted === false &&
    auth.isAuthenticated
  const canSetShowComponent = () => !!userAq && userAq.tos_accepted && auth.isAuthenticated

  const shouldGetAqUserCb = useCallback(shouldGetAqUser, [
    userAq,
    auth.isAuthenticated,
    isCurrentUserTriggered,
    api,
  ])
  const shouldSetRedirectToLoginCb = useCallback(shouldSetRedirectToLogin, [
    userAq,
    userAqStatus.hasError,
    auth.isAuthenticated,
    isCurrentUserTriggered,
    api,
  ])
  const shouldShowAnErrorCb = useCallback(shouldSetShowAnError, [
    userAq,
    userAqStatus.hasError,
    isCurrentUserTriggered,
    api,
  ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setAsAuthenticatedCb = useCallback(setAsAuthenticated, [
    userAq,
    auth.isAuthenticated,
    isCurrentUserTriggered,
    api,
  ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldSetRedirectToTermsCb = useCallback(shouldSetRedirectToTerms, [
    userAq,
    auth.isAuthenticated,
  ])
  const canSetShowComponentCb = useCallback(canSetShowComponent, [userAq, auth.isAuthenticated])

  const nextPageUrl = isStorePurchaseFlow
    ? chooseCourseSectionPageUrl({ customerSlug, vbid })
    : isProtectedPath
      ? pathname
      : coursesPageUrl()

  useEffect(() => {
    if (isTermsPage && shouldRedirectToTerms) {
      setShouldRedirectToTerms(false)
    }
  }, [isTermsPage, shouldRedirectToTerms])

  useEffect(() => {
    // there is no data but the sessionid might be valid, try to get the current user
    if (shouldGetAqUserCb()) {
      setIsCurrentUserTriggered(true)
      getAqUser({
        dispatch,
        data: {
          customerSlug,
          vbid,
          nextUrl: `${window.location.origin}${nextPageUrl}`,
        },
      })
    } else if (setAsAuthenticatedCb()) {
      // succeeded to get the current user, set as authenticated since the sessionid is valid
      setUserIsAuthenticated({ dispatch })
    } else if (shouldShowAnErrorCb()) {
      setShouldShowAnError(true)
    } else if (shouldSetRedirectToLoginCb()) {
      // invalid sessionid, needs to login again
      setShouldRedirectToLogin(true)
    } else if (shouldSetRedirectToTermsCb()) {
      // user is authenticated but did not accept TOS
      setShouldRedirectToTerms(true)
    } else if (canSetShowComponentCb()) {
      // after set the user as authenticated we can display the protected component
      setCanShowComponent(true)
    }
  }, [
    userAq,
    shouldGetAqUserCb,
    setAsAuthenticatedCb,
    shouldShowAnErrorCb,
    shouldSetRedirectToLoginCb,
    canSetShowComponentCb,
    getAqUser,
    dispatch,
    setUserIsAuthenticated,
    shouldSetRedirectToTermsCb,
    nextPageUrl,
    customerSlug,
    vbid,
  ])

  return [shouldShowAnError, shouldRedirectToLogin, shouldRedirectToTerms, canShowComponent]
}

export default useAuthentication
