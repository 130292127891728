import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import debounce from 'utils/debounce'
import { ReactComponent as CloseIcon } from 'images/close-icon.svg'
import { ReactComponent as Chevron } from 'images/select-chevron.svg'
import CustomThemeProvider from 'MUI'

const widthObject = {
  width50: {
    minWidth: '375px',
    maxWidth: '425px',
    width: '50vw',
  },
  width60: {
    minWidth: '375px',
    maxWidth: '475px',
    width: '60vw',
  },
  width70: {
    minWidth: '375px',
    maxWidth: '525px',
    width: '70vw',
  },
}

const CustomDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  '& .MuiPaper-root': {
    ...widthObject[width],
  },
  '& .drawer-back-button': {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 24px)',
    '& button': {
      borderRight: '1px solid #DADADA !important',
      paddingRight: '24px !important',
      height: '36px',
    },
    '& span': {
      paddingLeft: '24px',
    },
    '& svg': {
      transform: 'rotate(90deg)',
      height: '20px',
      width: '20px',
      fill: `${theme.palette.primary.main} !important`,
    },
  },
}))

const Drawer = ({ children, title, open, showBackBtn, closeCallback, MuiModalProps, width }) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const { t } = useTranslation()

  const onWindowResize = debounce(() => {
    setScreenHeight(window.innerHeight)
  })

  useEffect(() => {
    window.addEventListener('resize', onWindowResize)
    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClose = () => {
    closeCallback && closeCallback()
  }

  return (
    <CustomThemeProvider>
      <CustomDrawer
        className="drawer"
        anchor="right"
        open={open}
        onClose={onClose}
        style={{ height: `${screenHeight}px` }}
        ModalProps={MuiModalProps}
        width={width}
      >
        <div className="wrapper">
          <div className="header">
            {showBackBtn && (
              <div className="drawer-back-button">
                <button onClick={onClose} aria-label={t('generics.back')}>
                  <Chevron aria-hidden="true" />
                </button>
                <span className="title">{title}</span>
              </div>
            )}
            {!showBackBtn && <span className="title">{title}</span>}
            <button onClick={onClose} aria-label={t('generics.close')}>
              <CloseIcon aria-hidden="true" />
            </button>
          </div>
          {children}
        </div>
      </CustomDrawer>
    </CustomThemeProvider>
  )
}

export default memo(Drawer)

Drawer.propTypes = {
  closeCallback: PropTypes.func,
  children: PropTypes.element,
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  /**
   * Title of drawer
   */
  title: PropTypes.string,
  /**
   * Boolean for whether drawer is open or not
   */
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Boolean for whether to display back btn or not
   */
  showBackBtn: PropTypes.bool,
  MuiModalProps: PropTypes.object,
  width: PropTypes.string,
}

Drawer.defaultProps = {
  closeCallback: () => {},
  theme: {},
  title: 'Drawer Title',
  open: false,
  showBackBtn: false,
  MuiModalProps: {},
  width: 'width50',
}
