import 'moment/locale/es'
import 'moment/locale/pt'
import { ptBR, esES } from '@mui/material/locale'
import { ptBR as ptBRDatePickers, esES as esESDatePickers } from '@mui/x-date-pickers/locales'
import { DEFAULT_LANG_CODE } from './constants'

/*
  https://mui.com/material-ui/guides/localization/#supported-locales
  https://mui.com/x/react-date-pickers/localization/#supported-locales
*/
const MUI_LOCALES_MAP = {
  pt: [ptBR, ptBRDatePickers],
  es: [esES, esESDatePickers],
  en: [],
}

const MOMENTJS_LOCALES_MAP = {
  pt: 'pt',
  es: 'es',
  en: 'en',
}

export const getMuiLocale = (lang) => {
  lang = lang.toLowerCase().split('-')[0]
  const locale = Object.keys(MUI_LOCALES_MAP).includes(lang) ? MUI_LOCALES_MAP[lang] : {}
  return locale
}

export const getMomentJsLocale = (lang) => {
  let defLang = lang || DEFAULT_LANG_CODE
  defLang = defLang.toLowerCase().split('-')[0]
  const locale = Object.keys(MOMENTJS_LOCALES_MAP).includes(defLang)
    ? MOMENTJS_LOCALES_MAP[defLang]
    : null
  return locale
}
