export const isApiLoading = api => api && Object.values(api.loading).filter(Boolean).length > 0

export const hasError = api => api && Object.values(api.error).filter(Boolean).length > 0

export const isLoading = api => api && Object.values(api.loading).filter(Boolean).length > 0

export const getErrorMessage = (api, actionName) => {
  const { error } = api

  const actionErrors = Object.keys(error).reduce((result, errorKey) => {
    if (errorKey.includes(actionName)) {
      const errorObj = error[errorKey]

      result = errorObj
    }

    return result
  }, {})

  return actionErrors
}

const getActionsByKey = (actions, searchKey) => {
  if (!actions) {
    return []
  }

  return Object.keys(actions).reduce((result, key) => {
    if (key.includes(searchKey.toUpperCase())) {
      result.push(actions[key])
    }

    return result
  }, [])
}
/**
 * actionName is just the action without the type (REQUEST, SUCCESS, FAILURE)
 * for example pass LOGIN and it will return true if there is a LOGIN_REQUEST option loading
 */
export const isActionLoading = (api, actionName) =>
  api && getActionsByKey(api.loading, actionName).some(Boolean)

export const isActionLoaded = (api, actionName) =>
  api && getActionsByKey(api.loaded, actionName).some(Boolean)

export const actionHasErrors = (api, actionName) =>
  api && getActionsByKey(api.error, actionName).some(Boolean)

export const getActionTypeMatches = action => {
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|UNSET|API_RESET)/.exec(action.type)
  if (!matches) {
    return null
  }

  const [, requestName, requestState] = matches

  return { requestName, requestState }
}
