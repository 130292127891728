const actions = {
  ACCOUNT_CENTER_URL_GET_FAILURE: 'ACCOUNT_CENTER_URL_GET_FAILURE',
  ACCOUNT_CENTER_URL_GET_REQUEST: 'ACCOUNT_CENTER_URL_GET_REQUEST',
  ACCOUNT_CENTER_URL_GET_SUCCESS: 'ACCOUNT_CENTER_URL_GET_SUCCESS',
  ASSIGNMENT_PURPOSE_TYPES_GET_FAILURE: 'ASSIGNMENT_PURPOSE_TYPES_GET_FAILURE',
  ASSIGNMENT_PURPOSE_TYPES_GET_REQUEST: 'ASSIGNMENT_PURPOSE_TYPES_GET_REQUEST',
  ASSIGNMENT_PURPOSE_TYPES_GET_SUCCESS: 'ASSIGNMENT_PURPOSE_TYPES_GET_SUCCESS',
  ASSIGNMENT_PURPOSE_TYPES_UNSET: 'ASSIGNMENT_PURPOSE_TYPES_UNSET',
  ASSIGNMENT_TYPES_GET_FAILURE: 'ASSIGNMENT_TYPES_GET_FAILURE',
  ASSIGNMENT_TYPES_GET_REQUEST: 'ASSIGNMENT_TYPES_GET_REQUEST',
  ASSIGNMENT_TYPES_GET_SUCCESS: 'ASSIGNMENT_TYPES_GET_SUCCESS',
  ASSIGNMENT_TYPES_UNSET: 'ASSIGNMENT_TYPES_UNSET',
  ASSIGNMENTS_EXCEPTIONS_DELETE_FAILURE: 'ASSIGNMENTS_EXCEPTIONS_DELETE_FAILURE',
  ASSIGNMENTS_EXCEPTIONS_DELETE_REQUEST: 'ASSIGNMENTS_EXCEPTIONS_DELETE_REQUEST',
  ASSIGNMENTS_EXCEPTIONS_DELETE_SUCCESS: 'ASSIGNMENTS_EXCEPTIONS_DELETE_SUCCESS',
  ASSIGNMENTS_EXCEPTIONS_GET_FAILURE: 'ASSIGNMENTS_EXCEPTIONS_GET_FAILURE',
  ASSIGNMENTS_EXCEPTIONS_GET_REQUEST: 'ASSIGNMENTS_EXCEPTIONS_GET_REQUEST',
  ASSIGNMENTS_EXCEPTIONS_GET_SUCCESS: 'ASSIGNMENTS_EXCEPTIONS_GET_SUCCESS',
  ASSIGNMENTS_EXCEPTIONS_PATCH_FAILURE: 'ASSIGNMENTS_EXCEPTIONS_PATCH_FAILURE',
  ASSIGNMENTS_EXCEPTIONS_PATCH_REQUEST: 'ASSIGNMENTS_EXCEPTIONS_PATCH_REQUEST',
  ASSIGNMENTS_EXCEPTIONS_PATCH_SUCCESS: 'ASSIGNMENTS_EXCEPTIONS_PATCH_SUCCESS',
  ASSIGNMENTS_EXCEPTIONS_POST_FAILURE: 'ASSIGNMENTS_EXCEPTIONS_POST_FAILURE',
  ASSIGNMENTS_EXCEPTIONS_POST_REQUEST: 'ASSIGNMENTS_EXCEPTIONS_POST_REQUEST',
  ASSIGNMENTS_EXCEPTIONS_POST_SUCCESS: 'ASSIGNMENTS_EXCEPTIONS_POST_SUCCESS',
  ASSIGNMENTS_EXCEPTIONS_UNSET: 'ASSIGNMENTS_EXCEPTIONS_UNSET',
  ASSIGNMENTS_GET_FAILURE: 'ASSIGNMENTS_GET_FAILURE',
  ASSIGNMENTS_GET_REQUEST: 'ASSIGNMENTS_GET_REQUEST',
  ASSIGNMENTS_GET_SUCCESS: 'ASSIGNMENTS_GET_SUCCESS',
  ASSIGNMENTS_SCHEDULES_PATCH_FAILURE: 'ASSIGNMENTS_SCHEDULES_PATCH_FAILURE',
  ASSIGNMENTS_SCHEDULES_PATCH_REQUEST: 'ASSIGNMENTS_SCHEDULES_PATCH_REQUEST',
  ASSIGNMENTS_SCHEDULES_PATCH_SUCCESS: 'ASSIGNMENTS_SCHEDULES_PATCH_SUCCESS',
  ASSIGNMENTS_UNSET: 'ASSIGNMENTS_UNSET',
  AUTH_USER_ACCESS_POST_FAILURE: 'AUTH_USER_ACCESS_POST_FAILURE',
  AUTH_USER_ACCESS_POST_REQUEST: 'AUTH_USER_ACCESS_POST_REQUEST',
  AUTH_USER_ACCESS_POST_SUCCESS: 'AUTH_USER_ACCESS_POST_SUCCESS',
  AUTHORING_USER_ACTIONS_GET_FAILURE: 'AUTHORING_USER_ACTIONS_GET_FAILURE',
  AUTHORING_USER_ACTIONS_GET_REQUEST: 'AUTHORING_USER_ACTIONS_GET_REQUEST',
  AUTHORING_USER_ACTIONS_GET_SUCCESS: 'AUTHORING_USER_ACTIONS_GET_SUCCESS',
  AUTHORING_USER_ACTIONS_UNSET: 'AUTHORING_USER_ACTIONS_UNSET',
  BRANDED_SIGNIN_CUSTOMER_DATA_SET: 'BRANDED_SIGNIN_CUSTOMER_DATA_SET',
  BRANDED_SIGNIN_CUSTOMER_DATA_UNSET: 'BRANDED_SIGNIN_CUSTOMER_DATA_UNSET',
  CART_URL_GET_FAILURE: 'CART_URL_GET_FAILURE',
  CART_URL_GET_REQUEST: 'CART_URL_GET_REQUEST',
  CART_URL_GET_SUCCESS: 'CART_URL_GET_SUCCESS',
  CHECK_MANAGE_USER_GET_FAILURE: 'CHECK_MANAGE_USER_GET_FAILURE',
  CHECK_MANAGE_USER_GET_REQUEST: 'CHECK_MANAGE_USER_GET_REQUEST',
  CHECK_MANAGE_USER_GET_SUCCESS: 'CHECK_MANAGE_USER_GET_SUCCESS',
  CONFIGURATION_GET_FAILURE: 'CONFIGURATION_GET_FAILURE',
  CONFIGURATION_GET_REQUEST: 'CONFIGURATION_GET_REQUEST',
  CONFIGURATION_GET_SUCCESS: 'CONFIGURATION_GET_SUCCESS',
  COURSE_ACCESS_REQUIREMENTS_GET_FAILURE: 'COURSE_ACCESS_REQUIREMENTS_GET_FAILURE',
  COURSE_ACCESS_REQUIREMENTS_GET_REQUEST: 'COURSE_ACCESS_REQUIREMENTS_GET_REQUEST',
  COURSE_ACCESS_REQUIREMENTS_GET_SUCCESS: 'COURSE_ACCESS_REQUIREMENTS_GET_SUCCESS',
  COURSE_ACCESS_REQUIREMENTS_UNSET: 'COURSE_ACCESS_REQUIREMENTS_UNSET',
  COURSE_COPY_POST_FAILURE: 'COURSE_COPY_POST_FAILURE',
  COURSE_COPY_POST_REQUEST: 'COURSE_COPY_POST_REQUEST',
  COURSE_COPY_POST_SUCCESS: 'COURSE_COPY_POST_SUCCESS',
  COURSE_COPY_STATUS_GET_FAILURE: 'COURSE_COPY_STATUS_GET_FAILURE',
  COURSE_COPY_STATUS_GET_REQUEST: 'COURSE_COPY_STATUS_GET_REQUEST',
  COURSE_COPY_STATUS_GET_SUCCESS: 'COURSE_COPY_STATUS_GET_SUCCESS',
  COURSE_COPY_STATUS_POLLING_FAIL: 'COURSE_COPY_STATUS_POLLING_FAIL',
  COURSE_COPY_STATUS_POLLING_INIT: 'COURSE_COPY_STATUS_POLLING_INIT',
  COURSE_COPY_STATUS_POLLING_RESET: 'COURSE_COPY_STATUS_POLLING_RESET',
  COURSE_COPY_STATUS_POLLING_RESOLVE: 'COURSE_COPY_STATUS_POLLING_RESOLVE',
  COURSE_COPY_STATUS_POLLING_START: 'COURSE_COPY_STATUS_POLLING_START',
  COURSE_COPY_STATUS_POLLING_SUCCESS: 'COURSE_COPY_STATUS_POLLING_SUCCESS',
  COURSE_GET_FAILURE: 'COURSE_GET_FAILURE',
  COURSE_GET_REQUEST: 'COURSE_GET_REQUEST',
  COURSE_GET_SUCCESS: 'COURSE_GET_SUCCESS',
  COURSE_MESSAGES_SET: 'COURSE_MESSAGES_SET',
  COURSE_MESSAGES_UNSET: 'COURSE_MESSAGES_UNSET',
  COURSE_SCHEDULE_GET_FAILURE: 'COURSE_SCHEDULE_GET_FAILURE',
  COURSE_SCHEDULE_GET_REQUEST: 'COURSE_SCHEDULE_GET_REQUEST',
  COURSE_SCHEDULE_GET_SUCCESS: 'COURSE_SCHEDULE_GET_SUCCESS',
  COURSE_SCHEDULE_UNSET: 'COURSE_SCHEDULE_UNSET',
  COURSE_SET_LAST_PAGE_VISITED: 'COURSE_SET_LAST_PAGE_VISITED',
  COURSE_SETTINGS_GET_FAILURE: 'COURSE_SETTINGS_GET_FAILURE',
  COURSE_SETTINGS_GET_REQUEST: 'COURSE_SETTINGS_GET_REQUEST',
  COURSE_SETTINGS_GET_SUCCESS: 'COURSE_SETTINGS_GET_SUCCESS',
  COURSE_SETTINGS_PATCH_FAILURE: 'COURSE_SETTINGS_PATCH_FAILURE',
  COURSE_SETTINGS_PATCH_REQUEST: 'COURSE_SETTINGS_PATCH_REQUEST',
  COURSE_SETTINGS_PATCH_SUCCESS: 'COURSE_SETTINGS_PATCH_SUCCESS',
  COURSE_SETTINGS_UNSET: 'COURSE_SETTINGS_UNSET',
  COURSE_UNLOAD: 'COURSE_UNLOAD',
  COURSEKEY_GET_FAILURE: 'COURSEKEY_GET_FAILURE',
  COURSEKEY_GET_REQUEST: 'COURSEKEY_GET_REQUEST',
  COURSEKEY_GET_SUCCESS: 'COURSEKEY_GET_SUCCESS',
  COURSES_GET_FAILURE: 'COURSES_GET_FAILURE',
  COURSES_GET_REQUEST: 'COURSES_GET_REQUEST',
  COURSES_GET_SUCCESS: 'COURSES_GET_SUCCESS',
  COURSES_UNSET: 'COURSES_UNSET',
  CREATE_ADOPTION_POST_FAILURE: 'CREATE_ADOPTION_POST_FAILURE',
  CREATE_ADOPTION_POST_REQUEST: 'CREATE_ADOPTION_POST_REQUEST',
  CREATE_ADOPTION_POST_SUCCESS: 'CREATE_ADOPTION_POST_SUCCESS',
  CREATE_ADOPTION_UNSET: 'CREATE_ADOPTION_UNSET',
  CURRENT_LOCATION_SET: 'CURRENT_LOCATION_SET',
  CURRENT_LOCATION_UNSET: 'CURRENT_LOCATION_UNSET',
  CUSTOMER_GET_FAILURE: 'CUSTOMER_GET_FAILURE',
  CUSTOMER_GET_REQUEST: 'CUSTOMER_GET_REQUEST',
  CUSTOMER_GET_SUCCESS: 'CUSTOMER_GET_SUCCESS',
  CUSTOMER_INSTRUCTORS_GET_FAILURE: 'CUSTOMER_INSTRUCTORS_GET_FAILURE',
  CUSTOMER_INSTRUCTORS_GET_REQUEST: 'CUSTOMER_INSTRUCTORS_GET_REQUEST',
  CUSTOMER_INSTRUCTORS_GET_SUCCESS: 'CUSTOMER_INSTRUCTORS_GET_SUCCESS',
  CUSTOMER_INSTRUCTORS_UNSET: 'CUSTOMER_INSTRUCTORS_UNSET',
  CUSTOMER_UNSET: 'CUSTOMER_UNSET',
  ENROLL_POST_FAILURE: 'ENROLL_POST_FAILURE',
  ENROLL_POST_REQUEST: 'ENROLL_POST_REQUEST',
  ENROLL_POST_SUCCESS: 'ENROLL_POST_SUCCESS',
  ENROLLMENTS_GET_FAILURE: 'ENROLLMENTS_GET_FAILURE',
  ENROLLMENTS_GET_REQUEST: 'ENROLLMENTS_GET_REQUEST',
  ENROLLMENTS_GET_SUCCESS: 'ENROLLMENTS_GET_SUCCESS',
  ENROLLMENTS_UNSET: 'ENROLLMENTS_UNSET',
  EXTERNAL_MODULES_UNSET: 'EXTERNAL_MODULES_UNSET',
  EXTERNAL_MODULES_UPDATE: 'EXTERNAL_MODULES_UPDATE',
  LOGIN_POST_FAILURE: 'LOGIN_POST_FAILURE',
  LOGIN_POST_REQUEST: 'LOGIN_POST_REQUEST',
  LOGIN_POST_SUCCESS: 'LOGIN_POST_SUCCESS',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  MODULE_GET_FAILURE: 'MODULE_GET_FAILURE',
  MODULE_GET_REQUEST: 'MODULE_GET_REQUEST',
  MODULE_GET_SUCCESS: 'MODULE_GET_SUCCESS',
  MODULE_UNLOAD: 'MODULE_UNLOAD',
  PROFILE_CHANGE_PASSWORD_FAILURE: 'PROFILE_CHANGE_PASSWORD_FAILURE',
  PROFILE_CHANGE_PASSWORD_REQUEST: 'PROFILE_CHANGE_PASSWORD_REQUEST',
  PROFILE_CHANGE_PASSWORD_SUCCESS: 'PROFILE_CHANGE_PASSWORD_SUCCESS',
  PROFILE_GET_FAILURE: 'PROFILE_GET_FAILURE',
  PROFILE_GET_REQUEST: 'PROFILE_GET_REQUEST',
  PROFILE_GET_SUCCESS: 'PROFILE_GET_SUCCESS',
  PROFILE_UNSET: 'PROFILE_UNSET',
  PROFILE_UPDATE_FAILURE: 'PROFILE_UPDATE_FAILURE',
  PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROTECTED_ROUTER_CURRENT_PATH_SET: 'PROTECTED_ROUTER_CURRENT_PATH_SET',
  RESET_ASSESSMENTS_FAILURE: 'RESET_ASSESSMENTS_FAILURE',
  RESET_ASSESSMENTS_REQUEST: 'RESET_ASSESSMENTS_REQUEST',
  RESET_ASSESSMENTS_SUCCESS: 'RESET_ASSESSMENTS_SUCCESS',
  SCHEDULE_DELETE_FAILURE: 'SCHEDULE_DELETE_FAILURE',
  SCHEDULE_DELETE_REQUEST: 'SCHEDULE_DELETE_REQUEST',
  SCHEDULE_DELETE_SUCCESS: 'SCHEDULE_DELETE_SUCCESS',
  SCHEDULE_GET_FAILURE: 'SCHEDULE_GET_FAILURE',
  SCHEDULE_GET_REQUEST: 'SCHEDULE_GET_REQUEST',
  SCHEDULE_GET_SUCCESS: 'SCHEDULE_GET_SUCCESS',
  SCHEDULE_POST_FAILURE: 'SCHEDULE_POST_FAILURE',
  SCHEDULE_POST_REQUEST: 'SCHEDULE_POST_REQUEST',
  SCHEDULE_POST_SUCCESS: 'SCHEDULE_POST_SUCCESS',
  SCHEDULED_ASSESSMENTS_GET_FAILURE: 'SCHEDULED_ASSESSMENTS_GET_FAILURE',
  SCHEDULED_ASSESSMENTS_GET_REQUEST: 'SCHEDULED_ASSESSMENTS_GET_REQUEST',
  SCHEDULED_ASSESSMENTS_GET_SUCCESS: 'SCHEDULED_ASSESSMENTS_GET_SUCCESS',
  SCHEDULED_ASSESSMENTS_UNSET: 'SCHEDULED_ASSESSMENTS_UNSET',
  SEGMENT_SCHEDULES_PATCH_FAILURE: 'SEGMENT_SCHEDULES_PATCH_FAILURE',
  SEGMENT_SCHEDULES_PATCH_REQUEST: 'SEGMENT_SCHEDULES_PATCH_REQUEST',
  SEGMENT_SCHEDULES_PATCH_SUCCESS: 'SEGMENT_SCHEDULES_PATCH_SUCCESS',
  SET_USER_IS_AUTHENTICATED: 'SET_USER_IS_AUTHENTICATED',
  SIGNUP_FROM_MANAGE_POST_FAILURE: 'SIGNUP_FROM_MANAGE_POST_FAILURE',
  SIGNUP_FROM_MANAGE_POST_REQUEST: 'SIGNUP_FROM_MANAGE_POST_REQUEST',
  SIGNUP_FROM_MANAGE_POST_SUCCESS: 'SIGNUP_FROM_MANAGE_POST_SUCCESS',
  SIGNUP_POST_FAILURE: 'SIGNUP_POST_FAILURE',
  SIGNUP_POST_REQUEST: 'SIGNUP_POST_REQUEST',
  SIGNUP_POST_SUCCESS: 'SIGNUP_POST_SUCCESS',
  TERMS_ACCEPT_POST_FAILURE: 'TERMS_ACCEPT_POST_FAILURE',
  TERMS_ACCEPT_POST_REQUEST: 'TERMS_ACCEPT_POST_REQUEST',
  TERMS_ACCEPT_POST_SUCCESS: 'TERMS_ACCEPT_POST_SUCCESS',
  TERMS_GET_FAILURE: 'TERMS_GET_FAILURE',
  TERMS_GET_REQUEST: 'TERMS_GET_REQUEST',
  TERMS_GET_SUCCESS: 'TERMS_GET_SUCCESS',
  USER_AQ_HAS_ACCESS_TO_CONTENT_FAILURE: 'USER_AQ_HAS_ACCESS_TO_CONTENT_FAILURE',
  USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST: 'USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST',
  USER_AQ_HAS_ACCESS_TO_CONTENT_SUCCESS: 'USER_AQ_HAS_ACCESS_TO_CONTENT_SUCCESS',
  USER_AQ_HAS_ACCESS_TO_CONTENT_UNSET: 'USER_AQ_HAS_ACCESS_TO_CONTENT_UNSET',
  USER_AQ_POST_FAILURE: 'USER_AQ_POST_FAILURE',
  USER_AQ_POST_REQUEST: 'USER_AQ_POST_REQUEST',
  USER_AQ_POST_SUCCESS: 'USER_AQ_POST_SUCCESS',
  USER_AQ_UNSET: 'USER_AQ_UNSET',
  USER_LK_SET: 'USER_LK_SET',
  USER_LK_UNSET: 'USER_LK_UNSET',
  USER_PUBLISHER_BLUEPRINTS_GET_FAILURE: 'USER_PUBLISHER_BLUEPRINTS_GET_FAILURE',
  USER_PUBLISHER_BLUEPRINTS_GET_REQUEST: 'USER_PUBLISHER_BLUEPRINTS_GET_REQUEST',
  USER_PUBLISHER_BLUEPRINTS_GET_SUCCESS: 'USER_PUBLISHER_BLUEPRINTS_GET_SUCCESS',
  USER_PUBLISHER_BLUEPRINTS_UNSET: 'USER_PUBLISHER_BLUEPRINTS_UNSET',
  USER_PUBLISHER_CUSTOMERS_GET_FAILURE: 'USER_PUBLISHER_CUSTOMERS_GET_FAILURE',
  USER_PUBLISHER_CUSTOMERS_GET_REQUEST: 'USER_PUBLISHER_CUSTOMERS_GET_REQUEST',
  USER_PUBLISHER_CUSTOMERS_GET_SUCCESS: 'USER_PUBLISHER_CUSTOMERS_GET_SUCCESS',
  USER_PUBLISHER_CUSTOMERS_UNSET: 'USER_PUBLISHER_CUSTOMERS_UNSET',
}

export default actions
