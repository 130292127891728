import { actions } from 'store/actions'
import { initialState } from 'store'

const courseScheduleReducer = (state = initialState.courseSchedule, action) => {
  switch (action.type) {
    case actions.COURSE_SCHEDULE_GET_SUCCESS:
      return action.payload
    case actions.COURSE_SCHEDULE_UNSET:
      return initialState.courseSchedule
    default:
      return state
  }
}

export default courseScheduleReducer
