import PropTypes from 'prop-types'
import { CoursesType, CourseOverviewType } from 'types'

const StateType = PropTypes.shape({
  api: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.object,
    loaded: PropTypes.object,
    isReauthRequired: PropTypes.bool,
  }),
  course: CourseOverviewType,
  courses: CoursesType,
  // user: UserType,
  currentLocation: PropTypes.shape({
    course: PropTypes.string,
    unit: PropTypes.string,
    module: PropTypes.string,
    page: PropTypes.string,
    viewing: PropTypes.string,
  }),
  assignments: PropTypes.object,
  assignmentsExceptions: PropTypes.object,
  assignmentPurposeTypes: PropTypes.object,
  assignmentTypes: PropTypes.object,
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.bool,
    failedCount: PropTypes.number,
  }),
  authoringUserActions: PropTypes.object,
  authUserAccess: PropTypes.object,
  brandedSigninCustomerData: PropTypes.object,
  cartUrl: PropTypes.object,
  checkUserExist: PropTypes.object,
  configuration: PropTypes.object,
  courseAccessRequirements: PropTypes.object,
  courseMessages: PropTypes.shape({
    course: PropTypes.object, // courseKey,
    message: PropTypes.object,
    shown: PropTypes.bool,
  }),
  courseSettings: PropTypes.object,
  customer: PropTypes.object,
  enrollment: PropTypes.shape({
    courseKeyExist: PropTypes.bool,
  }),
  enrollments: PropTypes.object,
  theme: PropTypes.object,
  userAq: PropTypes.object,
  userAqHasAccess: PropTypes.object,
  userLk: PropTypes.object,
  profile: PropTypes.object,
  terms: PropTypes.object,
  protectedRouterCurrentPath: PropTypes.object,
  schedule: PropTypes.object,
  externalModules: PropTypes.object,
  resetAssessmentsMessage: PropTypes.object,
  courseSchedule: PropTypes.object,
})

export default StateType
