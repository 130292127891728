import { actions } from 'store/actions'
import { initialState } from 'store'

const courseAccessRequirementsReducer = (state = initialState.courseAccessRequirements, action) => {
  switch (action.type) {
    case actions.COURSE_ACCESS_REQUIREMENTS_GET_SUCCESS:
      return action.payload
    case actions.COURSE_ACCESS_REQUIREMENTS_UNSET:
      return initialState.courseAccessRequirements
    default:
      return state
  }
}

export default courseAccessRequirementsReducer
