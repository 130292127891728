import platformApi from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getAuthoringUserActions = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.AUTHORING_USER_ACTIONS_GET_REQUEST,
      actions.AUTHORING_USER_ACTIONS_GET_SUCCESS,
      actions.AUTHORING_USER_ACTIONS_GET_FAILURE
    ],
    fetchParams: {
      url: apiEndpoints().authoringUserActions(courseKey),
      params: { method: 'get', credentials: 'include' }
    }
  })

export const unsetAuthoringUserActions = ({ dispatch }) =>
  dispatch({ type: actions.AUTHORING_USER_ACTIONS_UNSET })
