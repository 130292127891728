import React, { useContext, useEffect } from 'react'
import { apiStatus } from 'store/api'
import { StoreContext } from 'store'
import { actions, getAqUser } from 'store/actions'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const withCurrentUser = (ChildComponent) => {
  const Component = (props) => {
    const { userAq, dispatch } = useContext(StoreContext)

    const userAqStatus = apiStatus({
      action: actions.USER_AQ_POST_REQUEST,
      isEmpty: !userAq,
      isLoaded: userAq,
    })

    useEffect(() => {
      if (userAqStatus.shouldLoad) getAqUser({ dispatch })
    }, [userAqStatus.shouldLoad, dispatch])

    if (userAqStatus.render) return userAqStatus.render

    return <ChildComponent {...props} currentUser={userAq} />
  }
  Component.displayName = `withCurrentUser(${getDisplayName(ChildComponent)})`
  return Component
}

export default withCurrentUser
