import moment from 'moment'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Newrelic from 'utils/newrelic'
import { Button } from '@vitalsource/vstui'
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { getCourseTimeInterval } from 'utils/dates'

import AlertMessage from 'components/AlertMessage'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import CalendarIcon from 'images/calendar.svg'
import CourseKeyIcon from 'images/course-key.svg'
import Footer from 'components/Footer'
import InstructorIcon from 'images/instructor.svg'
import NavMain from 'components/NavMain/NavMain'
import PageHeader from 'components/PageHeader'
import PayCode from 'images/pay-access-code.svg'
import PayCreditCard from 'images/pay-credit-card.svg'
import Spinner from 'components/Spinner'
import {
  actionHasErrors,
  getErrorMessage,
  isActionLoaded,
  isActionLoading,
  isLoading,
} from 'store/selectors'
import {
  actions,
  getCartUrl,
  getCourseAccessRequirements,
  postAuthUserAccess,
  resetAction,
  unsetCourseAccessRequirements,
  unsetCourses,
  unsetUserHasAccessToContent,
  userHasAccessToContent,
} from 'store/actions'
import { apiStatus } from 'store/api'
import { StoreContext } from 'store'

const Payment = ({ theme }) => {
  const {
    api,
    authUserAccess,
    cartUrl,
    courseAccessRequirements,
    dispatch,
    enrollment,
    userAqHasAccess,
  } = useContext(StoreContext)
  const navigate = useNavigate()
  const { courseKey } = useParams()
  const { i18n, t } = useTranslation()

  const [accessCode, setAccessCode] = useState('')
  const [accessCodeError, setAccessCodeError] = useState('')
  const [loadingCartUrl, setLoadingCartUrl] = useState(false)

  const isRedirectingToCourse = useRef(false)

  const authUserAccessActionApiErrors = getErrorMessage(api, 'AUTH_USER_ACCESS_POST')
  const authUserAccessActionHasErrors = actionHasErrors(api, 'AUTH_USER_ACCESS_POST')
  const bpe = buildPagesEndpoints(i18n)
  const color = theme.vstui.color.primary
  const enrollActionHasErrors = actionHasErrors(api, 'ENROLL_POST')
  const enrollActionLoaded = isActionLoaded(api, 'ENROLL_POST')
  const getCartUrlActionHasErrors = actionHasErrors(api, 'CART_URL_GET')
  const getCourseActionHasErrors = actionHasErrors(api, 'COURSE_ACCESS_REQUIREMENTS_GET')
  const getCourseActionLoaded = isActionLoaded(api, 'COURSE_ACCESS_REQUIREMENTS_GET')
  const isApiLoading = isLoading(api)
  const isAuthUserAccessActionLoading = isActionLoading(api, 'AUTH_USER_ACCESS_POST')

  const cardHeadingStyle = {
    color: color.getShade(7),
  }

  const headingStyle = {
    color: color.getShade(10),
  }

  const userAqHasAccessStatus = apiStatus({
    action: actions.USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST,
    isEmpty: userAqHasAccess === null,
    isLoaded: userAqHasAccess !== null,
  })

  const getHandleInputChange = (setValue, setError) => (event) => {
    const {
      target: { name, value },
    } = event
    validateEmptyField(name, value, setError)
    setValue(value)
  }

  // Submit the access code
  const handleSubmitAccessCode = (event) => {
    event.preventDefault()
    const isValidAccessCode = validateEmptyField('accessCode', accessCode, setAccessCodeError)
    if (!isValidAccessCode) {
      return
    }
    // Just for testing, delete this
    if (accessCode === 'acrobatiqtrial') {
      dispatch({
        type: actions.COURSE_MESSAGES_SET,
        payload: { course: courseKey, message: 'This is a test', shown: false },
      })
      const courseUrl = bpe.coursePageUrl({ courseKey: courseKey })
      navigate(courseUrl)
    } else {
      postAuthUserAccess({
        dispatch,
        courseKey: courseKey,
        code: accessCode,
        requestTrial: false,
      })
    }
  }

  const renderFormFeedback = (messages, feedbackFor) =>
    Array.isArray(messages) ? (
      messages.map((message, index) => (
        <FormFeedback key={`form_feedback_${feedbackFor}_${index}`}>{message}</FormFeedback>
      ))
    ) : (
      <FormFeedback>{messages}</FormFeedback>
    )

  const stateCleanup = useCallback(() => {
    unsetCourses({ dispatch })
    unsetCourseAccessRequirements({ dispatch })
    resetAction({ dispatch, action: 'COURSE_ACCESS_REQUIREMENTS_GET' })
    resetAction({ dispatch, action: 'ENROLL_POST' })
    resetAction({ dispatch, action: 'CART_URL_GET' })
    resetAction({ dispatch, action: 'USER_AQ_HAS_ACCESS_TO_CONTENT' })
  }, [dispatch])

  const validateEmptyField = (name, value, setError) => {
    if (!value) {
      const error = t(`courses.empty.${name}`)
      setError(error)
      return false
    }
    setError('')
    return true
  }

  const addCourseUrl = bpe.addCoursePageUrl()
  const coursesUrl = bpe.coursesPageUrl()
  const courseUrl = bpe.coursePageUrl({ courseKey })
  const didEnroll =
    enrollActionLoaded &&
    !enrollActionHasErrors &&
    (enrollment.status === 'Valid' || enrollment.status === 'Trial')
  const didGetCourseAccessRequirements =
    getCourseActionLoaded && !getCourseActionHasErrors && courseAccessRequirements
  const shouldCheckUserHasAccessToContent = userAqHasAccessStatus.shouldLoad
  const shouldRenderStartTrialButton =
    courseAccessRequirements &&
    courseAccessRequirements.trialIsAvailable &&
    !userAqHasAccessStatus.shouldLoad &&
    !(userAqHasAccess && userAqHasAccess.isTrial)
  const userIsEnrolled =
    didEnroll || (didGetCourseAccessRequirements && courseAccessRequirements.enrollmentStatus)
  const userTrialAccessHasEnded =
    courseAccessRequirements &&
    userAqHasAccess &&
    userAqHasAccess.message === 'Your free trial has ended.' // This cannot be i18n'ed

  const courseCustomerData = didGetCourseAccessRequirements
    ? { image: courseAccessRequirements.customerImage, name: null }
    : null

  const isPaymentPageWithNoTrialValidLicense =
    !userAqHasAccessStatus.shouldLoad &&
    userAqHasAccess &&
    userAqHasAccess.status !== 403 &&
    !userAqHasAccess.isTrial

  // if getCourse and userHasAccess fails, course doesnt exists
  const shouldRedirectFromPaymentToAddCourse =
    !isApiLoading &&
    didGetCourseAccessRequirements &&
    !userIsEnrolled &&
    !userAqHasAccessStatus.shouldLoad &&
    userAqHasAccess.status === 403

  // redirect to Courses when course does not exist
  const shouldRedirectFromPaymentToCourses =
    !isApiLoading && getCourseActionLoaded && getCourseActionHasErrors

  const shouldRedirectToCourse =
    !isApiLoading &&
    didGetCourseAccessRequirements &&
    userIsEnrolled &&
    (!courseAccessRequirements.paymentRequired || isPaymentPageWithNoTrialValidLicense)

  const shouldRedirectToCourseAfterCodeRedeem =
    authUserAccess && !isAuthUserAccessActionLoading && !authUserAccessActionHasErrors

  const shouldShowStoreBox =
    courseAccessRequirements &&
    courseAccessRequirements.availablePaymentMethods &&
    courseAccessRequirements.availablePaymentMethods.includes('store')

  const canUseTrial =
    courseAccessRequirements &&
    courseAccessRequirements.availablePaymentMethods &&
    courseAccessRequirements.availablePaymentMethods.includes('trial')

  useEffect(() => {
    if (shouldRedirectFromPaymentToAddCourse) {
      navigate(addCourseUrl, { state: { courseKeyFromPaymentPage: courseKey } })
    }
  }, [addCourseUrl, courseKey, navigate, shouldRedirectFromPaymentToAddCourse, stateCleanup])

  useEffect(() => {
    if (cartUrl) {
      window.location = cartUrl.url
    }
  }, [cartUrl])

  useEffect(() => {
    if (
      (shouldRedirectToCourse || shouldRedirectToCourseAfterCodeRedeem) &&
      !isRedirectingToCourse.current
    ) {
      isRedirectingToCourse.current = true

      shouldRedirectToCourseAfterCodeRedeem &&
        dispatch({
          type: actions.COURSE_MESSAGES_SET,
          payload: {
            course: courseKey,
            message: `${t('courses.enrollSuccess')} ${courseAccessRequirements.title}`,
            shown: false,
          },
        })

      stateCleanup()

      navigate(courseUrl)
    }
  }, [
    courseAccessRequirements,
    courseKey,
    courseUrl,
    didGetCourseAccessRequirements,
    dispatch,
    isApiLoading,
    isPaymentPageWithNoTrialValidLicense,
    navigate,
    shouldRedirectToCourse,
    shouldRedirectToCourseAfterCodeRedeem,
    stateCleanup,
    t,
    userIsEnrolled,
  ])

  useEffect(() => {
    if (!getCourseActionLoaded) {
      getCourseAccessRequirements({ dispatch, courseKey })
    }
  }, [courseKey, dispatch, getCourseActionLoaded])

  useEffect(() => {
    // Check if user has access to content on first mount
    if (shouldCheckUserHasAccessToContent) {
      userHasAccessToContent({ dispatch, courseKey: courseKey })
    }
  }, [courseKey, dispatch, shouldCheckUserHasAccessToContent])

  useEffect(() => {
    if (shouldRedirectFromPaymentToCourses) {
      stateCleanup()
      navigate(coursesUrl)
    }
  }, [coursesUrl, navigate, shouldRedirectFromPaymentToCourses, stateCleanup])

  useEffect(() => {
    return function cleanup() {
      unsetCourses({ dispatch })
      unsetUserHasAccessToContent({ dispatch })
    }
  }, [dispatch])

  function createMarkup(htmlString) {
    return { __html: htmlString }
  }

  // Request a trial
  const onRequestTrial = (event) => {
    event.preventDefault()
    postAuthUserAccess({
      dispatch,
      courseKey: courseKey,
      code: null,
      requestTrial: true,
    })
  }

  // Redirect to VS store
  const purchaseNow = (event) => {
    event.preventDefault()
    setLoadingCartUrl(true)
    const redirectUrl = window.location.origin + bpe.coursePageUrl({ courseKey })
    getCartUrl({ dispatch, courseKey, redirectUrl })
  }

  useEffect(() => {
    Newrelic.setPageViewName('payment')
  }, [])

  if (!didGetCourseAccessRequirements) return <Spinner />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className="px-0 overview"
      >
        <NavMain customerBrandData={courseCustomerData} hideMenuIcon />
        <PageHeader>
          <h1 className="courses-mast__title">
            {courseAccessRequirements
              ? courseAccessRequirements.title
              : t('courses.addCourseMastTitle')}
          </h1>
        </PageHeader>
        <Container className="pb-5">
          <div className="payment">
            {userAqHasAccess && userAqHasAccess.isTrial && (
              <AlertMessage className="payment--alert">
                <strong>{userAqHasAccess.message}</strong>
                {userTrialAccessHasEnded && t('course.choosePayment')}
              </AlertMessage>
            )}

            <div className="course_info">
              {courseAccessRequirements && (
                <ul
                  className="courses-card__info"
                  id={`course-${courseAccessRequirements.courseKey}`}
                >
                  {courseAccessRequirements.instructors &&
                    courseAccessRequirements.instructors.map((instructor) => (
                      <li key={instructor.id}>
                        <div className="courses-card__icon-wrapper courses-card__icon-wrapper__instructor">
                          <img src={InstructorIcon} alt={t('courses.instructorIcon')} />
                        </div>
                        <span className="courses-card__metadata">
                          {instructor.firstName} {instructor.lastName}
                        </span>
                      </li>
                    ))}
                  {(courseAccessRequirements.startDate || courseAccessRequirements.endDate) && (
                    <li>
                      <div className="courses-card__icon-wrapper courses-card__icon-wrapper__calendar">
                        <img src={CalendarIcon} alt={t('courses.calendarIcon')} />
                      </div>
                      <span
                        className="courses-card__metadata"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={createMarkup(
                          getCourseTimeInterval(
                            courseAccessRequirements.startDate,
                            courseAccessRequirements.endDate,
                            false,
                          ),
                        )}
                      />
                    </li>
                  )}
                  <li>
                    <div className="courses-card__icon-wrapper courses-card__icon-wrapper__course-key">
                      <img src={CourseKeyIcon} alt={t('courses.courseKeyIcon')} />
                    </div>
                    <span className="courses-card__metadata">
                      {courseAccessRequirements.courseKey}
                    </span>
                  </li>
                </ul>
              )}
            </div>

            <h2 className="heading" style={headingStyle}>
              {t('courses.howToPay')}
            </h2>
            <Row>
              <Col
                sm="12"
                md={{ size: 6, offset: shouldShowStoreBox ? 0 : 3 }}
                className="payment--column"
              >
                <Card>
                  <CardBody>
                    <img src={PayCode} alt={t('courses.useAccessCode')} className="card-icon" />
                    <CardTitle>
                      <h4 style={cardHeadingStyle}>{t('courses.useAccessCode')}</h4>
                    </CardTitle>

                    {authUserAccessActionHasErrors && (
                      <AlertMessage color="danger">
                        {authUserAccessActionApiErrors.message}
                      </AlertMessage>
                    )}

                    <Form onSubmit={handleSubmitAccessCode}>
                      <FormGroup>
                        <Label for="accessCode">{t('courses.accessCode')}</Label>
                        <Input
                          type="text"
                          name="accessCode"
                          id="accessCode"
                          placeholder="---- ---- ---- ----"
                          value={accessCode}
                          onChange={getHandleInputChange(setAccessCode, setAccessCodeError)}
                          invalid={!!accessCodeError}
                          disabled={isApiLoading}
                        />
                        {renderFormFeedback(accessCodeError, 'accessCodeError')}
                      </FormGroup>
                      <Button
                        color="theme"
                        fullWidth
                        type="submit"
                        disabled={isAuthUserAccessActionLoading}
                      >
                        {isAuthUserAccessActionLoading ? (
                          <Spinner position="left" width={25} height={25} />
                        ) : (
                          t('courses.submit')
                        )}
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              {shouldShowStoreBox && (
                <Col sm="12" md="6" className="payment--column">
                  <Card>
                    <CardBody>
                      <img src={PayCreditCard} alt={t('courses.payWith')} className="card-icon" />
                      <CardTitle>
                        <h4 style={cardHeadingStyle}>{t('courses.payWith')}</h4>
                      </CardTitle>
                      <p className="card-paragraph">{t('courses.visitStore')}</p>

                      {!getCartUrlActionHasErrors && (
                        <Button
                          color="theme"
                          disabled={loadingCartUrl}
                          fullWidth
                          onClick={purchaseNow}
                        >
                          {t('courses.purchaseNow')}
                        </Button>
                      )}
                      {getCartUrlActionHasErrors && (
                        <AlertMessage color="danger">{t('courses.cartError')}</AlertMessage>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )}
              {shouldRenderStartTrialButton && canUseTrial && (
                <Col md="12">
                  <Card className="card--trial">
                    <CardBody>
                      <p>
                        {t('course.freeTrialUntil')}{' '}
                        <time>{moment(courseAccessRequirements.trialEnds).format('l')}</time>.
                        <Button color="theme" onClick={onRequestTrial}>
                          {t('course.startTrialButton')}
                        </Button>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </main>
      <Footer />
    </>
  )
}

Payment.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Payment)
