import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const ProfileNav = () => {
  const { t, i18n } = useTranslation()
  const { changePasswordUrl, profilePageUrl } = buildPagesEndpoints(i18n)

  return (
    <div>
      <Nav vertical>
        <NavItem>
          <NavLink tag="div">
            <Link to={profilePageUrl()}>{t('profile.settings.title')}</Link>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag="div">
            <Link to={changePasswordUrl()}>{t('profile.changePassword.title')}</Link>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

export default ProfileNav
