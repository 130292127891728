import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'
import { Container } from 'reactstrap'
import IframeResizer from 'iframe-resizer-react'

import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import Footer from 'components/Footer'
import PageHeader from 'components/PageHeader'
import CourseSelector from 'components/CourseSelector'
import NavMain from 'components/NavMain'

const StudentWork = () => {
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { courseKey } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  const pageSource = bpe.platformStudentWorkPageUrl({ courseKey }, true)

  const containerRef = useRef(null)
  const ifref = React.createRef()

  const onCourseSelectorChange = ({ courseKey }) => navigate(bpe.studentWorkPageUrl({ courseKey }))

  requestAnimationFrame(() => window.scrollTo(0, 0))

  const iframeResizerOptions = {
    onInit: () => {
      setIsLoading(false)
    },
  }

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
        })}
      >
        <NavMain hideMenuIcon={true} />
        <PageHeader margin={0} fluid={true}>
          <div className="page-header-with-selector">
            <h1 className="courses-mast__title">{t('studentWork.headerTitle')}</h1>
            <CourseSelector
              currentCourseKey={courseKey}
              labelledBy="gradebook-title"
              onChange={onCourseSelectorChange}
              size="large"
            />
          </div>
        </PageHeader>
        <div className="" ref={containerRef}>
          <Container className="external-module-container">
            <div
              className={classnames({
                loading: isLoading,
                'iframe-placeholder': true,
              })}
            >
              <IframeResizer
                forwardRef={ifref}
                src={pageSource}
                className="external-module-iframe"
                allow="microphone *; camera *;"
                allowusermedia="true"
                onInit={iframeResizerOptions.onInit}
                checkOrigin="false"
                heightCalculationMethod="max"
              />
            </div>
          </Container>
        </div>
      </main>
      <Footer />
    </>
  )
}

export default StudentWork
