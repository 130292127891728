import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import GoogleReCAPTCHA from 'react-google-recaptcha'
import { GOOGLE_RECAPTCHA_SITE_KEY } from 'utils/constants'
import { isFunction } from 'utils/functional'

const CAPTCHA_NAME = 'captcha'

const ReCaptcha = ({ onChange, name, shouldResetCaptcha, resetCallback }) => {
  const captchaRef = React.createRef()
  const [captchaElem, setCaptchaElem] = useState(null)

  const getCaptchaEvent = () => {
    const captchaEvent = new window.Event('change', {
      bubbles: true,
      cancelable: true,
    })

    captchaEvent.persist = () => {}

    return captchaEvent
  }

  const handleChange = (value) => {
    if (!captchaElem) {
      return
    }

    const captchaEvent = getCaptchaEvent()

    captchaElem.value = value
    captchaElem.dispatchEvent(captchaEvent)
  }

  const handleChangeCb = useCallback(handleChange, [captchaElem])

  useEffect(() => {
    if (captchaElem) {
      return
    }

    const $captcha = document.getElementById('captcha-wrapper')
    $captcha.addEventListener('change', onChange)

    setCaptchaElem($captcha)
  }, [captchaElem, onChange])

  useEffect(() => {
    if (shouldResetCaptcha) {
      captchaRef.current.reset()
      handleChangeCb('')

      if (isFunction) {
        resetCallback()
      }
    }
  }, [shouldResetCaptcha, captchaRef, resetCallback, handleChangeCb])

  return (
    <div id="captcha-wrapper" data-field-name={name || CAPTCHA_NAME}>
      <GoogleReCAPTCHA
        sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
        onChange={handleChange}
        ref={captchaRef}
      />
    </div>
  )
}

ReCaptcha.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  resetCallback: PropTypes.func,
  shouldResetCaptcha: PropTypes.bool,
}

export default ReCaptcha
