import { actions } from 'store/actions'
import { initialState } from 'store'

const termsReducer = (state = initialState.terms, action) => {
  switch (action.type) {
    case actions.TERMS_ACCEPT_POST_SUCCESS:
      return {
        ...state,
        userAccepted: true
      }
    case actions.TERMS_GET_SUCCESS:
      return action.payload.tosData
    default:
      return state
  }
}

export default termsReducer
