import React from 'react'
import PropTypes from 'prop-types'
import { Card as CardComponent, CardBody } from 'reactstrap'

const Card = ({ children, title }) => {
  return (
    <CardComponent className="sidebar-layout__card">
      <h1> {title} </h1>
      <CardBody>{children}</CardBody>
    </CardComponent>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default Card
