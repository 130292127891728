import cloneDeep from 'lodash/cloneDeep'
import mergeWith from 'lodash/mergeWith'
import { actions } from 'store/actions'
import { initialState } from 'store'

const mergeTocAndScheduleData = (course, courseSchedule) => {
  if (!courseSchedule) {
    return course
  }

  let courseDeepCopy = cloneDeep(course)

  for (const unit of courseDeepCopy.syllabusUnitsMap) {
    const unitSchedule = findSchedule(courseSchedule, unit.unitId)
    for (const module of unit.modules) {
      const moduleSchedule = findSchedule(courseSchedule, module.moduleId) || unitSchedule
      setAssessmentsSchedule(moduleSchedule, courseSchedule, module.assessments)
      for (const page of module.pages) {
        const pageSchedule = moduleSchedule
        setAssessmentsSchedule(pageSchedule, courseSchedule, page.assessments)
        page.schedule = pageSchedule
      }
      module.schedule = moduleSchedule
    }
    unit.schedule = unitSchedule
  }

  return courseDeepCopy
}

const setAssessmentsSchedule = (parentSchedule, courseSchedule, assessments = []) => {
  for (const assessment of assessments) {
    const assessmentSchedule = findSchedule(courseSchedule, assessment.assessmentId)
    if (assessmentSchedule) {
      assessment.schedule = mergeWith(
        Object.assign({}, parentSchedule),
        assessmentSchedule,
        (objValue, srcValue) => (srcValue ? srcValue : objValue)
      )
    } else {
      assessment.schedule = parentSchedule
    }
  }
}

const findSchedule = (courseSchedule, targetId) => {
  return courseSchedule.find(({ objectId }) => objectId === targetId)
}

const courseReducer = (state = initialState.course, action, dependencies) => {
  switch (action.type) {
    case actions.COURSE_GET_SUCCESS: {
      // As TOC (course navigation) endpoint is already heavy and slow, we
      // decided to create a separate endpoint for scheduling data, and merge it
      // with TOC data client side
      const tocWithScheduleData = mergeTocAndScheduleData(
        action.payload,
        dependencies?.courseSchedule
      )

      // create a stack of pages and external modules so we can do page index lookups
      const syllabusUnitsMap = tocWithScheduleData.syllabusUnitsMap
      const mod_page_stack = []
      // We need to skip unavailable and not-yet-available pages in page
      // navigation for students, so we keep a separate stack of available pages
      // (and external modules) only
      const available_pages_stack = []
      let scheduled_assessments_stack = []
      for (const unit of syllabusUnitsMap) {
        for (const module of unit.modules) {
          for (const page of module.pages) {
            mod_page_stack.push(page)
            if (!page.schedule || page.schedule.studentAvailability) {
              available_pages_stack.push(page)
            }
          }
          if (module.isExternal) {
            mod_page_stack.push(module)
            available_pages_stack.push(module)
            if (module?.schedule?.availableDate || module?.schedule?.dueDate) {
              scheduled_assessments_stack.push(module)
            }
          } else {
            scheduled_assessments_stack = [
              ...scheduled_assessments_stack,
              ...module.assessments.filter(
                (assessment) => assessment?.schedule?.availableDate || assessment?.schedule?.dueDate
              ),
            ]
          }
        }
      }

      return {
        ...tocWithScheduleData,
        pages: mod_page_stack,
        availablePages: available_pages_stack,
        scheduledAssessments: scheduled_assessments_stack,
      }
    }
    case actions.COURSE_UNLOAD:
      return null
    case actions.COURSE_SET_LAST_PAGE_VISITED:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          lastPageVisited: action.payload,
        },
      }
    case actions.COURSE_GET_FAILURE:
      return action.payload
    default:
      return state
  }
}

export default courseReducer
