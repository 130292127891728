import { useContext, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { isActionLoaded } from 'store/selectors'
import { getAssignmentById, getAssignmentsExceptions, getEnrollments } from 'store/actions'
import { StoreContext } from 'store'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { setSelection } from 'pages/Settings/store/actions'

const useSelectedAssignmentData = ({ assignments, courseKey }) => {
  const { api, dispatch } = useContext(StoreContext)
  const { dispatchSettingsAction } = useSettingsContext()
  const requestedAssignmentData = useRef(false)
  const callbackRef = useRef()

  const isAssignmentSettingsDataLoaded = isActionLoaded(api, 'ASSIGNMENTS_GET')

  const setAssignmentSelection = useCallback(
    (selection) => setSelection({ dispatchSettingsAction, selection }),
    [dispatchSettingsAction]
  )

  const getAssignmentDataFromStore = (identifier) =>
    assignments?.find((a) => a.identifier === identifier)

  const getSelectedAssignmentSettingsData = ({ id, identifier, callback }) => {
    callbackRef.current = callback
    const assignmentInStore = getAssignmentDataFromStore(identifier)
    if (assignmentInStore) {
      setAssignmentSelection([assignmentInStore])
      callback()
    } else {
      requestedAssignmentData.current = true
      getAssignmentById({
        dispatch,
        courseKey,
        id,
      })
    }
  }

  const getSelectedAssignmentExceptionsData = async ({ id, identifier, callback }) => {
    await getEnrollments({
      dispatch,
      courseKey,
      returnRejectedPromise: true,
    }).catch(() => callback())

    await getAssignmentsExceptions({
      dispatch,
      courseKey,
      returnRejectedPromise: true,
    }).catch(() => callback())

    await getSelectedAssignmentSettingsData({
      id,
      identifier,
      callback,
    })
  }

  useEffect(() => {
    if (isAssignmentSettingsDataLoaded && assignments && requestedAssignmentData.current) {
      requestedAssignmentData.current = false
      setAssignmentSelection(assignments)
      callbackRef?.current()
    }
  }, [assignments, callbackRef, isAssignmentSettingsDataLoaded, setAssignmentSelection])

  return [getSelectedAssignmentSettingsData, getSelectedAssignmentExceptionsData]
}

useSelectedAssignmentData.propTypes = {
  courseKey: PropTypes.string,
}

export default useSelectedAssignmentData
