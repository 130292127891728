import { actions } from 'store/actions'
import { initialState } from 'store'

const { AUTH_USER_ACCESS_POST_SUCCESS } = actions

const authUserAccessReducer = (state = initialState.authUserAccess, action) => {
  switch (action.type) {
    case AUTH_USER_ACCESS_POST_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default authUserAccessReducer
