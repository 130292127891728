import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChangePasswordForm } from 'components/Profile'
import Profile from 'pages/Profile'

const ChangePassword = () => {
  const { t } = useTranslation()

  return (
    <Profile cardTitle={t('profile.changePassword.title')}>
      <ChangePasswordForm />
    </Profile>
  )
}

export default ChangePassword
