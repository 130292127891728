const clickCellButtonOnkeyPress = (params, options = { key: 'Enter' }) => {
  if (params.event.key === options.key) {
    const btn = params.event.target.getElementsByTagName('button')[0]
    if (btn) {
      btn.click()
    }
    return true
  }
  return false
}

const clickCellAnchorOnkeyPress = (params, options = { key: 'Enter' }) => {
  if (params.event.key === options.key) {
    const a = params.event.target.getElementsByTagName('a')[0]
    if (a) {
      a.click()
    }
    return true
  }
  return false
}

export { clickCellAnchorOnkeyPress, clickCellButtonOnkeyPress }
