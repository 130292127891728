import { actions } from 'store/actions'
import { initialState } from 'store'
import { DEFAULT_THEME } from 'utils/constants'

const themeReducer = (state = initialState.theme, action) => {
  switch (action.type) {
    // Checking COURSE_GET_SUCCESS because themes are loaded within the course
    case actions.COURSE_GET_SUCCESS:
      return action.payload.theme || DEFAULT_THEME
    case actions.COURSE_ACCESS_REQUIREMENTS_GET_SUCCESS:
      return action.payload.theme || DEFAULT_THEME
    case actions.BRANDED_SIGNIN_CUSTOMER_DATA_SET:
      return action.payload.customerTheme || DEFAULT_THEME
    case actions.COURSE_UNLOAD:
      return DEFAULT_THEME
    default:
      return state || DEFAULT_THEME
  }
}

export default themeReducer
