import React from 'react'
import { ASSIGNMENT_LIST_ITEM_TYPES } from 'utils/constants'

const nameCellRenderer = (props) => {
  const invokeParentMethod = (event) => {
    // props.context.onTitleClick(`Row: ${props.node.rowIndex}, Col: ${props.colDef.field}`)
    event.preventDefault()
    event.stopPropagation()
    props.context.onTitleClick(props.data)
    props.api.deselectAll()
  }

  const resolveRender = () => {
    const assignmentType = props.data.type.toLowerCase()
    let render = (
      <div className={`assignment-table ${assignmentType}`}>
        <span>{props.value}</span>
      </div>
    )

    if (props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment) {
      render = (
        <button className="assignment-table__cel-link" onClick={invokeParentMethod} aria-haspopup>
          <span>{props.value}</span>
        </button>
      )
    }
    return render
  }

  return resolveRender()
}

export default nameCellRenderer
