import styled from 'styled-components'

export const CoursesCardWrapper = styled.div`
  :not(.pending-course) {
    box-shadow:
      0 -3px 0 0 ${(props) => props.customerColor},
      0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
    :hover {
      box-shadow: 0 0 0 3px ${(props) => props.customerColorLight} !important;
    }
  }
`
