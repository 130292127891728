/* eslint-disable react/no-multi-comp */
import React, { memo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import Select from '../FormComponents/Select'
import DatePicker from '../FormComponents/DatePicker'
import TimePicker from '../FormComponents/TimePicker'
import {
  DATE_AND_TIME_FIELD_MODES,
  DATE_AND_TIME_FIELD_TYPES,
  STUDENT_AVAILABILITY_CHECK_FORM_FIELDS,
} from '../formDefinitions'
import {
  areISODateStringsEqual,
  fetchDateAndTimeFieldSetup,
  isDateTimeFieldValid,
} from '../smartFormHelpers'
import { useTranslation } from 'react-i18next'

const DateAndTimeField = ({
  theme,
  timezone,
  selectOptions: selectOptsFromProps,
  mode,
  multipleValuesMap,
  showEmptySelectLabel,
  defaultValues,
}) => {
  const { t } = useTranslation()
  const { initialValues, values } = useFormikContext()
  const [disableDateInput, setDisableDateInput] = useState(false)
  const [hideDateInput, setHideDateInput] = useState(false)
  const { formFields, selectValues, multipleValuesOption } = fetchDateAndTimeFieldSetup(mode)

  const handleInputState = (selectValue) => {
    if (selectValue === selectValues.definedDate) {
      setDisableDateInput(false)
    } else {
      setDisableDateInput(true)
    }
  }

  const dateTimeSelectOptions = {
    mode,
    dateField: formFields.date.name,
    timeField: formFields.time.name,
  }

  const isFieldEdited = useCallback(
    (field) => {
      if (!defaultValues) return false
      const fieldName = `${DATE_AND_TIME_FIELD_MODES[mode]}${DATE_AND_TIME_FIELD_TYPES[field]}`
      if (isDateTimeFieldValid(values[fieldName])) {
        return !areISODateStringsEqual(values[fieldName].toISOString(), defaultValues[fieldName], {
          compare: field, // 'date' or 'time'
        })
      } else {
        return values[fieldName] !== defaultValues[fieldName]
      }
    },
    [mode, values, defaultValues],
  )

  useEffect(() => {
    handleInputState(initialValues[formFields.select.name])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useEffect(() => {
    if (
      STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name in values &&
      values[STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name] === false &&
      !hideDateInput
    ) {
      setHideDateInput(true)
    }
    if (
      STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name in values &&
      values[STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name] === true &&
      hideDateInput
    ) {
      setHideDateInput(false)
    }
  }, [values, hideDateInput])

  const resolveSelectOptions = useCallback(() => {
    const localizedOptions = formFields.select.options.map((opt) => ({
      value: opt.value,
      label: t(opt.i18n),
    }))
    let selectFieldOptions = selectOptsFromProps || localizedOptions

    if (multipleValuesMap[mode] === true) {
      if (!selectFieldOptions.some((option) => option.value === multipleValuesOption.value)) {
        selectFieldOptions.push({
          label: t(multipleValuesOption.i18n),
          value: multipleValuesOption.value,
        })
      }
    } else if (showEmptySelectLabel) {
      if (!selectFieldOptions.some((option) => option.value === '')) {
        selectFieldOptions.push({
          label: '',
          value: '',
        })
      }
    } else {
      selectFieldOptions = selectFieldOptions.filter(
        (option) => option.value !== multipleValuesOption.value,
      )
    }
    return selectFieldOptions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, formFields, multipleValuesOption, multipleValuesMap, t])

  const selectFieldOptions = resolveSelectOptions()

  const onSelectChange = (option) => {
    handleInputState(option.value)
  }

  const fetchFieldClasses = () => {
    let classes = 'form-module date-time'
    if (hideDateInput) {
      classes = `${classes} hide`
    }
    return classes
  }

  return (
    <div className={fetchFieldClasses()}>
      <Select
        name={formFields.select.name}
        label={t(`${formFields.select.i18n}`)}
        options={selectFieldOptions}
        theme={theme}
        timezone={timezone}
        onChange={onSelectChange}
        dateTimeSelectOptions={dateTimeSelectOptions}
        editedField={isFieldEdited('select')}
      />
      {!disableDateInput && (
        <div className="pickers">
          <DatePicker
            name={formFields.date.name}
            label={t(`${formFields.date.i18n}`)}
            format={t('settingsForm.dateFormat') || 'MM/DD/YYYY'}
          />
          <TimePicker
            name={formFields.time.name}
            label={t(`${formFields.time.i18n}`)}
            format={t('settingsForm.timeFormat') || 'hh:mm A'}
          />
        </div>
      )}
    </div>
  )
}

const BaseDateAndTimeField = memo(DateAndTimeField)

const StartDateAndTimeField = ({
  theme,
  timezone,
  multipleValuesMap,
  showEmptySelectLabel,
  defaultValues,
}) => (
  <BaseDateAndTimeField
    mode={DATE_AND_TIME_FIELD_MODES.availableDate}
    theme={theme}
    timezone={timezone}
    multipleValuesMap={multipleValuesMap}
    showEmptySelectLabel={showEmptySelectLabel}
    defaultValues={defaultValues}
  />
)

StartDateAndTimeField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * select options
   */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  mode: PropTypes.oneOf([
    DATE_AND_TIME_FIELD_MODES.availableDate,
    DATE_AND_TIME_FIELD_MODES.dueDate,
  ]),
  timezone: PropTypes.string,
  showEmptySelectLabel: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  multipleValuesMap: PropTypes.shape({}),
}

const DueDateAndTimeField = ({
  theme,
  timezone,
  multipleValuesMap,
  showEmptySelectLabel,
  defaultValues,
}) => (
  <BaseDateAndTimeField
    mode={DATE_AND_TIME_FIELD_MODES.dueDate}
    theme={theme}
    timezone={timezone}
    multipleValuesMap={multipleValuesMap}
    showEmptySelectLabel={showEmptySelectLabel}
    defaultValues={defaultValues}
  />
)

DueDateAndTimeField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * select options
   */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  mode: PropTypes.oneOf([
    DATE_AND_TIME_FIELD_MODES.availableDate,
    DATE_AND_TIME_FIELD_MODES.dueDate,
  ]),
  timezone: PropTypes.string,
  showEmptySelectLabel: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  multipleValuesMap: PropTypes.shape({}),
}

export { StartDateAndTimeField, DueDateAndTimeField }

DateAndTimeField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * select options
   */
  selectOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  mode: PropTypes.oneOf([
    DATE_AND_TIME_FIELD_MODES.availableDate,
    DATE_AND_TIME_FIELD_MODES.dueDate,
  ]),
  timezone: PropTypes.string,
  showEmptySelectLabel: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  multipleValuesMap: PropTypes.shape({}),
}
