import platformApi from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getCustomer = ({ dispatch, customerSlug }) =>
  platformApi({
    dispatch,
    types: [
      actions.CUSTOMER_GET_REQUEST,
      actions.CUSTOMER_GET_SUCCESS,
      actions.CUSTOMER_GET_FAILURE
    ],
    fetchParams: {
      url: apiEndpoints().customer(customerSlug),
      params: { method: 'get', credentials: 'include' }
    }
  })

export const unsetCustomer = ({ dispatch }) => dispatch({ type: actions.CUSTOMER_UNSET })
