export const API_POLLING_STATUS = {
  notStarted: 'not-started',
  running: 'running',
  resolved: 'resolved',
  error: 'error',
}

export const API_POLLING_RESPONSES = {
  created: 'Created',
  queued: 'Queued',
  building: 'Building',
  valid: 'Valid',
}

export const extractPollingKey = (action) => {
  if (action?.payload?.key) {
    return action?.payload?.key
  }
  //eslint-disable-next-line
  console.error('createPollingReducer.js - Missing polling key.')
  return null
}

export const getNewPoll = ({
  pollingRequest,
  acceptCondition,
  onSuccess,
  onFailure,
  key,
  options = { rate: 5 },
}) => ({
  key,
  state: {
    error: null,
    status: API_POLLING_STATUS.notStarted,
  },
  definition: {
    rate: options.rate, // seconds
    pollingRequest,
    acceptCondition,
    onSuccess,
    onFailure,
  },
  nextCall: null,
})

export const pollingMethod = (poll) => {
  return async () => {
    let response = null
    try {
      response = await poll.definition.pollingRequest()
      if (poll.definition.acceptCondition(response)) {
        poll.definition.onSuccess(response)
      }
    } catch (e) {
      poll.definition.onFailure(e)
    }
  }
}
