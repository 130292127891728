import { actions } from 'store/actions'
import { initialState } from 'store'

const brandedSigninCustomerDataReducer = (
  state = initialState.brandedSigninCustomerData,
  action
) => {
  switch (action.type) {
    case actions.BRANDED_SIGNIN_CUSTOMER_DATA_SET:
      return action.payload
    case actions.BRANDED_SIGNIN_CUSTOMER_DATA_UNSET:
      return initialState.brandedSigninCustomerData
    default:
      return state
  }
}

export default brandedSigninCustomerDataReducer
