import React, { useContext, useEffect, useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import IframeResizer from 'iframe-resizer-react'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import Newrelic from 'utils/newrelic'
import classnames from 'classnames'
import Footer from 'components/Footer'
import {
  actionHasErrors,
  getAssignmentData,
  getIsInstructorOrAdmin,
  isActionLoaded,
  isActionLoading,
} from 'store/selectors'
import {
  actions,
  getAuthoringUserActions,
  getConfig,
  resetAction,
  unsetAssignments,
  unsetAssignmentsExceptions,
} from 'store/actions'
import { handleLinksToBookshelf } from 'utils/learnKit'
import TrialAlert from 'components/TrialAlert'
import { apiStatus } from 'store/api'
import AuthorToolbar from 'components/AuthorToolbar'
import { useIframeResizerMessages, useSelectedAssignmentData } from 'utils/customHooks'
import Spinner from 'components/Spinner'
import { ImageModal } from 'components'
import { PageLocationBarWithTheme } from 'components/PageElements/PageElements'
import { DEFAULT_DRAWER_ID, EXCEPTIONS_DRAWER_ID, PLATFORM_HOST } from 'utils/constants'
import { StoreContext } from 'store'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { isEmptyObject } from 'utils/functional'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import ContentNotAvailable from 'components/ContentNotAvailable'
import SettingsDrawer from 'pages/Settings/SettingsDrawer'
import Exceptions from 'components/Forms/Exceptions'
import SmartForm from 'components/Forms/SmartForm'
import { withAssignmentsSettingsContext } from 'hoc'
import {
  closeDefaultSettingsDrawer,
  openDefaultSettingsDrawer,
  openExceptionsSettingsDrawer,
  unsetAssignmentSelection,
} from 'pages/Settings/store/actions'

const Assignment = () => {
  const {
    api,
    assignments,
    authoringUserActions,
    course,
    dispatch,
    userAq,
    userLk,
    configuration,
  } = useContext(StoreContext)
  const { assignmentId, assignmentType, courseKey } = useParams()
  const navigate = useNavigate()
  const [assignmentUrl, setAssignmentUrl] = useState(null)
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)
  const [assignmentData, setAssignmentData] = useState(null)
  const [imageData, setImageData] = useState({})
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingInstructorSettings, setIsLoadingInstructorSettings] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const containerRef = useRef(null)

  const pageSourceHost = PLATFORM_HOST

  // click handler for links to Bookshelf
  const linksToBookshelfCallback = (linkHref) => {
    handleLinksToBookshelf({
      linkHref,
      isAuthenticating,
      setIsAuthenticating,
      userAq,
      userLk,
      dispatch,
      courseKey,
    })
  }

  const iframeResizerOptions = {
    checkOrigin: [PLATFORM_HOST],
    onInit: () => {
      setIsLoading(false)
    },
    onMessage: (msg) => {
      if (!msg.message) {
        return false
      }
      switch (msg.message.type) {
        case 'bookshelfLinkClicked': {
          const bookshelfUrl = msg.message.bookshelfUrl
          linksToBookshelfCallback(bookshelfUrl)
          break
        }
        case 'imageClicked': {
          const { imageAlt, imageSrc } = msg.message
          setImageData({
            alt: imageAlt,
            src: `${pageSourceHost}${imageSrc}`,
          })
          setModalOpen(true)
          break
        }
        case 'homepageLinkClicked': {
          const homeLink = bpe.coursePageUrl({ courseKey })
          navigate(homeLink)
          break
        }
        case 'backToCiteLinkClicked': {
          const offset = msg.message.offset
          const scrollToPosition = offset + containerRef.current.offsetTop
          window.scrollTo(0, scrollToPosition)
          break
        }
        case 'submitAssessmentButtonClicked': {
          window.scrollTo(0, 0)
          break
        }
        case 'instructorSettingsButtonClicked': {
          const { assessmentId, assessmentIdentifier } = msg.message
          if (isInstructorOrAdmin) {
            setIsLoadingInstructorSettings(true)
            getSelectedAssignmentSettingsData({
              id: assessmentId,
              identifier: assessmentIdentifier,
              callback: () => {
                setIsLoadingInstructorSettings(false)
                openDefaultSettingsDrawer({ dispatchSettingsAction })
              },
            })
          }
          break
        }
        case 'instructorSettingsExceptionsButtonClicked': {
          const { assessmentId, assessmentIdentifier } = msg.message
          if (isInstructorOrAdmin) {
            setIsLoadingInstructorSettings(true)
            getSelectedAssignmentExceptionsData({
              id: assessmentId,
              identifier: assessmentIdentifier,
              callback: () => {
                setIsLoadingInstructorSettings(false)
                openExceptionsSettingsDrawer({ dispatchSettingsAction })
              },
            })
          }
          break
        }
        default:
          return false
      }
    },
  }

  const getPagePosition = (module, page) => {
    const pageRange = module.pageRange
    if (pageRange[1] === null) return 1
    return (page.pageNumber - pageRange[0]) / (pageRange[1] - pageRange[0])
  }

  const authoringUserActionsStatus = apiStatus({
    action: actions.AUTHORING_USER_ACTIONS_GET_REQUEST,
    isLoaded: authoringUserActions !== null,
  })

  const configurationStatus = apiStatus({
    action: actions.CONFIGURATION_GET_REQUEST,
    isEmpty: isEmptyObject(configuration) || !configuration.serverMode,
    isLoaded: !isEmptyObject(configuration) && configuration.serverMode,
  })

  const isConfigurationLoading = isActionLoading(api, actions.CONFIGURATION_GET_REQUEST)

  const shouldShowAuthoringTools =
    authoringUserActions &&
    (authoringUserActions.userCanComment ||
      authoringUserActions.userCanEditPage ||
      authoringUserActions.showAnswerKey)

  const iframeRef = useRef(null)

  const authoringMode = configuration && configuration.serverMode === 'authoring'

  const { sendEditRequestToChild, sendToggleAnswerKey, sendToggleComments, sendFocusBack } =
    useIframeResizerMessages(iframeRef)

  // Instructor settings
  const [getSelectedAssignmentSettingsData, getSelectedAssignmentExceptionsData] =
    useSelectedAssignmentData({
      assignments,
      courseKey,
    })
  const { dispatchSettingsAction, isSettingsDrawerOpen } = useSettingsContext()

  const assignmentEditSubmitted = isActionLoaded(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditHasErrors = actionHasErrors(api, 'ASSIGNMENTS_SCHEDULES_PATCH')
  const assignmentEditSucceeded = assignmentEditSubmitted && !assignmentEditHasErrors

  const loadedAssignmentDataSuccessfully = assignmentUrl && assignmentData?.assignment

  // Parent module available date
  const parentModuleAvailableDate =
    assignmentData?.assignmentModule?.schedule?.availableDate &&
    moment.tz(assignmentData?.assignmentModule.schedule.availableDate, userAq.time_zone)
  const parentModuleHasAvailableDateInFuture =
    parentModuleAvailableDate &&
    moment().tz(userAq.time_zone).isSameOrBefore(parentModuleAvailableDate)

  // Assignment available date
  const assignmentAvailableDate =
    assignmentData?.assignment?.schedule?.availableDate &&
    moment.tz(assignmentData?.assignment.schedule.availableDate, userAq.time_zone)
  const assignmentHasAvailableDateInFuture =
    assignmentAvailableDate && moment().tz(userAq.time_zone).isSameOrBefore(assignmentAvailableDate)

  // If parent module has available date in future, it prevails over assignment available date
  const applicableDate = parentModuleHasAvailableDateInFuture
    ? parentModuleAvailableDate
    : assignmentAvailableDate

  const formattedContentAvailableDate =
    !!applicableDate && applicableDate.format(t('toc.contentAvailabilityStatementDateFormat'))

  const shouldShowContentNotAvailableMessage =
    !isInstructorOrAdmin &&
    loadedAssignmentDataSuccessfully &&
    assignmentData.assignment.schedule &&
    !assignmentData.assignment.schedule.studentAvailability
  const shouldShowContentAvailabilityMessage =
    !isInstructorOrAdmin &&
    loadedAssignmentDataSuccessfully &&
    assignmentData?.assignment?.schedule?.studentAvailability &&
    (parentModuleHasAvailableDateInFuture || assignmentHasAvailableDateInFuture)
  const shouldShowContent =
    loadedAssignmentDataSuccessfully &&
    !shouldShowContentNotAvailableMessage &&
    !shouldShowContentAvailabilityMessage
  const unavailableContentLabel =
    loadedAssignmentDataSuccessfully && assignmentData?.assignment?.title

  useEffect(() => {
    if (assignmentEditSucceeded) {
      iframeRef?.current?.sendMessage({ type: 'RELOAD' })
      unsetAssignments({ dispatch })
      resetAction({ dispatch, action: 'ASSIGNMENTS_GET' })
      resetAction({ dispatch, action: 'ASSIGNMENTS_SCHEDULES_PATCH' })
      unsetAssignmentSelection({ dispatchSettingsAction })
    }
  }, [assignmentEditSucceeded, dispatch, dispatchSettingsAction])

  useEffect(() => {
    return function cleanup() {
      unsetAssignments({ dispatch })
      unsetAssignmentsExceptions({ dispatch })
      resetAction({ dispatch, action: 'ASSIGNMENTS_EXCEPTIONS_GET' })
      resetAction({ dispatch, action: 'ASSIGNMENTS_GET' })
      resetAction({ dispatch, action: 'ASSIGNMENTS_SCHEDULES_PATCH' })
      unsetAssignmentSelection({ dispatchSettingsAction })
      closeDefaultSettingsDrawer({ dispatchSettingsAction })
    }
  }, [dispatch, dispatchSettingsAction])
  // Instructor settings ^^^

  useEffect(() => {
    setAssignmentData(getAssignmentData(course, assignmentType, assignmentId))
    // Update currentLocation.assignment every time assignmentId changes
    dispatch({
      type: actions.CURRENT_LOCATION_SET,
      payload: { assignment: assignmentId, viewing: 'assignment' },
    })
    // Unset currentLocation.assignment on unmount
    return () =>
      dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { assignment: null, viewing: null } })
  }, [assignmentId, assignmentType, course, dispatch])

  useEffect(() => {
    if (assignmentData && assignmentData.assignment) {
      setAssignmentUrl(assignmentData.assignment.startUrl)
      document.title = assignmentData.assignment.title
    }
  }, [assignmentData])

  useEffect(() => {
    if (configurationStatus.shouldLoad && !isConfigurationLoading) {
      getConfig({ dispatch })
    }
  }, [configurationStatus.shouldLoad, isConfigurationLoading, dispatch])

  useEffect(() => {
    if (authoringMode && authoringUserActionsStatus.shouldLoad)
      getAuthoringUserActions({ dispatch, courseKey })
  }, [authoringUserActionsStatus.shouldLoad, dispatch, courseKey, authoringMode])

  useEffect(() => {
    Newrelic.setPageViewName('course/assignment')
  }, [])

  useEffect(() => {
    Newrelic.setCustomAttribute('assignment_id', assignmentId)
  }, [assignmentId])

  const modalBody = !!imageData.src && <img alt={imageData.alt || ''} src={imageData.src} />

  const onImageModalClose = () => {
    sendFocusBack()
  }

  return (
    <>
      <ImageModal
        modalBody={modalBody}
        open={modalOpen}
        toggle={() => setModalOpen(false)}
        onClose={onImageModalClose}
      />
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        {(isAuthenticating || !assignmentUrl || !assignmentData || isLoadingInstructorSettings) && (
          <Spinner />
        )}
        {assignmentUrl && assignmentData && (
          <>
            <PageLocationBarWithTheme
              module={assignmentData.assignmentModule}
              unit={assignmentData.assignmentUnit}
              pageTitle={assignmentData.assignment.title}
              pagePosition={getPagePosition(
                assignmentData.assignmentModule,
                assignmentData.assignmentPage,
              )}
              withNavigation={false}
              courseKey={courseKey}
              lang={i18n.language}
              showBackToGradebookButton={userAq.is_impersonate_or_review}
            />
            <div className="workbook-page-reference-container" ref={containerRef}>
              <Container className="workbook-page-container">
                {shouldShowContent && (
                  <div
                    className={classnames({
                      loading: isLoading,
                      'iframe-placeholder': true,
                    })}
                  >
                    <Spinner />

                    <TrialAlert courseKey={courseKey} />

                    <IframeResizer
                      forwardRef={iframeRef}
                      src={assignmentUrl}
                      className="workbook-page-iframe"
                      allow="microphone *; camera *;"
                      allowusermedia="true"
                      onMessage={iframeResizerOptions.onMessage}
                      onInit={iframeResizerOptions.onInit}
                      checkOrigin={iframeResizerOptions.checkOrigin}
                    />
                    {shouldShowAuthoringTools && (
                      <AuthorToolbar
                        showEditButton={false}
                        showCommentsButton={authoringUserActions.userCanComment}
                        showAnswerKey={authoringUserActions.showAnswerKey}
                        sendEditRequest={sendEditRequestToChild}
                        sendToggleAnswerKey={sendToggleAnswerKey}
                        sendToggleComments={sendToggleComments}
                      />
                    )}
                  </div>
                )}
                {shouldShowContentNotAvailableMessage && (
                  <ContentNotAvailable
                    buttonLabel={t('page.jumpToNextAvailablePage')}
                    contentIsPage
                    contentLabel={unavailableContentLabel}
                    handleButtonOnClick={() => {}}
                  />
                )}
                {shouldShowContentAvailabilityMessage && (
                  <ContentNotAvailable
                    buttonLabel={t('page.jumpToNextAvailablePage')}
                    contentIsPage
                    contentLabel={unavailableContentLabel}
                    formattedDate={formattedContentAvailableDate}
                    handleButtonOnClick={() => {}}
                    willBeAvailable
                  />
                )}
              </Container>
            </div>
            <SettingsDrawer
              title={t('settingsDrawer.settingsTitle')}
              open={isSettingsDrawerOpen[DEFAULT_DRAWER_ID]}
              drawerId={DEFAULT_DRAWER_ID}
            >
              <SmartForm courseKey={courseKey} isAssignmentSplashPage={true} />
            </SettingsDrawer>
            <SettingsDrawer
              title={t('settingsDrawer.manageExceptionsTitle')}
              open={isSettingsDrawerOpen[EXCEPTIONS_DRAWER_ID]}
              drawerId={EXCEPTIONS_DRAWER_ID}
            >
              <Exceptions courseKey={courseKey} />
            </SettingsDrawer>
          </>
        )}
      </main>
      <Footer courseKey={courseKey} withNavigation={true} />
    </>
  )
}

export default withAssignmentsSettingsContext(Assignment)
