import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import { TextField, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import errorIcon from 'images/overdue.svg'

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '&.multiline': {
    // Multiline elements
    '& textarea': {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: theme.shape.borderRadius,
    },
    '& .MuiInputBase-root': {
      border: 'none',
      height: 'auto',
      padding: 0,
      '&.Mui-error': {
        '& textarea': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      },
      '&.Mui-focused': {
        '& textarea': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
  '& .MuiInputLabel-root': {
    position: 'relative',
    transform: 'none !important',
    color: '#1C1C1C',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    margin: `0 0 ${theme.spacing(1)}`,
  },
  '& .MuiInputBase-formControl': {
    '&.Mui-focused': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '10.5px 14px',
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: '#1C1C1C !important',
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: '#1C1C1C !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    border: `1px solid ${theme.palette.grey[500]}`,
    '& > div': {
      color: '#1C1C1C',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
      paddingRight: '60px !important',
    },
    '&.Mui-error': {
      borderColor: `${theme.palette.error.main} !important`,
      '& > svg': {
        fill: `${theme.palette.error.main} !important`,
      },
    },
  },
  '& .MuiSelect-icon': {
    width: '15px',
    height: '10px',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fill: theme.palette.primary.main,
  },
  '& .MuiFormHelperText-root': {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '24px',
    marginLeft: theme.spacing(0.5),
    '&.Mui-error': {
      marginLeft: '24px',
      color: `${theme.palette.error.main} !important`,
      '&::before': {
        content: "''",
        backgroundImage: `url(${errorIcon})`,
        transform: 'translate(-20px, 3px)',
        height: '16px',
        width: '16px',
        position: 'absolute',
        top: '0',
      },
    },
  },
}))

const TextEdit = ({
  required,
  onChange,
  multiline,
  rows,
  type,
  helperText,
  autoComplete,
  ...props
}) => {
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState(false)

  const onInputChange = (value) => {
    field.onChange(value)
    onChange(value)
  }

  const fetchDynamicProps = useCallback(() => {
    const dynProps = {
      type:
        type === 'password' && !showPassword
          ? 'password'
          : type === 'password' && showPassword
            ? 'text'
            : type,
    }

    if (type === 'password') {
      dynProps.InputProps = {
        endAdornment: (
          <IconButton onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        ),
      }
    }

    return dynProps
  }, [showPassword, type])

  const getHelperText = () => {
    if (meta.touched && meta.error) {
      return meta.error
    }

    if (helperText) {
      return helperText
    }

    return null
  }

  return (
    <CustomTextField
      id={props.name}
      defaultValue={props.defaultValue}
      label={required ? `${props.label} *` : props.label}
      value={field.value}
      onChange={onInputChange}
      onBlur={field.onBlur}
      error={meta.touched && meta.error ? true : false}
      helperText={getHelperText()}
      inputProps={{
        name: props.name,
        maxLength: props.maxLength,
      }}
      disabled={props.disabled}
      fullWidth
      margin="dense"
      variant="outlined"
      multiline={multiline}
      autoComplete={autoComplete}
      rows={rows}
      className={multiline ? 'multiline' : ''}
      {...fetchDynamicProps()}
    />
  )
}

export default TextEdit

TextEdit.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
}

TextEdit.defaultProps = {
  required: false,
  disabled: false,
  multiline: false,
  rows: 3,
  onChange: () => {},
  type: 'text',
  autoComplete: 'off',
}
