import { actions } from 'store/actions'
import { initialState } from 'store'

const courseSettingsReducer = (state = initialState.courseSettings, action) => {
  switch (action.type) {
    case actions.COURSE_SETTINGS_GET_SUCCESS:
      return action.payload
    case actions.COURSE_SETTINGS_PATCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case actions.COURSE_SETTINGS_UNSET:
      return initialState.courseSettings
    default:
      return state
  }
}

export default courseSettingsReducer
