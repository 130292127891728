import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import Select from '../FormComponents/Select'
import { useTranslation } from 'react-i18next'
import { ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES } from '../formDefinitions'
import { fetchAttemptsAndTimeLimitSetup, localizeOptionsMap } from '../smartFormHelpers'

const AttemptsAndTimeLimitField = ({
  theme,
  multipleValuesMap,
  showEmptySelectLabel,
  defaultValues,
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  const { formFields, multipleValuesOption } = fetchAttemptsAndTimeLimitSetup()

  const generateLocalizedOptions = () => {
    const localizedMaxAttemptOptions = localizeOptionsMap(formFields.maxAttempts.options, t)
    const localizedTimeLimitOptions = localizeOptionsMap(formFields.timeLimit.options, t)
    return {
      maxAttemptsOptions: localizedMaxAttemptOptions,
      timeLimitOptions: localizedTimeLimitOptions,
    }
  }

  const isFieldEdited = useCallback(
    (field) => {
      if (!defaultValues) return false
      const fieldName = ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES[field]
      return values[fieldName] !== defaultValues[fieldName]
    },
    [values, defaultValues]
  )

  const resolveModuleVariables = useCallback(() => {
    let { maxAttemptsOptions, timeLimitOptions } = generateLocalizedOptions()

    if (multipleValuesMap[formFields.maxAttempts.name] === true) {
      if (!maxAttemptsOptions.some((option) => option.value === multipleValuesOption.value)) {
        maxAttemptsOptions.push({
          label: t(multipleValuesOption.i18n),
          value: multipleValuesOption.value,
        })
      }
    } else if (showEmptySelectLabel) {
      if (!maxAttemptsOptions.some((option) => option.value === '')) {
        maxAttemptsOptions.push({
          label: '',
          value: '',
        })
      }
    } else {
      maxAttemptsOptions = maxAttemptsOptions.filter(
        (option) => option.value !== multipleValuesOption.value
      )
    }

    if (multipleValuesMap[formFields.timeLimit.name] === true) {
      if (!timeLimitOptions.some((option) => option.value === multipleValuesOption.value)) {
        timeLimitOptions.push({
          label: t(multipleValuesOption.i18n),
          value: multipleValuesOption.value,
        })
      }
    } else if (showEmptySelectLabel) {
      if (!timeLimitOptions.some((option) => option.value === '')) {
        timeLimitOptions.push({
          label: '',
          value: '',
        })
      }
    } else {
      timeLimitOptions = timeLimitOptions.filter(
        (option) => option.value !== multipleValuesOption.value
      )
    }
    return { maxAttemptsOptions, timeLimitOptions }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleValuesMap])

  const { maxAttemptsOptions, timeLimitOptions } = resolveModuleVariables()

  return (
    <div className="form-module attempts-time-limit">
      <Select
        name={formFields.maxAttempts.name}
        label={t(formFields.maxAttempts.i18n)}
        options={maxAttemptsOptions}
        theme={theme}
        editedField={isFieldEdited('maxAttempts')}
      />
      <Select
        name={formFields.timeLimit.name}
        label={t(formFields.timeLimit.i18n)}
        options={timeLimitOptions}
        theme={theme}
        editedField={isFieldEdited('timeLimit')}
      />
    </div>
  )
}

export default memo(AttemptsAndTimeLimitField)

AttemptsAndTimeLimitField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  /**
   * timeLimit options
   */
  timeLimitOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  /**
   * Attempts options
   */
  attemptsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  multipleValuesMap: PropTypes.shape({}),
  showEmptySelectLabel: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
}

AttemptsAndTimeLimitField.defaultProps = {
  theme: {},
  multipleValuesMap: {},
  showEmptySelectLabel: false,
  defaultValues: null,
}
