import { actions } from 'store/actions'
import { initialState } from 'store'

const resetAssessmentsReducer = (state = initialState.resetAssessmentsMessage, action) => {
  switch (action.type) {
    case actions.RESET_ASSESSMENTS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default resetAssessmentsReducer
