import React from 'react'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'

const ImageModal = (props) => {
  const { t } = useTranslation()
  return (
    <Modal
      {...props}
      centered
      className="modal-image"
      closeBtn={true}
      ariaLabel={t('modal.modalImageLabel')}
    />
  )
}

export default ImageModal
