import React from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

import Cloud from 'images/error-cloud.svg'

const ErrorAccess = () => {
  const { t } = useTranslation()

  return (
    <main
      aria-hidden="false"
      aria-label={t('nav.mainContent')}
      className="error-page"
      id="main"
      role="main"
      tabIndex="-1"
      style={{ backgroundColor: '#f2f4f9', height: '100vh' }}
    >
      <div className="error-page__wrapper" style={{ backgroundColor: 'white' }}>
        <img alt="Error" aria-hidden="true" src={Cloud} />
        <h1 className="error-page__heading">{t('errorAccess.heading')}</h1>
        <p className="error-page__subheading">{t('errorAccess.subheading')}</p>
        <p className="error-page__subheading">
          <Trans i18nKey="errorAccess.clickHere">
            <Link className="form__form-group--submit-success__message-link" to={'/courses'}>
              Click here
            </Link>{' '}
            to join a different course.
          </Trans>
        </p>
      </div>
    </main>
  )
}

export default ErrorAccess
