/* eslint-disable react/no-multi-comp */
/* eslint-disable no-unreachable */
import Newrelic from 'utils/newrelic'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { Trans, withTranslation } from 'react-i18next'
import Spinner from 'components/Spinner'
import { StoreContext } from 'store/StoreContext'
import { Modal } from 'components'
import { learnKitAuth } from 'utils/learnKit'
import { devConsole } from 'utils/log'
import { buildCfiRange, stripCFIAssertions } from './utils'

const IMMERSIVE_READER_HOST =
  process.env.REACT_APP_IMMERSIVE_READER_HOST || 'immersive-supernova.acrobatiq.com'

const VIEWER_WRAPPER_ID = 'book-reader'

const Supernova = window.Supernova

const VIEWER_EVENTS = {
  PAGE_ERROR: 'Reader:page:error',
  PAGE_LOAD: 'Reader:page:load',
  CONTENT_RESIZE: 'Reader:content:resize',
  LINK_CLICK: 'Reader:link:click',
  USER_SIGNOUT: 'Reader:user:signout',
}

const LEARNKIT_ERROR_CODES = {
  EULA_NOT_ACCEPTED: 'EULA_NOT_ACCEPTED',
  LEARNKIT_AUTH_ERROR: 'LEARNKIT_AUTH_ERROR',
  LEARNKIT_AUTH_FETCH_FAILED: 'LEARNKIT_AUTH_FETCH_FAILED',
}

const LEARNKIT_ERROR_MESSAGES = {
  [LEARNKIT_ERROR_CODES.EULA_NOT_ACCEPTED]: (customerSupportUrl) => (
    <Trans i18nKey="learnkit.eulaNotAccepted">
      It seems you do not have access to that book because you must first accept the End User
      License Agreement (EULA). If you have any questions or need assistance, please contact{' '}
      <a href={customerSupportUrl} target="_blank" rel="noopener noreferrer">
        support
      </a>
      .
    </Trans>
  ),
}

/**
 * Determines what to do when book page loads.
 * @param {Object} options
 * @param {number} [options.height] - Book content height, for updating viewer wrapper height.
 * @param {boolean} [options.scrollToTop] - Scroll to top of the page?
 * @param {boolean} [options.removeLoadingClass] - Remove 'loading' class from viewer wrapper?
 */
const onPageLoadHandler = ({ height = 0, scrollToTop = true, removeLoadingClass = true }) => {
  const viewerWrapperElement = document.getElementById(VIEWER_WRAPPER_ID)
  if (scrollToTop) requestAnimationFrame(() => window.scrollTo(0, 0))
  if (height > 0) viewerWrapperElement.style.height = `${height}px`
  if (removeLoadingClass) viewerWrapperElement.classList.remove('loading')
}
class LearnKitPage extends PureComponent {
  static contextType = StoreContext

  state = {
    viewer: null,
    error: null,
    isAuthenticating: false,
    isLoading: true,
    hasError: false,
    modalOpen: false,
  }

  eventsCallbacks = {
    [VIEWER_EVENTS.PAGE_LOAD]: (e) => {
      const { modalOpen } = this.state
      if (modalOpen) return
      devConsole.log('LearnKitPage - page:load', e)
      onPageLoadHandler({
        height: e?.height,
      })
      this.setState({ isLoading: false })
    },
    [VIEWER_EVENTS.CONTENT_RESIZE]: (e) => {
      const { modalOpen } = this.state
      if (modalOpen) return
      devConsole.log('LearnKitPage - content:resize 📐', e)
      onPageLoadHandler({
        height: e.detail.height + 20,
        scrollToTop: false,
        removeLoadingClass: false,
      })
    },
    [VIEWER_EVENTS.LINK_CLICK]: (e) => {
      const { modalOpen } = this.state
      if (modalOpen) return
      devConsole.log('LearnKitPage - Reader:link:click:', e)
      try {
        const linkHref = e.detail.href
        const targetElPosition = e.detail.targetElPosition
        const { pathname, hash } = new URL(linkHref)

        if (hash && targetElPosition) {
          // Scroll to element in the same page
          const viewerWrapperElement = document.getElementById(VIEWER_WRAPPER_ID)
          const viewerTop = viewerWrapperElement.getBoundingClientRect().top
          window.scrollTo(0, window.scrollY + viewerTop + targetElPosition.top)
          return
        } else {
          const { i18n } = this.props
          const locale = i18n.language.split('-')[0]
          // Open immersive reader modal
          this.modalReaderUrl = `https://${IMMERSIVE_READER_HOST}/reader${pathname}?locale=${locale}${hash}`

          this.setState({ modalOpen: true })
        }
      } catch (error) {
        const message = `User clicked a link with an invalid URL: ${e.detail.href}`
        Newrelic.noticeError(new Error(message))
        devConsole.log(message)
      }
    },
    [VIEWER_EVENTS.USER_SIGNOUT]: (e) => {
      devConsole.log('LearnKitPage - user:signout', e)
      this.setState({ isLoading: true })
      this.destroyViewerAndReAuth()
    },
    [VIEWER_EVENTS.PAGE_ERROR]: (e) => {
      devConsole.log('LearnKitPage - page:error e', e)
      const { t } = this.props
      Newrelic.noticeError(new Error(`Jigsaw page viewer returned status ${e?.detail?.code}`))
      if (e?.detail?.code === 403) {
        this.setState({
          hasError: true,
          error: t('learnkit.error403'),
          isLoading: false,
        })
      } else if (e?.detail?.code) {
        this.setState({
          hasError: true,
          isLoading: false,
        })
      }
    },
  }

  currentContentDocument = null

  modalReaderUrl = null

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    // return { error, hasError: true };
    return { hasError: true }
  }

  componentDidMount() {
    const { courseKey } = this.props

    if (window.readerScriptFailedToLoad) {
      this.setState({ hasError: true, isLoading: false })
    }
    this.learnKitAuth(courseKey, this.initViewer)
  }

  componentDidUpdate(prevProps) {
    const { data: { startCfi: prevPropsStartCfi, vbid: prevPropsVbid } = {} } = prevProps
    const { data } = this.props
    const { isAuthenticating, isLoading, viewer, modalOpen } = this.state
    devConsole.log('LearnKitPage - componentDidUpdate ~ data:', data)
    const newStartCfi = data?.startCfi
    const newEndCfi = data?.endCfi
    const newVbid = data.vbid

    const previousStartCfiMatchesNewStartCfi = prevPropsStartCfi === newStartCfi

    const cfiRange = buildCfiRange(newStartCfi, newEndCfi)

    const check = {
      isAuthenticating,
      isLoading,
      previousStartCfiMatchesNewStartCfi,
      modalOpen,
      modalReaderUrl: this.modalReaderUrl,
      cfiRange,
    }
    devConsole.log('LearnKitPage - componentDidUpdate ~ check:', check)

    // Stop here if component updates for reasons we do not care about
    if (isAuthenticating || isLoading || previousStartCfiMatchesNewStartCfi || modalOpen) {
      return false
    } else {
      devConsole.log('LearnKitPage - componentDidUpdate with new CFI range 🆕')
    }

    const viewerWrapperElement = document.getElementById(VIEWER_WRAPPER_ID)

    if (prevPropsVbid && prevPropsVbid !== newVbid) {
      devConsole.group('LearnKitPage - Navigating to a different VBID 💡')
      devConsole.log('LearnKitPage - oldVbid', prevPropsVbid)
      devConsole.log('LearnKitPage - newVbid', newVbid)
      devConsole.groupEnd()

      this.setState({ isLoading: true })
      this.destroyViewerAndReAuth()
    } else if (newStartCfi !== prevPropsStartCfi) {
      devConsole.group('LearnKitPage - newStartCfi 💡')
      devConsole.log('LearnKitPage - oldStartCfi', prevPropsStartCfi)
      devConsole.log('LearnKitPage - newStartCfi', newStartCfi)
      devConsole.groupEnd()

      if (!viewer) {
        this.destroyViewerAndReAuth()
        return false
      }

      if (viewerWrapperElement) viewerWrapperElement.classList.add('loading')

      let cleanOldStartCfi = stripCFIAssertions(prevPropsStartCfi)
      let cleanNewStartCfi = stripCFIAssertions(newStartCfi)

      const oldStartCfiArray = cleanOldStartCfi.split('!')
      const newStartCfiArray = cleanNewStartCfi.split('!')

      const cfisOnSameDoc = oldStartCfiArray[0] === newStartCfiArray[0]
      const newCfiMatchesCurrentDoc = newStartCfiArray[0] === this.currentContentDocument

      // For debugging only
      if (cfisOnSameDoc && newCfiMatchesCurrentDoc) {
        devConsole.log('LearnKitPage - prev/next CFIs on same doc? ✅')
      } else {
        devConsole.log('LearnKitPage - prev/next CFIs on same doc? ❌')
      }

      this.setState({ isLoading: true })
      viewer.Reader.goToCFIRange(cfiRange)
    }

    return true
  }

  destroyViewerAndReAuth = () => {
    devConsole.log('LearnKitPage - destroyViewerAndReAuth 💣')
    const { courseKey } = this.props
    const { viewer } = this.state
    if (viewer) {
      this.unbindViewerEvents()
      viewer.destroy()
      window.Viewer.destroy()
    }

    requestAnimationFrame(() => window.scrollTo(0, 0))

    const iframe = document.querySelectorAll(`#${VIEWER_WRAPPER_ID} iframe`)
    if (iframe.length) iframe[0].remove()
    this.setState({ isAuthenticating: true, isLoading: true, hasError: false, viewer: null })
    this.learnKitAuth(courseKey, this.initViewer)
  }

  learnKitAuth = (courseKey, callback) => {
    devConsole.log('LearnKitPage - learnKitAuth 🔓')
    const { customerSupportUrl } = this.props
    const { userAq, userLk, dispatch } = this.context
    const setError = ({ code, data: { message, error } = {} }) => {
      if (message) {
        devConsole.log(message)
        // If there is no exception, capture the message
        if (!error) Newrelic.noticeError(new Error(message))
      }
      if (error) Newrelic.noticeError(error)

      let newState = { hasError: true, isAuthenticating: false }

      if (code === LEARNKIT_ERROR_CODES.EULA_NOT_ACCEPTED) {
        newState.error = LEARNKIT_ERROR_MESSAGES.EULA_NOT_ACCEPTED(customerSupportUrl)
      }
      this.setState(newState)
    }
    const setLoading = (isLoading) => this.setState({ isLoading })
    learnKitAuth({
      courseKey,
      callback,
      userAq,
      userLk,
      dispatch,
      setError,
      setLoading,
      LEARNKIT_ERROR_CODES,
    })
  }

  bindViewerEvents = () => {
    const { viewer } = this.state
    const events = Object.keys(this.eventsCallbacks)
    if (viewer) {
      events.map((e) => viewer.bind(e, this.eventsCallbacks[e]))
    }
  }

  unbindViewerEvents = () => {
    const { viewer } = this.state
    const events = Object.keys(this.eventsCallbacks)
    if (viewer) {
      events.map((e) => viewer.unbind(e, this.eventsCallbacks[e]))
    }
  }

  initViewer = () => {
    const { data, i18n } = this.props
    const { isLoading } = this.state

    const locale = i18n.language.split('-')[0]

    const { startCfi, endCfi } = data
    const cfiRange = buildCfiRange(startCfi, endCfi)
    const bookContentPath = `cfirange${cfiRange}`

    devConsole.table({
      bookContentPath,
      cfiRange,
    })

    const viewerOptions = {
      el: VIEWER_WRAPPER_ID,
      appPath: `/books/${data.vbid}/${bookContentPath}?locale=${locale}&catchLinkClicks=true`,
    }

    if (!isLoading) this.setState({ isLoading: true })

    const viewerWrapperElement = document.getElementById(VIEWER_WRAPPER_ID)
    if (viewerWrapperElement) viewerWrapperElement.classList.add('loading')

    Supernova?.createEmbeddedView(viewerOptions).then((viewer) => {
      window.Viewer = viewer
      this.setState({ isAuthenticating: false, viewer })
      this.bindViewerEvents()

      this.currentContentDocument = startCfi.split('!')[0]
    })
  }

  modalBody = () => {
    const { t } = this.props
    return (
      <div className="container">
        <iframe title={t('learnkit.craneIframeTitle')} src={this.modalReaderUrl} />
      </div>
    )
  }

  render() {
    const { error, isAuthenticating, isLoading, hasError, modalOpen } = this.state
    const { t } = this.props
    if (hasError) {
      return (
        <Alert color="danger">
          {t('learnkit.error')}
          {error && (
            <>
              <br />
              {error}
            </>
          )}
        </Alert>
      )
    }
    return (
      <div className="learnkit-page">
        {error && <p>{error}</p>}
        {(isLoading || isAuthenticating) && <Spinner />}
        {!error && <div id={VIEWER_WRAPPER_ID} className="book-reader loading" />}
        <Modal
          modalBody={this.modalBody()}
          open={modalOpen}
          toggle={() => this.setState({ modalOpen: false })}
          fullHeight
          withTheme
        />
      </div>
    )
  }
}

LearnKitPage.propTypes = {
  courseKey: PropTypes.string,
  customerSupportUrl: PropTypes.string,
  data: PropTypes.object,
  i18n: PropTypes.object,
  t: PropTypes.func,
}

export default withTranslation()(LearnKitPage)
