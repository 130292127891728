import React from 'react'
import { ASSIGNMENT_LIST_ITEM_TYPES } from 'utils/constants'
import { ReactComponent as EditIcon } from 'images/edit-icon.svg'

const editUnitAndModuleCellRenderer = (props) => {
  const invokeParentMethod = (event) => {
    // props.context.onTitleClick(`Row: ${props.node.rowIndex}, Col: ${props.colDef.field}`)
    event.preventDefault()
    event.stopPropagation()
    props.context.onUnitOrModuleEdit(props.data)
    props.api.deselectAll()
  }

  const resolveRender = () => {
    let render = null
    if (
      props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.module ||
      props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.unit
    ) {
      render = (
        <button className="unit-module-edit" onClick={invokeParentMethod} aria-haspopup>
          <EditIcon />
        </button>
      )
    }
    return render
  }

  return resolveRender()
}

export default editUnitAndModuleCellRenderer
