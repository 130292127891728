import { initialState } from 'store'
import { getActionTypeMatches } from 'store/selectors'

const apiLoadingReducer = (state = initialState.api.loading, action) => {
  const requestMatch = getActionTypeMatches(action)

  if (!requestMatch) return state
  const { requestName, requestState } = requestMatch

  if (['API_RESET', 'UNSET'].includes(requestState)) {
    delete state[requestName]
    return state
  }

  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  }
}

export default apiLoadingReducer
