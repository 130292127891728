import { styled } from '@mui/material/styles'
import { Button } from '@vitalsource/vstui'

export const useFormStyles = () => ({
  FormModule: styled('div')(() => ({
    marginBottom: '15px',
    '&.dateTime': {
      '& .pickers': {
        display: 'flex',
        justifyContent: 'space-between',
        '& > div': {
          flexGrow: 1,
          '&:not(:first-of-type)': {
            paddingLeft: '10px',
          },
          '&:not(:last-child)': {
            paddingRight: '10px',
          },
        },
      },
      '& .date-picker': {
        width: '50%',
      },
      '& .time-picker': {
        width: '50%',
      },
    },
  })),
  SubmitButton: styled(Button)(() => ({
    '& > span > span': {
      overflow: 'hidden',
    },
  })),
})
