import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getUserPublisherCustomers = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [
      actions.USER_PUBLISHER_CUSTOMERS_GET_REQUEST,
      actions.USER_PUBLISHER_CUSTOMERS_GET_SUCCESS,
      actions.USER_PUBLISHER_CUSTOMERS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().userPublisherCustomers(),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

export const getUserPublisherBlueprints = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [
      actions.USER_PUBLISHER_BLUEPRINTS_GET_REQUEST,
      actions.USER_PUBLISHER_BLUEPRINTS_GET_SUCCESS,
      actions.USER_PUBLISHER_BLUEPRINTS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().userPublisherBlueprints(),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

export const getCreateNewAdoptionsFormRequiredData = ({ dispatch }) =>
  Promise.all([getUserPublisherCustomers({ dispatch }), getUserPublisherBlueprints({ dispatch })])

export const getCustomerInstructors = ({ dispatch, customerId }) =>
  platformApi({
    dispatch,
    types: [
      actions.CUSTOMER_INSTRUCTORS_GET_REQUEST,
      actions.CUSTOMER_INSTRUCTORS_GET_SUCCESS,
      actions.CUSTOMER_INSTRUCTORS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().customerInstructors(customerId),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

export const unsetUserPublisherCustomers = ({ dispatch }) =>
  dispatch({ type: actions.USER_PUBLISHER_CUSTOMERS_UNSET })

export const unsetUserPublisherBlueprints = ({ dispatch }) =>
  dispatch({ type: actions.USER_PUBLISHER_BLUEPRINTS_UNSET })

export const unsetCustomerInstructors = ({ dispatch }) =>
  dispatch({ type: actions.CUSTOMER_INSTRUCTORS_UNSET })

export const createAdoption = ({ dispatch, publisherSlug, data, returnRejectedPromise = false }) =>
  platformApi({
    dispatch,
    types: [
      actions.CREATE_ADOPTION_POST_REQUEST,
      actions.CREATE_ADOPTION_POST_SUCCESS,
      actions.CREATE_ADOPTION_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().createAdoption(publisherSlug),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
    returnRejectedPromise,
  })

export const unsetCreateAdoption = ({ dispatch }) =>
  dispatch({ type: actions.CREATE_ADOPTION_UNSET })

export const unsetCreateNewAdoptionsFormData = ({ dispatch }) => {
  unsetUserPublisherCustomers({ dispatch })
  unsetUserPublisherBlueprints({ dispatch })
  unsetCustomerInstructors({ dispatch })
}
