import Newrelic from 'utils/newrelic'
import React, { useContext, useEffect, useState, useRef } from 'react'
import { Alert, Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import {
  actions,
  getAqUser,
  getCourse,
  getCourseSchedule,
  getCustomer,
  unloadCourse,
  unsetCourseSchedule,
  unsetCustomer,
  userHasAccessToContent,
} from 'store/actions'
import { apiStatus } from 'store/api'
import { isActionLoaded, isActionLoading } from 'store/selectors'
import CourseNav from 'components/CourseNav'
import ErrorPage from 'components/ErrorPage'
import NavMain from '../../components/NavMain/NavMain'
import Spinner from 'components/Spinner'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom'
import classnames from 'classnames'

const Course = () => {
  const navigate = useNavigate()
  const { courseKey } = useParams()
  const { t, i18n } = useTranslation()
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const {
    api,
    course,
    courseSchedule,
    currentLocation,
    customer,
    dispatch,
    userAq,
    userAqHasAccess,
  } = useContext(StoreContext)
  const openMenuButton = React.createRef()
  const bpe = buildPagesEndpoints(i18n)
  const isRedirecting = useRef(false)
  const courseCustomerSlug = course?.customer?.slug
  const [movingOutFromMenu, setMovingOutFromMenu] = useState(false)
  const isSettingsPage = useMatch('/courses/:courseKey/settings')

  const onMenuTransitionEnd = (callback) => {
    const courseNav = document.getElementsByClassName('course-navigation')[0]
    const listenTransitionEnd = (event) => {
      if (event.propertyName === 'transform') {
        callback()
      }
      courseNav.removeEventListener('transitionend', listenTransitionEnd)
    }
    // this will be executed at the end of CourseNav open/close transition
    courseNav && courseNav.addEventListener('transitionend', listenTransitionEnd)
  }

  const toggleMenu = () => {
    document.body.classList.toggle('menu-opened')
    onMenuTransitionEnd(() => {
      setMenuIsOpen((menuIsOpen) => !menuIsOpen)
      setMovingOutFromMenu(!menuIsOpen)
    })
  }

  const courseStatus = apiStatus({
    action: actions.COURSE_GET_REQUEST,
    isEmpty: !course,
    isLoaded: course && course.courseContentsUrl && course.courseContentsUrl.includes(courseKey),
  })

  const courseScheduleStatus = apiStatus({
    action: actions.COURSE_SCHEDULE_GET_REQUEST,
    isEmpty: !courseSchedule,
    isLoaded: courseSchedule,
  })

  const customerStatus = apiStatus({
    action: actions.CUSTOMER_GET_REQUEST,
    isEmpty: !customer,
    isLoaded: customer,
  })

  const isLoadingGetAqUser = isActionLoading(api, 'USER_AQ_POST')
  const isLoadingGetCourse = isActionLoading(api, 'COURSE_GET')
  const isLoadingUserHasAccessToContent = isActionLoading(api, 'USER_AQ_HAS_ACCESS_TO_CONTENT')
  const hasLoadedGetAqUser = isActionLoaded(api, 'USER_AQ_POST')
  const hasLoadedUserHasAccessToContent = isActionLoaded(api, 'USER_AQ_HAS_ACCESS_TO_CONTENT')

  const shouldRedirectToPayment =
    hasLoadedUserHasAccessToContent && !!userAqHasAccess && userAqHasAccess.status === 403

  const shouldRedirectToErrorAccessPage =
    shouldRedirectToPayment && userAqHasAccess.detail === 'user_not_enrolled'

  const shouldRedirectToErrorPage =
    hasLoadedUserHasAccessToContent && !!userAqHasAccess && userAqHasAccess.status === 404

  const paymentUrl = bpe.payCoursePageUrl({ courseKey: courseKey })

  const shouldGetAqUser = !hasLoadedGetAqUser && !userAq

  const shouldGetUserHasAccessToContent = !hasLoadedUserHasAccessToContent && !userAqHasAccess

  // In order to avoid race-conditions when licensing Instructors, wait until
  // `userHasAccessToContent` is loaded, before loading Course data
  const shouldGetCourseData =
    hasLoadedUserHasAccessToContent &&
    !!userAqHasAccess &&
    !userAqHasAccess.status &&
    !courseScheduleStatus.shouldLoad &&
    courseStatus.shouldLoad

  const shouldGetCourseScheduleData =
    hasLoadedUserHasAccessToContent &&
    !!userAqHasAccess &&
    !userAqHasAccess.status &&
    courseScheduleStatus.shouldLoad

  const isLoadingData =
    isLoadingUserHasAccessToContent ||
    shouldGetCourseScheduleData ||
    shouldGetCourseData ||
    isLoadingGetCourse ||
    isLoadingGetAqUser

  const isDashboardEnabled = !courseStatus.shouldLoad && course.enableDashboard

  const hideSidebarMenuIcon = !!isSettingsPage

  useEffect(() => {
    // Get user data on first mount
    if (shouldGetAqUser) getAqUser({ dispatch })
  }, [shouldGetAqUser, dispatch])

  useEffect(() => {
    // Check if user has access to content on first mount
    if (shouldGetUserHasAccessToContent) userHasAccessToContent({ dispatch, courseKey })
  }, [shouldGetUserHasAccessToContent, dispatch, courseKey])

  useEffect(() => {
    if (shouldGetCourseScheduleData) getCourseSchedule({ dispatch, courseKey })
  }, [shouldGetCourseScheduleData, dispatch, courseKey])

  useEffect(() => {
    if (shouldGetCourseData) getCourse({ dispatch, courseKey })
  }, [shouldGetCourseData, dispatch, courseKey])

  useEffect(() => {
    if (customerStatus.shouldLoad && courseCustomerSlug) {
      getCustomer({ dispatch, customerSlug: courseCustomerSlug })
    }
  }, [courseCustomerSlug, customerStatus.shouldLoad, dispatch])

  useEffect(() => {
    // Initialize Newrelic custom attributes
    if (userAq) Newrelic.setCustomAttribute('user_id', userAq.id)
    if (course && !courseStatus.hasError && course.courseKey)
      Newrelic.setCustomAttribute('course_key', course.courseKey)
    if (course && !courseStatus.hasError && course.customer)
      Newrelic.setCustomAttribute('customer_slug', course.customer.slug)
  }, [userAq, course, courseStatus.hasError])

  useEffect(() => {
    // Update currentLocation.course every time courseKey changes
    dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { course: courseKey } })
    // Unset currentLocation on unmount
    return () => dispatch({ type: actions.CURRENT_LOCATION_UNSET })
  }, [courseKey, dispatch])

  useEffect(() => {
    movingOutFromMenu &&
      !menuIsOpen &&
      openMenuButton &&
      openMenuButton.current &&
      openMenuButton.current.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuIsOpen, openMenuButton])

  useEffect(() => {
    // When currentLocation changes, close CourseNav menu
    const closeMenu = () => {
      document.body.classList.remove('menu-opened')
      onMenuTransitionEnd(() => setMenuIsOpen(false))
    }
    closeMenu()
  }, [currentLocation])

  useEffect(() => {
    if (!hasLoadedGetAqUser || isRedirecting.current) return
    if (shouldRedirectToErrorAccessPage) {
      isRedirecting.current = true
      navigate('/courses/error-access')
    } else if (shouldRedirectToPayment) {
      isRedirecting.current = true
      navigate(paymentUrl)
    }
  }, [
    hasLoadedGetAqUser,
    navigate,
    paymentUrl,
    shouldRedirectToErrorAccessPage,
    shouldRedirectToPayment,
  ])

  useEffect(() => {
    // Unload course after leaving this component
    return function cleanup() {
      unloadCourse({ dispatch })
      unsetCustomer({ dispatch })
      unsetCourseSchedule({ dispatch })
    }
  }, [dispatch])

  if (isLoadingData) return <Spinner />

  if (shouldRedirectToErrorPage) return <ErrorPage />

  return (
    <>
      <NavMain
        customerBrandData={!courseStatus.shouldLoad ? course.customer : null}
        hasBooks={
          !courseStatus.shouldLoad
            ? !!course.learnkitBookInfo.length && course.enableEbookIcon
            : null
        }
        toggleMenu={toggleMenu}
        menuIsOpen={menuIsOpen}
        openMenuButtonRef={openMenuButton}
        courseKey={courseKey}
        isDashboardEnabled={isDashboardEnabled}
        hideMenuIcon={hideSidebarMenuIcon}
      />
      {userAqHasAccess ? (
        <>
          <CourseNav courseKey={courseKey} menuIsOpen={menuIsOpen} toggleMenu={toggleMenu} />
          <Outlet />
        </>
      ) : (
        <main
          id="main"
          role="main"
          aria-hidden="false"
          aria-label={t('nav.mainContent')}
          tabIndex="-1"
          className={classnames({
            'px-0': true,
            overview: true,
            'aq-small-caps': course && course.smallCapsEnabled,
          })}
        >
          <Container>
            <Alert color="danger" style={{ marginTop: '20px' }}>
              Something went wrong. It seems you do not have access to this content.
            </Alert>
          </Container>
        </main>
      )}
    </>
  )
}

export default React.memo(Course)
