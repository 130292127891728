import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import * as colors from '@vitalsource/vstui/colors'
import { grey } from '@mui/material/colors'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getMuiLocale } from 'utils/muiLocalization'
import { useTranslation } from 'react-i18next'

const getTheme = (theme, language) => {
  const { primaryColor: primaryVSTColor, secondaryColor: secondaryVSTColor } = theme

  const primaryColor = colors[primaryVSTColor.toLowerCase()]
  const secondaryColor = colors[secondaryVSTColor.toLowerCase()]
  const errorColor = colors.red.getShade(6)

  const primary = {
    main: primaryColor.getShade(6),
    light: primaryColor.getShade(3),
    dark: primaryColor.getShade(8),
    contrastText: '#000',
  }

  const secondary = {
    main: secondaryColor.getShade(6),
    light: secondaryColor.getShade(3),
    dark: secondaryColor.getShade(8),
    contrastText: '#000',
  }

  return createTheme(
    {
      palette: {
        primary: { ...primary },
        secondary: { ...secondary },
        legacy: {
          primary: primaryColor,
          secondary: secondaryColor,
        },
        neutral: {
          main: grey[300],
          dark: '#d5d5d5',
          contrastText: '#000',
        },
        error: {
          main: errorColor,
        },
        background: {
          default: '#fff',
        },
      },
      shape: {
        minWidth: '550px',
        maxWidth: '1200px',
        headerHeight: '6rem',
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920,
        },
      },
      components: {
        MuiSwitch: {
          variants: [
            {
              props: { variant: 'themed' },
              style: {
                padding: '5px',
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked': {
                    '& + .MuiSwitch-track': {
                      backgroundColor: `${primary.main} !important`,
                      opacity: '1 !important',
                      border: 'none',
                    },
                    '& .MuiSwitch-thumb': {
                      border: 'none',
                    },
                  },
                },
                '& .MuiSwitch-thumb': {
                  backgroundColor: '#ffffff',
                  border: '1px solid #868686',
                },
                '& .MuiSwitch-track': {
                  borderRadius: '100px',
                  backgroundColor: '#ECECEC !important',
                  border: '1px solid #919191',
                  opacity: 1,
                },
              },
            },
          ],
        },
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontFamily: 'Roboto',
              fontSize: '14px',
              padding: '16px',
              backgroundColor: '#1C1C1C',
              boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
              '& .MuiTooltip-arrow': {
                color: '#1C1C1C',
              },
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              '& li': {
                display: 'flex',
                justifyContent: 'left',
                paddingLeft: '14px',
                minHeight: '36px',
              },
            },
          },
        },
        MuiPickersDay: {
          styleOverrides: {
            root: {
              borderRadius: '50% !important',
              '&.Mui-selected': {
                color: '#FFF',
                borderRadius: '50% !important',
              },
            },
          },
        },
        MuiPickersMonth: {
          styleOverrides: {
            root: {
              '& .Mui-selected': {
                color: '#FFF !important',
              },
            },
          },
        },
        MuiPickersYear: {
          styleOverrides: {
            root: {
              '& .Mui-selected': {
                color: '#FFF !important',
              },
            },
          },
        },
        MuiClock: {
          styleOverrides: {
            root: {
              '& .Mui-selected': {
                color: '#FFF !important',
              },
            },
          },
        },
        MuiClockNumber: {
          styleOverrides: {
            root: {
              '& .Mui-selected': {
                color: '#FFF !important',
              },
            },
          },
        },
        MuiPickersPopper: {
          styleOverrides: {
            root: {
              '&.PM': {
                '& .MuiClock-pmButton': {
                  color: '#FFF !important',
                },
              },
              '&.AM': {
                '& .MuiClock-amButton': {
                  color: '#FFF !important',
                },
              },
            },
          },
        },
        MuiAutocomplete: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '10.5px 14px',
              },
              '& .MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
                paddingRight: '0 !important',
              },
              '& .MuiOutlinedInput-root > .MuiChip-root': {
                paddingRight: '0 !important',
              },
            },
            inputRoot: {
              padding: 0,
            },
          },
        },
      },
    },
    ...getMuiLocale(language),
  )
}

const CustomThemeProvider = ({ children }) => {
  const { theme: themeConfig } = useContext(StoreContext)
  const { i18n } = useTranslation()

  if (!themeConfig) return null

  const theme = getTheme(themeConfig, i18n.language)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
}

export default CustomThemeProvider
