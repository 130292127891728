import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const AdoptionsNav = () => {
  const { t, i18n } = useTranslation()
  const { coursewarePageUrl } = buildPagesEndpoints(i18n)

  return (
    <div>
      <Nav vertical>
        <NavItem>
          <NavLink tag="div">
            <Link to={coursewarePageUrl()}>{t('adoptions.coursewareRegistrationForm')}</Link>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  )
}

export default AdoptionsNav
