import { getI18n } from 'react-i18next'

const supportedLanguages = ['es', 'es-ar', 'pt-br', 'en-us']
const langMap = { es: 'es', pt: 'pt-br', en: 'en-us' }

const getSupportedLanguage = (langCode) => {
  langCode = langCode.toLowerCase()
  if (supportedLanguages.includes(langCode)) return langCode
  langCode = langCode.split('-')[0]
  if (Object.keys(langMap).includes(langCode)) {
    return langMap[langCode]
  }
  return 'en-us'
}

const apiEndpoints = (langCode) => {
  langCode = langCode || getI18n().language
  langCode = getSupportedLanguage(langCode)
  return {
    authoringUserActions: (courseKey) =>
      `/${langCode}/5.0/api/course/${courseKey}/authoring-user-actions/`,
    courses: () => `/${langCode}/5.0/api/course/`,
    learnKitAuth: (courseKey, nextUrl) =>
      `/${langCode}/5.0/learnkit_auth/${courseKey}?next_url=${nextUrl}`,
    navigation: (courseKey) => `/${langCode}/courseware/api/navigation/${courseKey}`,
    page: (courseKey, pageId, top = false, bottom = false) => {
      const pageUrl = `/${langCode}/5.0/page/${courseKey}/${pageId}`

      if ((!top && !bottom) || (top && bottom)) {
        return pageUrl
      }

      if (top) {
        return `${pageUrl}?top=1`
      }

      return `${pageUrl}?bottom=1`
    },
    sans: () => `/${langCode}/auth_get_token`,
    unit: (courseKey, unitIdentifier) =>
      `/${langCode}/courseware/api/navigation/${courseKey}/${unitIdentifier}`,
    user: () => `/${langCode}/5.0/api/currentuser`,
    userHasAccess: (courseKey) => `/${langCode}/5.0/api/currentuser_has_access/${courseKey}`,
    workbookPage: (courseKey, pageId) => `/${langCode}/5.0/api/workbookpage/${courseKey}/${pageId}`,
    profile: () => `/${langCode}/5.0/api/profile`,
    changePassword: () => `/${langCode}/5.0/api/profile/password`,
    login: () => `/${langCode}/5.0/api/login`,
    loginAndEnroll: () => `/${langCode}/5.0/api/login_and_enroll`,
    customer: (customerSlug) => `/${langCode}/5.0/api/customer/${customerSlug}`,
    configuration: () => `/${langCode}/5.0/api/configuration/`,
    acceptTerms: () => `/${langCode}/5.0/api/user-accept-tos/`,
    forgotPassword: () => `/${langCode}/5.0/api/forgot-password/`,
    forgotManagePassword: () => `/${langCode}/5.0/api/forgot-password.manage/`,
    courseAccessRequirements: (courseKey) =>
      `/${langCode}/5.0/api/course_access_requirements/${courseKey}`,
    authorizeCourseAccess: (courseKey) =>
      `/${langCode}/5.0/api/currentuser_authorize_course_access/${courseKey}`,
    getCartUrl: (courseKey, redirectUrl) =>
      `/${langCode}/5.0/api/get_cart_url/${courseKey}?redirect_url=${redirectUrl}`,
    checkManageUser: (userEmail) => `/${langCode}/5.0/api/check_user/${userEmail}`,
    signupFromManage: () => `/${langCode}/5.0/api/signup/from_manage/`,
    enroll: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/enrollment/`,
    enrollments: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/enrollments/`,
    getAccountCenterUrl: () => `/${langCode}/5.0/api/profile/get_account_center_url`,
    scheduleExternalModule: (courseKey, moduleId) =>
      `/${langCode}/5.0/api/course/${courseKey}/external-module/${moduleId}/schedule/`,
    resetAssessments: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/reset-assessments/`,
    assignmentById: (courseKey, id) => `/${langCode}/5.0/api/course/${courseKey}/assignments/${id}`,
    allSegments: (courseKey, purposeTypes) =>
      `/${langCode}/5.0/api/course/${courseKey}/scheduling-settings${
        purposeTypes ? `?purpose_types=${purposeTypes}` : ''
      }`,
    assignmentPurposeTypes: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/purpose_types`,
    assignmentTypes: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/assignment_types`,
    assignmentsSchedules: (courseKey) =>
      `/${langCode}/5.0/api/course/${courseKey}/assignments/schedules/`,
    segmentSchedules: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/segments/schedules/`,
    assignmentsExceptions: (courseKey, exceptionUuid) => {
      const exceptionsUrl = `/${langCode}/5.0/api/course/${courseKey}/assignments_exceptions/`
      if (exceptionUuid) {
        return `${exceptionsUrl}${exceptionUuid}/`
      }
      return exceptionsUrl
    },
    courseSettings: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/course_settings`,
    courseSchedule: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/scheduling`,
    courseCopy: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/copy`,
    courseCopyStatus: (courseKey) => `/${langCode}/5.0/api/course/${courseKey}/status_course_copy`,
    userPublisherCustomers: () => `/${langCode}/coursemanage/user-publisher-customers`,
    userPublisherBlueprints: () => `/${langCode}/coursemanage/user-publisher-blueprints`,
    customerInstructors: (customerId) =>
      `/${langCode}/coursemanage/customer-instructors/${customerId}`,
    createAdoption: (publisherSlug) =>
      `/${langCode}/rostering/v1/self-provisioning-tasks/${publisherSlug}/`,
  }
}

export default apiEndpoints
