import React from 'react'
import PropTypes from 'prop-types'
import { cloud } from '@vitalsource/vstui/colors'
import { withTheme } from 'styled-components'

const Spinner = ({ position, width = 52, height = 52, theme }) => (
  <div className={`vst-spinner-container position-${position}`} style={{ display: 'none' }}>
    <div className="vst-spinner">
      <svg
        className="vst-spinner__svg"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 52 52"
      >
        <path
          fill={cloud.getShade(3)}
          d="M26 4c12.1 0 22 9.9 22 22s-9.9 22-22 22S4 38.1 4 26 13.9 4 26 4m0-4C11.6 0 0 11.6 0 26s11.6 26 26 26 26-11.6 26-26S40.4 0 26 0z"
          opacity=".8"
        />
        <path
          fill={theme.vstui.color.primary.getShade(6)}
          d="M26 0v4c12.1 0 22 9.9 22 22s-9.9 22-22 22v4c14.4 0 26-11.6 26-26S40.4 0 26 0z"
        />
      </svg>
    </div>
  </div>
)

Spinner.propTypes = {
  height: PropTypes.number,
  position: PropTypes.oneOf(['fixed', 'left', 'center']),
  theme: PropTypes.object,
  width: PropTypes.number,
}

Spinner.defaultProps = {
  position: 'fixed',
}

export default withTheme(Spinner)
