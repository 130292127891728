// import { get } from 'js-cookie'
export const withCredentials = params => {
  // const credentials = get('csrftoken')
  const { headers: currentHeaders } = params

  const headers = {
    // 'X-CSRFToken': credentials,
    ...currentHeaders
  }

  return {
    ...params,
    headers
  }
}
