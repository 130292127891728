import React, { Suspense } from 'react'
import { Store } from 'store'
import Router, { history } from './router'
import Spinner from 'components/Spinner'
import ErrorBoundary from 'components/ErrorBoundary'
import { ThemeProvider } from 'components/ThemeProvider'
import './i18n'

const App = () => (
  <Store>
    <ThemeProvider>
      <ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <Router history={history} />
        </Suspense>
      </ErrorBoundary>
    </ThemeProvider>
  </Store>
)

export default App
