import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import Downshift from 'downshift'
import { Button, Input } from 'reactstrap'
import { ReactComponent as SearchIcon } from 'images/menu-search-icon.svg'
import { ReactComponent as CloseIcon } from 'images/menu-search-close.svg'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { getAssignmentTypeKey, getIsInstructorOrAdmin } from 'store/selectors'

const getAssignmentsFromPage = (page) =>
  page.assessments
    ? page.assessments.map((assessment) => ({
        ...assessment,
        type: getAssignmentTypeKey(assessment.assessmentType),
      }))
    : []

/*
 * TO-DO: this could be written as a store selector
 * We should do some refactoring and cleanup when we get store selectors merged in
 */
const flattenCourseObject = (syllabusUnitsMap, courseKey, bpe, isInstructorOrAdmin) => {
  const tempItems = []

  syllabusUnitsMap.forEach((unit) => {
    const unitIdentifier = unit.identifier
    if (unit.schedule && !unit.schedule.studentAvailability && !isInstructorOrAdmin) {
      return
    }
    tempItems.push({
      key: `unit-${unitIdentifier}`,
      value: `${unit.courseLabel} ${unit.ordinal}: ${unit.title}`,
      href: bpe.courseUnitUrl({ courseKey, unitIdentifier }),
    })
    unit.modules.forEach((module) => {
      const moduleIdentifier = module.identifier
      if (module.schedule && !module.schedule.studentAvailability && !isInstructorOrAdmin) {
        return
      }
      tempItems.push({
        key: `module-${module.identifier}`,
        value: module.isContent
          ? `${module.title}`
          : `${module.courseLabel} ${module.ordinal}: ${module.title}`,
        href: bpe.modulePageUrl({ courseKey, unitIdentifier, moduleIdentifier }),
      })
      module.pages.forEach((page) => {
        tempItems.push({
          key: `page-${page.identifier}`,
          value: page.title,
          href: bpe.contentPageUrl({ courseKey, pageIdentifier: page.identifier }),
        })
        const assignments = getAssignmentsFromPage(page)
        assignments.forEach((assignment) => {
          tempItems.push({
            key: `assignment-${assignment.identifier}`,
            value: `${assignment.columnTitle}: ${assignment.title}`,
            href: bpe.assignmentPageUrl({
              courseKey,
              assignmentType: assignment.type,
              assignmentIdentifier: assignment.identifier,
            }),
          })
        })
      })
    })
  })

  return tempItems
}

const CourseNavSearch = (props) => {
  const { courseKey } = props
  const navigate = useNavigate()
  const { course, userAq } = useContext(StoreContext)
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const [items, setItems] = useState([])
  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)

  const flattenCourseObjectCallback = useCallback(flattenCourseObject, [])

  useEffect(() => {
    course.syllabusUnitsMap &&
      items.length === 0 &&
      setItems(
        flattenCourseObjectCallback(course.syllabusUnitsMap, courseKey, bpe, isInstructorOrAdmin),
      )
  }, [
    course.syllabusUnitsMap,
    courseKey,
    flattenCourseObjectCallback,
    bpe,
    items,
    isInstructorOrAdmin,
  ])

  if (!course) return null

  const stateReducer = (state, changes) => {
    switch (changes.type) {
      // prevent input reset on blur
      case Downshift.stateChangeTypes.blurInput:
        return {} // no-changes
      // clear input on item selection/click
      case Downshift.stateChangeTypes.keyDownEnter:
        return {
          ...changes,
          inputValue: '',
        }
      // clear input on item selection/click
      case Downshift.stateChangeTypes.clickItem:
        return {
          ...changes,
          inputValue: '',
        }
      // do not select item on mouse hover
      case Downshift.stateChangeTypes.itemMouseEnter:
        return {
          ...changes,
          highlightedIndex: null,
        }
      default:
        return changes
    }
  }

  const filterItems = (item, inputValue) =>
    !inputValue || item.value.toLowerCase().includes(inputValue.toLowerCase())

  return (
    <Downshift
      itemToString={(item) => (item ? item.value : '')}
      stateReducer={stateReducer}
      onChange={(selectedItem) => {
        selectedItem && navigate(selectedItem.href)
      }}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        getRootProps,
        isOpen,
        inputValue,
        highlightedIndex,
        clearSelection,
      }) => (
        <div
          className={classnames({
            'course-navigation__search': true,
            'course-navigation__search--dirty': inputValue,
          })}
        >
          <label {...getLabelProps()} className="sr-only">
            {t('courseNav.searchInputLabel')}
          </label>
          <div className="course-navigation__search--input-wrapper">
            <SearchIcon
              className="course-navigation__search--icon"
              aria-hidden="true"
              focusable="false"
            />
            <Input
              {...getInputProps()}
              {...getRootProps({}, { suppressRefError: true })}
              placeholder={t('courseNav.searchPlaceholder')}
              className="course-navigation__search--input"
            />
            {inputValue && (
              <Button
                className="course-navigation__search--close-button"
                onClick={clearSelection}
                aria-label={t('courseNav.searchClear')}
              >
                <CloseIcon
                  className="course-navigation__search--close-icon"
                  aria-hidden="true"
                  focusable="false"
                />
              </Button>
            )}
          </div>
          <ul
            {...getMenuProps()}
            className={classnames({
              'course-navigation__search--results-list': true,
              'd-none': !isOpen,
            })}
          >
            {isOpen &&
              items.length > 0 &&
              inputValue &&
              items
                .filter((item) => filterItems(item, inputValue))
                .map((item, index) => (
                  <li
                    key={item.key}
                    {...getItemProps({
                      key: item.key,
                      index,
                      item,
                      className: classnames({
                        'course-navigation__search--results-item': true,
                        'course-navigation__search--results-item--highlighted':
                          highlightedIndex === index,
                      }),
                    })}
                  >
                    <Link
                      to={item.href}
                      className="course-navigation__search--results-link"
                      onClick={clearSelection}
                    >
                      {item.value}
                    </Link>
                  </li>
                ))}
          </ul>
          {inputValue && !items.filter((item) => filterItems(item, inputValue)).length && (
            <p className="course-navigation__search--no-results-message">
              {t('courseNav.searchNothingFound')}
              <br />
              <span>{t('courseNav.searchUpdate')}</span>
            </p>
          )}
        </div>
      )}
    </Downshift>
  )
}

CourseNavSearch.propTypes = {
  courseKey: PropTypes.string.isRequired,
}

export default React.memo(CourseNavSearch)
