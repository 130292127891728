import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const getCourseSettings = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_SETTINGS_GET_REQUEST,
      actions.COURSE_SETTINGS_GET_SUCCESS,
      actions.COURSE_SETTINGS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseSettings(courseKey),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

export const updateCourseSettings = ({ dispatch, courseKey, settings }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_SETTINGS_PATCH_REQUEST,
      actions.COURSE_SETTINGS_PATCH_SUCCESS,
      actions.COURSE_SETTINGS_PATCH_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseSettings(courseKey),
      params: withCredentials({
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(settings),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
  })

export const unsetCourseSettings = ({ dispatch }) =>
  dispatch({ type: actions.COURSE_SETTINGS_UNSET })
