import React, { memo } from 'react'
import PropTypes from 'prop-types'
import Drawer from 'components/Drawer'
import { closeDrawer } from 'components/Forms/smartFormHelpers'
import { useSettingsContext } from './store/settingsContext'
import { withTheme } from 'styled-components'

const SettingsDrawer = ({ children, title, open, drawerId }) => {
  const { confirmationDialogMode, dispatchSettingsAction, isFormDirty } = useSettingsContext()

  const handleSettingsDrawerClose = () => {
    closeDrawer({ dispatchSettingsAction, drawerId, isFormDirty })
  }

  return (
    <Drawer
      title={title}
      open={open}
      drawerId={drawerId}
      closeCallback={handleSettingsDrawerClose}
      MuiModalProps={{
        disableEnforceFocus: !!confirmationDialogMode?.mode,
        disableEscapeKeyDown: !!confirmationDialogMode?.mode,
      }}
    >
      {children}
    </Drawer>
  )
}

export default memo(withTheme(SettingsDrawer))

SettingsDrawer.propTypes = {
  /**
   * Drawer id
   */
  drawerId: PropTypes.string,
  children: PropTypes.element,
  /**
   * Title of drawer
   */
  title: PropTypes.string,
  /**
   * Boolean for whether drawer is open or not
   */
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * Boolean for whether to display back btn or not
   */
  showBackBtn: PropTypes.bool,
  MuiModalProps: PropTypes.object,
}

SettingsDrawer.defaultProps = {
  drawerId: 'default',
  title: 'Drawer Title',
  open: false,
  showBackBtn: false,
  MuiModalProps: {},
}
