import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const postAuthUserAccess = ({ dispatch, courseKey, requestTrial = false, code }) => {
  const data = {
    course_key: courseKey,
    request_trial: requestTrial,
    code: code
  }
  return platformApi({
    dispatch,
    types: [
      actions.AUTH_USER_ACCESS_POST_REQUEST,
      actions.AUTH_USER_ACCESS_POST_SUCCESS,
      actions.AUTH_USER_ACCESS_POST_FAILURE
    ],
    fetchParams: {
      url: apiEndpoints().authorizeCourseAccess(courseKey),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  })
}
