import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'
import BaseLayout from 'components/BaseLayout'
import Card from './Card'

const SidebarLayout = ({ children, sidebarNavComponent, pageTitle, cardTitle }) => (
  <BaseLayout title={pageTitle}>
    <div className="sidebar-layout">
      <Row>
        <Col sm="12" md="3">
          {sidebarNavComponent}
        </Col>
        <Col sm="12" md="8" className="sidebar-layout__content">
          <Card title={cardTitle}>{children}</Card>
        </Col>
      </Row>
    </div>
  </BaseLayout>
)

SidebarLayout.propTypes = {
  children: PropTypes.node,
  sidebarNavComponent: PropTypes.node.isRequired,
  pageTitle: PropTypes.string,
  cardTitle: PropTypes.string,
}

export default React.memo(SidebarLayout)
