import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import Newrelic from 'utils/newrelic'
import { StoreContext } from 'store'
import { actions } from 'store/actions'
import Spinner from 'components/Spinner'
import CourseMast from 'components/CourseMast'
import ExternalModule from 'components/ExternalModule'
import LearningObjectivesAccordion from 'components/LearningObjectives'
// import ModuleParticipationScoreRow from 'components/ModuleParticipationScoreRow'
import TableOfContents from 'components/TableOfContents'
import Footer from 'components/Footer'
import TrialAlert from 'components/TrialAlert'
import classnames from 'classnames'

const ModuleDetail = () => {
  const { courseKey, moduleId, unitId } = useParams()
  const { course, dispatch } = useContext(StoreContext)
  const { t } = useTranslation()
  const courseDashboardLevel = 'module'

  /**
   * 1- Get units from store
   * 2- Filter units and get current unit only
   * 3- Copy over that unit into a new object ('thisUnit')
   * 4- Filter unit modules and get current module only ('thisModule')
   * 5- Overwrite 'thisUnit' modules, to include current module only
   */
  const thisUnit = [
    Object.assign(
      {},
      course && course?.syllabusUnitsMap.filter((unit) => unit.identifier === unitId)[0],
    ),
  ]
  const thisUnitModules = thisUnit[0].modules
  const thisModule =
    thisUnitModules && thisUnitModules.filter((module) => module.identifier === moduleId)
  const thisModuleIsExternal = thisModule && thisModule[0].isExternal
  thisUnit[0].modules = thisModule
  const tocElements = thisUnit

  const thisModuleLOs = thisModule && [...thisModule[0].learningObjectives]

  const shouldRenderTableOfContents = !!course && !!tocElements

  useEffect(() => {
    if (course && course.title) document.title = course.title
    // Update currentLocation.module every time moduleId changes
    dispatch({
      type: actions.CURRENT_LOCATION_SET,
      payload: { module: moduleId, viewing: 'module' },
    })
    // Unset currentLocation.module on unmount
    return () =>
      dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { module: null, viewing: null } })
  }, [dispatch, course, moduleId])

  useEffect(() => {
    Newrelic.setPageViewName('course/module')
  }, [])

  useEffect(() => {
    Newrelic.setCustomAttribute('module_id', moduleId)
  }, [moduleId])

  if (!course) return <Spinner />

  if (thisModuleIsExternal) return <ExternalModule module={thisModule[0]} courseKey={courseKey} />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          overview: true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        <CourseMast
          title={course ? course.title : ''}
          level={courseDashboardLevel}
          courseKey={courseKey}
        />
        <Container>
          <Row>
            <Col>
              {thisModuleLOs && thisModuleLOs.length > 0 && (
                <LearningObjectivesAccordion los={thisModuleLOs} />
              )}
              <div className={`course-dashboard course-dashboard--${courseDashboardLevel}`}>
                <TrialAlert courseKey={courseKey} />
                {!shouldRenderTableOfContents && <Spinner />}
                {shouldRenderTableOfContents && (
                  <TableOfContents
                    courseKey={courseKey}
                    level={courseDashboardLevel}
                    tocElements={tocElements}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </main>

      <Footer />
    </>
  )
}

export default React.memo(ModuleDetail)
