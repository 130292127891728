import React, { useState, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import { isActionLoaded, actionHasErrors, isLoading } from 'store/selectors'
import NavMain from 'components/NavMain/NavMain'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import AlertMessage from 'components/AlertMessage'
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from 'reactstrap'
import { Button } from '@vitalsource/vstui'
import {
  actions,
  enroll,
  getCourseAccessRequirements,
  resetAction,
  unsetCourseAccessRequirements,
  unsetCourses,
  unsetUserHasAccessToContent,
  userHasAccessToContent,
} from 'store/actions'
import Spinner from 'components/Spinner'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useCustomerData, useStateWithLocalStorage } from 'utils/customHooks'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { apiStatus } from 'store/api'

const AddCourse = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n, t } = useTranslation()
  const {
    api,
    dispatch,
    courseAccessRequirements,
    brandedSigninCustomerData,
    userAqHasAccess,
    userAq,
    enrollment,
  } = useContext(StoreContext)
  const bpe = buildPagesEndpoints(i18n)
  const courseKeyFromPaymentPage = location.state && location.state.courseKeyFromPaymentPage
  const [brandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')
  const [isCustomerDataReady] = useCustomerData({ customerSlug: brandedSignInCustomer })
  const { customerImage, customerName } = brandedSigninCustomerData
  const brandData = isCustomerDataReady ? { image: customerImage, name: customerName } : null

  const [newCourseKey, setNewCourseKey] = useState(courseKeyFromPaymentPage || '')
  const [newCourseKeyError, setNewCourseKeyError] = useState('')
  const [errorMessages, setErrorMessages] = useState('')
  const isFormSubmitted = useRef(false)
  const isRedirectingToCourse = useRef(false)

  const paymentUrl = bpe.payCoursePageUrl({ courseKey: newCourseKey })
  const courseUrl = bpe.coursePageUrl({ courseKey: newCourseKey })

  // API status

  const isApiLoading = isLoading(api)

  const getCourseAccessRequirementsActionLoaded = isActionLoaded(
    api,
    'COURSE_ACCESS_REQUIREMENTS_GET',
  )
  const getCourseAccessRequirementsActionHasErrors = actionHasErrors(
    api,
    'COURSE_ACCESS_REQUIREMENTS_GET',
  )

  const enrollActionLoaded = isActionLoaded(api, 'ENROLL_POST')
  const enrollActionHasErrors = actionHasErrors(api, 'ENROLL_POST')

  const userAqHasAccessStatus = apiStatus({
    action: actions.USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST,
    isEmpty: userAqHasAccess === null,
    isLoaded: userAqHasAccess !== null,
  })

  const didGetCourseAccessRequirements =
    getCourseAccessRequirementsActionLoaded &&
    !getCourseAccessRequirementsActionHasErrors &&
    courseAccessRequirements

  const didEnroll =
    enrollActionLoaded &&
    !enrollActionHasErrors &&
    (enrollment.status === 'Valid' || enrollment.status === 'Trial')

  const userIsEnrolled =
    didEnroll || (didGetCourseAccessRequirements && courseAccessRequirements.enrollmentStatus)

  const canEnrollToCourse =
    didGetCourseAccessRequirements &&
    !courseAccessRequirements.courseEnded &&
    courseAccessRequirements.enrollmentStatus !== 'Valid' &&
    courseAccessRequirements.enrollmentStatus !== 'Trial' &&
    courseAccessRequirements.allowStandardRegistration

  // Sends POST request to API
  const shouldEnroll = !isApiLoading && !didEnroll && canEnrollToCourse && isFormSubmitted.current

  const shouldCheckUserHasAccessToContent =
    !isApiLoading && didEnroll && userAqHasAccessStatus.shouldLoad && canEnrollToCourse

  const hasCheckedLicense = !userAqHasAccessStatus.shouldLoad && userAqHasAccess

  // Redirects to course TOC
  const shouldRedirectToCourse =
    isFormSubmitted.current &&
    !isApiLoading &&
    userIsEnrolled &&
    hasCheckedLicense &&
    (!courseAccessRequirements.paymentRequired || userAqHasAccess.status !== 403)

  const shouldRedirectToPayment =
    isFormSubmitted.current &&
    !isApiLoading &&
    userIsEnrolled &&
    hasCheckedLicense &&
    courseAccessRequirements.paymentRequired &&
    userAqHasAccess.status === 403

  const validateCourseAccessRequirements = useCallback(() => {
    if (!isFormSubmitted.current) return
    let feedback = ''
    if (!getCourseAccessRequirementsActionLoaded) return
    if (!courseAccessRequirements && getCourseAccessRequirementsActionHasErrors) {
      feedback = t('courses.courseDoesNotExist')
    } else {
      const { courseEnded, allowStandardRegistration, enrollmentStatus } = courseAccessRequirements
      switch (true) {
        case courseEnded:
          // Error message indicates the course has ended
          feedback = t('courses.errors.courseEnded')
          break
        case allowStandardRegistration === false:
          // Error message indicates course only accepts registration via LTI
          feedback = t('courses.errors.courseDoesOnlyAllowLtiRegistration')
          break
        case enrollmentStatus === 'Valid' || enrollmentStatus === 'Trial':
          // Error message indicates user is already enrolled and provides a link to the course
          feedback = t('courses.errors.courseEnrollmentValid')
          break
        default:
          break
      }
    }
    setErrorMessages(feedback)
  }, [
    courseAccessRequirements,
    getCourseAccessRequirementsActionHasErrors,
    getCourseAccessRequirementsActionLoaded,
    t,
  ])

  const validateEmptyField = (name, value, setError) => {
    if (!value) {
      const error = t(`courses.empty.${name}`)
      setError(error)
      return false
    }
    setError('')
    return true
  }

  const renderFormFeedback = (messages, feedbackFor) =>
    Array.isArray(messages) ? (
      messages.map((message, index) => (
        <FormFeedback key={`form_feedback_${feedbackFor}_${index}`}>{message}</FormFeedback>
      ))
    ) : (
      <FormFeedback>{messages}</FormFeedback>
    )

  const getHandleInputChange = (setValue, setError) => (event) => {
    const {
      target: { name, value },
    } = event
    validateEmptyField(name, value, setError)
    setValue(value)
  }

  const handleSubmitKey = (event) => {
    event.preventDefault()

    const isValidCourseKey = validateEmptyField('newCourseKey', newCourseKey, setNewCourseKeyError)

    if (!isValidCourseKey) {
      return
    }

    isFormSubmitted.current = true
    setErrorMessages('')
    getCourseAccessRequirements({ dispatch, courseKey: newCourseKey })
    setNewCourseKey(newCourseKey.trim())
  }

  const stateCleanup = useCallback(() => {
    unsetCourses({ dispatch })
    unsetCourseAccessRequirements({ dispatch })
    unsetUserHasAccessToContent({ dispatch })
    resetAction({ dispatch, action: 'COURSE_ACCESS_REQUIREMENTS_GET' })
    resetAction({ dispatch, action: 'ENROLL_POST' })
    resetAction({ dispatch, action: 'CART_URL_GET' })
    resetAction({ dispatch, action: 'USER_AQ_HAS_ACCESS_TO_CONTENT' })
  }, [dispatch])

  // Effects

  useEffect(() => {
    stateCleanup()
  }, [stateCleanup])

  useEffect(() => {
    validateCourseAccessRequirements()
  }, [
    courseAccessRequirements,
    getCourseAccessRequirementsActionHasErrors,
    validateCourseAccessRequirements,
  ])

  useEffect(() => {
    if (shouldCheckUserHasAccessToContent) {
      userHasAccessToContent({ dispatch, courseKey: newCourseKey })
    }
  }, [shouldCheckUserHasAccessToContent, dispatch, newCourseKey])

  useEffect(() => {
    if (shouldEnroll) {
      enroll({ dispatch, courseKey: newCourseKey, data: { user: userAq } })
    }
  }, [shouldEnroll, dispatch, newCourseKey, userAq])

  useEffect(() => {
    if (shouldRedirectToCourse && !isRedirectingToCourse.current) {
      isRedirectingToCourse.current = true

      dispatch({
        type: actions.COURSE_MESSAGES_SET,
        payload: {
          course: newCourseKey,
          message: `${t('courses.enrollSuccess')} ${courseAccessRequirements.title}`,
          shown: false,
        },
      })

      stateCleanup()
      const courseUrl = bpe.coursePageUrl({ courseKey: newCourseKey })
      navigate(courseUrl)
    }
  }, [
    shouldRedirectToCourse,
    dispatch,
    courseAccessRequirements,
    newCourseKey,
    t,
    stateCleanup,
    bpe,
    navigate,
  ])

  useEffect(() => {
    if (shouldRedirectToPayment) {
      navigate(paymentUrl)
    }
  }, [shouldRedirectToPayment, paymentUrl, navigate])

  useEffect(() => {
    return function cleanup() {
      unsetCourses({ dispatch })
    }
  }, [dispatch])

  if (!isCustomerDataReady) return <Spinner />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className="px-0 overview"
      >
        <NavMain customerBrandData={brandData} hideMenuIcon={true} />
        <PageHeader>
          <h1 className="courses-mast__title">
            {courseAccessRequirements
              ? courseAccessRequirements.title
              : t('courses.addCourseMastTitle')}
          </h1>
        </PageHeader>
        <Container className="pt-3 pb-5">
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <Card className="add-course-card course-key-card">
                <CardBody>
                  <p>{t('courses.enterCourseKey')}</p>
                  {errorMessages && (
                    <AlertMessage color="danger" className="mt-4">
                      {errorMessages}
                      {courseAccessRequirements &&
                        courseAccessRequirements.enrollmentStatus === 'Valid' && (
                          <>
                            {' '}
                            <Link to={courseUrl}>{courseAccessRequirements.title}</Link>
                          </>
                        )}
                    </AlertMessage>
                  )}
                  <Form onSubmit={handleSubmitKey}>
                    <FormGroup>
                      <Label for="newCourseKey">{t('courses.courseKey')}</Label>
                      <Input
                        type="text"
                        name="newCourseKey"
                        id="newCourseKey"
                        placeholder="---- ---- ---- ----"
                        value={newCourseKey}
                        onChange={getHandleInputChange(setNewCourseKey, setNewCourseKeyError)}
                        invalid={!!newCourseKeyError}
                        disabled={isApiLoading}
                      />
                      {renderFormFeedback(newCourseKeyError, 'newCourseKey')}
                    </FormGroup>

                    <Button color="theme" fullWidth type="submit" disabled={isApiLoading}>
                      {isApiLoading ? (
                        <Spinner position="left" width={25} height={25} />
                      ) : (
                        t('courses.submit')
                      )}
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default AddCourse
