/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from '../FormComponents/DatePicker'
import { CLONED_COURSE_DATE_FIELDS } from '../formDefinitions'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'
import moment from 'moment'

const DateField = ({ theme, ...props }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext()
  const afterStartDate = moment(values.startDate).add(1, 'd')

  return (
    <div>
      <div className="pickers">
        <DatePicker
          name={CLONED_COURSE_DATE_FIELDS.startDate.name}
          label={t(`${CLONED_COURSE_DATE_FIELDS.startDate.i18n}`)}
          theme={theme}
          disabled={props.disabled}
          format={t('settingsForm.dateFormat') || 'MM-DD-YYYY'}
        />
        <DatePicker
          name={CLONED_COURSE_DATE_FIELDS.endDate.name}
          label={t(`${CLONED_COURSE_DATE_FIELDS.endDate.i18n}`)}
          theme={theme}
          disabled={props.disabled}
          minDate={afterStartDate || null}
          format={t('settingsForm.dateFormat') || 'MM/DD/YYYY'}
        />
      </div>
    </div>
  )
}

export default DateField

DateField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  disabled: PropTypes.bool,
}
