import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Modal } from 'components'
import { Button } from '@vitalsource/vstui'

const CourseCreatedModal = ({ course, showCourseCreatedModal, toggleCourseCreatedModal }) => {
  const { t } = useTranslation()

  if (!course) return <></>

  const modalHeader = <>{t('courses.courseCloneModal.title')}</>

  const modalBody = (
    <>
      <p>
        {t('courses.courseCloneModal.courseTitleLabel')}: {course && course.courseTitle}
      </p>
      <p>
        {t('courses.courseCloneModal.courseIdLabel')}: <code>{course && course.courseKey}</code>
      </p>
    </>
  )

  const modalFooter = (
    <Button color="theme" onClick={toggleCourseCreatedModal}>
      {t('courses.courseCloneModal.modalOk')}
    </Button>
  )

  return (
    <Modal
      modalBody={modalBody}
      modalHeader={modalHeader}
      modalFooter={modalFooter}
      open={showCourseCreatedModal}
      toggle={toggleCourseCreatedModal}
      className="instructions-toolbar-modal"
    />
  )
}

CourseCreatedModal.propTypes = {
  course: PropTypes.object,
  showCourseCreatedModal: PropTypes.bool,
  toggleCourseCreatedModal: PropTypes.func,
  customerSupportUrl: PropTypes.string,
}

export default CourseCreatedModal
