import React from 'react'
import { useTranslation } from 'react-i18next'

import Oops from 'images/oops.svg'

const ErrorPage = () => {
  const { t } = useTranslation()

  return (
    <main
      aria-hidden="false"
      aria-label={t('nav.mainContent')}
      className="error-page"
      id="main"
      role="main"
      tabIndex="-1"
    >
      <div className="error-page__wrapper">
        <img alt="Oops!" aria-hidden="true" src={Oops} />
        <h1 className="error-page__heading">{t('errorPage.heading')}</h1>
        <p className="error-page__subheading">{t('errorPage.subheading')}</p>
      </div>
    </main>
  )
}

export default ErrorPage
