import { actions } from 'store/actions'
import { initialState } from 'store'

const scheduleReducer = (state = initialState.schedule, action) => {
  switch (action.type) {
    case actions.SCHEDULE_GET_SUCCESS:
      return action.payload
    case actions.SCHEDULE_UNSET:
      return initialState.schedule
    default:
      return state
  }
}

export default scheduleReducer
