import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { copyTextToClipboard } from 'utils/functional'
import { getCourseTimeInterval } from 'utils/dates'
import { Tooltip } from 'reactstrap'

const TOOLTIP_COPYTEXT_TIMEOUT = 5000

const CourseMetadataItem = ({
  identifier,
  metadata,
  cssClass,
  icon,
  iconAlt,
  isCalendar,
  startDate,
  endDate,
  tooltipLabel,
}) => {
  const { t } = useTranslation()
  const tooltipTextLabel = tooltipLabel ? tooltipLabel : t('courses.clickToCopy')

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipText, setTooltipText] = useState(tooltipTextLabel)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const handleMetadataClick = (e, text) => {
    e.preventDefault()
    e.stopPropagation()
    setTooltipText(t('courses.copiedToClipboard'))
    setTimeout(() => {
      setTooltipText(tooltipText)
    }, TOOLTIP_COPYTEXT_TIMEOUT)
    setTooltipOpen(true)
    copyTextToClipboard(text)
    return false
  }

  function createMarkup(htmlString) {
    return { __html: htmlString }
  }

  function onMetadataClick(event) {
    const dates = getCourseTimeInterval(startDate, endDate, true)
    handleMetadataClick(event, dates)
  }

  return (
    <li>
      <div className={`courses-card__icon-wrapper courses-card__icon-wrapper__${cssClass}`}>
        <img src={icon} alt={iconAlt} />
      </div>
      {isCalendar && (
        <span
          className="courses-card__metadata"
          onClick={onMetadataClick}
          onKeyDown={(event) => {
            if (['Enter', ' '].includes(event.key)) {
              onMetadataClick()
            }
          }}
          role="button"
          tabIndex="0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createMarkup(getCourseTimeInterval(startDate, endDate, false))}
          id={`${cssClass}-${identifier}`}
        />
      )}
      {!isCalendar && (
        <span
          className="courses-card__metadata"
          onClick={(event) => handleMetadataClick(event, metadata)}
          onKeyDown={(event) => {
            if (['Enter', ' '].includes(event.key)) {
              handleMetadataClick(event, metadata)
            }
          }}
          role="button"
          tabIndex="0"
          id={`${cssClass}-${identifier}`}
        >
          {metadata}
        </span>
      )}
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target={`${cssClass}-${identifier}`}
        toggle={toggle}
      >
        {tooltipText}
      </Tooltip>
    </li>
  )
}

CourseMetadataItem.propTypes = {
  cssClass: PropTypes.string,
  endDate: PropTypes.string,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  identifier: PropTypes.string,
  isCalendar: PropTypes.bool,
  metadata: PropTypes.string,
  startDate: PropTypes.string,
  tooltipLabel: PropTypes.string,
}

export default CourseMetadataItem
