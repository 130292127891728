import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './styles/main.sass'

if (window.Supernova) window.Supernova.setHost(process.env.REACT_APP_IMMERSIVE_READER_HOST)

ReactDOM.render(<App />, document.getElementById('root'))

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('@axe-core/react')
//   axe(React, ReactDOM, 1000)
// }
