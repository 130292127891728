import { actions } from 'store/actions'
import { initialState } from 'store'

const configurationReducer = (state = initialState.configuration, action) => {
  switch (action.type) {
    case actions.CONFIGURATION_GET_SUCCESS:
      // we only need serverMode from configuration API endpoint
      return { serverMode: action.payload.serverMode }
    default:
      return state
  }
}

export default configurationReducer
