import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Button } from '@vitalsource/vstui'
import Breadcrumbs from 'components/Breadcrumbs'
import MastWaves from 'components/MastWaves'
import { StoreContext } from 'store'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { getIsInstructorOrAdmin } from 'store/selectors'

const CourseMast = ({ theme, title, level, courseKey }) => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { course, currentLocation, userAq } = useContext(StoreContext)
  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)
  const color = theme.vstui.color.primary
  let unit = null
  let module = null

  if (level === 'unit' || level === 'module') {
    unit = course.syllabusUnitsMap.filter((unit) => unit.identifier === currentLocation.unit)
    unit = unit[0]
  }

  if (unit && level === 'module') {
    module = unit.modules.filter((module) => module.identifier === currentLocation.module)
    module = module[0]
  }

  const courseHomeLink = bpe.coursePageUrl({ courseKey: currentLocation.course })
  const unitLink = bpe.courseUnitUrl({
    courseKey: currentLocation.course,
    unitIdentifier: currentLocation.unit,
  })

  let resumeWorkUrl = null
  if (course && course.enableResumeWork && course.enrollment && course.enrollment.lastPageVisited) {
    resumeWorkUrl = bpe.contentPageUrl({
      courseKey: currentLocation.course,
      pageIdentifier: course.enrollment.lastPageVisited.identifier,
    })
  }

  const shouldDisplaySettings = course?.enableInstructorSettings && isInstructorOrAdmin

  const settingsPageUrl = bpe.settingsPageUrl({ courseKey })

  return (
    <div className="course-mast" style={{ backgroundColor: color.getShade(7) }}>
      <Container>
        <Breadcrumbs
          level={level}
          unit={unit}
          mod={module}
          unitLink={unitLink}
          courseHomeLink={courseHomeLink}
        />
        <h1 className="course-mast__title" id="course-mast__title">
          {level === 'course' && title === '' && (
            <div className="course-mast__title__placeholder" />
          )}
          {level === 'course' && title !== '' && <>{title}</>}
          {unit && level === 'unit' && (
            <>
              {unit.ordinal ? `${unit.courseLabel} ${unit.ordinal}: ` : ``}
              {unit.title}
            </>
          )}
          {module && level === 'module' && (
            <>
              {module.isContent && (
                <>
                  {module.courseLabel} {module.ordinal}:{' '}
                </>
              )}
              {module.title}
            </>
          )}
        </h1>

        {shouldDisplaySettings && (
          <Button
            className="mt-3 mr-3 mast__button"
            color="white"
            onClick={() => navigate(settingsPageUrl)}
            size="large"
            outline
          >
            {t('nav.accountMenu.settings')}
          </Button>
        )}

        {resumeWorkUrl && (
          <Button
            className="mt-3 mast__button"
            color="white"
            onClick={() => navigate(resumeWorkUrl)}
            size="large"
          >
            {t('nav.resume')}
          </Button>
        )}
      </Container>
      <MastWaves />
    </div>
  )
}

CourseMast.propTypes = {
  courseKey: PropTypes.string,
  level: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.object,
}

export default withTheme(CourseMast)
