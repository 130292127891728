import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Donut from 'components/Donut'
import { ReactComponent as ActivityStatusComplete } from 'images/activity-status-complete.svg'
import { ReactComponent as ActivityStatusInProgress } from 'images/activity-status-in-progress.svg'
import { ReactComponent as ActivityStatusNotStarted } from 'images/activity-status-not-started.svg'
import { ReactComponent as MenuActivityComplete } from 'images/menu-activity-complete.svg'
import { ReactComponent as MenuActivityIncomplete } from 'images/menu-activity-incomplete.svg'
import { ReactComponent as MenuModuleComplete } from 'images/menu-module-check.svg'
import { ReactComponent as MenuModuleIncomplete } from 'images/menu-module-incomplete.svg'
import { ReactComponent as ModuleStatusComplete } from 'images/module-status-complete.svg'
import { ReactComponent as ModuleStatusNotStarted } from 'images/module-status-not-started.svg'

const getModuleCompletionIcon = (completion, tocLevel, desc) => {
  switch (true) {
    case completion < 100 && tocLevel === 'nav':
      return <MenuModuleIncomplete title={desc} />
    case completion === 100 && tocLevel === 'nav':
      return <MenuModuleComplete title={desc} />
    case completion === 0 && tocLevel !== 'nav':
      return <ModuleStatusNotStarted title={desc} />
    case completion > 0 && completion <= 50 && tocLevel !== 'nav':
      return <Donut percentage={completion} />
    case completion > 50 && completion < 100 && tocLevel !== 'nav':
      return <Donut percentage={completion} />
    case completion === 100 && tocLevel !== 'nav':
      return <ModuleStatusComplete title={desc} />
    default:
      return ''
  }
}

const getPageCompletionIcon = (completion, tocLevel, desc) => {
  switch (true) {
    case completion < 100 && tocLevel === 'nav':
      return <MenuActivityIncomplete title={desc} />
    case completion === 100 && tocLevel === 'nav':
      return <MenuActivityComplete title={desc} />
    case completion === 0 && tocLevel !== 'nav':
      return <ActivityStatusNotStarted title={desc} />
    case completion > 0 && completion < 100 && tocLevel !== 'nav':
      return <ActivityStatusInProgress title={desc} />
    case completion === 100 && tocLevel !== 'nav':
      return <ActivityStatusComplete title={desc} />
    default:
      return ''
  }
}

const Completion = props => {
  const { itemType, completion, tocLevel } = props
  const { t } = useTranslation()
  const completionTools = {
    module: {
      icon: getModuleCompletionIcon,
      desc: `${t('toc.moduleCompletion')}: ${completion}%`
    },
    page: {
      icon: getPageCompletionIcon,
      desc: `${t('toc.pageCompletion')}: ${completion}%`
    }
  }
  const completionIcon = completionTools[itemType]['icon'](
    completion,
    tocLevel,
    completionTools[itemType]['desc']
  )

  return <span className="toc__completion">{completionIcon}</span>
}

Completion.propTypes = {
  itemType: PropTypes.string,
  completion: PropTypes.number,
  tocLevel: PropTypes.string
}

export default React.memo(Completion)
