import { useContext } from 'react'
import { StoreContext } from 'store'
import {
  failStatusPolling,
  initStatusPolling,
  resetStatusPolling,
  resolveStatusPolling,
  startStatusPolling,
} from 'store/actions'
import apiEndpoints from 'apiEndpoints'
import { doFetch } from 'store/api/platformApi'
import { API_POLLING_RESPONSES } from 'utils/polling'

const useCourseStatusPolling = () => {
  const { dispatch } = useContext(StoreContext)

  const doPolling = ({ course, onPollingSuccess = () => {}, onPollingFailure = () => {} }) => {
    // One key to find the interval in the dictionary
    const key = course.courseKey

    // The polling request
    const pollingRequest = async () => {
      const url = apiEndpoints().courseCopyStatus(key)
      return doFetch({ url: url, params: { method: 'get', credentials: 'include' } })
    }

    // The condition to stop the polling
    const acceptCondition = (response) => response.status === API_POLLING_RESPONSES.valid

    // Callbacks
    const onSuccess = () => {
      resolveStatusPolling({ dispatch, key })
      resetStatusPolling({ dispatch, key })
      onPollingSuccess(key)
    }

    const onFailure = (e) => {
      failStatusPolling({ dispatch, key, e })
      onPollingFailure()
    }

    // Action to init and start the polling
    initStatusPolling({ dispatch, key, pollingRequest, acceptCondition, onSuccess, onFailure })
    startStatusPolling({ dispatch, key })
  }

  const handleQueuedCoursesPolling = ({ courses, onPollingSuccess }) => {
    courses.forEach((c) => {
      doPolling({ course: c, onPollingSuccess })
    })
  }

  const handleCoursePolling = ({ course, onPollingSuccess }) => {
    doPolling({ course, onPollingSuccess })
  }

  return [handleQueuedCoursesPolling, handleCoursePolling]
}

export default useCourseStatusPolling
