import React from 'react'
import i18n from 'i18n'
import { ASSIGNMENT_LIST_ITEM_TYPES } from 'utils/constants'

const formatDateCellRenderer = (props) => {
  const dateFormat = i18n.t('settingsForm.dateFormat') || 'MM-DD-yyyy'
  if (props.colDef.field === 'dueDate') {
    return <span>{props.value?.format(dateFormat)}</span>
  }

  if (
    props.data.type !== ASSIGNMENT_LIST_ITEM_TYPES.assignment &&
    !props.data.studentAvailability
  ) {
    // Disabled unit or module.
    return <span>{i18n.t('assignmentTable.notAvailable')}</span>
  }

  if (props.value) {
    // Explicit start date
    return <span>{props.value?.format(dateFormat)}</span>
  }

  const ascendantSegment = props.context.findStartDateAscendantSegment(
    props.node.rowIndex,
    props.api
  )
  if (ascendantSegment) {
    // Return inherited date
    return (
      <span className="inherited_date">{ascendantSegment.availableDate?.format(dateFormat)}</span>
    )
  }
  // No date to display
  return null
}

export default formatDateCellRenderer
