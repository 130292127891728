import PropTypes from 'prop-types'
import ModuleType from './ModuleType'

const UnitType = PropTypes.shape({
  courseLabel: PropTypes.string,
  identifier: PropTypes.string,
  modules: PropTypes.arrayOf(ModuleType),
  modulesUrl: PropTypes.string,
  numModules: PropTypes.number,
  ordinal: PropTypes.string,
  pageNumber: PropTypes.number,
  title: PropTypes.string,
  unitId: PropTypes.string,
  url: PropTypes.string,
})

export default UnitType
