import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import { apiStatus } from 'store/api'
import { actions, getConfig } from 'store/actions'
import { isActionLoaded, isActionLoading } from 'store/selectors'
import CreateNewAdoptionsForm from 'components/Adoptions/CreateNewAdoptionForm'
import AdoptionsNav from 'components/Adoptions/AdoptionsNav'
import SidebarLayout from 'components/SidebarLayout'
import Spinner from 'components/Spinner'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { isEmptyObject } from 'utils/functional'
import CustomThemeProvider from 'MUI/CustomThemeProvider'

const Courseware = ({ theme }) => {
  const { t, i18n } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { api, configuration, dispatch, userAq } = useContext(StoreContext)

  const isAdminOrSalesRep = !!userAq && (userAq?.is_admin || userAq?.is_sales_rep)
  const isSalesRepSelfProvisioningEnabled = !!userAq && userAq?.publisher_enable_course_copy
  const isConfigurationLoading = isActionLoading(api, 'CONFIGURATION_GET')
  const isConfigurationLoaded = isActionLoaded(api, 'CONFIGURATION_GET')
  const productionMode = configuration && configuration.serverMode === 'production'

  const configurationStatus = apiStatus({
    action: actions.CONFIGURATION_GET_REQUEST,
    isEmpty: isEmptyObject(configuration) || !configuration.serverMode,
    isLoaded: !isEmptyObject(configuration) && configuration.serverMode,
  })

  useEffect(() => {
    if (configurationStatus.shouldLoad && !isConfigurationLoading) {
      getConfig({ dispatch })
    }
  }, [configurationStatus.shouldLoad, isConfigurationLoading, dispatch])

  if (
    !isAdminOrSalesRep ||
    !isSalesRepSelfProvisioningEnabled ||
    (isConfigurationLoaded && !productionMode)
  ) {
    const coursesUrl = bpe.coursesPageUrl()
    return <Navigate to={coursesUrl} />
  }

  if (isConfigurationLoading) return <Spinner />

  return (
    <CustomThemeProvider>
      <SidebarLayout
        sidebarNavComponent={<AdoptionsNav />}
        cardTitle={t('adoptions.coursewareRegistrationForm')}
        pageTitle={t('adoptions.courseware')}
      >
        <CreateNewAdoptionsForm theme={theme} />
      </SidebarLayout>
    </CustomThemeProvider>
  )
}

export default withTheme(Courseware)

Courseware.propTypes = {
  theme: PropTypes.object,
}
