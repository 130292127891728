import AttemptsAndTimeLimitField from './FormModules/AttemptsAndTimeLimitField'
import { StartDateAndTimeField, DueDateAndTimeField } from './FormModules/DateAndTimeField'
import StudentAvailabilityCheckField from './FormModules/StudentAvailabilityCheckField'
/**
 * SmartForm modules mappings
 */
export const SmartFormModules = {
  AttemptsAndTimeLimitField: AttemptsAndTimeLimitField,
  StartDateAndTimeField: StartDateAndTimeField,
  DueDateAndTimeField: DueDateAndTimeField,
  StudentAvailabilityCheckField: StudentAvailabilityCheckField,
}
