import { actions } from 'store/actions'
import { initialState } from 'store'

const userLkReducer = (state = initialState.userLk, action) => {
  switch (action.type) {
    case actions.USER_LK_SET:
      return {
        ...state,
        ...action.payload
      }
    case actions.USER_LK_UNSET:
      return initialState.userLk
    default:
      return state
  }
}

export default userLkReducer
