import React from 'react'
import PropTypes from 'prop-types'

const TOCLabel = ({ label }) => <span className="toc__label">{label}</span>

TOCLabel.propTypes = {
  label: PropTypes.string,
}

export default TOCLabel
