import moment from 'moment'
import {
  closeConfirmationDialog,
  closeSettingsDrawer,
  setConfirmationDialogMode,
  unsetUnitOrModuleSelection,
} from 'pages/Settings/store/actions'
import {
  ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS,
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES,
  AVAILABLE_DATE_TIME_FORM_FIELDS,
  DUE_DATE_TIME_FORM_FIELDS,
  DATE_AND_TIME_FIELD_MODES,
  DATE_AND_TIME_FIELD_NAMES,
  MULTIPLE_VALUES_OPTION,
  DATE_AND_TIME_SELECT_VALUES,
} from './formDefinitions'
import { error } from 'utils/log'

/**
 * SmartForm helper methods
 */

//---------- Global form helpers

export const localizeOptionsMap = (options, t) => {
  return options.map((option) => {
    const localizedOption = { value: option.value }
    localizedOption.label = option.i18n ? t(option.i18n) : option.label
    return localizedOption
  })
}

export const resolveMultipleValuesMap = (formInitialValues) => {
  return Object.keys(formInitialValues).reduce((acc, field) => {
    const hasMultipleValues =
      field.match(/MultipleValues/) && formInitialValues[field] === true
        ? field.match(/MultipleValues/)
        : null

    if (hasMultipleValues) {
      const multipleValueField = field.slice(0, hasMultipleValues.index)
      acc[multipleValueField] = true
    }
    return acc
  }, {})
}

export const isDateTimeFieldValid = (field) => {
  return field && typeof field === 'object' && 'isValid' in field && field.isValid()
}

export const consolidateDateTime = ({ date, time, timezone }) => {
  if (!isDateTimeFieldValid(date) || !isDateTimeFieldValid(time)) return null
  const formAvailableDateOnly = date.format('YYYY-MM-DD')
  const formAvailableTimeOnly = time.format('HH:mm')
  return moment(`${formAvailableDateOnly} ${formAvailableTimeOnly}`).tz(timezone, true)
}

export const isISODate = (dateString) => {
  if (typeof dateString !== 'string') return false
  return /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(dateString)
}

export const areISODateStringsEqual = (isoA, isoB, { compare = 'date' }) => {
  // ISO Date format: '2021-10-07T15:01:00.000Z'
  if (!isISODate(isoA) || !isISODate(isoB)) {
    error('areISODateStringsEqual: Non ISO dates are being compared.')
    return false
  }
  if (compare === 'date') {
    return isoA.slice(0, isoA.indexOf('T')) === isoB.slice(0, isoB.indexOf('T'))
  } else {
    return (
      isoA.slice(isoA.indexOf('T'), isoA.indexOf('Z')) ===
      isoB.slice(isoB.indexOf('T'), isoB.indexOf('Z'))
    )
  }
}

export const closeDrawer = ({ dispatchSettingsAction, drawerId, isFormDirty }) => {
  if (isFormDirty) {
    return setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'cancel',
        callback: () => {
          closeConfirmationDialog({ dispatchSettingsAction })
          closeSettingsDrawer({ dispatchSettingsAction, drawerId })
        },
      },
    })
  } else {
    closeConfirmationDialog({ dispatchSettingsAction })
    closeSettingsDrawer({ dispatchSettingsAction, drawerId })
    unsetUnitOrModuleSelection({ dispatchSettingsAction })
  }
}

//---------- AttemptsAndTimeLimitField

export const fetchAttemptsAndTimeLimitSetup = () => {
  return {
    formFields: ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS,
    multipleValuesOption: MULTIPLE_VALUES_OPTION,
  }
}

//---------- DateAndTimeField Definitions

export const fetchDateAndTimeFieldSetup = (mode) => {
  return {
    formFields:
      mode === DATE_AND_TIME_FIELD_MODES.availableDate
        ? AVAILABLE_DATE_TIME_FORM_FIELDS
        : DUE_DATE_TIME_FORM_FIELDS,
    selectValues: DATE_AND_TIME_SELECT_VALUES,
    multipleValuesOption: MULTIPLE_VALUES_OPTION,
  }
}

//---------- Exception Definitions

export const fetchEmptyException = () => ({
  // To differentiate null values from placeholders for new exceptions
  // empty exception values are set to undefined
  [ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.maxAttempts.name]: undefined,
  [ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.timeLimit.name]: undefined,
  [DATE_AND_TIME_FIELD_NAMES.availableDate]: undefined,
  [DATE_AND_TIME_FIELD_NAMES.dueDate]: undefined,
})

export const EXCEPTION_SUBMIT_FIELD_NAMES = [
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.timeLimit,
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.maxAttempts,
  DATE_AND_TIME_FIELD_NAMES.availableDate,
  DATE_AND_TIME_FIELD_NAMES.dueDate,
]

/**
 * Compares EXCEPTION_SUBMIT_FIELD_NAMES values between two objects
 * empty, or true, means all fields match
 * @param {object with EXCEPTION_SUBMIT_FIELD_NAMES} exceptionValues
 * @param {object with EXCEPTION_SUBMIT_FIELD_NAMES} defaultValues
 * @returns {boolean}
 */
export const isExceptionEmpty = (exceptionValues, defaultValues) => {
  return EXCEPTION_SUBMIT_FIELD_NAMES.reduce(
    (acc, fieldName) => acc && exceptionValues[fieldName] === defaultValues[fieldName],
    true,
  )
}

/**
 * This method extracts and transforms EXCEPTION_SUBMIT_FIELD_NAMES fields
 * from a single assignmentSelection object.
 * @param {assignmentSelection} assignment
 * @returns EXCEPTION_SUBMIT_FIELD_NAMES object
 */
export const extractDefaultValuesFromAssignment = (assignment) => {
  return EXCEPTION_SUBMIT_FIELD_NAMES.reduce((acc, fieldName) => {
    if (
      (fieldName === DATE_AND_TIME_FIELD_NAMES.availableDate ||
        fieldName === DATE_AND_TIME_FIELD_NAMES.dueDate) &&
      assignment[fieldName] !== null
    ) {
      acc[fieldName] = assignment[fieldName].toISOString()
    } else {
      acc[fieldName] =
        assignment[fieldName] && 'value' in assignment[fieldName]
          ? assignment[fieldName].value
          : assignment[fieldName]
    }
    if (acc[fieldName] === '9999') {
      // Due to sorting requirements, when certain fields are NA, a value of
      // '9999' is set. The real value is actually null.
      acc[fieldName] = null
    }
    return acc
  }, {})
}
