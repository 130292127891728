import React from 'react'
import { useTranslation } from 'react-i18next'

const AccountIcon = () => {
  const { t } = useTranslation()
  return (
    <svg
      id="account-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="-467 269 24 24"
    >
      <title>{t('nav.accountMenu.menu')}</title>
      <path
        fill="#40404C"
        d="M-455 271c5.5 0 10 4.5 10 10s-4.5 10-10 10-10-4.5-10-10 4.5-10 10-10m0-2c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12z"
      />
      <path
        fill="#40404C"
        d="M-451.2 282.9c-.2-.1-1-.6-1.3-.3-.4.4-1.5 1.3-2.5 1.3s-2.1-.9-2.5-1.3c-.3-.3-1.1.2-1.3.3-.6.4-.7.5-1.2 1.1-.3.3-.5.7-.7 1 1.3 1.8 3.4 3 5.7 3 2.4 0 4.5-1.2 5.7-3-.2-.3-.4-.7-.7-1-.4-.5-.6-.7-1.2-1.1zM-451.8 277.4c0 2-1.6 4-3.2 4s-3.2-2.1-3.2-4 1.4-3.6 3.2-3.6 3.2 1.6 3.2 3.6z"
      />
    </svg>
  )
}

export default AccountIcon
