import { COURSE_CLONE_DRAWER_ID, COURSE_INFO_DRAWER_ID } from 'pages/Courses/constants'

export const CoursesActions = {
  DRAWER: {
    CLOSE: 'CLOSE_DRAWER',
    CLOSE_ALL: 'CLOSE_ALL',
    OPEN: 'OPEN_DRAWER',
  },
  CONFIRMATION_DIALOG: {
    CLOSE: 'CLOSE',
    SET_MODE: 'SET_MODE',
  },
  SET_SELECTION: 'SET_SELECTION',
  GET_SELECTION: 'GET_SELECTION',
  UNSET_SELECTION: 'UNSET_SELECTION',
}

export const closeConfirmationDialog = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({
    type: CoursesActions.CONFIRMATION_DIALOG.CLOSE,
  })

export const closeAllDrawers = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({ type: CoursesActions.DRAWER.CLOSE_ALL })

export const closeCourseInfoDrawer = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({ type: CoursesActions.DRAWER.CLOSE, payload: COURSE_INFO_DRAWER_ID })

export const openDrawer = ({ dispatchCoursesAction, drawerId, course }) =>
  dispatchCoursesAction({
    type: CoursesActions.DRAWER.OPEN,
    payload: { drawerId: drawerId, course: course },
  })

export const openCourseInfoDrawer = ({ dispatchCoursesAction, course }) =>
  openDrawer({ dispatchCoursesAction, drawerId: COURSE_INFO_DRAWER_ID, course })

export const openCloneDrawer = ({ dispatchCoursesAction, course }) =>
  openDrawer({ dispatchCoursesAction, drawerId: COURSE_CLONE_DRAWER_ID, course })

export const closeCourseCloneDrawer = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({ type: CoursesActions.DRAWER.CLOSE, payload: COURSE_CLONE_DRAWER_ID })

export const selectCourse = ({ dispatchCoursesAction, selection }) =>
  dispatchCoursesAction({ type: CoursesActions.SET_SELECTION, payload: selection })

export const getSelectedCourse = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({ type: CoursesActions.GET_SELECTION })

export const unsetSelectedCourse = ({ dispatchCoursesAction }) =>
  dispatchCoursesAction({ type: CoursesActions.UNSET_SELECTION })
