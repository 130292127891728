import { actions } from 'store/actions'
import { initialState } from 'store'
import { API_POLLING_STATUS, extractPollingKey, getNewPoll, pollingMethod } from 'utils/polling'

const courseCopyStatusReducer = (state = initialState.courseCopyStatus, action) => {
  let pollingKey
  let currentPollState
  let pollsState

  switch (action.type) {
    case actions.COURSE_COPY_STATUS_POLLING_INIT:
      pollingKey = extractPollingKey(action)
      if (!pollingKey || state[pollingKey] !== undefined) {
        return state
      }
      return {
        ...state,
        [pollingKey]: { ...getNewPoll(action.payload) },
      }

    case actions.COURSE_COPY_STATUS_POLLING_START:
      pollingKey = extractPollingKey(action)
      if (!pollingKey || state[pollingKey].state.status !== API_POLLING_STATUS.notStarted) {
        return state
      }
      currentPollState = state[pollingKey]
      return {
        ...state,
        [pollingKey]: {
          state: {
            error: null,
            status: API_POLLING_STATUS.running,
          },
          nextCall: setInterval(
            pollingMethod(currentPollState),
            currentPollState.definition.rate * 1000,
          ),
        },
      }

    case actions.COURSE_COPY_STATUS_POLLING_SUCCESS:
      pollingKey = extractPollingKey(action)
      if (!pollingKey) {
        return state
      }
      currentPollState = state[pollingKey]
      clearInterval(currentPollState.nextCall)
      return {
        ...state,
        [pollingKey]: {
          state: {
            error: null,
            status: API_POLLING_STATUS.resolved,
          },
          nextCall: null,
        },
      }

    case actions.COURSE_COPY_STATUS_POLLING_FAIL:
      pollingKey = extractPollingKey(action)
      if (!pollingKey) {
        return state
      }
      currentPollState = state[pollingKey]
      clearInterval(currentPollState.nextCall)
      return {
        ...state,
        [action.payload.key]: {
          state: {
            error: action.payload,
            status: API_POLLING_STATUS.error,
          },
          nextCall: null,
        },
      }

    case actions.COURSE_COPY_STATUS_POLLING_RESET:
      pollingKey = extractPollingKey(action)
      if (!pollingKey) {
        return state
      }
      currentPollState = state[pollingKey]
      if (!currentPollState) {
        return state
      }
      if (currentPollState.nextCall) {
        clearInterval(currentPollState.nextCall)
      }
      pollsState = { ...state }
      delete pollsState[pollingKey]
      return pollsState

    default:
      return state
  }
}

export default courseCopyStatusReducer
