import { SmartFormModules } from './SmartFormModules'
import { isDateTimeFieldValid } from './smartFormHelpers'
import {
  DATE_AND_TIME_FIELD_TYPES,
  DATE_AND_TIME_SELECT_VALUES,
  AVAILABLE_DATE_TIME_FORM_FIELDS,
  DUE_DATE_TIME_FORM_FIELDS,
  ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS,
  MULTIPLE_VALUES_OPTION,
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES,
  DATE_AND_TIME_FIELD_NAMES,
  STUDENT_AVAILABILITY_CHECK_FORM_FIELDS,
} from './formDefinitions'

/**
 * Array of form field names that can be edited inside the form.
 * These string values must match the submit value keys.
 */
const EDITABLE_FIELDS = [
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.maxAttempts,
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.timeLimit,
  DATE_AND_TIME_FIELD_NAMES.availableDate,
  DATE_AND_TIME_FIELD_NAMES.dueDate,
]

/**
 * Based on behavior, FIELD_SUPPORT_MAP, maps behaviors to required module names.
 * Each behavior is mapped to an array of form field names.
 * These values are the same used on EDITABLE_FIELDS.
 * These string values must match the submit value keys.
 */
const FIELD_SUPPORT_MAP = {
  allowMultipleAttempts: [ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.maxAttempts],
  supportsTimeLimit: [ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.timeLimit],
  supportsScheduling: [DATE_AND_TIME_FIELD_NAMES.availableDate, DATE_AND_TIME_FIELD_NAMES.dueDate],
}

/**
 * MODULES_MAP maps available SmartModules to an array of dependencies.
 * The array of dependencies is formed by strings of fields found in the form at the moment of form creation.
 * It's important to notice that certain fields, like date/time fields are extended before reaching the form.
 * Values in these arrays might not be in the submit form, as they could be an intermediate step.
 */
const MODULES_MAP = {
  attemptsTimeLimitModule: {
    dependencies: [
      ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.maxAttempts.name,
      ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.timeLimit.name,
    ],
    markup: SmartFormModules.AttemptsAndTimeLimitField,
  },
  availableDateModule: {
    dependencies: [AVAILABLE_DATE_TIME_FORM_FIELDS.date.name],
    markup: SmartFormModules.StartDateAndTimeField,
  },
  dueDateModule: {
    dependencies: [DUE_DATE_TIME_FORM_FIELDS.date.name],
    markup: SmartFormModules.DueDateAndTimeField,
  },
}

/**
 * fieldsToSkip generates an array of field names as defined in FIELD_SUPPORT_MAP
 * for which current assignmentSelection has no support.
 * This fields are then removed from the form, so no SmartModules are added.
 */
const resolveFieldsToSkip = (selection) => {
  return selection.reduce((acc, assignment) => {
    Object.keys(assignment.behavior).forEach((behaviorType) => {
      if (!assignment.behavior[behaviorType] && FIELD_SUPPORT_MAP[behaviorType]) {
        acc.push(...FIELD_SUPPORT_MAP[behaviorType])
      }
    })
    return acc
  }, [])
}

/**
 * This method uses the MODULE_MAP definition to generate an array
 * of SmartModules that are required based on the initialValues
 * generated by getFormInitialValues
 * @param {} SmartForm initial values object
 * @returns [] SmartModules
 */
const getFormModules = (initialValues) => {
  const modules = []
  const fieldsToEdit = Object.keys(initialValues)
  Object.keys(MODULES_MAP).forEach((moduleDef) => {
    const moduleDeps = MODULES_MAP[moduleDef].dependencies
    if (moduleDeps.every((dep) => fieldsToEdit.includes(dep))) {
      const moduleMarkup = MODULES_MAP[moduleDef].markup
      modules.push(moduleMarkup)
    }
  })
  return modules
}

/**
 * This method takes the assignmentSelection array and defines initial values
 * and SmartModules for assignment settings.
 * @param {array of selected assignments} selection
 * @returns [SmartModules]
 */
export const generateAssignmentScheduleFormData = (selection) => {
  const selectedAssignmentTypes = []

  /**
   * This method generates SmartForm initial values object based on the assignment
   * selection.
   * @param {array of selected assignments} selection
   * @returns {
   *  formInitialValues: SmartForm initial values object,
   *  formModules: SmartModules array
   * }
   */
  const getFormInitialValues = (selection) => {
    const fieldsToSkip = resolveFieldsToSkip(selection)
    // Provide a consistent set of initial values for each EDITABLE_FIELD
    const initialValues = EDITABLE_FIELDS.reduce((result, fieldName) => {
      result[fieldName] = undefined
      return result
    }, {})

    // Iterate assignment selection in order to reduce their field values into the final
    // initial values for each field.
    selection.forEach((assignment) => {
      // Get assessment type
      if (!selectedAssignmentTypes.includes(assignment.purposeType)) {
        selectedAssignmentTypes.push(assignment.purposeType)
      }

      // Iterate EDITABLE_FIELDS to stablish initial values for each one.
      EDITABLE_FIELDS.forEach((fieldName) => {
        if (typeof initialValues[fieldName] === 'undefined') {
          // No value was previously defined for the field, so we set one.
          initialValues[fieldName] = assignment[fieldName]?.value || assignment[fieldName]
        } else {
          // A value for the field was already defined, compare and define action.
          let initialValue
          let establishedValue

          if (isDateTimeFieldValid(assignment[fieldName])) {
            // Date field value comparison
            initialValue = isDateTimeFieldValid(initialValues[fieldName])
              ? initialValues[fieldName].toISOString()
              : initialValues[fieldName]

            establishedValue = assignment[fieldName].toISOString()
          } else {
            // Non date fields
            initialValue = initialValues[fieldName]
            establishedValue = assignment[fieldName]?.value || assignment[fieldName]
          }

          if (initialValue !== establishedValue) {
            // initialValue and establishedValue miss match means there are multiple
            // values for the field.
            initialValues[fieldName] = MULTIPLE_VALUES_OPTION.value
            initialValues[`${fieldName}MultipleValues`] = true
          }
        }
      })

      // Remove unsupported fields
      fieldsToSkip.forEach((fieldName) => delete initialValues[fieldName])
    })

    /**
     * Extend scheduling fields: date - time
     * For each field defined in FIELD_SUPPORT_MAP: supportsScheduling
     * date - field modules are extended based their values.
     */
    FIELD_SUPPORT_MAP.supportsScheduling.forEach((fieldName) => {
      if (fieldName in initialValues) {
        const scheduleFieldValue = initialValues[fieldName]
        // Field is deleted since it's going to be extended.
        delete initialValues[fieldName]

        if (scheduleFieldValue === MULTIPLE_VALUES_OPTION.value) {
          // Multiple values
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            MULTIPLE_VALUES_OPTION.value
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = null
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = null
          initialValues[`${fieldName}MultipleValues`] = true
        }

        if (scheduleFieldValue === null) {
          // There's no defined date
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.anyTime
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = null
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = null
          initialValues[`${fieldName}MultipleValues`] = false
        }

        if (scheduleFieldValue && scheduleFieldValue.isValid()) {
          // Specific value is set
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.definedDate
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = scheduleFieldValue
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = scheduleFieldValue
          initialValues[`${fieldName}MultipleValues`] = false
        }
      }
    })
    //
    return initialValues
  }

  const formInitialValues = getFormInitialValues(selection)
  const formModules = getFormModules(formInitialValues)

  return { formInitialValues, formModules }
}

export const generateExceptionFormData = (selection, userException) => {
  /**
   * This method generates SmartForm initial values object based on the assignment
   * selection.
   * @param {array of selected assignments} selection
   * @returns {
   *  formInitialValues: SmartForm initial values object,
   *  formModules: SmartModules array
   * }
   */
  const getFormInitialValues = (selection, userException) => {
    const fieldsToSkip = resolveFieldsToSkip(selection)

    // Provide established exception value or course default to EDITABLE_FIELDs
    const formValues = EDITABLE_FIELDS.reduce(
      (result, fieldName) => {
        if (userException?.exception[fieldName] === null) {
          // Date values can be null and should not be overwritten by defaults
          result.initialValues[fieldName] = null
        } else {
          result.initialValues[fieldName] =
            userException?.exception[fieldName]?.value ||
            userException?.exception[fieldName] ||
            selection[0][fieldName]?.value ||
            selection[0][fieldName]
        }
        result.defaultValues[fieldName] = selection[0][fieldName]?.value || selection[0][fieldName]
        return result
      },
      { initialValues: {}, defaultValues: {} }
    )

    const { initialValues, defaultValues } = formValues

    initialValues.uuid = userException?.exception?.uuid || null
    initialValues.userId = userException.user.id

    fieldsToSkip.forEach((fieldName) => delete initialValues[fieldName])

    /**
     * Extend scheduling fields: date - time
     * For each field defined in FIELD_SUPPORT_MAP: supportsScheduling
     * date - field modules are extended based their values.
     */
    FIELD_SUPPORT_MAP.supportsScheduling.forEach((fieldName) => {
      if (fieldName in initialValues) {
        const scheduleFieldValue = initialValues[fieldName]
        // Field is deleted since it's going to be extended.
        delete initialValues[fieldName]

        if (scheduleFieldValue === null) {
          // Date is Any Time
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.anyTime
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = null
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = null
          initialValues[`${fieldName}MultipleValues`] = false
        }

        if (scheduleFieldValue && scheduleFieldValue.isValid()) {
          // Specific value is set
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.definedDate
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = scheduleFieldValue
          initialValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = scheduleFieldValue
          initialValues[`${fieldName}MultipleValues`] = false
        }
      }
      if (fieldName in defaultValues) {
        const scheduleFieldValue = defaultValues[fieldName]
        // Field is deleted since it's going to be extended.
        delete defaultValues[fieldName]

        if (scheduleFieldValue === null) {
          // Date is Any Time. Set default values for comparison
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.anyTime
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] = null
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] = null
        }

        if (scheduleFieldValue && scheduleFieldValue.isValid()) {
          // Specific value is set. Set default values for comparison
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.select}`] =
            DATE_AND_TIME_SELECT_VALUES.definedDate
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.date}`] =
            scheduleFieldValue.toISOString()
          defaultValues[`${fieldName}${DATE_AND_TIME_FIELD_TYPES.time}`] =
            scheduleFieldValue.toISOString()
        }
      }
    })
    return { initialValues, defaultValues }
  }

  const { initialValues: formInitialValues, defaultValues } = getFormInitialValues(
    selection,
    userException
  )
  const formModules = getFormModules(formInitialValues)

  return { formInitialValues, formModules, defaultValues }
}

export const generateUnitsAndModulesFormData = (selection) => {
  /**
   * This method generates SmartForm initial values object based on the Module or Unit selected
   * @param {Unit or Module} selection
   * @returns {
   *  formInitialValues: SmartForm initial values object,
   *  formModules: SmartModules array
   * }
   */
  const getFormInitialValues = (selection) => {
    if (!selection) return {}

    const availableDateKey = DATE_AND_TIME_FIELD_NAMES.availableDate
    const initialValues = {
      [availableDateKey]: selection[availableDateKey]?.value || selection[availableDateKey],
    }

    const scheduleFieldValue = initialValues[availableDateKey]
    // Field is deleted since it's going to be extended.
    delete initialValues[availableDateKey]

    if (scheduleFieldValue === null) {
      // Date is Any Time
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.select}`] =
        DATE_AND_TIME_SELECT_VALUES.anyTime
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.date}`] = null
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.time}`] = null
      initialValues[`${availableDateKey}MultipleValues`] = false
    }

    if (scheduleFieldValue && scheduleFieldValue.isValid()) {
      // Specific value is set
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.select}`] =
        DATE_AND_TIME_SELECT_VALUES.definedDate
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.date}`] = scheduleFieldValue
      initialValues[`${availableDateKey}${DATE_AND_TIME_FIELD_TYPES.time}`] = scheduleFieldValue
      initialValues[`${availableDateKey}MultipleValues`] = false
    }

    initialValues[STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name] =
      selection[STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name]

    return initialValues
  }

  const formInitialValues = getFormInitialValues(selection)

  return { formInitialValues }
}
