import { initialState } from 'store'
import {
  accountCenterUrlReducer,
  apiErrorReducer,
  apiLoadedReducer,
  apiLoadingReducer,
  apiReauthReducer,
  assignmentPurposeTypesReducer,
  assignmentsExceptionsReducer,
  assignmentsReducer,
  assignmentTypesReducer,
  authoringUserActionsReducer,
  authReducer,
  authUserAccessReducer,
  brandedSigninCustomerDataReducer,
  cartUrlReducer,
  checkUserExistReducer,
  configurationReducer,
  courseAccessRequirementsReducer,
  courseCopyReducer,
  courseCopyStatusReducer,
  courseMessagesReducer,
  courseReducer,
  courseScheduleReducer,
  courseSettingsReducer,
  coursesReducer,
  currentLocationReducer,
  customerInstructorsReducer,
  customerReducer,
  enrollmentReducer,
  enrollmentsReducer,
  externalModulesReducer,
  profileReducer,
  protectedRouterCurrentPathReducer,
  resetAssessmentsReducer,
  scheduleReducer,
  termsReducer,
  themeReducer,
  userAqHasAccessReducer,
  userAqReducer,
  userLkReducer,
  userPublisherBlueprintsReducer,
  userPublisherCustomersReducer,
} from 'store/reducers'
import devTools from 'utils/devTools'

// Redux DevTools integration, super useful to inspect state changes
// https://github.com/reduxjs/redux-devtools
const useDevTools =
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__

const devToolsSendAction = useDevTools ? devTools({ initialState }) : null

const rootReducer = (state = initialState, action) => {
  const newState = {
    api: {
      error: apiErrorReducer(state.api.error, action),
      loading: apiLoadingReducer(state.api.loading, action),
      loaded: apiLoadedReducer(state.api.loaded, action),
      isReauthRequired: apiReauthReducer(state.api.isReauthRequired, action),
    },
    // Passing a 3rd parameter, which is considered a `dependencies` object
    assignments: assignmentsReducer(state.assignments, action, {
      userAq: Object.assign({}, state.userAq),
    }),
    assignmentsExceptions: assignmentsExceptionsReducer(state.assignmentsExceptions, action, {
      userAq: Object.assign({}, state.userAq),
    }),
    assignmentTypes: assignmentTypesReducer(state.assignmentTypes, action),
    assignmentPurposeTypes: assignmentPurposeTypesReducer(state.assignmentPurposeTypes, action),
    authoringUserActions: authoringUserActionsReducer(state.authoringUserActions, action),
    brandedSigninCustomerData: brandedSigninCustomerDataReducer(
      state.brandedSigninCustomerData,
      action,
    ),
    cartUrl: cartUrlReducer(state.cartUrl, action),
    checkUserExist: checkUserExistReducer(state.checkUserExist, action),
    configuration: configurationReducer(state.configuration, action),
    // Passing a 3rd parameter, which is considered a `dependencies` object
    course: courseReducer(state.course, action, {
      courseSchedule: state.courseSchedule,
    }),
    courses: coursesReducer(state.courses, action),
    courseAccessRequirements: courseAccessRequirementsReducer(
      state.courseAccessRequirements,
      action,
    ),
    courseMessages: courseMessagesReducer(state.courseMessages, action),
    courseSettings: courseSettingsReducer(state.courseSettings, action),
    currentLocation: currentLocationReducer(state.currentLocation, action),
    customer: customerReducer(state.customer, action),
    enrollment: enrollmentReducer(state.enrollment, action),
    enrollments: enrollmentsReducer(state.enrollments, action),
    theme: themeReducer(state.theme, action),
    userAq: userAqReducer(state.userAq, action),
    userAqHasAccess: userAqHasAccessReducer(state.userAqHasAccess, action),
    userLk: userLkReducer(state.userLk, action),
    auth: authReducer(state.auth, action),
    profile: profileReducer(state.profile, action),
    terms: termsReducer(state.terms, action),
    protectedRouterCurrentPath: protectedRouterCurrentPathReducer(
      state.protectedRouterCurrentPath,
      action,
    ),
    authUserAccess: authUserAccessReducer(state.authUserAccessReducer, action),
    accountCenterUrl: accountCenterUrlReducer(state.accountCenterUrl, action),
    schedule: scheduleReducer(state.schedule, action),
    externalModules: externalModulesReducer(state.externalModules, action),
    resetAssessmentsMessages: resetAssessmentsReducer(state.resetAssessmentsMessages, action),
    courseSchedule: courseScheduleReducer(state.courseSchedule, action),
    courseCopy: courseCopyReducer(state.courseCopy, action),
    courseCopyStatus: courseCopyStatusReducer(state.courseCopyStatus, action),
    userPublisherBlueprints: userPublisherBlueprintsReducer(state.userPublisherBlueprints, action),
    userPublisherCustomers: userPublisherCustomersReducer(state.userPublisherCustomers, action),
    customerInstructors: customerInstructorsReducer(state.customerInstructors, action),
  }

  if (useDevTools) devToolsSendAction(action, newState)
  return newState
}

export default rootReducer
