import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import MUIAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useField } from 'formik'
import { CustomTextField } from './TextEdit'

const filter = createFilterOptions({
  stringify: (option) => option.label,
})

const Autocomplete = (props) => {
  const [field, meta, helpers] = useField(props)

  const getHelperText = () => {
    if (meta.touched && meta.error) {
      return meta.error
    }

    if (props.helperText) {
      return props.helperText
    }

    return null
  }

  return (
    <MUIAutocomplete
      value={field.value}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          helpers.setValue({
            label: newValue,
          })
        }

        if (props.multiple) {
          const values = newValue.map((v) => (typeof v === 'string' ? { label: v } : v))
          helpers.setValue(values)
        } else {
          if (typeof newValue === 'string') {
            helpers.setValue({
              label: newValue,
            })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            // if the new value is not blank
            if (newValue.inputValue.trim() !== '') {
              helpers.setValue({
                label: newValue.inputValue,
              })
            }
          } else {
            helpers.setValue(newValue)
          }
        }

        props.onChange(newValue)
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        // Suggest the creation of a new value
        if (
          props.allowAddingNewOptions &&
          params.inputValue !== '' &&
          !options.filter((o) => o.label === params.inputValue).length
        ) {
          filtered.push({
            value: params.inputValue,
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`,
          })
        }

        return filtered
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={props.name}
      name={props.name}
      options={props.options}
      getOptionLabel={(option) => {
        if (Array.isArray(option) && !option.length) return ''

        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option
        }

        // Add "xxx" option created dynamically
        if (option?.inputValue) {
          return option.inputValue
        }

        // Regular option
        return option.label
      }}
      renderOption={(optionProps, option) => {
        if (props.customOptionLabel) {
          return <li {...optionProps}>{props.customOptionLabel(option)}</li>
        } else {
          return <li {...optionProps}>{option.label}</li>
        }
      }}
      freeSolo={props.allowAddingNewOptions}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          helperText={getHelperText()}
          label={props.required ? `${props.label} *` : props.label}
          InputProps={{
            ...params.InputProps,

            inputProps: {
              ...params.inputProps,
              autoComplete: props.inputAutoComplete,
            },
          }}
          error={meta.touched && meta.error ? true : false}
          fullWidth
          variant="outlined"
          margin="dense"
          name={props.name}
          type={props.type}
        />
      )}
      disabled={props.disabled}
      required={props.required}
      loading={props.loading}
      multiple={props.multiple}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={option.value}
            variant="outlined"
            label={option.inputValue || option.label}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  )
}

export default Autocomplete

Autocomplete.propTypes = {
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  allowAddingNewOptions: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  editedField: PropTypes.bool,
  helperText: PropTypes.string,
  inputAutoComplete: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.bool,
  showEmptyLabel: PropTypes.bool,
  showSubTitle: PropTypes.bool,
  customOptionLabel: PropTypes.func,
}

Autocomplete.defaultProps = {
  theme: {},
  allowAddingNewOptions: false,
  disabled: false,
  editedField: false,
  inputAutoComplete: 'off',
  loading: false,
  multiple: false,
  type: 'text',
  onChange: () => {},
  options: [],
  required: false,
  showEmptyLabel: false,
  showSubTitle: false,
  customOptionLabel: null,
}
