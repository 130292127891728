import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import {
  createAdoption,
  getCreateNewAdoptionsFormRequiredData,
  getCustomerInstructors,
  unsetCreateNewAdoptionsFormData,
  unsetCustomerInstructors,
  unsetUserPublisherBlueprints,
  unsetUserPublisherCustomers,
} from 'store/actions'
import { actionHasErrors, isActionLoaded, isActionLoading, isLoading } from 'store/selectors'

const useAdoptions = () => {
  const { t } = useTranslation()
  const { api } = useContext(StoreContext)
  const isApiLoading = isLoading(api)
  const isSubmitting = isActionLoading(api, 'CREATE_ADOPTION_POST')
  const hasErrorsLoadingRequiredData =
    actionHasErrors(api, 'USER_PUBLISHER_BLUEPRINTS_GET') ||
    actionHasErrors(api, 'USER_PUBLISHER_CUSTOMERS_GET')
  const hasSuccessfullySubmitted =
    isActionLoaded(api, 'CREATE_ADOPTION_POST') && !actionHasErrors(api, 'CREATE_ADOPTION_POST')
  const hasSubmittedWithErrors =
    isActionLoaded(api, 'CREATE_ADOPTION_POST') && actionHasErrors(api, 'CREATE_ADOPTION_POST')
  const isLoadingUserPublisherCustomers = isActionLoading(api, 'USER_PUBLISHER_CUSTOMERS_GET')
  const isLoadingUserPublisherBlueprints = isActionLoading(api, 'USER_PUBLISHER_BLUEPRINTS_GET')
  const isLoadingInstructors = isActionLoading(api, 'CUSTOMER_INSTRUCTORS_GET')
  const isLoadingRequiredData = isLoadingUserPublisherCustomers || isLoadingUserPublisherBlueprints

  const getMessageType = () => {
    let type
    switch (true) {
      case hasSuccessfullySubmitted:
        type = 'success'
        break
      case hasErrorsLoadingRequiredData:
        type = 'danger'
        break
      default:
        type = 'danger'
        break
    }
    return type
  }
  const getMessage = () => {
    let message
    switch (true) {
      case hasSuccessfullySubmitted:
        message = t('adoptions.success')
        break
      case hasSubmittedWithErrors:
        message = t('adoptions.errorSubmitting')
        break
      case hasErrorsLoadingRequiredData:
        message = t('adoptions.errorGettingRequiredData')
        break
      default:
        break
    }
    return message
  }

  const shouldDisplayAlert =
    hasSubmittedWithErrors || hasSuccessfullySubmitted || hasErrorsLoadingRequiredData

  return {
    createAdoption,
    getCreateNewAdoptionsFormRequiredData,
    getCustomerInstructors,
    getMessage,
    getMessageType,
    hasErrorsLoadingRequiredData,
    hasSubmittedWithErrors,
    hasSuccessfullySubmitted,
    isApiLoading,
    isLoadingInstructors,
    isLoadingRequiredData,
    isLoadingUserPublisherBlueprints,
    isLoadingUserPublisherCustomers,
    isSubmitting,
    shouldDisplayAlert,
    unsetCreateNewAdoptionsFormData,
    unsetCustomerInstructors,
    unsetUserPublisherBlueprints,
    unsetUserPublisherCustomers,
  }
}

export default useAdoptions
