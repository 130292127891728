import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { ReactComponent as MenuModuleChevron } from 'images/menu-module-chevron.svg'
import { ReactComponent as MenuUnitChevron } from 'images/menu-unit-chevron.svg'
import { ReactComponent as UnitChevron } from 'images/unit-chevron.svg'

function onTogglerKeyPress(event, onClick) {
  /*
   * As <Toggler> is actually a div and not a button, we need a keyPress handler
   * for accessibility. We will trigger onClick handler when user presses either the
   * enter or space key
   */
  switch (event.key) {
    case ' ':
      if (onClick) onClick(event)
      break
    case 'Enter':
      if (onClick) onClick(event)
      break
    default:
      break
  }
}

const Toggler = props => {
  const { onClick, isOpen, itemType, tocLevel, collapseId } = props
  const { t } = useTranslation()
  const actionDescription = isOpen ? t('toc.collapse') : t('toc.expand')

  return (
    <div
      className={classnames({
        toc__toggler: true,
        'toc__toggler--open': isOpen
      })}
      role="button"
      tabIndex="0"
      aria-expanded={isOpen}
      aria-controls={collapseId}
      onClick={event => {
        event.currentTarget.blur()
        onClick(event)
      }}
      onKeyPress={event => onTogglerKeyPress(event, onClick)}
    >
      {tocLevel === 'nav' && itemType === 'module' && (
        <MenuModuleChevron className="toc__toggler-icon" aria-hidden="true" />
      )}
      {tocLevel === 'nav' && itemType === 'unit' && (
        <MenuUnitChevron className="toc__toggler-icon" aria-hidden="true" />
      )}
      {tocLevel !== 'nav' && <UnitChevron className="toc__toggler-icon" aria-hidden="true" />}
      <span className="sr-only">{actionDescription}</span>
    </div>
  )
}

Toggler.propTypes = {
  isOpen: PropTypes.bool,
  itemType: PropTypes.string,
  onClick: PropTypes.func,
  tocLevel: PropTypes.string,
  collapseId: PropTypes.string
}

export default React.memo(Toggler)
