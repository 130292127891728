import { useState } from 'react'
import { isFunction } from 'utils/functional'

const useFormHook = (initialState, formSubmitHandler, formValidator) => {
  const [blurredFrom, setBlurredFrom] = useState()
  const [errors, setErrors] = useState({})
  const [inputs, setInputs] = useState(initialState || {})

  const handleSubmit = event => {
    if (event) {
      event.preventDefault()
    }

    let hasError = false
    if (isFunction(formValidator)) {
      const formErrors = formValidator(inputs)
      hasError = formErrors && Object.keys(formErrors).length > 0

      if (hasError) {
        setErrors({
          ...errors,
          ...formErrors
        })
      }
    }

    if (hasError) {
      return
    }

    setErrors({})

    if (isFunction(formSubmitHandler)) {
      formSubmitHandler(inputs)
    }
  }

  const getFieldName = input => {
    const dataFieldName = input.getAttribute('data-field-name')
    if (dataFieldName) {
      return dataFieldName
    }

    const { name, id } = input

    if (name || id) {
      return name || id
    }

    return `field_${inputs.length}`
  }

  const getFieldValue = input => {
    const { value } = input
    const transformer = input.getAttribute('data-field-transformer')

    if (isFunction(transformer)) {
      transformer(value)
    }

    return value
  }

  const clearFieldError = field => {
    setErrors({ ...errors, [field]: '' })
  }

  const handleInputChange = event => {
    event.persist()

    const { target } = event
    const field = getFieldName(target)

    clearFieldError(field)

    setInputs(inputs => ({
      ...inputs,
      [field]: getFieldValue(target)
    }))
  }

  const resetForm = callback => {
    setInputs(initialState || {})
    setErrors({})

    if (isFunction(callback)) {
      callback()
    }
  }

  const destruct = (obj, ...keys) => keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {})

  const handleFieldBlur = event => {
    const { target } = event
    const field = getFieldName(target)

    if (isFunction(formValidator)) {
      const formErrors = formValidator(inputs)
      const hasErrors = formErrors && Object.keys(formErrors).length > 0
      const thisFieldHasErrors = hasErrors && formErrors[field]

      if (thisFieldHasErrors) {
        const thisFieldErrors = destruct(formErrors, field)
        setErrors({
          ...errors,
          ...thisFieldErrors
        })
      }
    }

    setBlurredFrom(field)
  }

  return {
    blurredFrom,
    errors,
    handleFieldBlur,
    handleInputChange,
    handleSubmit,
    inputs,
    resetForm,
    setErrors
  }
}

export default useFormHook
