/* eslint-disable no-console */
const DEV = process.env.NODE_ENV !== 'production'

export const debug = (...args) => DEV && console.debug(...args)

export const error = (...args) => DEV && console.error(...args)

export const info = (...args) => DEV && console.info(...args)

export const log = (...args) => DEV && console.log(...args)

export const warn = (...args) => DEV && console.warn(...args)

export const table = (...args) => DEV && console.table(...args)

export const group = (...args) => DEV && console.group(...args)

export const groupEnd = (...args) => DEV && console.groupEnd(...args)

export const devConsole = {
  debug,
  error,
  info,
  warn,
  log,
  table,
  group,
  groupEnd,
}
