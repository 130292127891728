export const DEFAULT_THEME = {
  primaryColor: 'ocean',
  secondaryColor: 'jungle',
}

export const VITALSOURCE_THEME = {
  primaryColor: 'magic',
  secondaryColor: 'magic',
}

export const USER_ROLES = {
  INSTRUCTOR: 'Instructor',
  MENTOR: 'Mentor',
  STUDENT: 'Student',
}

//------- Assignment Settings

export const ASSIGNMENT_LIST_ITEM_TYPES = {
  unit: 'unit',
  module: 'module',
  assignment: 'assessment',
}

export const ASSIGNMENT_LIST_COLUMN_FIELDS = {
  checker: 'checker',
  edit_action: 'editAction',
  title: 'title',
  type: 'type',
  max_attempts: 'maxAttempts',
  time_limit: 'timeLimit',
  student_exceptions: 'studentExceptions',
  available_date: 'availableDate',
  due_date: 'dueDate',
}

export const DEFAULT_DRAWER_ID = 'default'
export const EXCEPTIONS_DRAWER_ID = 'exceptions'
export const EDIT_EXCEPTIONS_DRAWER_ID = 'editExceptions'
export const UNITS_AND_MODULES_DRAWER_ID = 'unitsAndModules'

//------- End assignment settings

let platformHost = process.env.REACT_APP_PLATFORM_HOST || ''
// remove trailing slashes
if (platformHost.endsWith('/')) {
  platformHost = platformHost.slice(0, -1)
}

const getDynamicPlatformHost = () => {
  const protocol = window.location.protocol
  const slashes = protocol.concat('//')
  const hostname = window.location.hostname.replace('learn.', '')
  return slashes.concat(hostname)
}

export const PLATFORM_HOST = platformHost === '' ? getDynamicPlatformHost() : platformHost

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || ''

export const CONTACT_SUPPORT_URL =
  'https://support.vitalsource.com/hc/en-us/requests/new?ticket_form_id=360000472494'

export const DEFAULT_CUSTOMER = 'aqpublisher'

export const DEFAULT_LANG_CODE = 'en'
