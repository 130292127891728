import { CoursesActions } from './actions/coursesActions'
import { coursesInitialState } from './coursesContext'

export const coursesReducer = (state = coursesInitialState, action) => {
  let newState = {}
  switch (action.type) {
    case CoursesActions.CONFIRMATION_DIALOG.CLOSE: {
      newState = {
        ...state,
        confirmationDialogMode: coursesInitialState.confirmationDialogMode,
      }
      break
    }
    case CoursesActions.CONFIRMATION_DIALOG.SET_MODE: {
      newState = {
        ...state,
        confirmationDialogMode: action.payload,
      }
      break
    }
    case CoursesActions.DRAWER.CLOSE: {
      newState = {
        ...state,
        isDrawerOpen: { ...state.isDrawerOpen, [action.payload]: false },
        isFormDirty: false,
        selectedCourse: {},
      }
      break
    }
    case CoursesActions.DRAWER.CLOSE_ALL: {
      newState = {
        ...state,
        isDrawerOpen: coursesInitialState.isDrawerOpen,
        isFormDirty: false,
        selectedCourse: {},
      }
      break
    }
    case CoursesActions.DRAWER.OPEN: {
      newState = {
        ...state,
        isDrawerOpen: { ...state.isDrawerOpen, [action.payload.drawerId]: true },
        selectedCourse: action.payload.course,
      }
      break
    }
    case CoursesActions.SET_FORM_DIRTY: {
      newState = {
        ...state,
        isFormDirty: action.payload,
      }
      break
    }
    case CoursesActions.SET_SELECTION: {
      newState = {
        ...state,
        selectedCourse: action.payload,
      }
      break
    }
    case CoursesActions.GET_SELECTION: {
      newState = {
        ...state,
        selectedCourse: action.payload,
      }
      break
    }
    case CoursesActions.UNSET_SELECTION: {
      newState = {
        ...state,
        selectedCourse: coursesInitialState.selectedCourse,
      }
      break
    }
    default:
      newState = state
  }

  return newState
}
