import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { StoreContext } from 'store'
import { useTranslation } from 'react-i18next'
import CourseNavSearch from 'components/CourseNav/CourseNavSearch'
import TOCElement from './TOCElement'
import Spinner from 'components/Spinner'
import DefaultLogo from 'images/logo-acrobatiq.svg'
import MenuIcon from 'images/menu.svg'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { actionHasErrors, getIsInstructorOrAdmin } from 'store/selectors'
import AlertsPanel from './AlertsPanel'
// import { ReactComponent as MenuGlossary } from 'images/menu-glossary.svg'
import CurrentTimezone from 'components/CurrentTimezone'
import ContentNotAvailable from 'components/ContentNotAvailable'

const TableOfContents = (props) => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { courseKey, level, tocElements, toggleMenu, menuIsOpen, glossaryUrl } = props
  const { api, course, userAq } = useContext(StoreContext)

  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)

  const courseActionHasErrors = actionHasErrors(api, 'COURSE_GET')

  const assessmentsWithDueDate = course?.scheduledAssessments?.filter(
    (assignment) => assignment.schedule.dueDate !== null,
  )
  const upcomingAssignments = course?.scheduledAssessments?.filter(
    (assignment) => assignment.schedule.isUpcomingDue,
  )

  const showAlertsPanel = level === 'course' && assessmentsWithDueDate?.length > 0

  const topTocElement =
    tocElements &&
    (level === 'unit' ? tocElements[0] : level === 'module' ? tocElements[0].modules[0] : null)

  const unavailableContentLabel =
    topTocElement &&
    (topTocElement.ordinal === null
      ? topTocElement.title
      : `${topTocElement.label || topTocElement.courseLabel} ${topTocElement.ordinal}: ${
          topTocElement.title
        }`)

  const contentAvailableDate = topTocElement?.schedule?.availableDate

  const contentAvailableDateMoment =
    contentAvailableDate && moment.tz(contentAvailableDate, userAq.time_zone)

  const formattedContentAvailableDate =
    contentAvailableDateMoment &&
    contentAvailableDateMoment.format(t('toc.contentAvailabilityStatementDateFormat'))

  const showContentAvailabilityStatement =
    contentAvailableDateMoment &&
    moment().tz(userAq.time_zone).isSameOrBefore(contentAvailableDateMoment)

  const shouldShowContentNotAvailableMessage =
    level !== 'course' &&
    level !== 'nav' &&
    tocElements &&
    !isInstructorOrAdmin &&
    topTocElement.schedule &&
    !topTocElement.schedule.studentAvailability

  const courseHomeUrl = bpe.coursePageUrl({ courseKey })

  const goToHomePage = () => navigate(courseHomeUrl)

  if (!course || courseActionHasErrors) return null

  const logoSrc = (course && course.customer && course.customer.image) || DefaultLogo
  const showCompletion = course.studentProgressService

  if (!tocElements) return <Spinner />

  return (
    <div className="toc">
      {showContentAvailabilityStatement && (
        <p className="toc__content-availability-statement">
          {t('toc.contentAvailabilityStatement', { formattedContentAvailableDate })}
        </p>
      )}
      {showAlertsPanel && (
        <AlertsPanel courseKey={courseKey} upcomingAssignments={upcomingAssignments} />
      )}
      {/* Logo bar with toggle button - displayed in Course Nav only */}
      {level === 'nav' && (
        <div className="toc__logo-bar">
          <button
            className="toc__logo-bar-button"
            aria-label={t('toc.collapse')}
            onClick={toggleMenu}
            data-autofocus
            aria-expanded={menuIsOpen}
          >
            <img src={MenuIcon} alt={t('nav.openMenuIcon')} />
          </button>
          <Link to={courseHomeUrl} title={course.title} className="toc__logo-bar-link">
            <img src={logoSrc} alt={course.customer.name} className="toc__logo" />
          </Link>
        </div>
      )}
      {/* Search bar */}
      {level === 'nav' && <CourseNavSearch courseKey={courseKey} />}
      {shouldShowContentNotAvailableMessage ? (
        <div className="toc--skeleton">
          <ul className="toc__list--skeleton" aria-hidden="true">
            <li className="toc__list-item--skeleton" />
            <li className="toc__list-item--skeleton" />
            <li className="toc__list-item--skeleton" />
            <li className="toc__list-item--skeleton" />
          </ul>
          <ContentNotAvailable
            buttonLabel={t('page.goToHomePage')}
            contentLabel={unavailableContentLabel}
            handleButtonOnClick={goToHomePage}
            hasNextPage
          />
        </div>
      ) : (
        <>
          {/* Unit list */}
          <ul className="toc__list--unit">
            {tocElements.map((unit) => (
              <TOCElement
                key={unit.unitId}
                obj={unit}
                courseKey={courseKey}
                unitId={unit.identifier}
                itemType="unit"
                tocLevel={level}
                showCompletion={showCompletion}
              />
            ))}
            {glossaryUrl && (
              <>
                <li className="toc__list-item--unit">
                  <Link
                    to={`/courses/${courseKey}/glossary`}
                    onClick={toggleMenu}
                    className="toc__link toc__link--unit toc__list-item--bottom"
                  >
                    {/* <MenuGlossary className="toc__link--bottom-icon" aria-hidden="true" /> */}
                    <strong>{t('courseNav.glossary')}</strong>
                  </Link>
                </li>
              </>
            )}
          </ul>
          {level !== 'nav' && <CurrentTimezone timezone={userAq?.time_zone} />}
        </>
      )}
    </div>
  )
}

TableOfContents.propTypes = {
  courseKey: PropTypes.string,
  level: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  tocElements: PropTypes.array,
  toggleMenu: PropTypes.func,
  glossaryUrl: PropTypes.string,
}

export default React.memo(TableOfContents)
