import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const Landing = () => {
  const navigate = useNavigate()
  const { customerSlug, vbid } = useParams()
  const { i18n } = useTranslation()
  const { chooseCourseSectionPageUrl, coursesPageUrl } = buildPagesEndpoints(i18n)
  const isStorePurchaseFlow = !!customerSlug && !!vbid

  const redirectUserToPage = useCallback(() => {
    const loginNext = isStorePurchaseFlow
      ? chooseCourseSectionPageUrl({ customerSlug, vbid })
      : coursesPageUrl()

    navigate(loginNext)
  }, [
    chooseCourseSectionPageUrl,
    coursesPageUrl,
    customerSlug,
    isStorePurchaseFlow,
    navigate,
    vbid,
  ])

  useEffect(() => {
    redirectUserToPage()
  }, [redirectUserToPage])

  return null
}

export default Landing
