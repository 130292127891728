/* eslint-disable no-underscore-dangle */
import { history } from 'router'
import Newrelic from 'utils/newrelic'
import { PLATFORM_HOST } from 'utils/constants'

// empty right now but we may want to consistently use a standard
// prefix across all API calls. Something like `/api/5.0/` so that we
// can eventually version the API endpoints
const urlPrefix = PLATFORM_HOST

function getCSRFToken() {
  return window.__CSRFToken
}

function setCSRFToken(CSRFToken) {
  window.__CSRFToken = CSRFToken
}

function isUnsafeMethod(httpMethod) {
  return !['GET', 'OPTIONS'].includes(httpMethod.toUpperCase())
}

const isJsonResponse = (response) => {
  const contentType = response.headers.get('content-type')
  return contentType && contentType === 'application/json'
}

/**
 * `doFetch` is only called by `platformApi` and it does the actual
 * calling of fetch. It then returns the results or and error.
 *
 * @param {string} url url to call
 * @param {object} params object of key:value pairs that we pass to
 * the fetch method that tells it to do POST or GET, what payload
 * to include (if any), etc. This can include headers and other
 * params in the future (like JWT auth tokens).
 */
export const doFetch = async ({ url, params }) => {
  const csrfToken = getCSRFToken()
  if (isUnsafeMethod(params.method) && csrfToken) {
    if (!params.headers) {
      params.headers = {}
    }
    params.headers['X-CSRFToken'] = csrfToken
  }
  const response = await window.fetch(`${urlPrefix}${url}`, params)
  const responseParsed = await (isJsonResponse(response) ? response.json() : response.text())

  if (response.status >= 400 && response.status < 600) {
    const responseDetails =
      typeof responseParsed === 'string' ? { detail: responseParsed } : responseParsed

    // return json.then(Promise.reject.bind(Promise))
    // eslint-disable-next-line no-throw-literal
    throw { ...responseDetails, status: response.status }
  }

  if (response.headers.has('X-CSRFToken')) {
    setCSRFToken(response.headers.get('X-CSRFToken'))
  }

  return responseParsed
}

const handleError = ({ dispatch, error, failureType, returnRejectedPromise }) => {
  if (error.detail) {
    switch (error.detail) {
      case 'missing_api_key':
        return history.push('/courses/error')
      default:
        dispatch({ payload: error, type: failureType })
        if (returnRejectedPromise) return Promise.reject(error)
    }
  }
  dispatch({ payload: error, type: failureType })
  if (returnRejectedPromise) return Promise.reject(error)
}

/**
 * `platformApi` encapsulates the functionality of calling our api, returning
 * results or returning an error. The "returning" here is done through dispatching
 * actions so that our various reducers can properly update the store. This is
 * essential for us to track things like loading and error states. And it greatly
 * reduces the complexity involved in making api calls.
 *
 * @param {function} dispatch function to dispatch actions
 * @param {object} fetchParams the params to pass to the fetch method
 * @param {object} payload payload (if any) to send with fetch call (eg, POST calls)
 * @param {array} types the action names (*_GET_REQUEST, *_GET_SUCCESS, *_GET_FAILURE)
 */
const platformApi = ({
  dispatch,
  fetchParams,
  payload = {},
  types,
  returnRejectedPromise = false,
}) => {
  if (
    !Array.isArray(types) ||
    types.length !== 3 ||
    !types.every((type) => typeof type === 'string')
  ) {
    throw new Error('Expected an array of three string types for this action.')
  }
  const [requestType, successType, failureType] = types
  dispatch(Object.assign({}, payload, { type: requestType, fetchParams, payload }))

  return doFetch({ url: fetchParams.url, params: fetchParams.params }).then(
    (response) => dispatch({ payload: response, type: successType }),
    (error) => {
      let errorMsg = ''
      if (typeof error === 'object') {
        Object.keys(error).forEach((key) => {
          const keyCapitalized = key.charAt(0).toUpperCase() + key.slice(1)
          errorMsg += `\n${keyCapitalized}: ${error[key]}`
        })
      } else {
        errorMsg = error
      }
      // log server errors only
      if (error.status && error.status >= 500 && error.status < 600) {
        Newrelic.noticeError(new Error(`Platform API returned with error: ${errorMsg}`))
      }
      return handleError({ dispatch, error, failureType, returnRejectedPromise })
    },
  )
}

export default platformApi
