import { USER_ROLES } from 'utils/constants'

// replace with `getPropertySafe` when it gets merged
export const getSyllabusData = (course) => (course ? course.syllabusUnitsMap : null)

export const getAssignmentTypeKey = (type) => {
  const assignmentTypeKeyMap = {
    adaptive_assessment: 'adaptive-assessment',
    handin: 'handin-assessment',
    homework: 'homework',
    prereq_test: 'prereq-test',
    summative_assessment: 'summative-assessment',
  }

  return assignmentTypeKeyMap[type]
}

export const getAssignmentData = (course, assignmentType, assignmentIdentifier) => {
  if (!course || !assignmentType || !assignmentIdentifier) {
    return null
  }

  const syllabusData = getSyllabusData(course)

  if (!syllabusData) {
    return null
  }

  let assignmentUnit, assignmentModule, assignmentPage, assignment
  units_loop: for (const unit of syllabusData) {
    for (const module of unit.modules) {
      for (const page of module.pages) {
        assignment = page.assessments.find(
          (assignment) => assignment.identifier === assignmentIdentifier,
        )
        if (assignment) {
          assignmentUnit = { identifier: unit.identifier }
          assignmentModule = module
          assignmentPage = page
          break units_loop
        }
      }
    }
  }

  if (!assignmentUnit || !assignmentModule || !assignmentPage) {
    return null
  }

  return {
    assignmentUnit,
    assignmentModule,
    assignmentPage,
    assignment,
  }
}

export const getIsInstructor = (course) => {
  if (!course) return
  const enrollment = course?.enrollment
  const isInstructor = enrollment && enrollment.role === USER_ROLES.INSTRUCTOR
  return isInstructor
}

export const getIsInstructorByEmail = (userEmail = '', instructors = []) => {
  for (let i = 0; i < instructors.length; i++) {
    const instructor = instructors[i]
    if (instructor.email === userEmail) return true
  }
  return false
}
