/* eslint-disable react/no-multi-comp */
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const PinIcon = () => {
  const { t } = useTranslation()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 12">
      <title>{t('pinIcon')}</title>
      <path
        fill="#fff"
        d="M5.376 7.45l2.25-3.897.433.25.55-.952-4.33-2.5-.55.952.346.2-2.25 3.898-1.416.452-.5.866 2.252 1.3L.41 11.05l.693.4 1.75-3.03 2.251 1.3.5-.867z"
      />
    </svg>
  )
}

const QuizIcon = () => {
  const { t } = useTranslation()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
      <title>{t('quizIcon')}</title>
      <path
        d="M11.2 5.3c.5 0 .8.3.8.7v4.5c0 .8-.7 1.5-1.5 1.5h-9C.7 12 0 11.3 0 10.5v-9C0 .7.7 0 1.5 0H6c.4 0 .8.3.8.8s-.4.7-.8.7H1.5v9h9V6c0-.4.3-.7.7-.7zM3.6 8.4l1.3-2.9 1.6 1.6-2.9 1.3zM11.8.8c.3.3.3.8 0 1.1l-.1.1L7 6.6 5.4 5 10 .4l.1-.1c.3-.3.8-.3 1.1 0l.6.5z"
        fill="#fff"
      />
      <path d="M11.2 5.3c.5 0 .8.3.8.7v4.5c0 .8-.7 1.5-1.5 1.5h-9C.7 12 0 11.3 0 10.5v-9C0 .7.7 0 1.5 0H6c.4 0 .8.3.8.8s-.4.7-.8.7H1.5v9h9V6c0-.4.3-.7.7-.7zM3.6 8.4l1.3-2.9 1.6 1.6-2.9 1.3zM11.8.8c.3.3.3.8 0 1.1l-.1.1L7 6.6 5.4 5 10 .4l.1-.1c.3-.3.8-.3 1.1 0l.6.5z" />
    </svg>
  )
}

const UnitIcon = () => {
  const { t } = useTranslation()
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.7 12">
      <title>{t('unitIcon')}</title>
      <path
        className="filled"
        d="M6.2 0H1.4C.6 0 0 .6 0 1.3v9.3c0 .8.6 1.4 1.4 1.4h6.9c.8 0 1.4-.6 1.4-1.3V3.3L6.2 0zm-.5 1.3l2.7 2.6v6.8h-7V1.3h4.3z"
      />
      <path d="M0 12h9.7V0H0v12z" fill="none" />
      <path className="filled" d="M2.8 6.7H7V6H2.8v.7zm0 2H7V8H2.8v.7zM9.7 4H5.5V0l4.2 4z" />
      <path
        className="filled"
        d="M8.2 2.6c-.3.3-.7.3-1 0L5.7 1.2c-.3-.3-.3-.7 0-1s.7-.3 1 0l1.5 1.4c.3.3.3.7 0 1"
      />
      <path
        className="filled"
        d="M9.5 3.8c-.3.3-.7.3-1 0L7 2.4c-.3-.3-.3-.7 0-1s.7-.3 1 0l1.5 1.4c.3.3.3.7 0 1"
      />
    </svg>
  )
}

const ClockIcon = () => {
  const { t } = useTranslation()
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 11">
      <title>{t('clockIcon')}</title>
      <path
        d="M5.5 0C2.5 0 0 2.5 0 5.5S2.5 11 5.5 11 11 8.5 11 5.5 8.5 0 5.5 0m0 1.2c2.4 0 4.3 1.9 4.3 4.3S7.9 9.8 5.5 9.8 1.2 7.9 1.2 5.5s1.9-4.3 4.3-4.3zm.8 4.3s0-.1 0 0c0-.1-.5-3.4-.5-3.4 0-.2-.1-.3-.3-.3-.2 0-.3.2-.3.3l-.4 3-1.6 1.6c-.1.2-.2.3-.1.4.1.1.3.2.4.1l2.3-1.1c.3 0 .5-.3.5-.6z"
        fill="#fff"
      />
      <path d="M5.5 0C2.5 0 0 2.5 0 5.5S2.5 11 5.5 11 11 8.5 11 5.5 8.5 0 5.5 0m0 1.2c2.4 0 4.3 1.9 4.3 4.3S7.9 9.8 5.5 9.8 1.2 7.9 1.2 5.5s1.9-4.3 4.3-4.3zm.8 4.3s0-.1 0 0c0-.1-.5-3.4-.5-3.4 0-.2-.1-.3-.3-.3-.2 0-.3.2-.3.3l-.4 3-1.6 1.6c-.1.2-.2.3-.1.4.1.1.3.2.4.1l2.3-1.1c.3 0 .5-.3.5-.6z" />
    </svg>
  )
}

const Chevron = ({ title, ...props }) => {
  const { color } = props
  return (
    <svg width="6" height="10">
      {title && <title>{title}</title>}
      <path d="M0 8.764L1.189 10 6 5 1.189 0 0 1.236 3.622 5z" fill={color} fillRule="nonzero" />
    </svg>
  )
}

Chevron.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
}

const FooterHome = ({ title, ...props }) => {
  const { color } = props
  return (
    <svg width="48" height="39" viewBox="0 0 61 39" xmlns="http://www.w3.org/2000/svg">
      {title && <title>{title}</title>}
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <path
          d="M38 22.414l-8.722-8.722L21 21.971V34h17V22.414zm2 2V35a1 1 0 0 1-1 1H20a.997.997 0 0 1-1-1V23.97l-1.879 1.88a1 1 0 1 1-1.414-1.415l12.728-12.728a.998.998 0 0 1 .843-.284c.3-.04.613.054.843.284L42.85 24.435a1 1 0 0 1-1.414 1.414L40 24.414z"
          fill={color}
        />
        <path
          d="M29.5 23a4.5 4.5 0 0 1 4.5 4.5V36h-9v-8.5a4.5 4.5 0 0 1 4.5-4.5zM32 34v-6.5a2.5 2.5 0 1 0-5 0V34h5z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

FooterHome.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
}

const ExclamationCircle = ({ title }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-exclamation-circle-fill"
      viewBox="0 0 16 16"
    >
      {title && <title>{title}</title>}
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
    </svg>
  )
}

ExclamationCircle.propTypes = {
  title: PropTypes.string,
}

const ArrowRepeat = ({ title, ...props }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      className={`${props.className}`}
    >
      {title && <title>{title}</title>}
      <path
        fill="#161C27"
        d="M69.816,256H0l93.096,93.096L186.2,256h-69.816c0.224-77.016,62.6-139.4,139.616-139.632
        c22.672,0.432,44.952,6,65.16,16.296l34.896-34.896C325.6,80.144,291.176,70.528,256,69.832
        C153.296,70.112,70.104,153.296,69.816,256z M395.616,256c-0.224,77.016-62.6,139.4-139.616,139.632
        c-22.672-0.432-44.952-6-65.16-16.296l-34.896,34.896c30.456,17.624,64.88,27.24,100.056,27.936
        C358.696,441.872,441.88,358.696,442.184,256H512l-93.096-93.096L325.8,256H395.616z"
      />
    </svg>
  )
}

ArrowRepeat.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
}

const ExclamationCircleOutline = ({ title, ...props }) => {
  return (
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth={0}
      viewBox="0 0 24 24"
      height="18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title && <title>{title}</title>}
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  )
}

ExclamationCircleOutline.propTypes = {
  title: PropTypes.string,
}

const AssignmentIcon = ({ color = '#B2BEE0', height = 21, width = 22, title, ...props }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
    {title && <title>{title}</title>}
    <g transform="translate(0 2)" fill="none">
      <rect
        stroke={color}
        strokeWidth={2}
        fill="#FFF"
        x={1}
        y={3.063}
        width={14}
        height={14}
        rx={2}
      />
      <path
        d="M19.858 4.716c-.04.05-.082.099-.127.144l-.028.028-.111.112-8.649 8.648a1.5 1.5 0 0 1-.696.387l-3.36.91a1.557 1.557 0 0 1-1.898-1.91l.906-3.342a1.54 1.54 0 0 1 .4-.699L14.91.4l.119-.133a.944.944 0 0 1 .13-.137l.175-.174A3.25 3.25 0 0 1 17.646-1c.867 0 1.708.335 2.33.956a3.283 3.283 0 0 1 0 4.642l-.118.118z"
        stroke="#FFF"
        strokeWidth={2}
        fill={color}
      />
      <path fill="#FFF" d="m16.442 1.85 1.64 1.64-8.55 8.567-1.64-1.622.017-.07z" />
    </g>
  </svg>
)

AssignmentIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

const MenuAssignmentIcon = ({ color = '#8093A6', height = 12, width = 12, title, ...props }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {...props}>
    {title && <title>{title}</title>}
    <path
      d="M11.25 5.25A.75.75 0 0 1 12 6v4.502A1.5 1.5 0 0 1 10.502 12H1.498A1.5 1.5 0 0 1 0 10.502V1.499A1.5 1.5 0 0 1 1.498 0H6a.75.75 0 1 1 0 1.5H1.498l.002 9.002 9.002-.002L10.5 6a.75.75 0 0 1 .75-.75zM3.56 8.44l1.326-2.917 1.591 1.59L3.56 8.44zM11.78.75a.75.75 0 0 1 0 1.06l-.129.13-4.644 4.644-1.59-1.591L10.06.349l.13-.13a.75.75 0 0 1 1.06 0l.53.531z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)

MenuAssignmentIcon.propTypes = {
  color: PropTypes.string,
  height: PropTypes.number,
  title: PropTypes.string,
  width: PropTypes.number,
}

export {
  QuizIcon,
  PinIcon,
  UnitIcon,
  ClockIcon,
  Chevron,
  FooterHome,
  ExclamationCircle,
  ArrowRepeat,
  ExclamationCircleOutline,
  AssignmentIcon,
  MenuAssignmentIcon,
}
