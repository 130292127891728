import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from '@vitalsource/vstui/Button'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { closeConfirmationDialog } from 'pages/Settings/store/actions'
import Spinner from 'components/Spinner'
import { StoreContext } from 'store'
import { isLoading } from 'store/selectors'
import { useKeypress } from 'utils/customHooks'
import FormMessage from '@vitalsource/vstui/FormMessage/FormMessage'

const AssessmentSettingsDialog = ({ mode: modeProp, theme }) => {
  const { t } = useTranslation()
  const { api } = useContext(StoreContext)
  const { assignmentSelection, dispatchSettingsAction, unitOrModuleSelection } =
    useSettingsContext()
  const { mode, callback = () => {}, bodyI18n } = modeProp
  const color = theme.vstui.color.primary
  const headerStyle = {
    color: color.getShade(6),
  }

  const isApiLoading = isLoading(api)

  const closeDialog = () => closeConfirmationDialog({ dispatchSettingsAction })

  const modesToStringsMap = {
    apply: {
      title: ['assignmentsSettings.dialogApply.header'],
      body: [
        'assignmentsSettings.dialogApply.body',
        {
          count: assignmentSelection?.length,
        },
      ],
      primaryButtonLabel: ['assignmentsSettings.dialogApply.primaryActionButtonLabel'],
      secondaryButtonLabel: ['assignmentsSettings.dialogApply.secondaryActionButtonLabel'],
    },
    applyUnitOrModule: {
      title: ['assignmentsSettings.dialogApplyUnitOrModule.header'],
      body: [
        'assignmentsSettings.dialogApplyUnitOrModule.body',
        {
          title: unitOrModuleSelection?.title,
        },
      ],
      primaryButtonLabel: ['assignmentsSettings.dialogApplyUnitOrModule.primaryActionButtonLabel'],
      secondaryButtonLabel: [
        'assignmentsSettings.dialogApplyUnitOrModule.secondaryActionButtonLabel',
      ],
    },
    cancel: {
      title: ['assignmentsSettings.dialogCancel.header'],
      body: ['assignmentsSettings.dialogCancel.body'],
      primaryButtonLabel: ['assignmentsSettings.dialogCancel.primaryActionButtonLabel'],
      secondaryButtonLabel: ['assignmentsSettings.dialogCancel.secondaryActionButtonLabel'],
    },
    delete: {
      title: ['assignmentsSettings.dialogDelete.header'],
      body: ['assignmentsSettings.dialogDelete.body'],
      primaryButtonLabel: ['assignmentsSettings.dialogDelete.primaryActionButtonLabel'],
      secondaryButtonLabel: ['assignmentsSettings.dialogDelete.secondaryActionButtonLabel'],
    },
    deleteOnEmpty: {
      title: ['assignmentsSettings.dialogDeleteOnEmpty.header'],
      body: ['assignmentsSettings.dialogDeleteOnEmpty.body'],
      primaryButtonLabel: ['assignmentsSettings.dialogDeleteOnEmpty.primaryActionButtonLabel'],
      secondaryButtonLabel: ['assignmentsSettings.dialogDeleteOnEmpty.secondaryActionButtonLabel'],
    },
    error: {
      title: ['assignmentsSettings.dialogError.header'],
      body: [bodyI18n],
      primaryButtonLabel: ['assignmentsSettings.dialogError.primaryActionButtonLabel'],
    },
    none: {
      title: ['assignmentsSettings.dialogCancel.header'],
      body: ['assignmentsSettings.dialogCancel.body'],
      primaryButtonLabel: ['assignmentsSettings.dialogCancel.primaryActionButtonLabel'],
      secondaryButtonLabel: ['assignmentsSettings.dialogCancel.secondaryActionButtonLabel'],
    },
    lowerSchedulingConflict: {
      title: ['assignmentsSettings.lowerSchedulingConflict.header'],
      body: ['assignmentsSettings.lowerSchedulingConflict.body'],
      primaryButtonLabel: ['assignmentsSettings.lowerSchedulingConflict.primaryActionButtonLabel'],
      secondaryButtonLabel: [
        'assignmentsSettings.lowerSchedulingConflict.secondaryActionButtonLabel',
      ],
    },
    higherSchedulingConflict: {
      title: ['assignmentsSettings.higherSchedulingConflict.header'],
      body: ['assignmentsSettings.higherSchedulingConflict.body'],
      primaryButtonLabel: ['assignmentsSettings.higherSchedulingConflict.primaryActionButtonLabel'],
      secondaryButtonLabel: [
        'assignmentsSettings.higherSchedulingConflict.secondaryActionButtonLabel',
      ],
    },
  }

  const modesToActionsMap = {
    apply: {
      primaryAction: () => {
        callback()
      },
      secondaryAction: closeDialog,
    },
    applyUnitOrModule: {
      primaryAction: () => {
        callback()
      },
      secondaryAction: closeDialog,
    },
    cancel: {
      primaryAction: () => {
        callback()
      },
      secondaryAction: closeDialog,
    },
    delete: {
      primaryAction: () => {
        callback()
      },
      secondaryAction: closeDialog,
    },
    deleteOnEmpty: {
      primaryAction: () => {
        callback()
      },
      secondaryAction: closeDialog,
    },
    error: {
      primaryAction: closeDialog,
    },
    higherSchedulingConflict: {
      primaryAction: closeDialog,
      secondaryAction: () => {
        callback()
      },
    },
    lowerSchedulingConflict: {
      primaryAction: closeDialog,
      secondaryAction: () => {
        callback()
      },
    },
  }

  const getString = (stringKey) =>
    mode ? t(modesToStringsMap[mode][stringKey][0], modesToStringsMap[mode][stringKey][1]) : ''
  const getHandler = (actionType) => (mode ? modesToActionsMap[mode][actionType] : () => {})

  useKeypress('Escape', () => {
    return false
  })

  if (!mode) {
    return null
  }

  return (
    <Modal
      backdrop="static"
      centered
      isOpen={true}
      labelledBy="modal-title"
      modalClassName="assessment-settings-dialog"
      zIndex="9999"
    >
      <ModalHeader id="modal-title" style={headerStyle} tag="h1">
        {getString('title')}
      </ModalHeader>
      <ModalBody>
        {mode === 'error' ? <FormMessage message={getString('body')} error /> : getString('body')}
      </ModalBody>
      <ModalFooter>
        {getHandler('secondaryAction') && (
          <Button color="neutral" onClick={getHandler('secondaryAction')} disabled={isApiLoading}>
            {getString('secondaryButtonLabel')}
          </Button>
        )}
        <Button color="theme" primary onClick={getHandler('primaryAction')} disabled={isApiLoading}>
          {isApiLoading ? (
            <Spinner position="left" width={20} height={20} />
          ) : (
            getString('primaryButtonLabel')
          )}
        </Button>{' '}
      </ModalFooter>
    </Modal>
  )
}

AssessmentSettingsDialog.propTypes = {
  /**
   * Mode object
   *    mode: 'apply', 'cancel', 'delete', 'deleteOnEmpty'
   *    callback: function to call
   *    bodyI18n: i18n key of body string
   */
  mode: PropTypes.shape({
    mode: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    callback: PropTypes.func,
    bodyI18n: PropTypes.string,
  }),
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
}

AssessmentSettingsDialog.defaultProps = {
  mode: { mode: null, callback: () => {}, bodyI18n: '' },
  theme: {},
}

export default withTheme(AssessmentSettingsDialog)
