import { actions } from 'store/actions'
import { initialState } from 'store'

const coursesReducer = (state = initialState.courses, action) => {
  switch (action.type) {
    case actions.COURSES_GET_SUCCESS:
      return action.payload
    case actions.COURSES_UNSET:
      return initialState.courses
    default:
      return state
  }
}

export default coursesReducer
