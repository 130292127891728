import { actions } from 'store/actions'
import { initialState } from 'store'

const { ENROLL_POST_SUCCESS } = actions

const enrollmentReducer = (state = initialState.enrollment, action) => {
  switch (action.type) {
    case ENROLL_POST_SUCCESS:
      return {
        ...action.payload,
        courseKeyExist: true
      }
    default:
      return state
  }
}

export default enrollmentReducer
