import { actions } from 'store/actions'
import { initialState } from 'store'

const authoringUserActionsReducer = (state = initialState.authoringUserActions, action) => {
  switch (action.type) {
    case actions.AUTHORING_USER_ACTIONS_GET_SUCCESS:
      return action.payload
    case actions.AUTHORING_USER_ACTIONS_UNSET:
      return initialState.authoringUserActions
    default:
      return state
  }
}

export default authoringUserActionsReducer
