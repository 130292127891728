import PropTypes from 'prop-types'
import UnitType from './UnitType'
import PageType from './PageType'

const CourseOverviewType = PropTypes.shape({
  courseContentsUrl: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  syllabusUnitsMap: PropTypes.arrayOf(UnitType),
  pages: PropTypes.arrayOf(PageType)
})

export default CourseOverviewType
