import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import initialState from './initialState'
import { StateType } from 'types'
import rootReducer from 'store/reducers'
import { StoreContext } from './StoreContext'

export const Store = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState)

  return <StoreContext.Provider value={{ ...state, dispatch }}>{children}</StoreContext.Provider>
}

Store.propTypes = {
  initialState: StateType,
  children: PropTypes.element,
}

Store.defaultProps = { initialState }
