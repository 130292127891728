import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

const AlertMessage = ({ children, color, className, icon }) => {
  const { t } = useTranslation()
  let cssClasses = `mb-4 ${className || ''}`
  if (icon) {
    cssClasses += ' has-icon'
  }
  return (
    <Alert color={color} className={cssClasses}>
      {icon}
      {children ? children : t('api.loadingError')}
    </Alert>
  )
}

AlertMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  icon: PropTypes.node,
}

AlertMessage.defaultProps = {
  color: 'danger',
}

export default AlertMessage
