import {
  DEFAULT_DRAWER_ID,
  EDIT_EXCEPTIONS_DRAWER_ID,
  EXCEPTIONS_DRAWER_ID,
  UNITS_AND_MODULES_DRAWER_ID,
} from 'utils/constants'

export const AssignmentSettingsActions = {
  ASSIGNMENT_TAGS: {
    SET_ACTIONS: 'SET_ACTIONS',
    SET_STATE: 'SET_STATE',
  },
  SETTINGS_DRAWER: {
    CLOSE: 'CLOSE_SETTINGS_DRAWER',
    CLOSE_ALL: 'CLOSE_ALL',
    OPEN: 'OPEN_SETTINGS_DRAWER',
  },
  SET_ASSIGNMENT_TYPES: 'SET_ASSIGNMENT_TYPES',
  CONFIRMATION_DIALOG: {
    CLOSE: 'CLOSE',
    SET_MODE: 'SET_MODE',
  },
  SET_SELECTION: 'SET_SELECTION',
  SET_UNIT_OR_MODULE: 'SET_UNIT_OR_MODULE',
  UNSET_UNIT_OR_MODULE: 'UNSET_UNIT_OR_MODULE',
  UNSET_SELECTION: 'UNSET_SELECTION',
  SET_FORM_DIRTY: 'SET_FORM_DIRTY',
}

export const closeConfirmationDialog = ({ dispatchSettingsAction }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.CONFIRMATION_DIALOG.CLOSE,
  })

export const closeAllSettingsDrawers = ({ dispatchSettingsAction }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.SETTINGS_DRAWER.CLOSE_ALL })

export const closeSettingsDrawer = ({ dispatchSettingsAction, drawerId }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.SETTINGS_DRAWER.CLOSE,
    payload: drawerId,
  })

export const closeDefaultSettingsDrawer = ({ dispatchSettingsAction }) =>
  closeSettingsDrawer({ dispatchSettingsAction, drawerId: DEFAULT_DRAWER_ID })

export const closeEditExceptionsSettingsDrawer = ({ dispatchSettingsAction }) =>
  closeSettingsDrawer({ dispatchSettingsAction, drawerId: EDIT_EXCEPTIONS_DRAWER_ID })

export const closeExceptionsSettingsDrawer = ({ dispatchSettingsAction }) =>
  closeSettingsDrawer({ dispatchSettingsAction, drawerId: EXCEPTIONS_DRAWER_ID })

export const openSettingsDrawer = ({ dispatchSettingsAction, drawerId }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.SETTINGS_DRAWER.OPEN,
    payload: drawerId,
  })

export const openDefaultSettingsDrawer = ({ dispatchSettingsAction }) =>
  openSettingsDrawer({ dispatchSettingsAction, drawerId: DEFAULT_DRAWER_ID })

export const openEditExceptionsSettingsDrawer = ({ dispatchSettingsAction }) =>
  openSettingsDrawer({ dispatchSettingsAction, drawerId: EDIT_EXCEPTIONS_DRAWER_ID })

export const openExceptionsSettingsDrawer = ({ dispatchSettingsAction }) =>
  openSettingsDrawer({ dispatchSettingsAction, drawerId: EXCEPTIONS_DRAWER_ID })

export const openUnitsAndModulesDrawer = ({ dispatchSettingsAction }) =>
  openSettingsDrawer({ dispatchSettingsAction, drawerId: UNITS_AND_MODULES_DRAWER_ID })

export const closeUnitsAndModulesSettingsDrawer = ({ dispatchSettingsAction }) =>
  closeSettingsDrawer({ dispatchSettingsAction, drawerId: UNITS_AND_MODULES_DRAWER_ID })

export const setAssignmentTagsActions = ({ dispatchSettingsAction, assignmentTagsActions }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.ASSIGNMENT_TAGS.SET_ACTIONS,
    payload: assignmentTagsActions,
  })

export const setAssignmentTagsState = ({ dispatchSettingsAction, assignmentTagsState }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.ASSIGNMENT_TAGS.SET_STATE,
    payload: assignmentTagsState,
  })

export const setAssignmentTypes = ({ dispatchSettingsAction, types }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.SET_ASSIGNMENT_TYPES,
    payload: types,
  })

export const setConfirmationDialogMode = ({ dispatchSettingsAction, mode }) =>
  dispatchSettingsAction({
    type: AssignmentSettingsActions.CONFIRMATION_DIALOG.SET_MODE,
    payload: mode,
  })

export const setSelection = ({ dispatchSettingsAction, selection }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.SET_SELECTION, payload: selection })

export const unsetAssignmentSelection = ({ dispatchSettingsAction }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.UNSET_SELECTION })

export const setUnitOrModuleSelection = ({ dispatchSettingsAction, selection }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.SET_UNIT_OR_MODULE, payload: selection })

export const unsetUnitOrModuleSelection = ({ dispatchSettingsAction }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.UNSET_UNIT_OR_MODULE })

export const setFormDirtyStatus = ({ dispatchSettingsAction, isFormDirty }) =>
  dispatchSettingsAction({ type: AssignmentSettingsActions.SET_FORM_DIRTY, payload: isFormDirty })
