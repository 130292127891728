import { actions } from 'store/actions'
import { initialState } from 'store'

const userAqReducer = (state = initialState.userAq, action) => {
  switch (action.type) {
    case actions.USER_AQ_POST_SUCCESS:
      return {
        ...action.payload,
      }
    case actions.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        time_zone: action.payload.timezone,
      }
    case actions.USER_AQ_POST_FAILURE: {
      return {
        ...action.payload,
        alohomoraUrl: action.payload.alohomora_url,
      }
    }
    case actions.USER_AQ_UNSET:
      return initialState.userAq
    default:
      return state
  }
}

const userAqHasAccessReducer = (state = initialState.userAqHasAccess, action) => {
  switch (action.type) {
    case actions.USER_AQ_HAS_ACCESS_TO_CONTENT_SUCCESS:
      return action.payload
    case actions.USER_AQ_HAS_ACCESS_TO_CONTENT_FAILURE:
      return action.payload
    case actions.USER_AQ_HAS_ACCESS_TO_CONTENT_UNSET:
      return initialState.userAqHasAccess
    default:
      return state
  }
}

const checkUserExistReducer = (state = initialState.checkUserExist, action) => {
  switch (action.type) {
    case actions.CHECK_MANAGE_USER_GET_SUCCESS:
      return action.payload
    case actions.CHECK_MANAGE_USER_GET_FAILURE:
      return action.payload
    default:
      return state
  }
}

export { userAqReducer, userAqHasAccessReducer, checkUserExistReducer }
