import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FocusLock from 'react-focus-lock'
import { StoreContext } from 'store'
import TableOfContents from 'components/TableOfContents'
import classnames from 'classnames'

const CourseNav = (props) => {
  const { courseKey, menuIsOpen, toggleMenu } = props
  const { course } = useContext(StoreContext)
  const { t } = useTranslation()

  if (!course) return null

  const tocElements = course && course.syllabusUnitsMap

  return (
    <FocusLock
      disabled={!menuIsOpen}
      className={classnames({
        'course-navigation-wrapper': true,
        'aq-small-caps': course && course.smallCapsEnabled,
      })}
    >
      <nav className="course-navigation" aria-label={t('courseNav.name')}>
        <div className="menus-container">
          <TableOfContents
            courseKey={courseKey}
            level="nav"
            tocElements={tocElements}
            toggleMenu={toggleMenu}
            glossaryUrl={course.glossaryUrl}
            menuIsOpen={menuIsOpen}
          />
        </div>
      </nav>
      <button
        type="button"
        className="course-navigation__overlay"
        aria-label={t('toc.collapse')}
        onClick={toggleMenu}
      />
    </FocusLock>
  )
}

CourseNav.propTypes = {
  courseKey: PropTypes.string,
  menuIsOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default React.memo(CourseNav)
