import React from 'react'
import { Button } from '@vitalsource/vstui'
import { useTranslation } from 'react-i18next'

const SkipToContentLink = () => {
  const { t } = useTranslation()

  return (
    <Button
      className="skip-to-main"
      href="#main"
      size="large"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        document.getElementById('main').focus()
      }}
    >
      {t('nav.skipToMain')}
    </Button>
  )
}

export default SkipToContentLink
