import PropTypes from 'prop-types'
import PageType from './PageType'

const ModuleType = PropTypes.shape({
  courseLabel: PropTypes.string,
  identifier: PropTypes.string,
  isConclusion: PropTypes.bool,
  isContent: PropTypes.bool,
  isIntroduction: PropTypes.bool,
  isSupplement: PropTypes.bool,
  moduleId: PropTypes.string,
  pageNumber: PropTypes.number,
  pages: PropTypes.arrayOf(PageType),
  ordinal: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  isExternal: PropTypes.bool,
  externalUrl: PropTypes.string,
})

export default ModuleType
