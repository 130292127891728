import { actions } from 'store/actions'
import { initialState } from 'store'

const { LOGIN_POST_SUCCESS, SET_USER_IS_AUTHENTICATED } = actions

const isFailureRequest = (type) => type && type.includes('_FAILURE')
const isLoginFailured = (type) => type.includes('LOGIN')

// See comments below for more info on what this list of actions means
const actionsAllowedTo403 = [
  'AUTH_USER_ACCESS_POST_FAILURE',
  'COURSE_GET_FAILURE',
  'USER_AQ_HAS_ACCESS_TO_CONTENT_FAILURE',
]

// This reducer is used for setting the flag `isAuthenticated` to false when any
// request returns a 403 status code. When that happens, the custom hook
// `src/utils/customHooks/useAuthentication.js` validates authentication status
// and redirect to login page, if needed
const unauthorizedReducer = (state, action) => {
  const { payload, type } = action

  // There are some actions/requests for which a 403 response does not mean that
  // the user is no longer authenticated, but he/she does not have a
  // permission/access to a given resource. For cases like these, we need to
  // skip this reducer, in order to avoid issues on component mounting and
  // transition between components
  if (actionsAllowedTo403.includes(type)) {
    return state
  }

  const { status } = payload
  const is403Status = status === 403
  const failedCount = state.failedCount + (isLoginFailured(action.type) ? 1 : 0)

  return is403Status ? { ...state, isAuthenticated: false, failedCount } : { ...state, failedCount }
}

const authReducer = (state = initialState.auth, action) => {
  if (isFailureRequest(action.type)) {
    return unauthorizedReducer(state, action)
  }

  switch (action.type) {
    case SET_USER_IS_AUTHENTICATED:
    case LOGIN_POST_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        failedCount: 0,
      }
    default:
      return state
  }
}

export default authReducer
