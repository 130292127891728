import { parseCfi, parseCfiRange } from 'epubjs'
import { devConsole } from 'utils/log'

/**
 * Comparators assume full paths with doc and path. Add a fake
 * path to doc-only cfis. Looking for "!" to avoid the overhead
 * of cfi parses.
 * @param {string} cfi
 * @returns {string}
 */
export function getExtendedCfi(cfi) {
  if (!cfi) {
    return cfi
  }
  return cfi.indexOf('!') === -1 ? `${cfi}!/4` : cfi
}

/**
 * Determines whether a cfiRange is valid or not, by parsing it
 * @param {string} cfiRange
 * @returns {boolean}
 */
export function isValidCfiRange(cfiRange) {
  try {
    parseCfiRange(cfiRange)
    return true
  } catch {
    return false
  }
}

/**
 * Given a start and end CFIs, returns a CFI range
 * @param {string} startCfi
 * @param {string} endCfi
 * @returns {string}
 */
export function buildCfiRange(startCfi, endCfi) {
  const startCfiExtended = getExtendedCfi(startCfi) || ''
  const endCfiExtended = getExtendedCfi(endCfi) || ''
  const [cfiStartContentDoc, cfiStartSteps] = startCfiExtended.split('!')
  const [cfiEndContentDoc, cfiEndSteps] = endCfiExtended.split('!')
  const cfisBelongToSameContentDoc = cfiStartContentDoc === cfiEndContentDoc

  let cfiRange, cfiRangeEnd

  if (cfisBelongToSameContentDoc) {
    cfiRangeEnd = cfiEndSteps
  } else {
    cfiRangeEnd = '/100000' // hacky way to get to the end of content doc
  }

  cfiRange = `${cfiStartContentDoc}!,${cfiStartSteps},${cfiRangeEnd}`
  const isValidRange = isValidCfiRange(cfiRange)

  devConsole.log(
    'LearnKitPage/utils - buildCfiRange ~ cfisBelongToSameContentDoc:',
    cfisBelongToSameContentDoc,
  )
  devConsole.log('LearnKitPage/utils - buildCfiRange ~ isValidRange:', isValidRange)
  devConsole.log('LearnKitPage/utils - buildCfiRange ~ cfiRange:', cfiRange)

  if (!isValidRange) {
    cfiRange = `${cfiStartContentDoc}!,${cfiStartSteps},/100000`
  }

  devConsole.log({
    startCfiExtended,
    cfiStartContentDoc,
    cfiStartSteps,
    endCfiExtended,
    cfiEndContentDoc,
    cfiEndSteps,
    cfiRange,
    isValidRange,
  })

  return cfiRange
}

/**
 * Parses CFI and removes assertions
 * @param {string} cfi
 */
export function stripCFIAssertions(cfi) {
  if (!cfi) return
  return parseCfi(cfi).toString({ assertions: false })
}
