import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Switch from '@mui/material/Switch'
import { withTheme } from 'styled-components'
import { ReactComponent as InfoOutline } from 'images/information-outline.svg'
import Tooltip from 'components/Tooltip'

const AllowLateWorkSwitch = ({ onAllowLateWork, lateWorkAllowed }) => {
  const [isLateWorkAllowed, setIsLateWorkAllowed] = useState(lateWorkAllowed)
  const { t } = useTranslation()

  const tooltipTitle = t('allowLateWorkSwitch.lateWorkTooltip')

  const onSwitch = (e) => {
    setIsLateWorkAllowed(e.target.checked)
    onAllowLateWork(e.target.checked)
  }

  return (
    <div className="allow-late-work-switch">
      <Tooltip title={tooltipTitle}>
        <span className="label">
          <InfoOutline className="info-icon" />
          {t('allowLateWorkSwitch.allowLateWork')}
        </span>
      </Tooltip>
      <Switch variant="themed" onClick={onSwitch} checked={isLateWorkAllowed} />
    </div>
  )
}

export default memo(withTheme(AllowLateWorkSwitch))

AllowLateWorkSwitch.propTypes = {
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  /**
   * Allow late work function
   */
  onAllowLateWork: PropTypes.func,
  /**
   * Allow late work function
   */
  lateWorkAllowed: PropTypes.bool,
}

AllowLateWorkSwitch.defaultProps = {
  theme: {},
  onAllowLateWork: () => {},
  lateWorkAllowed: false,
}
