import React, { useContext, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Alert } from 'reactstrap'
import { StoreContext } from 'store'
import Newrelic from 'utils/newrelic'
import Spinner from 'components/Spinner'
import CourseMast from 'components/CourseMast'
import TableOfContents from 'components/TableOfContents'
import InstructorToolbar from 'components/InstructorToolbar'
import Footer from 'components/Footer'
import { actions } from 'store/actions'
import TrialAlert from 'components/TrialAlert'
import queryString from 'query-string'
import classnames from 'classnames'
import { USER_ROLES } from 'utils/constants'

const CourseOverview = () => {
  const { courseKey } = useParams()
  const location = useLocation()
  const { course, courseMessages, dispatch } = useContext(StoreContext)
  const { t } = useTranslation()
  const courseDashboardLevel = 'course'
  const { search: querySearch } = location
  const { completed } = queryString.parse(querySearch)
  const isPaymentCompleted = completed === 'true'
  const enrollment = course ? course.enrollment : null

  const showCourseMessages =
    (courseMessages && courseMessages.course === courseKey && !courseMessages.viewed) ||
    isPaymentCompleted

  useEffect(() => {
    return function cleanup() {
      course && dispatch({ type: actions.COURSE_MESSAGES_UNSET })
    }
  }, [course, dispatch])

  const tocElements = course && course?.syllabusUnitsMap

  const shouldRenderTableOfContents = !!course

  useEffect(() => {
    if (course && course.title) document.title = course.title
  }, [course])

  useEffect(() => {
    Newrelic.setPageViewName('course')
  }, [])

  const isInstructor = enrollment && enrollment.role === USER_ROLES.INSTRUCTOR

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          overview: true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        <CourseMast
          title={course ? course.title : ''}
          level={courseDashboardLevel}
          courseKey={courseKey}
        />
        <Container>
          <Row>
            <Col>
              <div className={`course-dashboard course-dashboard--${courseDashboardLevel}`}>
                <TrialAlert courseKey={courseKey} />

                {showCourseMessages && course && (
                  <Alert className="primary-alert" style={{ marginTop: '20px' }}>
                    {courseMessages.message
                      ? courseMessages.message
                      : `${t('courses.enrollSuccess')} ${course.title}`}
                  </Alert>
                )}

                {!shouldRenderTableOfContents && <Spinner />}

                {shouldRenderTableOfContents && (
                  <TableOfContents
                    courseKey={courseKey}
                    level={courseDashboardLevel}
                    tocElements={tocElements}
                    glossaryUrl={course.glossaryUrl}
                  />
                )}

                {isInstructor && <InstructorToolbar courseKey={courseKey} />}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default React.memo(CourseOverview)
