import { useCallback, useContext } from 'react'
import { StoreContext } from 'store'
import {
  actions,
  resetAction,
  unsetCourseAccessRequirements,
  unsetCourses,
  unsetUserHasAccessToContent,
} from 'store/actions'
import { apiStatus } from 'store/api'
import { actionHasErrors, isActionLoaded, isLoading } from 'store/selectors'

const useEnrollment = () => {
  const { api, dispatch, courseAccessRequirements, enrollment, userAqHasAccess } =
    useContext(StoreContext)

  const getCourseAccessRequirementsActionLoaded = isActionLoaded(
    api,
    'COURSE_ACCESS_REQUIREMENTS_GET'
  )
  const getCourseAccessRequirementsActionHasErrors = actionHasErrors(
    api,
    'COURSE_ACCESS_REQUIREMENTS_GET'
  )

  const enrollActionLoaded = isActionLoaded(api, 'ENROLL_POST')
  const enrollActionHasErrors = actionHasErrors(api, 'ENROLL_POST')

  const userAqHasAccessStatus = apiStatus({
    action: actions.USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST,
    isEmpty: userAqHasAccess === null,
    isLoaded: userAqHasAccess !== null,
  })

  const isApiLoading = isLoading(api)

  const didGetCourseAccessRequirements =
    getCourseAccessRequirementsActionLoaded &&
    !getCourseAccessRequirementsActionHasErrors &&
    courseAccessRequirements

  const canEnrollToCourse =
    didGetCourseAccessRequirements &&
    !courseAccessRequirements.courseEnded &&
    courseAccessRequirements.enrollmentStatus !== 'Valid' &&
    courseAccessRequirements.enrollmentStatus !== 'Trial' &&
    courseAccessRequirements.allowStandardRegistration

  const didEnroll =
    enrollActionLoaded &&
    !enrollActionHasErrors &&
    (enrollment.status === 'Valid' || enrollment.status === 'Trial')

  const userIsEnrolled =
    didEnroll || (didGetCourseAccessRequirements && courseAccessRequirements.enrollmentStatus)

  const shouldCheckUserHasAccessToContent =
    !isApiLoading && didEnroll && userAqHasAccessStatus.shouldLoad && canEnrollToCourse

  const hasCheckedLicense = !userAqHasAccessStatus.shouldLoad && userAqHasAccess

  const stateCleanup = useCallback(() => {
    unsetCourses({ dispatch })
    unsetCourseAccessRequirements({ dispatch })
    unsetUserHasAccessToContent({ dispatch })
    resetAction({ dispatch, action: 'COURSE_ACCESS_REQUIREMENTS_GET' })
    resetAction({ dispatch, action: 'ENROLL_POST' })
    resetAction({ dispatch, action: 'CART_URL_GET' })
    resetAction({ dispatch, action: 'USER_AQ_HAS_ACCESS_TO_CONTENT' })
  }, [dispatch])

  return {
    getCourseAccessRequirementsActionLoaded,
    getCourseAccessRequirementsActionHasErrors,
    didEnroll,
    canEnrollToCourse,
    userIsEnrolled,
    hasCheckedLicense,
    shouldCheckUserHasAccessToContent,
    stateCleanup,
  }
}

export default useEnrollment
