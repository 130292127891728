import React, { useContext, useEffect } from 'react'
import { getProfile, unsetProfile } from 'store/actions'
import { StoreContext } from 'store'
import { hasError } from 'store/selectors'
import { useTranslation } from 'react-i18next'
import AlertMessage from 'components/AlertMessage'
import { ProfileSettingsForm } from 'components/Profile'
import Profile from 'pages/Profile/Profile'

const ProfileSettings = () => {
  const { t } = useTranslation()
  const { api, profile, dispatch } = useContext(StoreContext)

  useEffect(() => {
    getProfile({ dispatch })
  }, [dispatch])

  useEffect(() => {
    return function cleanup() {
      unsetProfile({ dispatch })
    }
  }, [dispatch])

  const getMessageType = () => (hasError(api) ? 'danger' : 'success')
  const getMessage = () =>
    hasError(api) ? t('profile.settings.error') : t('profile.settings.success')

  return (
    <Profile cardTitle={t('profile.settings.title')}>
      {profile && profile.isUpdated && (
        <AlertMessage color={getMessageType()}>{getMessage()}</AlertMessage>
      )}

      {profile && <ProfileSettingsForm profile={profile} />}
    </Profile>
  )
}

export default ProfileSettings
