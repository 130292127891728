import i18n from 'i18n'
import moment from 'moment'

export const getCourseTimeInterval = (startDate, endDate, stringOnly) => {
  if (startDate && !endDate) return `${i18n.t('courses.startsOn')} ${moment(startDate).format('l')}`
  if (!startDate && endDate) return `${i18n.t('courses.endsOn')} ${moment(endDate).format('l')}`
  const dash = stringOnly ? '-' : '&dash;'
  if (startDate && endDate)
    return `${moment(startDate).format('l')} ${dash} ${moment(endDate).format('l')}`
}
