import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import Spinner from 'components/Spinner'
import AlertMessage from 'components/AlertMessage'

/**
 * Method to determine if any actions in the `actions` array are found to be
 * loading or errored, and accordingly returns a loading spinner or error message
 * to render along with array of flags used to determine what API call to make.
 *
 * This method relies on the `api` node in our global store, which has two
 * arrays in it: `loading` and `error`. For every fetch action that is fired
 * from the app, apiErrorReducer and apiLoadingReducer properly track the state
 * (is it loading? has it errored?) of those calls. This method simply takes
 * in some conditions and queries the `api` store to determine if api status.
 * r
 *
 * @param {string} action name of action to check api status for
 * @param {bool} isEmpty tells us if no item has been loaded for this action
 * @param {bool} isLoaded tells us if the right item is already loaded
 */
const apiStatus = ({ action, isEmpty, isLoaded }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { api } = useContext(StoreContext)
  const actionName = action.replace('_REQUEST', '')
  const status = {
    render: null,
    hasError: false,
    // load new if item is empty or the right item isn't loaded
    shouldLoad: isEmpty || !isLoaded
  }

  // render an error message if API call failed
  if (api.error[actionName]) {
    status.hasError = true
    status.render = <AlertMessage />
  }

  // render a spinner if we're loading or need to load
  if (api.loading[actionName] || status.shouldLoad) status.render = <Spinner />

  return status
}

apiStatus.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.string),
  isEmpty: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired
}

apiStatus.defaultProps = {
  isEmpty: false,
  isLoaded: false
}

export default apiStatus
