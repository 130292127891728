import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { isDateTimeFieldValid } from './smartFormHelpers'

const SettingsSummary = () => {
  const { t } = useTranslation()
  const { assignmentSelection } = useSettingsContext()

  const currentAssignment = assignmentSelection[0]

  const summaryLabels = {
    attempts: `${t('settingsForm.attemptsLabel')}: `,
    timeLimit: `${t('settingsForm.timeLimitLabel')}: `,
    availableBeginning: `${t('settingsForm.helpTexts.availableBeginning')}: `,
    availableUntil: `${t('settingsForm.helpTexts.availableUntil')}: `,
  }

  const resolveDateTimeValue = (date) => {
    if (isDateTimeFieldValid(date)) {
      return `${date.format(t('settingsForm.dateFormat'))} @ ${date.format(
        t('settingsForm.timeFormat')
      )}`
    }
    if (!date) {
      return t('settingsForm.anyTime')
    }
  }

  return (
    <div className="settings-summary">
      <h3>{t('settingsForm.helpTexts.classSettings')}</h3>
      {currentAssignment.behavior.allowMultipleAttempts && (
        <div className="settings-row">
          <span>{summaryLabels.attempts}</span>
          <span>
            {t(currentAssignment.maxAttempts.i18n) || currentAssignment.maxAttempts.label}
          </span>
        </div>
      )}
      {currentAssignment.behavior.supportsTimeLimit && (
        <div className="settings-row">
          <span>{summaryLabels.timeLimit}</span>
          <span>{t(currentAssignment.timeLimit.i18n) || currentAssignment.timeLimit.label}</span>
        </div>
      )}
      {currentAssignment.behavior.supportsScheduling && (
        <>
          <div className="settings-row">
            <span>{summaryLabels.availableBeginning}</span>
            <span>{resolveDateTimeValue(currentAssignment.availableDate)}</span>
          </div>
          <div className="settings-row">
            <span>{summaryLabels.availableUntil}</span>
            <span>{resolveDateTimeValue(currentAssignment.dueDate)}</span>
          </div>
        </>
      )}
    </div>
  )
}

// SettingsSummary.propTypes = {}

// SettingsSummary.defaultProps = {}

export default SettingsSummary
