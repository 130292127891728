import { actions } from 'store/actions'
import { getActionTypeMatches } from 'store/selectors'

const { LOGIN_POST_REQUEST, LOGIN_POST_FAILURE, LOGIN_POST_SUCCESS } = actions

const isLoginActionToIgnore = (action) =>
  [LOGIN_POST_REQUEST, LOGIN_POST_FAILURE].includes(action.type)

const isLoginSuccess = (action) => action.type === LOGIN_POST_SUCCESS

const apiReauthReducer = (state = false, action) => {
  const requestMatch = getActionTypeMatches(action)

  if (!requestMatch) return state
  const { requestState } = requestMatch

  // return true if some request fails due 403 status
  // also return true for the login request/failure itself
  // returns false for anything else

  return state
    ? !isLoginSuccess(action)
    : (requestState === 'FAILURE' && action.payload.status === 403) || isLoginActionToIgnore(action)
}

export default apiReauthReducer
