import moment from 'moment'
import { DATE_AND_TIME_SELECT_VALUES, DATE_AND_TIME_FIELD_MODES } from './formDefinitions'
import {
  fetchDateAndTimeFieldSetup,
  isDateTimeFieldValid,
  consolidateDateTime,
} from './smartFormHelpers'

export const formValidationFunction = (readOnlyValues, t, timezone) => {
  const errors = {}
  const availableDateFields = fetchDateAndTimeFieldSetup(
    DATE_AND_TIME_FIELD_MODES.availableDate
  ).formFields
  const dueDateFields = fetchDateAndTimeFieldSetup(DATE_AND_TIME_FIELD_MODES.dueDate).formFields

  const values = { ...readOnlyValues }

  const now = moment().tz(timezone)

  const isFieldValid = {
    [availableDateFields.date.name]: isDateTimeFieldValid(values[availableDateFields.date.name]),
    [availableDateFields.time.name]: isDateTimeFieldValid(values[availableDateFields.time.name]),
    [dueDateFields.date.name]: isDateTimeFieldValid(values[dueDateFields.date.name]),
    [dueDateFields.time.name]: isDateTimeFieldValid(values[dueDateFields.time.name]),
  }

  // Available date picker validation
  if (values[availableDateFields.select.name] === DATE_AND_TIME_SELECT_VALUES.definedDate) {
    if (!isFieldValid[availableDateFields.date.name]) {
      errors[availableDateFields.date.name] = t('formValidation.invalidDate')
    }
  } else {
    // No date validation errors if not definedDate
    delete errors[availableDateFields.date.name]
  }

  // Available time picker validation
  if (values[availableDateFields.select.name] === DATE_AND_TIME_SELECT_VALUES.definedDate) {
    if (!isFieldValid[availableDateFields.time.name]) {
      errors[availableDateFields.time.name] = t('formValidation.invalidTime')
    }
  } else {
    // No time validation errors if not definedDate
    delete errors[availableDateFields.time.name]
  }

  // Due date picker validation
  if (values[dueDateFields.select.name] === DATE_AND_TIME_SELECT_VALUES.definedDate) {
    if (isFieldValid[dueDateFields.date.name]) {
      let dueDateDatePickerError = false

      const consolidatedDueDate = consolidateDateTime({
        date: values[dueDateFields.date.name],
        time: values[dueDateFields.time.name],
        timezone,
      })

      const differentDate = now.date() !== consolidatedDueDate.date()
      if (now.isAfter(consolidatedDueDate) && differentDate) {
        // Due date should be in the future
        dueDateDatePickerError = true
        errors[dueDateFields.date.name] = t('formValidation.dueDateMustBeFuture')
      }

      if (!dueDateDatePickerError) {
        delete errors[dueDateFields.date.name]
      }
    } else {
      errors[dueDateFields.date.name] = t('formValidation.invalidDate')
    }
  } else {
    // No date validation errors if not definedDate
    delete errors[dueDateFields.date.name]
  }

  // Due time picker validation
  if (values[dueDateFields.select.name] === DATE_AND_TIME_SELECT_VALUES.definedDate) {
    if (isFieldValid[dueDateFields.time.name]) {
      if (!isFieldValid[dueDateFields.date.name]) return errors
      let dueDateTimePickerError = false

      const consolidatedDueDate = consolidateDateTime({
        date: values[dueDateFields.date.name],
        time: values[dueDateFields.time.name],
        timezone,
      })

      if (now.diff(consolidatedDueDate, 'minutes') > 0 && !errors[dueDateFields.date.name]) {
        // Start time should be in the future
        dueDateTimePickerError = true
        errors[dueDateFields.time.name] = t('formValidation.dueTimeMustBeFuture')
      }

      if (!dueDateTimePickerError) {
        delete errors[dueDateFields.time.name]
      }
    } else {
      errors[dueDateFields.time.name] = t('formValidation.invalidTime')
    }
  } else {
    // No time validation errors if not definedDate
    delete errors[dueDateFields.time.name]
  }

  // Date cross validation
  const allDatesAreValid = Object.values(isFieldValid).reduce((acc, valid) => valid && acc, true)
  const hasDateValidationErrors = Object.keys(isFieldValid).reduce(
    (acc, field) => Boolean(errors[field] || acc),
    false
  )

  if (allDatesAreValid && !hasDateValidationErrors) {
    const consolidatedAvailableDate = consolidateDateTime({
      date: values[availableDateFields.date.name],
      time: values[availableDateFields.time.name],
      timezone,
    })

    const consolidatedDueDate = consolidateDateTime({
      date: values[dueDateFields.date.name],
      time: values[dueDateFields.time.name],
      timezone,
    })

    const differentDate = consolidatedAvailableDate.date() !== consolidatedDueDate.date()
    if (consolidatedAvailableDate.isAfter(consolidatedDueDate) && differentDate) {
      // Due date should happen after start date
      errors[availableDateFields.date.name] = t('formValidation.startDateAfterDueDate')
      errors[dueDateFields.date.name] = t('formValidation.dueDateBeforeStartDate')
    }
    if (
      consolidatedAvailableDate.diff(consolidatedDueDate, 'minutes') > 0 &&
      !errors[dueDateFields.date.name]
    ) {
      // Due time should happen after start time.
      errors[availableDateFields.time.name] = t('formValidation.startTimeAfterDueTime')
      errors[dueDateFields.time.name] = t('formValidation.dueTimeBeforeStartTime')
    }
  }

  return errors
}
