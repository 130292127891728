import { useContext, useEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import {
  actions,
  getAssignmentsUnitsAndModules,
  getAssignmentsExceptions,
  getAssignmentTypes,
  getCourseSettings,
  getEnrollments,
  resetAllAssignmentsActions,
  unsetAllAssignmentsData,
  unsetAssignmentsExceptions,
  unsetCourseSettings,
  unsetEnrollments,
} from 'store/actions'
import { apiStatus } from 'store/api'
import { isActionLoaded } from 'store/selectors'
import { USER_ROLES } from 'utils/constants'
import { isEmptyArray, isEmptyObject } from 'utils/functional'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { setAssignmentTypes } from 'pages/Settings/store/actions'

const ASSIGNMENT_BEHAVIORS_REQUIRED = [
  'supports_attempts',
  'supports_time_limit',
  'supports_scheduling',
]

const getSupportedTypesAndLabels = (assignmentTypes, behaviorsRequired) => {
  if (!assignmentTypes || !behaviorsRequired) return
  const supportedTypesAndLabels = Object.keys(assignmentTypes).reduce((acc, assignmentType) => {
    for (const purposeType of assignmentTypes[assignmentType]) {
      const hasAnyRequiredBehavior = behaviorsRequired.filter((behavior) =>
        purposeType?.capabilities.includes(behavior)
      ).length
      if (hasAnyRequiredBehavior) {
        acc[purposeType.purposeType] = purposeType.label
      }
    }
    return acc
  }, {})
  return supportedTypesAndLabels
}

const useAssignments = ({ courseKey }) => {
  const {
    api,
    assignments,
    assignmentsExceptions,
    assignmentTypes,
    course,
    courseSettings,
    dispatch,
    enrollments,
    userAq,
  } = useContext(StoreContext)
  const { dispatchSettingsAction } = useSettingsContext()
  const isInitialLoadCompleted = useRef(false)

  const isInstructor = course?.enrollment?.role === USER_ROLES.INSTRUCTOR
  const isAdmin = !!userAq && userAq?.is_admin

  const isInstructorSettingsAllowed = course?.enableInstructorSettings && (isInstructor || isAdmin)

  const assignmentsStatus = apiStatus({
    action: actions.ASSIGNMENTS_GET_REQUEST,
    isEmpty: !assignments,
    isLoaded: assignments !== null,
  })

  const assignmentsExceptionsStatus = apiStatus({
    action: actions.ASSIGNMENTS_EXCEPTIONS_GET_REQUEST,
    isEmpty: !assignmentsExceptions,
    isLoaded: assignmentsExceptions && !isEmptyArray(assignmentsExceptions),
  })

  const assignmentTypesStatus = apiStatus({
    action: actions.ASSIGNMENT_TYPES_GET_REQUEST,
    isEmpty: !assignmentTypes,
    isLoaded: assignmentTypes !== null,
  })

  const courseSettingsStatus = apiStatus({
    action: actions.COURSE_SETTINGS_GET_REQUEST,
    isEmpty: isEmptyObject(courseSettings),
    isLoaded: !isEmptyObject(courseSettings),
  })

  const enrollmentsStatus = apiStatus({
    action: actions.ENROLLMENTS_GET_REQUEST,
    isEmpty: isEmptyArray(enrollments),
    isLoaded: !isEmptyArray(enrollments),
  })

  const supportedTypesAndLabels = useMemo(
    () => getSupportedTypesAndLabels(assignmentTypes, ASSIGNMENT_BEHAVIORS_REQUIRED),
    [assignmentTypes]
  )

  const isAssignmentsLoaded = isActionLoaded(api, 'ASSIGNMENTS_GET')
  const isAssignmentsExceptionsLoaded = isActionLoaded(api, 'ASSIGNMENTS_EXCEPTIONS_GET')
  const isAssignmentTypesLoaded = isActionLoaded(api, 'ASSIGNMENT_TYPES_GET')
  const isCourseSettingsLoaded = isActionLoaded(api, 'COURSE_SETTINGS_GET')
  const isEnrollmentsLoaded = isActionLoaded(api, 'ENROLLMENTS_GET')

  const hasAssignmentTypes = isAssignmentTypesLoaded
  const shouldLoadAssignmentTypes = isInstructorSettingsAllowed && assignmentTypesStatus.shouldLoad
  const shouldLoadAssignments =
    isInstructorSettingsAllowed && !!userAq && hasAssignmentTypes && assignmentsStatus.shouldLoad
  const shouldLoadAssignmentsExceptions =
    isInstructorSettingsAllowed &&
    !!userAq &&
    hasAssignmentTypes &&
    assignmentsExceptionsStatus.shouldLoad
  const isAllAssignmentsDataLoaded =
    isCourseSettingsLoaded &&
    (hasAssignmentTypes
      ? isAssignmentsLoaded && isAssignmentsExceptionsLoaded && isEnrollmentsLoaded
      : isAssignmentTypesLoaded)

  useEffect(() => {
    if (shouldLoadAssignmentTypes) {
      getAssignmentTypes({
        dispatch,
        courseKey,
      })
    }
  }, [shouldLoadAssignmentTypes, courseKey, dispatch])

  useEffect(() => {
    if (shouldLoadAssignments) {
      getAssignmentsUnitsAndModules({
        dispatch,
        courseKey,
        purposeTypes: supportedTypesAndLabels ? Object.keys(supportedTypesAndLabels).join() : null,
      })
    }
  }, [dispatch, courseKey, shouldLoadAssignments, supportedTypesAndLabels])

  useEffect(() => {
    if (shouldLoadAssignmentsExceptions) {
      getAssignmentsExceptions({
        dispatch,
        courseKey,
      })
    }
  }, [courseKey, dispatch, shouldLoadAssignmentsExceptions])

  useEffect(() => {
    if (courseSettingsStatus.shouldLoad) {
      getCourseSettings({
        dispatch,
        courseKey,
      })
    }
  }, [courseKey, courseSettingsStatus.shouldLoad, dispatch])

  useEffect(() => {
    if (enrollmentsStatus.shouldLoad) {
      getEnrollments({
        dispatch,
        courseKey,
      })
    }
  }, [courseKey, dispatch, enrollmentsStatus.shouldLoad])

  useEffect(() => {
    setAssignmentTypes({ dispatchSettingsAction, types: supportedTypesAndLabels })
  }, [dispatchSettingsAction, supportedTypesAndLabels])

  useEffect(() => {
    if (isAllAssignmentsDataLoaded && !isInitialLoadCompleted?.current) {
      isInitialLoadCompleted.current = true
    }
  }, [isAllAssignmentsDataLoaded])

  useEffect(() => {
    return function cleanup() {
      unsetAllAssignmentsData({ dispatch })
      resetAllAssignmentsActions({ dispatch })
      unsetAssignmentsExceptions({ dispatch })
      unsetCourseSettings({ dispatch })
      unsetEnrollments({ dispatch })
    }
  }, [dispatch])

  return [isInitialLoadCompleted?.current || isAllAssignmentsDataLoaded]
}

useAssignments.propTypes = {
  courseKey: PropTypes.string,
}

export default useAssignments
