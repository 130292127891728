import i18n from 'i18n'
import { fetchAgGridLocale } from './localization'
import {
  formatDateCellRenderer,
  exceptionsCellRenderer,
  showLabelCellRenderer,
  nameCellRenderer,
  editUnitAndModuleCellRenderer,
} from '../cellRenderers'
import { ASSIGNMENT_LIST_ITEM_TYPES } from 'utils/constants'
import { clickCellButtonOnkeyPress } from './keyboard'

export const getFormattedLocation = (location) => {
  const { module = {}, unit = {} } = location
  const formattedUnit = `${unit.label}${unit.isUnnumbered ? '' : ` ${unit.number}`}: ${unit.title}`
  const formattedModule = `${module.label}${module.isUnnumbered ? '' : ` ${module.number}`} ${
    module.title
  }`
  return `${formattedUnit} > ${formattedModule}`
}

const getFormattedDate = (date) => {
  if (!date) return
  return `${date.format(i18n.t('settingsForm.dateFormat'))} @ ${date.format(
    i18n.t('settingsForm.timeFormat'),
  )}`
}

export const comparators = {
  labelValue: (a, b) => {
    let { label: labelA, value: valueA } = a
    let { label: labelB, value: valueB } = b

    // A-Z sort order → Numbers first (1, 2, 3, 4, 5) then letters (Unlimited/None), then NA
    if (labelA === 'None' || labelA === 'Unlimited') {
      valueA = 999
    }
    if (labelB === 'None' || labelB === 'Unlimited') {
      valueB = 999
    }

    return parseInt(valueA, 10) - parseInt(valueB, 10)
  },
  location: (valueA, valueB) => valueA?.position - valueB?.position,
}

export const fetchColumnDefs = (columns) => {
  return [
    {
      headerName: '',
      field: 'checker',
      colId: 'col_checker',
      maxWidth: 50,
      resizable: false,
      checkboxSelection: true,
      headerCheckboxSelection: false,
      suppressMovable: true,
    },
    {
      headerName: '',
      field: 'editAction',
      colId: 'col_edit_action',
      maxWidth: 0,
      resizable: false,
      cellRenderer: editUnitAndModuleCellRenderer,
      suppressKeyboardEvent: clickCellButtonOnkeyPress,
      suppressMovable: true,
      // cellClass: 'edit-action-cell',
      cellClass: (props) => {
        return props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment
          ? 'edit-action-cell no-pointer-events'
          : 'edit-action-cell'
      },
    },
    // {
    //   headerName: columns?.location,
    //   headerTooltip: columns?.location,
    //   field: 'location',
    //   colId: 'col_location',
    //   sort: 'asc',
    //   flex: 2,
    //   comparator: comparators.location,
    //   sortable: true,
    //   cellRenderer: locationCellRenderer,
    //   tooltipValueGetter: (props) => getFormattedLocation(props.value),
    //   suppressNavigable: true,
    //   // cellClass: 'no-border', // Removes blue border
    // },
    {
      headerName: columns?.title,
      headerTooltip: columns?.title,
      field: 'title',
      colId: 'col_title',
      sortable: false,
      flex: 8,
      minWidth: 300,
      cellRenderer: nameCellRenderer,
      suppressKeyboardEvent: clickCellButtonOnkeyPress,
      tooltipField: 'title',
      suppressMovable: true,
    },
    {
      headerName: columns?.type,
      headerTooltip: columns?.type,
      field: 'purposeLabel',
      colId: 'col_type',
      maxWidth: 300,
      flex: 3,
      sortable: false,
      tooltipField: 'purposeLabel',
      suppressNavigable: true,
    },
    {
      headerName: columns?.maxAttempts,
      headerTooltip: columns?.maxAttempts,
      field: 'maxAttempts',
      colId: 'col_attempts',
      maxWidth: 150,
      flex: 2,
      comparator: comparators.labelValue,
      sortable: false,
      cellRenderer: showLabelCellRenderer,
      tooltipValueGetter: (props) =>
        props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment ? props.value?.label : null,
      suppressNavigable: true,
    },
    {
      headerName: columns?.timeLimit,
      headerTooltip: columns?.timeLimit,
      field: 'timeLimit',
      colId: 'col_timeLimit',
      maxWidth: 120,
      flex: 2,
      comparator: comparators.labelValue,
      sortable: false,
      cellRenderer: showLabelCellRenderer,
      tooltipValueGetter: (props) =>
        props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment ? props.value?.label : null,
      suppressNavigable: true,
    },
    {
      headerName: columns?.studentExceptions,
      headerTooltip: columns?.studentExceptions,
      field: 'studentExceptions',
      colId: 'col_studentExceptions',
      maxWidth: 160,
      flex: 2,
      sortable: false,
      suppressNavigable: true,
      cellRenderer: exceptionsCellRenderer,
      tooltipValueGetter: (props) =>
        props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment ? props.value : null,
    },
    {
      headerName: `${columns?.availableDate} *`,
      headerTooltip: columns?.availableDate,
      field: 'availableDate',
      colId: 'col_available_date',
      minWidth: 120,
      flex: 2,
      sortable: false,
      cellRenderer: formatDateCellRenderer,
      tooltipValueGetter: (props) => {
        if (
          props.data.type !== ASSIGNMENT_LIST_ITEM_TYPES.assignment &&
          !props.data.studentAvailability
        ) {
          // Disabled unit or module.
          return i18n.t('assignmentTable.notAvailable')
        }
        if (props.value) {
          // Explicit start date
          return getFormattedDate(props.value)
        }
        const ascendantSegment = props.context.findStartDateAscendantSegment(
          props.node.rowIndex,
          props.api,
        )
        if (ascendantSegment) {
          // Return inherited date
          return `${getFormattedDate(ascendantSegment.availableDate)} - ${i18n.t(
            'assignmentTable.inheritedFrom',
          )} ${ascendantSegment.title}`
        }
        // No date to display
        return null
      },
      suppressNavigable: true,
    },
    {
      headerName: `${columns?.dueDate} *`,
      headerTooltip: columns?.dueDate,
      field: 'dueDate',
      colId: 'col_due_date',
      minWidth: 120,
      flex: 2,
      sortable: false,
      cellRenderer: formatDateCellRenderer,
      tooltipValueGetter: (props) => {
        if (props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment) {
          return getFormattedDate(props.value)
        } else {
          return null
        }
      },
      suppressNavigable: true,
      resizable: false,
    },
  ]
}

// const preventTitleRowSelection = (e) => {
//   const suppressRowSelection = e?.column?.colId === 'col_title' ? true : false
//   e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = suppressRowSelection
// }

export const fetchInitialGridOptions = ({ columns, t }) => {
  const gridOptions = {
    defaultColDef: {
      flex: 1,
      minWidth: 100,
      resizable: true,
    },
    localeText: { ...fetchAgGridLocale(), ...t('table') },
    columnDefs: fetchColumnDefs(columns),
    rowSelection: 'multiple',
    // onCellFocused: preventTitleRowSelection,
    rowHeight: 56,
    headerHeight: 37,
    tooltipShowDelay: 750,
    suppressRowClickSelection: true,
    isRowSelectable: (node) => {
      return node.data ? node.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment : false
    },
    rowClassRules: {
      'disabled-row': (params) => !params.data.studentAvailability,
    },
    dataTypeDefinitions: {
      // Default data type formatters
      object: {
        baseDataType: 'object',
        extendsDataType: 'object',
        valueParser: () => 'Missing valueParser',
        valueFormatter: () => 'Missing cellRenderer or value formatter',
      },
    },
  }
  return gridOptions
}
