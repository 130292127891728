import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'reactstrap'
import LearningObjectives from './LearningObjectives'
import { ReactComponent as Chevron } from 'images/chevron.svg'

const LearningObjectivesAccordion = ({ los }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const actionDescription = isExpanded
    ? t('learningObjective.collapse')
    : t('learningObjective.expand')

  return (
    <div className="lo-accordion">
      <button
        className={classnames({
          'lo-accordion__toggler': true,
          'lo-accordion__toggler--active': isExpanded
        })}
        id="lo-accordion-toggler"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-controls="lo-accordion__collapse"
      >
        {t('learningObjective.namePlural')}
        <Chevron className="lo-accordion__toggler-icon" aria-hidden="true" />
        <span className="sr-only">{actionDescription}</span>
      </button>
      <Collapse
        isOpen={isExpanded}
        className="lo-accordion__content"
        toggler="#lo-accordion-toggler"
        id="lo-accordion__collapse"
      >
        <LearningObjectives los={los} />
      </Collapse>
    </div>
  )
}

LearningObjectivesAccordion.propTypes = {
  los: PropTypes.arrayOf(PropTypes.string)
}

export default LearningObjectivesAccordion
