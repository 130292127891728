import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Alert, Container } from 'reactstrap'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone'

import Newrelic from 'utils/newrelic'
import apiEndpoints from 'apiEndpoints'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import ContentNotAvailable from 'components/ContentNotAvailable'
import Footer from 'components/Footer'
import LearnKitPage from 'components/LearnKitPage'
import Spinner from 'components/Spinner'
import TrialAlert from 'components/TrialAlert'
import { apiStatus } from 'store/api'
import { getIsInstructorOrAdmin, isActionLoading } from 'store/selectors'
import { error as logError, log } from 'utils/log'
import { ImageModal } from 'components'
import {
  PageLocationBarWithTheme,
  WorkbookPageContentReference as WorkbookPageContent,
  WorkbookPageLearningObjectivesWithTheme,
} from 'components/PageElements/PageElements'
import { doFetch } from 'store/api/platformApi'
import { actions, getAuthoringUserActions, getConfig } from 'store/actions'
import { handleLinksToBookshelf } from 'utils/learnKit'
import queryString from 'query-string'
import { CONTACT_SUPPORT_URL, PLATFORM_HOST } from 'utils/constants'
import { StoreContext } from 'store'
import { isEmptyObject } from 'utils/functional'
import classnames from 'classnames'

function isNumeric(num) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(num)
}

const getUnitId = (courseStructure, pageId) => {
  // find the unit where the page or external module exists.
  for (const unit of courseStructure) {
    for (const mod of unit.modules) {
      if (mod.identifier === pageId) {
        // the pageId is from an external module that exists in course.pages
        return unit.identifier
      }
      for (const page in mod.pages) {
        if (page && page.identifier === pageId) {
          return unit.identifier
        }
      }
    }
  }
}

function calculatePrevAndNextPages(bpe, course, courseKey, pageIndex) {
  // given a course with flattened pages and a page index return the previous and next page urls
  const getUrlFromIndex = (index) => {
    if (index > -1 && index < course.pages.length) {
      if (course.pages[index].pageId) {
        // it's a page
        const pageUrl = bpe.contentPageUrl({
          courseKey: courseKey,
          pageIdentifier: course.pages[index].identifier,
        })
        return pageUrl
      } else if (course.pages[index].moduleId) {
        // it's a module
        const unitId = getUnitId(course.syllabusUnitsMap, course.pages[index].identifier)
        const modUrl = bpe.modulePageUrl({
          courseKey: courseKey,
          unitIdentifier: unitId,
          moduleIdentifier: course.pages[index].identifier,
        })
        return modUrl
      }
    } else {
      return null
    }
  }
  const prevPage = getUrlFromIndex(pageIndex - 1)
  const nextPage = getUrlFromIndex(pageIndex + 1)
  return { prevPage, nextPage }
}

function calculatePrevAndNextAvailablePages(bpe, course, courseKey, pageIndex, userAq) {
  /**
   * Given a course with flattened pages and a page index return the previous and next page urls
   * if current page is available:
   *    search next/prev in course.availablePages
   *    if next/prev is found, return it
   *    if not, null
   * if current page is NOT available:
   *    search next/prev in course.pages
   *    search recursively for the next/prev available page
   */
  const getUrlFromIndex = (pageToGet, index, pages) => {
    if (index > -1 && index < pages.length) {
      const pageByIndex = pages[index]
      const pageByIndexAvailableDate =
        pageByIndex?.schedule?.availableDate &&
        moment.tz(pageByIndex.schedule.availableDate, userAq.time_zone)

      const pageByIndexNotAvailable =
        pageByIndex?.schedule && !pageByIndex?.schedule?.studentAvailability
      const pageByIndexNotYetAvailable =
        !!pageByIndexAvailableDate &&
        moment().tz(userAq.time_zone).isSameOrBefore(pageByIndexAvailableDate)

      if (pageByIndexNotAvailable || pageByIndexNotYetAvailable) {
        const pageIndexToGet =
          pageToGet === 'previous' ? index - 1 : pageToGet === 'next' ? index + 1 : -1
        return getUrlFromIndex(pageToGet, pageIndexToGet, pages)
      }
      if (pageByIndex.pageId) {
        // it's a page
        const pageUrl = bpe.contentPageUrl({
          courseKey: courseKey,
          pageIdentifier: pageByIndex.identifier,
        })
        return pageUrl
      } else if (pageByIndex.moduleId) {
        // it's a module
        const unitId = getUnitId(course.syllabusUnitsMap, pageByIndex.identifier)
        const moduleUrl = bpe.modulePageUrl({
          courseKey: courseKey,
          unitIdentifier: unitId,
          moduleIdentifier: pageByIndex.identifier,
        })
        return moduleUrl
      }
    } else {
      return null
    }
  }

  const currentPage = course.pages[pageIndex]
  const currentPageIsAvailable = currentPage?.schedule?.studentAvailability
  let pagesSource

  if (currentPageIsAvailable) {
    pagesSource = course.availablePages
  } else {
    pagesSource = course.pages
  }

  const currentAvailablePageIndex = pagesSource.findIndex(
    (page) => page.identifier === currentPage.identifier,
  )

  const prevPage = getUrlFromIndex('previous', currentAvailablePageIndex - 1, pagesSource)
  const nextPage = getUrlFromIndex('next', currentAvailablePageIndex + 1, pagesSource)

  return { prevPage, nextPage }
}

const getModule = (moduleId, course) => {
  const { syllabusUnitsMap } = course
  for (let i = 0; i < syllabusUnitsMap.length; i++) {
    for (let j = 0; j < syllabusUnitsMap[i].modules.length; j++) {
      if (syllabusUnitsMap[i].modules[j].identifier === moduleId)
        return syllabusUnitsMap[i].modules[j]
    }
  }
}

const getPagePosition = (data, course) => {
  const currentModule = getModule(data.module.identifier, course)
  const pageRange = currentModule.pageRange
  if (pageRange[1] === null) return 1
  return (data.pageNumber - pageRange[0]) / (pageRange[1] - pageRange[0])
}

function pageReducer(state, action) {
  switch (action.type) {
    case 'loadingBegins': {
      return {
        ...state,
        isLoadingWorkbookPageContent: true,
      }
    }
    case 'setPrevPage': {
      return {
        ...state,
        prevPage: action.payload,
      }
    }
    case 'setNextPage': {
      return {
        ...state,
        nextPage: action.payload,
      }
    }
    case 'loadingEnds': {
      return {
        ...state,
        isLoadingWorkbookPageContent: false,
      }
    }
    case 'setData': {
      return {
        ...state,
        data: action.payload,
        isLoadingWorkbookPageContent: false,
        pageNotFound: false,
        refreshPagination: true,
        hasError: false,
      }
    }
    case 'pageNotFound': {
      return {
        ...state,
        pageNotFound: true,
        hasError: true,
      }
    }
    case 'error': {
      return {
        ...state,
        hasError: true,
      }
    }
    case 'authBegins': {
      return {
        ...state,
        isAuthenticating: true,
      }
    }
    case 'authEnds': {
      return {
        ...state,
        isAuthenticating: false,
      }
    }
    default:
      return state
  }
}

const initialState = {
  data: null,
  prevPage: null,
  nextPage: null,
  hasError: false,
  isAuthenticating: false,
  isLoadingWorkbookPageContent: true,
  pageNotFound: false,
}

const Page = () => {
  const {
    api,
    authoringUserActions,
    course,
    dispatch: dispatchContext,
    dispatch,
    userAq,
    userLk,
    configuration,
    customer,
  } = useContext(StoreContext)
  const location = useLocation()
  const { search: querySearch } = location
  const { courseKey, pageId } = useParams()
  const navigate = useNavigate()
  const [state, dispatchPageReducer] = useReducer(pageReducer, initialState)
  const isAuthenticatingRef = useRef(false)
  const lastPageFetchedRef = useRef(false)
  const { i18n, t } = useTranslation()

  const [imageData, setImageData] = useState({})
  const [modalOpen, setModalOpen] = useState(false)

  const containerRef = useRef(null)

  const bpe = buildPagesEndpoints(i18n)
  const {
    data,
    prevPage,
    nextPage,
    isAuthenticating,
    isLoadingWorkbookPageContent,
    pageNotFound,
    hasError,
  } = state
  const { preview: isPreview } = queryString.parse(querySearch)

  const customerSupportUrl = customer?.studentHelpUrl || CONTACT_SUPPORT_URL

  const pageSourceHost = PLATFORM_HOST

  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)

  const setIsAuthenticating = (authBool) => {
    dispatchPageReducer({ type: authBool ? 'authBegins' : 'authEnds' })
    isAuthenticatingRef.current = authBool
  }

  const configurationStatus = apiStatus({
    action: actions.CONFIGURATION_GET_REQUEST,
    isEmpty: isEmptyObject(configuration) || !configuration.serverMode,
    isLoaded: !isEmptyObject(configuration) && configuration.serverMode,
  })

  const isConfigurationLoading = isActionLoading(api, actions.CONFIGURATION_GET_REQUEST)

  useEffect(() => {
    Newrelic.setPageViewName('course/page')
  }, [])

  useEffect(() => {
    Newrelic.setCustomAttribute('page_id', pageId)
  }, [pageId])

  useEffect(() => {
    if (configurationStatus.shouldLoad && !isConfigurationLoading) {
      getConfig({ dispatch })
    }
  }, [configurationStatus.shouldLoad, isConfigurationLoading, dispatch])

  // click handler for links to Bookshelf
  const linksToBookshelfCallback = (linkHref) => {
    handleLinksToBookshelf({
      linkHref,
      isAuthenticating: isAuthenticatingRef.current,
      setIsAuthenticating,
      userAq,
      userLk,
      dispatch: dispatchContext,
      courseKey,
    })
  }

  const iframeResizerOptions = {
    checkOrigin: [PLATFORM_HOST],
    onInit: () => {
      dispatchPageReducer({ type: 'loadingEnds' })
    },
    onMessage: (msg) => {
      if (!msg.message) {
        return false
      }
      switch (msg.message.type) {
        case 'assignmentLinkClicked': {
          const assignmentType = msg.message.assignmentType
          const assignmentIdentifier = msg.message.assignmentIdentifier
          navigate(
            bpe.assignmentPageUrl(
              {
                courseKey,
                assignmentType,
                assignmentIdentifier,
              },
              true,
            ),
          )
          break
        }
        case 'bookshelfLinkClicked': {
          const bookshelfUrl = msg.message.bookshelfUrl
          linksToBookshelfCallback(bookshelfUrl)
          break
        }
        case 'imageClicked': {
          const { imageAlt, imageSrc } = msg.message
          setImageData({
            alt: imageAlt,
            src: `${pageSourceHost}${imageSrc}`,
          })
          setModalOpen(true)
          break
        }
        case 'smartAuthorLinkToLearn': {
          const smartAuthorUrl = pageSourceHost + msg.message.url
          window.open(smartAuthorUrl, '_blank')
          break
        }
        case 'backToCiteLinkClicked': {
          const offset = msg.message.offset
          const scrollToPosition = offset + containerRef.current.offsetTop
          window.scrollTo(0, scrollToPosition)
          break
        }
        default:
          return false
      }
    },
  }

  const getPageData = (pageId) => {
    const { syllabusUnitsMap } = course
    let id = pageId

    // fix to avoid fetching page data twice when `course` changes
    if (lastPageFetchedRef.current === id) {
      return
    } else {
      lastPageFetchedRef.current = id
    }

    let pageEndpoint = apiEndpoints().workbookPage(courseKey, id)
    dispatchPageReducer({ type: 'loadingBegins' })
    if (isNumeric(id) && id === '1') {
      // if the page is '1' redirect to course home (toc)
      const path = `${window.location.pathname.split('/page')[0]}`
      navigate(path)
      return false
    }
    if (syllabusUnitsMap && syllabusUnitsMap.find((x) => x.pageNumber === parseInt(id, 10))) {
      let nextPage = parseInt(id, 10)
      nextPage += 1
      if (isNumeric(nextPage) && nextPage === data.pageNumber) {
        // is the same page
        dispatchPageReducer({ type: 'setData', payload: data })
        return false
      }
      id = nextPage.toString()
      pageEndpoint = apiEndpoints().workbookPage(courseKey, id)
    }

    return doFetch({ url: pageEndpoint, params: { method: 'get', credentials: 'include' } })
      .then((pageData) => {
        // get the prev and next page from the information we already have
        const pageIndex = course.pages.findIndex(
          ({ identifier }) => identifier === pageData.identifier,
        )

        if (!pageData.identifier) {
          dispatchPageReducer({ type: 'pageNotFound' })
          return false
        }

        let prevPage, nextPage
        // Instructors/Admins do not need to skip not available pages
        if (isInstructorOrAdmin) {
          ;({ prevPage, nextPage } = calculatePrevAndNextPages(bpe, course, courseKey, pageIndex))
        } else {
          ;({ prevPage, nextPage } = calculatePrevAndNextAvailablePages(
            bpe,
            course,
            courseKey,
            pageIndex,
            userAq,
          ))
        }

        // Update page title
        document.title = pageData.title
        // Update currentLocation
        dispatchContext({
          type: actions.CURRENT_LOCATION_SET,
          payload: {
            unit: pageData.unit.identifier,
            module: pageData.module.identifier,
            page: pageData.identifier,
            viewing: 'page',
          },
        })
        if (isNumeric(id)) {
          const pathArray = window.location.pathname.split('/')
          pathArray.pop()
          pathArray.push(pageData.identifier)
          const newPathname = pathArray.join('/')
          navigate(`${window.location.origin}${newPathname}`)
        } else {
          dispatchPageReducer({ type: 'setData', payload: pageData })
          dispatchPageReducer({ type: 'setPrevPage', payload: prevPage })
          dispatchPageReducer({ type: 'setNextPage', payload: nextPage })
        }
        return true
      })
      .catch((error) => {
        logError(error)
        if (isNumeric(id)) {
          dispatchPageReducer({ type: 'pageNotFound' })
        } else {
          dispatchPageReducer({ type: 'error' })
        }
        log('There has been a problem with your fetch operation: ', error.message)
      })
  }

  useEffect(() => {
    if (course) {
      getPageData(pageId)
      // update the last page visited so we don't have to fetch again from the backend
      if (course.enrollment?.lastPageVisited?.identifier !== pageId) {
        dispatchContext({
          type: actions.COURSE_SET_LAST_PAGE_VISITED,
          payload: {
            identifier: pageId,
            title: '',
          },
        })
      }
    }
    // Unset currentLocation.page on unmount
    return () =>
      dispatchContext({
        type: actions.CURRENT_LOCATION_SET,
        payload: { page: null, viewing: null },
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course, pageId, dispatchContext])

  const hasLearnkit = data && data.learnkitFragment && data.learnkitFragment.startCfi

  const isLearnkitAtBottom =
    hasLearnkit && data.contentSectionsLength === data.learnkitFragment.index

  const hasToSplitWorkbookContent =
    hasLearnkit && data.learnkitFragment.index > 0 && data.hasContentSections && !isLearnkitAtBottom

  const buildPageSource = (top = false, bottom = false) => {
    return pageSourceHost + apiEndpoints().page(courseKey, pageId, top, bottom)
  }

  const pageSourceTop = hasToSplitWorkbookContent ? buildPageSource(true) : null
  const pageSourceBottom =
    hasToSplitWorkbookContent && !isLearnkitAtBottom ? buildPageSource(false, true) : null
  const pageSource = hasToSplitWorkbookContent ? null : buildPageSource()

  // render conditions
  const shouldShowErrorAlert = hasError && !data
  const isLoading = isAuthenticating || (!hasError && (!data || !data.identifier))
  const loadedPageDataSuccessfully =
    (!hasError || (hasError && pageNotFound)) && data && data.identifier

  const pageScheduleData =
    loadedPageDataSuccessfully && course?.pages.find((page) => page.identifier === data.identifier)

  const contentAvailableDate =
    pageScheduleData?.schedule?.availableDate &&
    moment.tz(pageScheduleData.schedule.availableDate, userAq.time_zone)
  const formattedContentAvailableDate =
    !!contentAvailableDate &&
    contentAvailableDate.format(t('toc.contentAvailabilityStatementDateFormat'))
  const shouldShowContentNotAvailableMessage =
    !isInstructorOrAdmin &&
    loadedPageDataSuccessfully &&
    pageScheduleData.schedule &&
    !pageScheduleData.schedule.studentAvailability
  const shouldShowContentAvailabilityMessage =
    !isInstructorOrAdmin &&
    loadedPageDataSuccessfully &&
    pageScheduleData?.schedule?.studentAvailability &&
    contentAvailableDate &&
    moment().tz(userAq.time_zone).isSameOrBefore(contentAvailableDate)
  const shouldShowContent =
    loadedPageDataSuccessfully &&
    !shouldShowContentNotAvailableMessage &&
    !shouldShowContentAvailabilityMessage
  const moduleLabel =
    loadedPageDataSuccessfully &&
    (data.module.ordinal
      ? `${data.module.label || data.module.courseLabel} ${data.module.ordinal}`
      : `${data.module.title}`)
  const unavailableContentLabel = loadedPageDataSuccessfully && `${moduleLabel}: ${data.title}`
  const hasLearningObjectives = data && data.learningObjectives.length > 0
  const shouldShowSingleContentBlock = !hasToSplitWorkbookContent
  const shouldShowLearnkitOnTop = hasLearnkit && shouldShowSingleContentBlock && !isLearnkitAtBottom
  const authoringMode = configuration && configuration.serverMode === 'authoring'
  const maintenanceMode = configuration && configuration.serverMode === 'maintenance'
  const productionMode = configuration && configuration.serverMode === 'production'

  // Authoring tools render conditions
  const authoringUserActionsStatus = apiStatus({
    action: actions.AUTHORING_USER_ACTIONS_GET_REQUEST,
    isLoaded: authoringUserActions !== null,
  })

  useEffect(() => {
    if (
      (authoringMode || maintenanceMode || productionMode) &&
      authoringUserActionsStatus.shouldLoad
    )
      getAuthoringUserActions({ dispatch, courseKey })
  }, [
    authoringUserActionsStatus.shouldLoad,
    dispatch,
    courseKey,
    authoringMode,
    maintenanceMode,
    productionMode,
  ])

  const modalBody = !!imageData.src && <img alt={imageData.alt || ''} src={imageData.src} />

  const ifref = React.createRef()
  const ifrefTop = React.createRef()
  const ifrefBottom = React.createRef()

  const onImageModalClose = () => {
    ifref.current && ifref.current.focusBack()
    ifrefTop.current && ifrefTop.current.focusBack()
    ifrefBottom.current && ifrefBottom.current.focusBack()
  }

  const goToNextAvailablePage = () => navigate(nextPage)

  return (
    <>
      <ImageModal
        modalBody={modalBody}
        open={modalOpen}
        toggle={() => setModalOpen(false)}
        onClose={onImageModalClose}
      />
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        {!pageNotFound && data && (
          <PageLocationBarWithTheme
            prev={prevPage}
            next={nextPage}
            module={data.module}
            unit={data.unit}
            pageTitle={data.title}
            pagePosition={getPagePosition(data, course)}
            courseKey={courseKey}
            lang={i18n.language}
          />
        )}
        <div className="workbook-page-reference-container" ref={containerRef}>
          <Container className="workbook-page-container">
            <>
              {shouldShowErrorAlert && (
                <Alert color="danger">
                  {!pageNotFound && t('page.error')}
                  {pageNotFound && t('page.notFound')}
                </Alert>
              )}
              {hasError && !pageNotFound && hasLearnkit && (
                <Alert color="danger">{t('learnkit.error')}</Alert>
              )}
              {isLoading && <Spinner />}
              {shouldShowContent && (
                <>
                  {pageNotFound && <Alert color="danger">{t('page.notFound')}</Alert>}
                  {!pageNotFound && (
                    <>
                      <h1 className="sr-only">{data.title}</h1>
                      {/* Trial alert banner */}
                      <TrialAlert courseKey={courseKey} />
                      {/* Learning objectives */}
                      {hasLearningObjectives && (
                        <WorkbookPageLearningObjectivesWithTheme
                          learningObjectives={data.learningObjectives}
                        />
                      )}
                      {shouldShowSingleContentBlock && (
                        <>
                          {/* LearnKit content */}
                          {shouldShowLearnkitOnTop && (
                            <LearnKitPage
                              courseKey={courseKey}
                              data={data.learnkitFragment}
                              customerSupportUrl={customerSupportUrl}
                            />
                          )}
                          {/* Workbook page content */}
                          {(data.hasContentSections || isPreview) && (
                            <WorkbookPageContent
                              ref={ifref}
                              pageSource={pageSource}
                              isLoadingWorkbookPageContent={isLoadingWorkbookPageContent}
                              iframeResizerOptions={iframeResizerOptions}
                              authoringUserActions={authoringUserActions}
                            />
                          )}
                          {hasLearnkit && !shouldShowLearnkitOnTop && (
                            <LearnKitPage
                              courseKey={courseKey}
                              data={data.learnkitFragment}
                              customerSupportUrl={customerSupportUrl}
                            />
                          )}
                        </>
                      )}
                      {!shouldShowSingleContentBlock && (
                        <>
                          <WorkbookPageContent
                            ref={ifrefTop}
                            pageSource={pageSourceTop}
                            isLoadingWorkbookPageContent={isLoadingWorkbookPageContent}
                            iframeResizerOptions={iframeResizerOptions}
                            authoringUserActions={authoringUserActions}
                          />
                          <LearnKitPage
                            courseKey={courseKey}
                            data={data.learnkitFragment}
                            customerSupportUrl={customerSupportUrl}
                          />
                          <WorkbookPageContent
                            ref={ifrefBottom}
                            pageSource={pageSourceBottom}
                            isLoadingWorkbookPageContent={isLoadingWorkbookPageContent}
                            iframeResizerOptions={iframeResizerOptions}
                            authoringUserActions={authoringUserActions}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
              {shouldShowContentNotAvailableMessage && (
                <ContentNotAvailable
                  buttonLabel={t('page.jumpToNextAvailablePage')}
                  contentIsPage
                  contentLabel={unavailableContentLabel}
                  handleButtonOnClick={goToNextAvailablePage}
                  hasNextPage={!!nextPage}
                />
              )}
              {shouldShowContentAvailabilityMessage && (
                <ContentNotAvailable
                  buttonLabel={t('page.jumpToNextAvailablePage')}
                  contentIsPage
                  formattedDate={formattedContentAvailableDate}
                  handleButtonOnClick={goToNextAvailablePage}
                  hasNextPage={!!nextPage}
                  willBeAvailable
                />
              )}
            </>
          </Container>
        </div>
      </main>
      {!pageNotFound && data && (
        <Footer courseKey={courseKey} withNavigation={true} prev={prevPage} next={nextPage} />
      )}
    </>
  )
}

export default Page
