import { actions } from 'store/actions'
import { initialState } from 'store'

const courseCopyReducer = (state = initialState.courseCopy, action) => {
  switch (action.type) {
    case actions.COURSE_COPY_POST_REQUEST:
      return action.payload
    case actions.COURSE_COPY_POST_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default courseCopyReducer
