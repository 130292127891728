import moment from 'moment-timezone'
import i18n from 'i18n'
import { actions } from 'store/actions'
import { initialState } from 'store'
import { ATTEMPTS_OPTIONS, TIME_LIMIT_OPTIONS } from 'components/Forms/formDefinitions'

const assignmentsReducer = (state = initialState.assignments, action, dependencies) => {
  switch (action.type) {
    case actions.ASSIGNMENTS_GET_SUCCESS: {
      // Payload contains assignments units and modules.
      const payloadAsArray = Array.isArray(action.payload) ? action.payload : [action.payload]
      const parsedPayload = payloadAsArray.reduce((acc, assignment) => {
        // Set label/value pairs for maxAttempts and timeLimit
        const { availableDate, dueDate, maxAttempts, timeLimit } = assignment
        const attemptLabelValuePair = ATTEMPTS_OPTIONS.find(
          (option) => option.value === (maxAttempts ? maxAttempts.toString() : '-1')
        )
        const timeLimitLabelValuePair = TIME_LIMIT_OPTIONS.find(
          (option) => option.value === (timeLimit ? timeLimit.toString() : '0')
        )
        assignment.maxAttempts = attemptLabelValuePair
        assignment.timeLimit = timeLimitLabelValuePair

        // Set `NA` if behavior not supported
        const supportsTimeLimit = assignment.behavior.supportsTimeLimit
        const allowMultipleAttempts = assignment.behavior.allowMultipleAttempts

        if (!supportsTimeLimit) {
          assignment.timeLimit = { label: i18n.t('assignmentTable.notApplicable'), value: '9999' }
        }

        if (!allowMultipleAttempts) {
          assignment.maxAttempts = { label: i18n.t('assignmentTable.notApplicable'), value: '9999' }
        }

        // Store moment object for dates
        const userTimezone = dependencies?.userAq?.time_zone

        if (availableDate && userTimezone) {
          assignment.availableDate = moment.tz(availableDate, userTimezone)
        } else if (availableDate) {
          assignment.availableDate = moment(availableDate)
        }

        if (dueDate && userTimezone) {
          assignment.dueDate = moment.tz(dueDate, userTimezone)
        } else if (dueDate) {
          assignment.dueDate = moment(dueDate)
        }

        acc.push(assignment)

        return acc
      }, [])
      return parsedPayload
    }
    case actions.ASSIGNMENTS_UNSET:
      return initialState.assignments
    default:
      return state
  }
}

export default assignmentsReducer
