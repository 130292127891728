import React from 'react'
import { withTheme } from 'styled-components'
import CourseMetadataItem from './CourseMetadataItem'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import CalendarIcon from 'images/calendar.svg'
import CourseKeyIcon from 'images/course-key.svg'
import InstructorIcon from 'images/instructor.svg'
import { CoursesCardWrapper } from './CoursesCardWrapper'
import { CardBody, CardTitle } from 'reactstrap'
import { Button } from '@vitalsource/vstui'
import _ from 'lodash'

const CourseCard = ({ course, theme, openCourseInfo, showInfoButton, isPending }) => {
  const { t } = useTranslation()
  const color = theme.vstui.color.primary

  const customerColor = color.getShade(7)
  const customerColorLight = color.getShade(5)

  const handleOpenCourseInfo = (event) => {
    event.preventDefault()
    event.stopPropagation()
    openCourseInfo()
  }

  const printName = (i) => `${i.firstName} ${i.lastName}`
  const getInstructorsMetadataLabel = (instructors) => {
    const head = _.head(instructors)
    return instructors.length === 1
      ? printName(head)
      : `${printName(head)} +${instructors.length - 1}`
  }
  const instructorsList = (instructors) =>
    _.join(
      instructors.map((i) => `${printName(i)}`),
      ', ',
    )

  return (
    <CoursesCardWrapper
      className={`mt-3 mb-3 card courses-card ${isPending && 'pending-course'}`}
      customerColor={customerColor}
      customerColorLight={customerColorLight}
    >
      <CardBody>
        <CardTitle>
          <h5>{course.title}</h5>
        </CardTitle>
        <ul className="courses-card__info" id={`course-${course.courseKey}`}>
          {(course.startDate || course.endDate) && !isPending && (
            <CourseMetadataItem
              identifier={course.courseKey}
              cssClass="calendar"
              icon={CalendarIcon}
              iconAlt={t('courses.courseKeyIcon')}
              isCalendar={true}
              startDate={course.startDate}
              endDate={course.endDate}
            />
          )}
          <CourseMetadataItem
            metadata={course.courseKey}
            identifier={course.courseKey}
            cssClass="course-key"
            icon={CourseKeyIcon}
            iconAlt={t('courses.courseKeyIcon')}
          />
          {course.instructors && course.instructors.length > 0 && (
            <CourseMetadataItem
              metadata={`${getInstructorsMetadataLabel(course.instructors)}`}
              identifier={`${course.courseKey}`}
              cssClass="instructor"
              icon={InstructorIcon}
              iconAlt={t('courses.instructorIcon')}
              tooltipLabel={instructorsList(course.instructors)}
            />
          )}
        </ul>
        {showInfoButton && !isPending && (
          <Button className="course-info_button" outline onClick={handleOpenCourseInfo}>
            {t('courses.courseInfoButton')}
          </Button>
        )}
        {isPending && <p className="help-text">{t('courses.beingCreated')}</p>}
      </CardBody>
    </CoursesCardWrapper>
  )
}

CourseCard.propTypes = {
  course: PropTypes.object,
  theme: PropTypes.object,
  openCourseInfo: PropTypes.func,
  showInfoButton: PropTypes.bool,
  isPending: PropTypes.bool,
}

CourseCard.defaultProps = {
  course: {},
  theme: {},
  openCourseInfo: () => {},
  showInfoButton: false,
  isPending: false,
}

export default withTheme(CourseCard)
