import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'
import { StoreContext } from 'store'
import Newrelic from 'utils/newrelic'
import { actions } from 'store/actions'
import Spinner from 'components/Spinner'
import CourseMast from 'components/CourseMast'
import TableOfContents from 'components/TableOfContents'
import Footer from 'components/Footer'
import TrialAlert from 'components/TrialAlert'
import classnames from 'classnames'

const UnitDetail = () => {
  const { courseKey, unitId } = useParams()
  const { course, dispatch } = useContext(StoreContext)
  const { t } = useTranslation()
  const courseDashboardLevel = 'unit'

  const tocElements =
    course && course?.syllabusUnitsMap.filter((unit) => unit.identifier === unitId)

  const shouldRenderTableOfContents = !!course && !!tocElements

  useEffect(() => {
    if (course && course.title) document.title = course.title
    // Update currentLocation.viewing on mount
    dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { viewing: 'unit' } })
    // Unset currentLocation.viewing on unmount
    return () => dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { viewing: null } })
  }, [course, dispatch])

  useEffect(() => {
    Newrelic.setPageViewName('unit')
  }, [])

  if (!course) return <Spinner />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          overview: true,
          'aq-small-caps': course && course.smallCapsEnabled,
        })}
      >
        <CourseMast title={course.title} level={courseDashboardLevel} courseKey={courseKey} />
        <Container>
          <Row>
            <Col>
              <div className={`course-dashboard course-dashboard--${courseDashboardLevel}`}>
                <TrialAlert courseKey={courseKey} />
                {!shouldRenderTableOfContents && <Spinner />}
                {shouldRenderTableOfContents && (
                  <TableOfContents
                    courseKey={courseKey}
                    level={courseDashboardLevel}
                    tocElements={tocElements}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  )
}

export default React.memo(UnitDetail)
