import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormController from 'components/Forms/FormController'
import { Button } from '@vitalsource/vstui'
import PreserveInstructorsCheckField from 'components/Forms/FormModules/PreserveInstructorsCheckField'
import DateField from 'components/Forms/FormModules/DateField'
import { isLoading } from 'store/selectors'
import { StoreContext } from 'store'
import Spinner from 'components/Spinner'
import moment from 'moment'
import { isDateTimeFieldValid } from 'components/Forms/smartFormHelpers'
import { CLONED_COURSE_DATE_FIELDS } from 'components/Forms/formDefinitions'
import TextEdit from 'components/Forms/FormComponents/TextEdit'

const CourseCloneForm = ({ theme, course, closeDrawer, cloneCourse }) => {
  const { t } = useTranslation()
  const { api } = useContext(StoreContext)

  const formInitialValues = {
    preserveInstructors: true,
    newTitle: course.title + ' ' + t('courses.courseCloneForm.titleSufix'),
    startDate: null,
    endDate: null,
  }

  const submitFunction = (values) => {
    !isApiLoading && cloneCourse(values)
  }

  const validationFunction = (values) => {
    const errors = {}
    const isFieldValid = {
      [CLONED_COURSE_DATE_FIELDS.startDate.name]: isDateTimeFieldValid(
        moment(values[CLONED_COURSE_DATE_FIELDS.startDate.name]),
      ),
      [CLONED_COURSE_DATE_FIELDS.endDate.name]: isDateTimeFieldValid(
        moment(values[CLONED_COURSE_DATE_FIELDS.endDate.name]),
      ),
    }
    // Startdate date picker validation
    if (!isFieldValid[CLONED_COURSE_DATE_FIELDS.startDate.name]) {
      errors[CLONED_COURSE_DATE_FIELDS.startDate.name] = t('formValidation.invalidDate')
    } else {
      // Delete error if valid
      delete errors[CLONED_COURSE_DATE_FIELDS.startDate.name]
    }
    // End date picker validation
    delete errors[CLONED_COURSE_DATE_FIELDS.endDate.name]

    // End date picker validation
    if (isFieldValid[CLONED_COURSE_DATE_FIELDS.endDate.name]) {
      const momentStartDate = moment(values[CLONED_COURSE_DATE_FIELDS.startDate.name])
      const momentEndDate = moment(values[CLONED_COURSE_DATE_FIELDS.endDate.name])
      if (momentEndDate < momentStartDate) {
        errors[CLONED_COURSE_DATE_FIELDS.endDate.name] = t('formValidation.startDateAfterDueDate')
      }
    } else {
      // No date validation errors if not definedDate
      delete errors[CLONED_COURSE_DATE_FIELDS.endDate.name]
    }

    if (values.newTitle === '') {
      errors['newTitle'] = t('errors.isRequired')
    } else {
      delete errors['newTitle']
    }
    return errors
  }

  const onClose = () => {
    closeDrawer()
  }

  const isApiLoading = isLoading(api)

  return (
    <>
      {isApiLoading && <Spinner />}
      <FormController
        className="smart-form"
        initialValues={formInitialValues}
        onSubmit={submitFunction}
        validate={validationFunction}
        validateOnChange={true}
        validateOnBlur={false}
      >
        <div className="form-module course-title">
          <TextEdit
            id={'newTitle'}
            name={'newTitle'}
            autoComplete="off"
            label={t('courses.courseCloneForm.title')}
            disabled={isApiLoading}
            theme={theme}
          />
        </div>
        <div className="form-module date-time">
          <DateField theme={theme} disabled={isApiLoading} />
        </div>
        <PreserveInstructorsCheckField theme={theme} value={true} disabled={isApiLoading} />
        <div className="bottom-actions text-right">
          <Button outline onClick={onClose} disabled={isApiLoading}>
            {t('courses.courseCloneForm.cancelButton')}
          </Button>
          <Button type="submit" disabled={isApiLoading}>
            {t('courses.courseCloneForm.saveButton')}
          </Button>
        </div>
      </FormController>
    </>
  )
}

CourseCloneForm.propTypes = {
  course: PropTypes.object,
  theme: PropTypes.shape({}),
  closeDrawer: PropTypes.func,
  cloneCourse: PropTypes.func,
}

export default CourseCloneForm
