import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SelectControl from '@vitalsource/vstui/SelectControl'
import { StoreContext } from 'store'
import { apiStatus } from 'store/api'
import { actions, getCourses } from 'store/actions'

const findCourseByCourseKey = (courses, courseKey) =>
  courses?.find((course) => course.courseKey === courseKey)

const CourseSelector = ({ currentCourseKey, onChange, includeStudentCourses, ...rest }) => {
  const { courses, dispatch, userAq } = useContext(StoreContext)
  const [currentCourse, setCurrentCourse] = useState(
    findCourseByCourseKey(courses, currentCourseKey),
  )

  const isAdmin = !!userAq && userAq?.is_admin

  const coursesList = [...(courses || [])].filter((course) => {
    // Include 5.x courses only
    const is5xCourse = !course.legacyUrl

    // If not Admin, only include courses where current user is instructor
    const isCurrentUserAdminOrInstructorOfThisCourse = isAdmin
      ? true
      : course.instructors &&
        course.instructors.length > 0 &&
        course.instructors.filter((instructor) => instructor.id === userAq.id).length > 0

    const showCoursesAnyway = !isCurrentUserAdminOrInstructorOfThisCourse && includeStudentCourses

    if (
      (is5xCourse && isCurrentUserAdminOrInstructorOfThisCourse) ||
      (is5xCourse && showCoursesAnyway)
    ) {
      return true
    }

    return false
  })

  const coursesStatus = apiStatus({
    action: actions.COURSES_GET_REQUEST,
    isEmpty: !courses,
    isLoaded: courses !== null,
  })

  const handleChange = (e) => {
    const selectedCourse = findCourseByCourseKey(courses, e.target.value)
    setCurrentCourse(selectedCourse)
    if (typeof onChange === 'function') {
      onChange(selectedCourse)
    }
  }

  useEffect(() => {
    if (coursesStatus.shouldLoad) getCourses({ dispatch })
  }, [coursesStatus.shouldLoad, dispatch])

  useEffect(() => {
    if (courses) {
      setCurrentCourse(findCourseByCourseKey(courses, currentCourseKey))
    }
  }, [courses, currentCourseKey])

  if (!currentCourse || !courses) {
    return null
  }

  return (
    <SelectControl
      className="course-selector"
      onChange={handleChange}
      title={currentCourse?.title}
      value={currentCourse?.courseKey}
      {...rest}
    >
      {coursesList.map(({ courseKey, title }) => (
        <option key={courseKey} value={courseKey}>
          {title}
        </option>
      ))}
    </SelectControl>
  )
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.label && !props.labelledBy) {
    return new Error(`One of 'label' or 'labelledBy' is required by '${componentName}' component.`)
  }
}

CourseSelector.propTypes = {
  currentCourseKey: PropTypes.string,
  onChange: PropTypes.func,
  includeStudentCourses: PropTypes.bool,
  label: requiredPropsCheck,
  labelledBy: requiredPropsCheck,
}

export default CourseSelector
