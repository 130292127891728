import { isEmptyObject } from 'utils/functional'

const Newrelic = window.newrelic || {}

if (isEmptyObject(window.newrelic)) {
  Newrelic.noticeError = () => {}
  Newrelic.setCustomAttribute = () => {}
  Newrelic.setPageViewName = () => {}
}

export default Newrelic
