import { actions } from 'store/actions'
import { initialState } from 'store'

const cartUrlReducer = (state = initialState.cartUrl, action) => {
  switch (action.type) {
    case actions.CART_URL_GET_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default cartUrlReducer
