import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Oops from 'images/oops.svg'

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Center>
      <img alt="Oops!" src={Oops} />
      <p style={{ marginTop: 8 }}>{t('page.notFound')}</p>
    </Center>
  )
}

export default NotFound
