import React, { useContext, useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import Newrelic from 'utils/newrelic'
import { StoreContext } from 'store'
import { actions } from 'store/actions'

const Unit = () => {
  const { unitId } = useParams()
  const { dispatch } = useContext(StoreContext)

  useEffect(() => {
    // Update currentLocation.unit every time unitId changes
    dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { unit: unitId } })
    // Unset currentLocation.unit on unmount
    return () => dispatch({ type: actions.CURRENT_LOCATION_SET, payload: { unit: null } })
  }, [dispatch, unitId])

  useEffect(() => {
    Newrelic.setCustomAttribute('unit_id', unitId)
  }, [unitId])

  return <Outlet />
}

export default React.memo(Unit)
