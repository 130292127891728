import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import PageMast from './PageMast'
import Footer from 'components/Footer'
import NavMain from 'components/NavMain/NavMain'
import Spinner from 'components/Spinner'
import { useCustomerData, useStateWithLocalStorage } from 'utils/customHooks'

const BaseLayout = ({ children, title }) => {
  const { t } = useTranslation()

  const [brandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')

  const { brandedSigninCustomerData } = useContext(StoreContext)
  const [isCustomerDataReady] = useCustomerData({ customerSlug: brandedSignInCustomer })
  const { customerImage, customerName } = brandedSigninCustomerData
  const brandData = isCustomerDataReady ? { image: customerImage, name: customerName } : null

  if (!isCustomerDataReady) return <Spinner />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className="px-0 overview base-layout"
      >
        <NavMain customerBrandData={brandData} hideMenuIcon={true} />
        <PageMast title={title} />
        <Container className="p-0">{children}</Container>
      </main>
      <Footer />
    </>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
}

export default BaseLayout
