/* eslint-disable react/no-multi-comp */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardBody, CardTitle, Container, Row, Col } from 'reactstrap'
import { StoreContext } from 'store'
import {
  getCourses,
  actions,
  resetAction,
  unsetCourseAccessRequirements,
  unsetUserHasAccessToContent,
} from 'store/actions'
import { apiStatus } from 'store/api'
import NavMain from 'components/NavMain/NavMain'
import PageHeader from 'components/PageHeader'
import Footer from 'components/Footer'
import AddIcon from 'images/add.svg'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { useCustomerData, useStateWithLocalStorage } from 'utils/customHooks'
import Spinner from 'components/Spinner'
import styled, { withTheme } from 'styled-components'
import CourseCard from './CourseCard'
import { CoursesCardWrapper } from './CoursesCardWrapper'
import {
  openCourseInfoDrawer,
  closeCourseInfoDrawer,
  openCloneDrawer,
  closeCourseCloneDrawer,
  selectCourse,
} from './store/actions'
import { useCoursesContext } from './store/coursesContext'
import { withCoursesContext } from 'hoc'
import { COURSE_INFO_DRAWER_ID, COURSE_CLONE_DRAWER_ID } from './constants'
import Drawer from 'components/Drawer'
import CourseInfo from './CourseInfo'
import CourseCloneForm from './CourseCloneForm'
import { courseCopy as courseCopyAction, unsetCourses } from 'store/actions/courseActions'
import { actionHasErrors, isActionLoaded } from 'store/selectors'
import { getIsInstructorByEmail } from 'store/selectors/courseSelectors'
import CourseRegisterInstructions from './CourseRegisterInstructions'
import CourseCreatedModal from './CourseCreatedModal'
import _ from 'lodash'
import useCourseStatusPolling from './useCourseStatusPolling'
import { API_POLLING_RESPONSES } from 'utils/polling'

const AddCardTitle = styled.h4`
  color: ${(props) => props.customerColor};
`

const Courses = ({ theme }) => {
  const navigate = useNavigate()
  const { api, brandedSigninCustomerData, courses, courseCopy, dispatch, userAq } =
    useContext(StoreContext)
  const { t, i18n } = useTranslation()
  const color = theme.vstui.color.primary
  const bpe = buildPagesEndpoints(i18n)

  const [showOkModal, setShowOkModal] = useState(false)
  const [showInstructionsModal, setShowInstructionsModal] = useState(false)
  const [coursePollingQueue, setCoursePollingQueue] = useState([])
  const [coursePollingQueueMustUpdate, setCoursePollingQueueMustUpdate] = useState('')
  const [brandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')
  const [handleQueuedCoursesPolling, handleCoursePolling] = useCourseStatusPolling()

  const [isCustomerDataReady] = useCustomerData({ customerSlug: brandedSignInCustomer })
  const { customerImage, customerName, customerSupportUrl } = brandedSigninCustomerData
  const brandData = isCustomerDataReady ? { image: customerImage, name: customerName } : null

  const customerColor = color.getShade(7)
  const customerColorLight = color.getShade(5)

  const { dispatchCoursesAction, isDrawerOpen, selectedCourse } = useCoursesContext()

  // let queuedCourses = []

  const isAdmin = !!userAq && userAq?.is_admin

  const status = apiStatus({
    action: actions.COURSES_GET_REQUEST,
    isEmpty: !courses,
    isLoaded: courses !== null,
  })

  const handleCardClick = (event, ck, isLearn, legacyUrl) => {
    event.preventDefault()
    if (isLearn) {
      const courseLink = bpe.coursePageUrl({ courseKey: ck })
      navigate(courseLink)
    } else {
      window.location.href = legacyUrl
    }
  }

  const handleCourseInfoClick = useCallback(
    (course) => {
      openCourseInfoDrawer({ dispatchCoursesAction, course })
    },
    [dispatchCoursesAction],
  )

  const handleCourseInfoDrawerClose = useCallback(() => {
    closeCourseInfoDrawer({ dispatchCoursesAction, COURSE_INFO_DRAWER_ID })
  }, [dispatchCoursesAction])

  const handleOpenCloneDrawer = useCallback(
    (course) => {
      handleCourseInfoDrawerClose()
      openCloneDrawer({ dispatchCoursesAction, course })
    },
    [dispatchCoursesAction, handleCourseInfoDrawerClose],
  )

  const handleCloneDrawerClose = useCallback(() => {
    closeCourseCloneDrawer({ dispatchCoursesAction, COURSE_CLONE_DRAWER_ID })
  }, [dispatchCoursesAction])

  const handleCloneCourse = (data) => {
    const sanitizedData = {
      enrollUsers: data.preserveInstructors,
      ...data,
    }
    courseCopyAction({ dispatch, courseKey: selectedCourse.courseKey, data: sanitizedData })
  }

  const stateCleanup = useCallback(() => {
    unsetUserHasAccessToContent({ dispatch })
    unsetCourseAccessRequirements({ dispatch })
    resetAction({ dispatch, action: 'COURSE_ACCESS_REQUIREMENTS_GET' })
    resetAction({ dispatch, action: 'ENROLL_POST' })
    resetAction({ dispatch, action: 'CART_URL_GET' })
    resetAction({ dispatch, action: 'USER_AQ_HAS_ACCESS_TO_CONTENT' })
    resetAction({ dispatch, action: 'COURSE_COPY_POST' })
  }, [dispatch])

  const hasErrorsOnCourseCopy = actionHasErrors(api, 'COURSE_COPY_POST')
  const isCourseCopied = isActionLoaded(api, 'COURSE_COPY_POST')

  const toggleOkModal = useCallback(() => {
    resetAction({ dispatch, action: 'COURSE_COPY_POST' })
    setShowOkModal(!showOkModal)
  }, [dispatch, showOkModal])

  const toggleInstructionsModal = useCallback(
    (course) => {
      handleCourseInfoDrawerClose()
      selectCourse({ dispatchCoursesAction, selection: course })
      setShowInstructionsModal(!showInstructionsModal)
    },
    [showInstructionsModal, dispatchCoursesAction, handleCourseInfoDrawerClose],
  )

  const handleNewCourseModal = useCallback(
    () => toggleInstructionsModal(courseCopy),
    [courseCopy, toggleInstructionsModal],
  )

  const getPendingCourses = () =>
    _.filter(courses, (course) => {
      if (course.status !== API_POLLING_RESPONSES.valid) {
        return course.courseKey
      }
    })

  const handlePollingSuccess = (key) => {
    resetAction({ dispatch, action: 'COURSE_COPY_POST' })
    setShowOkModal(false)
    unsetCourses({ dispatch })
    setCoursePollingQueueMustUpdate(key)
  }

  const addCourseToQueue = useCallback(
    (course) => {
      setCoursePollingQueue([
        {
          ...course,
          title: course.courseTitle,
        },
        ...coursePollingQueue,
      ])
    },
    [setCoursePollingQueue, coursePollingQueue],
  )

  const updateCourseQueue = useCallback(
    (key) => {
      let cpq = coursePollingQueue
      const index = _.findIndex(coursePollingQueue, (c) => c.courseKey === key)
      if (index !== -1) {
        cpq.splice(index, 1)
        setCoursePollingQueue(cpq)
      }
    },
    [coursePollingQueue],
  )

  useEffect(() => {
    if (coursePollingQueueMustUpdate !== '') {
      updateCourseQueue(coursePollingQueueMustUpdate)
      setCoursePollingQueueMustUpdate('')
    }
  }, [
    updateCourseQueue,
    coursePollingQueue,
    coursePollingQueueMustUpdate,
    setCoursePollingQueueMustUpdate,
  ])

  useEffect(() => {
    if (isCourseCopied && !hasErrorsOnCourseCopy) {
      addCourseToQueue(courseCopy)
      handleCoursePolling({ course: courseCopy, onPollingSuccess: handlePollingSuccess })
      handleCloneDrawerClose()
      toggleOkModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCourseCopied, hasErrorsOnCourseCopy, handleCloneDrawerClose])

  useEffect(() => {
    document.title = 'Acrobatiq'
    // cleanup course state on mount
    stateCleanup()
  }, [stateCleanup])

  useEffect(() => {
    if (status.shouldLoad) getCourses({ dispatch })
  }, [status.shouldLoad, dispatch])

  useEffect(() => {
    const queuedCourses = getPendingCourses()
    handleQueuedCoursesPolling({ courses: queuedCourses, onPollingSuccess: handlePollingSuccess })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status.shouldLoad])

  // if we need to, display spinner or error message instead of the courses
  // doing it this way means no more conditional loading/error checks inside
  // the component markup. This return must always happen after `useEffect`
  // as hooks have to fire on each render.
  if (status.render) return status.render

  if (!isCustomerDataReady) return <Spinner />

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className="px-0 overview"
      >
        <NavMain customerBrandData={brandData} hideMenuIcon={true} />
        <PageHeader>
          <h1 className="courses-mast__title">{t('courses.mastTitle')}</h1>
        </PageHeader>
        <Container className="pt-3 pb-5">
          {courses.length === 0 && (
            <h5 className="courses-not-enrolled__title">{t('courses.notEnrolled')}</h5>
          )}
          <Row>
            <Col sm="12" md="4">
              <Link to={bpe.addCoursePageUrl()}>
                <CoursesCardWrapper
                  className="mt-3 mb-3 courses-card add-course-card"
                  customerColor={customerColor}
                  customerColorLight={customerColorLight}
                >
                  <CardBody>
                    <img src={AddIcon} alt={t('courses.addCourse')} className="card-icon" />
                    <CardTitle>
                      <AddCardTitle customerColor={customerColor}>
                        {t('courses.addCourse')}
                      </AddCardTitle>
                      <p>{t('courses.addCourseDesc')}</p>
                    </CardTitle>
                  </CardBody>
                </CoursesCardWrapper>
              </Link>
            </Col>
            {courses.map((course) => (
              <Col sm="12" md="4" key={course.courseKey}>
                {course.status === API_POLLING_RESPONSES.valid && (
                  <a
                    href={`#card-${course.courseKey}`}
                    onClick={(event) => {
                      handleCardClick(
                        event,
                        course.courseKey,
                        course.reactLearnerExperienceEnabled,
                        course.legacyUrl,
                      )
                    }}
                    className="courses-link"
                  >
                    <CourseCard
                      course={course}
                      theme={theme}
                      id={`card-${course.courseKey}`}
                      openCourseInfo={() => handleCourseInfoClick(course)}
                      showInfoButton={
                        isAdmin || getIsInstructorByEmail(userAq.email, course.instructors)
                      }
                    />
                  </a>
                )}
                {course.status !== API_POLLING_RESPONSES.valid && (
                  <CourseCard
                    course={course}
                    theme={theme}
                    id={`card-${course.courseKey}`}
                    showInfoButton={false}
                    isPending={true}
                  />
                )}
              </Col>
            ))}
            {coursePollingQueue.map((course) => (
              <Col sm="12" md="4" key={course.courseKey}>
                <CourseCard
                  course={course}
                  theme={theme}
                  id={`card-${course.courseKey}`}
                  showInfoButton={false}
                  isPending={true}
                />
              </Col>
            ))}
          </Row>
          {/* Course info Drawer */}
          {selectCourse && (
            <Drawer
              title={t('courses.courseInfoDrawer.header')}
              open={isDrawerOpen[COURSE_INFO_DRAWER_ID]}
              drawerId={COURSE_INFO_DRAWER_ID}
              closeCallback={handleCourseInfoDrawerClose}
              width="width70"
            >
              <CourseInfo
                course={selectedCourse}
                theme={theme}
                closeDrawer={handleCourseInfoDrawerClose}
                openCloneDrawer={handleOpenCloneDrawer}
                toggleInstructionsModal={toggleInstructionsModal}
                userEmail={userAq.email}
              />
            </Drawer>
          )}
          {/* Copy course Drawer */}
          {selectCourse && (
            <Drawer
              title={t('courses.courseCloneDrawer.header')}
              open={isDrawerOpen[COURSE_CLONE_DRAWER_ID]}
              drawerId={COURSE_CLONE_DRAWER_ID}
              closeCallback={handleCloneDrawerClose}
              width="width70"
            >
              <CourseCloneForm
                theme={theme}
                course={selectedCourse}
                closeDrawer={handleCloneDrawerClose}
                cloneCourse={handleCloneCourse}
              />
            </Drawer>
          )}
          <CourseCreatedModal
            course={courseCopy}
            toggleCourseCreatedModal={toggleOkModal}
            showCourseCreatedModal={showOkModal}
            handleNewCourseModal={handleNewCourseModal}
          />

          <CourseRegisterInstructions
            course={selectedCourse}
            showInstructionsModal={showInstructionsModal}
            toggleInstructionsModal={toggleInstructionsModal}
            customerSupportUrl={customerSupportUrl}
          />
        </Container>
      </main>
      <Footer />
    </>
  )
}

Courses.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(withCoursesContext(Courses))
