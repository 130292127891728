import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { setConfirmationDialogMode } from 'pages/Settings/store/actions'

const FormDescription = ({ courseKey, isAssignmentSplashPage, unitOrModuleTitle }) => {
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { assignmentSelection, dispatchSettingsAction } = useSettingsContext()

  const settingsPageUrl = bpe.settingsPageUrl({ courseKey })

  const handleSettingsPageLink = (e) => {
    e.preventDefault()
    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: { mode: 'cancel', callback: () => navigate(settingsPageUrl) },
    })
  }

  const attemptsNotSupported = assignmentSelection?.find(
    (assignment) =>
      !assignment.behavior.allowMultipleAttempts || !assignment.behavior.supportsTimeLimit,
  )

  const descriptions = {
    general: 'settingsForm.helpTexts.general',
    generalUnitOrModule: 'settingsForm.helpTexts.generalUnitOrModule',
    notSupported: 'settingsForm.helpTexts.attemptsAndTimeLimitNotAvailable',
    visitSettingsPage: 'settingsForm.helpTexts.visitSettingsPage',
  }

  return (
    <div className="form-description">
      <p>
        {unitOrModuleTitle ? (
          <>
            {t(descriptions.generalUnitOrModule)} <strong>{unitOrModuleTitle}</strong>
          </>
        ) : (
          t(descriptions.general)
        )}
        {isAssignmentSplashPage && (
          <>
            <br />
            <Trans i18nKey={descriptions.visitSettingsPage}>
              To change settings for all assignments, visit the{' '}
              <Link to={settingsPageUrl} onClick={handleSettingsPageLink}>
                settings page
              </Link>
              .
            </Trans>
          </>
        )}
      </p>
      {!!attemptsNotSupported && (
        <p>
          <i>{t(descriptions.notSupported)}</i>
        </p>
      )}
    </div>
  )
}

FormDescription.propTypes = {
  /**
   * Course key
   */
  courseKey: PropTypes.string,
  /**
   * Boolean for whether drawer is on assignment splash page or not
   */
  isAssignmentSplashPage: PropTypes.bool,
  /**
   * Title of the unit or module
   */
  unitOrModuleTitle: PropTypes.string,
}

FormDescription.defaultProps = {
  courseKey: '',
  isAssignmentSplashPage: false,
  unitOrModuleTitle: null,
}

export default FormDescription
