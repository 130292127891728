export const getEnrollmentsByUserInput = (enrollments, userInput) => {
  if (!enrollments || !userInput) return
  const currentInput = userInput.toLowerCase()
  return enrollments.filter(({ user: { email, firstName, lastName } }) => {
    const sanitizedFirstName = firstName?.trim().toLowerCase()
    const sanitizedLastName = lastName?.trim().toLowerCase()
    const fullName = `${sanitizedFirstName ? sanitizedFirstName : ''} ${
      sanitizedLastName ? sanitizedLastName : ''
    }`
    const reverseFullName = `${sanitizedLastName ? sanitizedLastName : ''} ${
      sanitizedFirstName ? sanitizedFirstName : ''
    }`
    return (
      !!email?.includes(currentInput) ||
      !!fullName.includes(currentInput) ||
      !!reverseFullName.includes(currentInput)
    )
  })
}
