import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTheme } from 'styled-components'
import { Chevron } from '../LXIcons/LXIcons'

const BreadcrumbDivider = props => {
  const color = props.dark ? props.theme.vstui.color.primary.getShade(7) : '#fff'
  const dividerClassname = props.level ? `breadcrumb-divider--${props.level}` : ''
  return (
    <li
      className={classnames({
        'breadcrumb-divider': true,
        dark: props.dark,
        [dividerClassname]: true
      })}
      aria-hidden="true"
    >
      <Chevron color={color} />
    </li>
  )
}

BreadcrumbDivider.propTypes = {
  level: PropTypes.oneOf(['unit', 'module']),
  dark: PropTypes.bool,
  theme: PropTypes.object
}

export default withTheme(BreadcrumbDivider)
