import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext, useField } from 'formik'
import Checkbox from '@mui/material/Checkbox'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { ReactComponent as InfoOutline } from 'images/information-outline.svg'
import Tooltip from 'components/Tooltip'
import { STUDENT_AVAILABILITY_CHECK_FORM_FIELDS } from '../formDefinitions'

const Div = styled('div')(({ theme }) => ({
  '& .MuiCheckbox-root': {
    paddingLeft: 0,
    '&.Mui-checked': {
      color: `${theme.palette.primary.main} !important`,
    },
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  '& .label': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
}))

const StudentAvailabilityCheckField = ({ theme, ...props }) => {
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)

  const handleChange = (event) => {
    setFieldValue(STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name, !event.target.checked)
  }

  return (
    <Div className="form-module student-availability-check">
      <Checkbox
        id={STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name}
        checked={!field.value}
        onChange={handleChange}
        inputProps={{ 'aria-label': t(STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.i18n) }}
        disableRipple={true}
      />
      <Tooltip title={t('settingsForm.helpTexts.studentAvailability')}>
        <div className="label">
          <span>{t(STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.i18n)}</span>
          <InfoOutline className="info-icon" />
        </div>
      </Tooltip>
    </Div>
  )
}

export default memo(StudentAvailabilityCheckField)

StudentAvailabilityCheckField.propTypes = {
  /**
   * Theme object
   */
  theme: PropTypes.shape({}),
  name: PropTypes.string,
}

StudentAvailabilityCheckField.defaultProps = {
  theme: {},
  name: STUDENT_AVAILABILITY_CHECK_FORM_FIELDS.available.name,
}
