import React, { useContext, useRef, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import IframeResizer from 'iframe-resizer-react'

import Newrelic from 'utils/newrelic'
import TrialAlert from 'components/TrialAlert'
import Footer from 'components/Footer'
import Spinner from 'components/Spinner'
import Breadcrumbs from 'components/Breadcrumbs'
import MastWaves from 'components/MastWaves'
import { PLATFORM_HOST } from 'utils/constants'
import { StoreContext } from 'store'
import classnames from 'classnames'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { withTheme } from 'styled-components'

const Glossary = ({ theme }) => {
  const navigate = useNavigate()
  const { courseKey } = useParams()
  const { course, currentLocation } = useContext(StoreContext)
  const { i18n, t } = useTranslation()
  const containerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)

  const glossaryUrl = course && course.glossaryUrl ? course.glossaryUrl : ''
  const bpe = buildPagesEndpoints(i18n)
  const courseHomeLink = bpe.coursePageUrl({ courseKey: currentLocation.course })
  const color = theme?.vstui?.color.primary

  const [pageSource, setPageSource] = useState(PLATFORM_HOST + glossaryUrl)

  const ifref = React.createRef()

  const buildGlossaryUrl = ({ letter, page, query }) => {
    let pageSource = `${PLATFORM_HOST}${glossaryUrl}?`
    if (letter && page) {
      pageSource = `${pageSource}l=${letter}&p=${page}`
    } else if (query && page) {
      pageSource = `${pageSource}q=${query}&p=${page}`
    } else if (letter) {
      pageSource = `${pageSource}l=${letter}`
    } else if (page) {
      pageSource = `${pageSource}p=${page}`
    } else {
      pageSource = `${pageSource}p=1`
    }
    setPageSource(pageSource)
  }

  requestAnimationFrame(() => window.scrollTo(0, 0))

  const iframeResizerOptions = {
    checkOrigin: [PLATFORM_HOST],
    onInit: () => {
      setIsLoading(false)
    },
    onMessage: (msg) => {
      if (!msg.message) {
        return false
      }
      switch (msg.message.type) {
        case 'homepageLinkClicked': {
          const homeLink = bpe.coursePageUrl({ courseKey })
          navigate(homeLink)
          break
        }
        case 'glossaryLetterClicked': {
          const letter = msg.message.letter
          buildGlossaryUrl({ letter: letter })
          break
        }
        case 'glossaryPaginationArrowClicked': {
          const { pageNumber, letter, q } = msg.message
          buildGlossaryUrl({ page: pageNumber, letter: letter, query: q })
          break
        }
        default:
          return false
      }
    },
  }

  useEffect(() => {
    Newrelic.setPageViewName('course/glossary')
  }, [])

  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className={classnames({
          'px-0': true,
          'aq-small-caps': course && course.smallCapsEnabled,
          overview: true,
        })}
      >
        <div className="course-mast" style={{ backgroundColor: color.getShade(7) }}>
          <Container>
            <Breadcrumbs
              level={'single_page'}
              pageTitle={t('glossary.pageTitle')}
              courseHomeLink={courseHomeLink}
            />
            <h1 className="course-mast__title" id="course-mast__title">
              {t('glossary.pageTitle')}
            </h1>
          </Container>
          <MastWaves />
        </div>

        <div className="workbook-page-reference-container" ref={containerRef}>
          <Container className="workbook-page-container">
            <div
              className={classnames({
                loading: isLoading,
                'iframe-placeholder': true,
              })}
            >
              <Spinner />

              <TrialAlert courseKey={courseKey} />
              <IframeResizer
                forwardRef={ifref}
                src={pageSource}
                className="workbook-page-iframe"
                allow="microphone *; camera *;"
                allowusermedia="true"
                onMessage={iframeResizerOptions.onMessage}
                onInit={iframeResizerOptions.onInit}
                checkOrigin={iframeResizerOptions.checkOrigin}
              />
            </div>
          </Container>
        </div>
      </main>
      <Footer courseKey={courseKey} withNavigation={false} />
    </>
  )
}

Glossary.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(Glossary)
