import { useReducer, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

const TypeSelectorActions = {
  SET_TAG_VALUE: 'SET_TAG_VALUE',
  CLEAR_TAGS: 'CLEAR_TAGS',
  UPDATE_STATE: 'UPDATE_STATE',
}

const useAssignmentTagsReducer = (assignmentTypes) => {
  const fetchInitialState = useCallback(
    (propagate) => {
      if (!assignmentTypes) return null
      return Object.keys(assignmentTypes).reduce((acc, assignmentKey) => {
        acc[assignmentKey] = { value: false, propagate: !!propagate }
        return acc
      }, {})
    },
    [assignmentTypes]
  )

  const selectorReducer = (state, action) => {
    switch (action.type) {
      case TypeSelectorActions.CLEAR_TAGS:
        return {
          ...fetchInitialState(action?.payload?.propagate),
        }
      case TypeSelectorActions.SET_TAG_VALUE:
        return {
          ...state,
          [action.payload.key]: {
            value: action.payload.value,
            propagate: action.payload.propagate,
          },
        }
      case TypeSelectorActions.UPDATE_STATE:
        return action.payload
      default:
        return fetchInitialState()
    }
  }

  const [assignmentTagsState, assignmentTagsDispatch] = useReducer(
    selectorReducer,
    fetchInitialState()
  )
  const assignmentTagsActions = useMemo(
    () => ({
      setTagValue: (payload) => {
        assignmentTagsDispatch({ type: TypeSelectorActions.SET_TAG_VALUE, payload })
      },
      clearTags: (payload) => {
        assignmentTagsDispatch({ type: TypeSelectorActions.CLEAR_TAGS, payload })
      },
    }),
    [assignmentTagsDispatch]
  )

  useEffect(() => {
    assignmentTagsDispatch({
      type: TypeSelectorActions.UPDATE_STATE,
      payload: fetchInitialState(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentTypes])

  return [assignmentTagsState, assignmentTagsActions]
}

export default useAssignmentTagsReducer

useAssignmentTagsReducer.propTypes = {
  assignmentTypes: PropTypes.object,
}
