import React from 'react'
import { ASSIGNMENT_LIST_ITEM_TYPES } from 'utils/constants'

const showLabelCellRenderer = (props) => {
  if (props.data.type === ASSIGNMENT_LIST_ITEM_TYPES.assignment) {
    return <span>{props.value?.label}</span>
  }
  return null
}

export default showLabelCellRenderer
