import React from 'react'
import { CoursesContextProvider } from 'pages/Courses/store/coursesContext'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const withCoursesContext = (WrappedComponent) => {
  const Component = (props) => {
    return (
      <CoursesContextProvider>
        <WrappedComponent {...props} />
      </CoursesContextProvider>
    )
  }
  Component.displayName = `withCoursesContext(${getDisplayName(WrappedComponent)})`
  return Component
}

export default withCoursesContext
