import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import AlertMessage from 'components/AlertMessage'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { Link } from 'react-router-dom'

const TrialAlert = (props) => {
  const { courseKey } = props
  const { t, i18n } = useTranslation()
  const { payCoursePageUrl } = buildPagesEndpoints(i18n)
  const { userAqHasAccess, userAq } = useContext(StoreContext)

  // Note: This component is not themed, so, it uses the colors of the designs

  if (userAqHasAccess && !userAq.lti && userAqHasAccess.isTrial) {
    return (
      <AlertMessage className="trial-alert">
        {userAqHasAccess.message}{' '}
        <Link to={payCoursePageUrl({ courseKey })}>{t('course.purchaseFullAccess')}</Link>
      </AlertMessage>
    )
  } else {
    return null
  }
}

TrialAlert.propTypes = {
  courseKey: PropTypes.string,
}

export default TrialAlert
