const COMMENT_MODES = {
  off: 0,
  on: 1,
  allText: 2,
  allBlock: 3
}

const useIframeResizerMessages = iframeRef => {
  let commentMode = COMMENT_MODES.off

  const sendEditRequestToChild = () => {
    iframeRef.current && iframeRef.current.sendMessage({ type: 'LEARN_PAGE_EDIT_REQUEST' })
  }

  const sendToggleAnswerKey = () => {
    iframeRef.current && iframeRef.current.sendMessage({ type: 'LEARN_PAGE_ANSWER_KEY_REQUEST' })
  }

  const sendToggleComments = () => {
    commentMode = commentMode < COMMENT_MODES.allBlock ? commentMode + 1 : COMMENT_MODES.off
    iframeRef.current &&
      iframeRef.current.sendMessage({
        type: 'LEARN_PAGE_TOGGLE_COMMENTS_REQUEST',
        mode: commentMode
      })
  }

  const sendFocusBack = () => {
    iframeRef.current &&
      iframeRef.current.sendMessage({
        type: 'LEARN_PAGE_FOCUS_BACK'
      })
  }

  return {
    sendEditRequestToChild,
    sendToggleAnswerKey,
    sendToggleComments,
    sendFocusBack
  }
}

export default useIframeResizerMessages
