import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const { CONFIGURATION_GET_REQUEST, CONFIGURATION_GET_SUCCESS, CONFIGURATION_GET_FAILURE } = actions

export const getConfig = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [CONFIGURATION_GET_REQUEST, CONFIGURATION_GET_SUCCESS, CONFIGURATION_GET_FAILURE],
    fetchParams: {
      url: apiEndpoints().configuration(),
      params: withCredentials({ method: 'get', credentials: 'include' })
    }
  })
