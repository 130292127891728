import moment from 'moment-timezone'
import { actions } from 'store/actions'
import { initialState } from 'store'
import { ATTEMPTS_OPTIONS, TIME_LIMIT_OPTIONS } from 'components/Forms/formDefinitions'

const assignmentsExceptionsReducer = (
  state = initialState.assignmentsExceptions,
  action,
  dependencies
) => {
  switch (action.type) {
    case actions.ASSIGNMENTS_EXCEPTIONS_GET_SUCCESS: {
      const payloadAsArray = Array.isArray(action.payload) ? action.payload : [action.payload]
      const parsedPayload = payloadAsArray.reduce((acc, assignmentException) => {
        // Set label/vaue pairs for maxAttempts and timeLimit
        const { studentExceptions } = assignmentException
        const parsedExceptions = studentExceptions.map((exception) => {
          const { availableDate, dueDate, maxAttempts, timeLimit, uuid, user } = exception
          const tempException = { uuid, user }
          let attemptLabelValuePair = null
          if (maxAttempts !== null) {
            attemptLabelValuePair = ATTEMPTS_OPTIONS.find(
              (option) => option.value === (maxAttempts ? maxAttempts.toString() : '-1')
            )
          }
          let timeLimitLabelValuePair = null
          if (timeLimit !== null) {
            timeLimitLabelValuePair = TIME_LIMIT_OPTIONS.find(
              (option) => option.value === (timeLimit ? timeLimit.toString() : '0')
            )
          }
          tempException.maxAttempts = attemptLabelValuePair
          tempException.timeLimit = timeLimitLabelValuePair

          // Store moment object for dates
          const userTimezone = dependencies?.userAq?.time_zone

          if (availableDate && userTimezone) {
            tempException.availableDate = moment.tz(availableDate, userTimezone)
          } else if (availableDate) {
            tempException.availableDate = moment(availableDate)
          } else {
            tempException.availableDate = null
          }

          if (dueDate && userTimezone) {
            tempException.dueDate = moment.tz(dueDate, userTimezone)
          } else if (dueDate) {
            tempException.dueDate = moment(dueDate)
          } else {
            tempException.dueDate = null
          }
          return tempException
        })

        const tempException = { ...assignmentException, studentExceptions: parsedExceptions }

        acc.push(tempException)

        return acc
      }, [])
      return parsedPayload
    }
    case actions.ASSIGNMENTS_EXCEPTIONS_UNSET:
      return initialState.assignmentsExceptions
    default:
      return state
  }
}

export default assignmentsExceptionsReducer
