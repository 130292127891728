import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import BreadcrumbDivider from './BreadcrumbDivider'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const Breadcrumbs = ({
  level,
  unit,
  mod,
  unitLink,
  courseHomeLink,
  moduleLink,
  pageTitle,
  light,
}) => {
  const { t } = useTranslation()

  if (level === 'course') return null

  return (
    <nav
      className={classnames({
        light: light,
        breadcrumbs: true,
      })}
      aria-label={t('mastHead.breadcrumbs')}
    >
      <ol className="breadcrumbs-list">
        <li className="breadcrumb-item">
          {level === 'page' && (
            <Link to={moduleLink} className="breadcrumb-item__link">
              {mod.ordinal === null ? (
                <>{mod.title}</>
              ) : (
                <>
                  {mod.label || mod.courseLabel} {mod.ordinal}
                </>
              )}
            </Link>
          )}
          {level !== 'page' && (
            <Link to={courseHomeLink} className="breadcrumb-item__link">
              {t('nav.home')}
            </Link>
          )}
        </li>
        {level === 'page' && (
          <>
            <BreadcrumbDivider dark />
            <li className="breadcrumb-item" aria-current="page">
              {pageTitle}
            </li>
          </>
        )}
        {unit && (level === 'unit' || level === 'module') && (
          <>
            <BreadcrumbDivider level="unit" />
            <li className="breadcrumb-item" aria-current="page">
              {level === 'unit' &&
                (unit.ordinal ? `${unit.courseLabel} ${unit.ordinal}` : `${unit.title}`)}
              {level === 'module' && (
                <Link to={unitLink} title={unit.title} className="breadcrumb-item__link">
                  {unit.ordinal ? `${unit.courseLabel} ${unit.ordinal}` : `${unit.title}`}
                </Link>
              )}
            </li>
            {mod && mod.title && level === 'module' && (
              <>
                <BreadcrumbDivider level="module" />
                <li className="breadcrumb-item breadcrumb-item--module" aria-current="page">
                  {mod.isContent && (
                    <>
                      {mod.courseLabel} {mod.ordinal}:{' '}
                    </>
                  )}
                  {mod.title}
                </li>
              </>
            )}
          </>
        )}
        {level === 'single_page' && (
          <>
            <BreadcrumbDivider />
            <li className="breadcrumb-item" aria-current="page">
              {pageTitle}
            </li>
          </>
        )}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  courseHomeLink: PropTypes.string,
  level: PropTypes.string,
  light: PropTypes.bool,
  mod: PropTypes.object,
  moduleLink: PropTypes.string,
  pageTitle: PropTypes.string,
  unit: PropTypes.object,
  unitLink: PropTypes.string,
}

export default React.memo(Breadcrumbs)
