import i18n from 'i18next'
import HttpApi from 'i18next-http-backend'
import LngDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

// When downloading translation files from Smartling, the tags that enclose HTML
// elements get replaced by the corresponding HTML entities. In order to
// workaround that issue, added this preprocessor that restores back the
// enclosing tags.
const removeHtmlEntities = (str) => {
  const entities = {
    lt: '<',
    gt: '>',
  }

  return str.replace(/&([^;]+);/gm, function (match, entity) {
    return entities[entity] || match
  })
}

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(HttpApi)
  .use(LngDetector)
  .use(initReactI18next)
  .use({
    type: 'postProcessor',
    name: 'removeHtmlEntitiesProcessor',
    process: (value) => removeHtmlEntities(value),
  })
  .init({
    fallbackLng: 'en-us',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,

    debug: process.env.NODE_ENV !== 'production' ? true : false,

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
    },

    // detect language from path
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupLocalStorage: 'lang',
    },

    backend: {
      // load translations from Django's static route
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      // Adds parameters to resource URL.
      // Force new version on every request to break cache.
      queryStringParams: { v: Date.now() },
    },

    postProcess: ['removeHtmlEntitiesProcessor'],
  })

export default i18n
