import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const {
  PROFILE_GET_REQUEST,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_CHANGE_PASSWORD_REQUEST,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAILURE
} = actions

export const getProfile = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [PROFILE_GET_REQUEST, PROFILE_GET_SUCCESS, PROFILE_GET_FAILURE],
    fetchParams: {
      url: apiEndpoints().profile(),
      params: { method: 'get', credentials: 'include' }
    }
  })

export const updateProfile = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [PROFILE_UPDATE_REQUEST, PROFILE_UPDATE_SUCCESS, PROFILE_UPDATE_FAILURE],
    fetchParams: {
      url: apiEndpoints().profile(),
      params: withCredentials({
        method: 'put',
        credentials: 'include',
        body: data,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  })

export const unsetProfile = ({ dispatch }) => dispatch({ type: actions.PROFILE_UNSET })

export const changePassword = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [
      PROFILE_CHANGE_PASSWORD_REQUEST,
      PROFILE_CHANGE_PASSWORD_SUCCESS,
      PROFILE_CHANGE_PASSWORD_FAILURE
    ],
    fetchParams: {
      url: apiEndpoints().changePassword(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: data,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  })

export const getAccountCenterUrl = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [
      actions.ACCOUNT_CENTER_URL_GET_REQUEST,
      actions.ACCOUNT_CENTER_URL_GET_SUCCESS,
      actions.ACCOUNT_CENTER_URL_GET_FAILURE
    ],
    fetchParams: {
      url: apiEndpoints().getAccountCenterUrl(),
      params: { method: 'get', credentials: 'include' }
    }
  })
