import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

const LearningObjectives = ({ los }) => (
  <ul className="lo-list">
    {Object.keys(los).map((key) => (
      <li key={`lo-${key}`} className="lo-item">
        {los[key]}
      </li>
    ))}
  </ul>
)

LearningObjectives.propTypes = {
  los: PropTypes.arrayOf(PropTypes.string),
  theme: PropTypes.object,
}

export default withTheme(LearningObjectives)
