import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { ProfileNav } from 'components/Profile'
import SidebarLayout from 'components/SidebarLayout'

const Profile = ({ children, cardTitle }) => {
  const { t } = useTranslation()

  return (
    <SidebarLayout
      sidebarNavComponent={<ProfileNav />}
      cardTitle={cardTitle}
      pageTitle={t('profile.title')}
    >
      {children}
    </SidebarLayout>
  )
}

Profile.propTypes = {
  children: PropTypes.node,
  cardTitle: PropTypes.string,
}

export default React.memo(Profile)
