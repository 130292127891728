import { actions } from 'store/actions'
import { initialState } from 'store'

const assignmentTypesReducer = (state = initialState.assignmentTypes, action) => {
  switch (action.type) {
    case actions.ASSIGNMENT_TYPES_GET_SUCCESS:
      return action.payload.assignmentTypes
    case actions.ASSIGNMENT_TYPES_UNSET:
      return initialState.assignmentTypes
    default:
      return state
  }
}

export default assignmentTypesReducer
