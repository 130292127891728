export const FIELDS = {
  institution: {
    name: 'institution',
    i18nLabel: 'adoptions.institution',
    i18nHelperText: 'adoptions.institutionHelper',
  },
  courseware: {
    name: 'courseware',
    i18nLabel: 'adoptions.selectCourseware',
    i18nHelperText: 'adoptions.selectCoursewareHelper',
  },
  titleOfCourseware: {
    name: 'titleOfCourseware',
    i18nLabel: 'adoptions.titleOfCourseware',
    i18nHelperText: 'adoptions.titleOfCoursewareHelper',
  },
  courseKey: {
    name: 'courseKey',
    i18nLabel: 'adoptions.courseKey',
    i18nHelperText: 'adoptions.courseKeyHelper',
  },
  startDate: {
    name: 'startDate',
    i18nLabel: 'adoptions.startDate',
    i18nHelperText: 'adoptions.startDateHelper',
  },
  endDate: {
    name: 'endDate',
    i18nLabel: 'adoptions.endDate',
    i18nHelperText: 'adoptions.endDateHelper',
  },
  remindMeNextTerm: {
    name: 'remindMeNextTerm',
    i18nLabel: 'adoptions.remindMeNextTerm',
    i18nHelperText: 'adoptions.remindMeNextTermHelper',
  },
  instructorEmail: {
    name: 'instructorEmail',
    i18nLabel: 'adoptions.instructorEmail',
    i18nHelperText: 'adoptions.instructorEmailHelper',
  },
}
