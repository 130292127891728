import {
  DATE_AND_TIME_FIELD_MODES,
  DATE_AND_TIME_SELECT_VALUES,
  ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES,
} from './formDefinitions'
import {
  consolidateDateTime,
  fetchDateAndTimeFieldSetup,
  closeDrawer,
  isExceptionEmpty,
  extractDefaultValuesFromAssignment,
} from './smartFormHelpers'
import {
  updateAssignmentsSchedules,
  updateSegmentSchedules,
  createOrUpdateAssignmentException,
  getAssignmentsUnitsAndModules,
  getAssignmentsExceptions,
  deleteAssignmentException,
} from 'store/actions'
import { isEmptyObject } from 'utils/functional'
import {
  DEFAULT_DRAWER_ID,
  EDIT_EXCEPTIONS_DRAWER_ID,
  UNITS_AND_MODULES_DRAWER_ID,
} from 'utils/constants'
import {
  closeConfirmationDialog,
  closeDefaultSettingsDrawer,
  closeEditExceptionsSettingsDrawer,
  closeUnitsAndModulesSettingsDrawer,
  setConfirmationDialogMode,
} from 'pages/Settings/store/actions'
import { log } from 'utils/log'

const testOnSubmit = (values) => {
  log('SUBMITTED VALUES: ', JSON.stringify(values))
}

const handleErrorsUpdatingSegments = (dispatchSettingsAction, e, drawerId = DEFAULT_DRAWER_ID) => {
  closeConfirmationDialog({ dispatchSettingsAction })
  delete e.status
  const errorCodesArray = Object.values(e)
  // Define generic error code and message
  let errorType = 'error'
  let bodyI18n = 'assignmentsSettings.errors.modifyingAssignmentSettings'
  if (errorCodesArray.length > 0 && errorCodesArray[0].length > 0) {
    // Check for specific error cases.
    const errorCode = errorCodesArray[0][0]
    // Units and modules hierarchy specific errors
    errorType =
      errorCode === 'lower_hierarchy_schedule_conflict' ? 'lowerSchedulingConflict' : errorType
    errorType =
      errorCode === 'higher_hierarchy_schedule_conflict' ? 'higherSchedulingConflict' : errorType

    // There's an error code but not from a scheduling conflict
    bodyI18n = errorType === 'error' ? `assignmentsSettings.errors.${errorCode}` : bodyI18n
  }
  setConfirmationDialogMode({
    dispatchSettingsAction,
    mode: {
      mode: errorType,
      bodyI18n,
      callback: () => {
        closeDrawer({
          dispatchSettingsAction,
          drawerId: drawerId,
          isFormDirty: false,
        })
      },
    },
  })
}

const handleErrorsDeletingExceptions = (dispatchSettingsAction) => {
  closeConfirmationDialog({ dispatchSettingsAction })
  setConfirmationDialogMode({
    dispatchSettingsAction,
    mode: {
      mode: 'error',
      bodyI18n: 'assignmentsSettings.errors.deletingExceptions',
    },
  })
}

const handleErrorsGettingExceptions = (dispatchSettingsAction) => {
  closeConfirmationDialog({ dispatchSettingsAction })
  setConfirmationDialogMode({
    dispatchSettingsAction,
    mode: {
      mode: 'error',
      bodyI18n: 'assignmentsSettings.errors.gettingExceptions',
    },
  })
}

const handleErrorsCreatingOrUpdatingExceptions = (dispatchSettingsAction) => {
  closeConfirmationDialog({ dispatchSettingsAction })
  setConfirmationDialogMode({
    dispatchSettingsAction,
    mode: {
      mode: 'error',
      bodyI18n: 'assignmentsSettings.errors.creatingOrUpdatingExceptions',
    },
  })
}

export const formSubmitFunction = (
  values,
  formInitialValues,
  assignmentSelection,
  userTimezone,
  courseKey,
  dispatch,
  dispatchSettingsAction,
  assignmentTypes,
  isAssignmentSplashPage,
) => {
  testOnSubmit(values)
  const availableDateFields = fetchDateAndTimeFieldSetup(
    DATE_AND_TIME_FIELD_MODES.availableDate,
  ).formFields
  const dueDateFields = fetchDateAndTimeFieldSetup(DATE_AND_TIME_FIELD_MODES.dueDate).formFields

  const initialValues = Object.assign({}, formInitialValues)
  const formValues = Object.assign({}, values)

  formValues.availableDate = consolidateDateTime({
    date: formValues[availableDateFields.date.name],
    time: formValues[availableDateFields.time.name],
    timezone: userTimezone,
  })

  formValues.availableDate = formValues.availableDate
    ? formValues.availableDate.toISOString()
    : null

  formValues.dueDate = consolidateDateTime({
    date: formValues[dueDateFields.date.name],
    time: formValues[dueDateFields.time.name],
    timezone: userTimezone,
  })
  formValues.dueDate = formValues.dueDate ? formValues.dueDate.toISOString() : null

  initialValues.availableDate = initialValues[availableDateFields.date.name]
    ? initialValues[availableDateFields.date.name].toISOString()
    : null

  initialValues.dueDate = initialValues[dueDateFields.date.name]
    ? initialValues[dueDateFields.date.name].toISOString()
    : null

  const assignmentIds = assignmentSelection.reduce((acc, assignment) => {
    acc.push(assignment.identifier)
    return acc
  }, [])

  // Delete moment objects
  delete initialValues[availableDateFields.date.name]
  delete initialValues[availableDateFields.time.name]
  delete initialValues[dueDateFields.date.name]
  delete initialValues[dueDateFields.time.name]
  delete formValues[availableDateFields.date.name]
  delete formValues[availableDateFields.time.name]
  delete formValues[dueDateFields.date.name]
  delete formValues[dueDateFields.time.name]

  const updatedValues = Object.keys(initialValues).reduce((acc, field) => {
    const initialValue = initialValues[field]
    const newValue = formValues[field]
    // log('newValue: ', newValue, 'initialValue', initialValue, 'field', field)
    if (newValue !== initialValue) {
      acc[field] = newValue
    }
    return acc
  }, {})

  if (Object.keys(updatedValues).includes(availableDateFields.select.name)) {
    if (formValues.availableDateSelect === DATE_AND_TIME_SELECT_VALUES.anyTime) {
      updatedValues.availableDate = null
    }
  }

  if (Object.keys(updatedValues).includes(dueDateFields.select.name)) {
    if (formValues.dueDateSelect === DATE_AND_TIME_SELECT_VALUES.anyTime) {
      updatedValues.dueDate = null
    }
  }

  if (!isEmptyObject(updatedValues)) {
    /**
     * applyChangesFn
     * -> Update Assignment
     *    |-> Refresh assignments table
     *        |-> Close dialog and drawer
     */
    const applyChangesFn = async () => {
      await updateAssignmentsSchedules({
        dispatch,
        courseKey,
        data: {
          assignmentIds,
          ...updatedValues,
        },
        returnRejectedPromise: true,
      })
      if (isAssignmentSplashPage) {
        closeConfirmationDialog({ dispatchSettingsAction })
        closeDefaultSettingsDrawer({ dispatchSettingsAction })
      } else {
        await getAssignmentsUnitsAndModules({
          dispatch,
          courseKey,
          purposeTypes: assignmentTypes ? Object.keys(assignmentTypes).join() : null,
          returnRejectedPromise: true,
        })
        closeConfirmationDialog({ dispatchSettingsAction })
        closeDefaultSettingsDrawer({ dispatchSettingsAction })
      }
    }

    const applyFnWrapper = () =>
      applyChangesFn().catch((e) => handleErrorsUpdatingSegments(dispatchSettingsAction, e))

    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'apply',
        callback: applyFnWrapper,
      },
    })
  } else {
    closeDrawer({ dispatchSettingsAction, drawerId: DEFAULT_DRAWER_ID, isFormDirty: false })
  }
}

export const formExceptionsSubmitFunction = (
  values,
  formInitialValues,
  assignment,
  userTimezone,
  courseKey,
  dispatch,
  dispatchSettingsAction,
  assignmentTypes,
) => {
  testOnSubmit(values)
  const availableDateFields = fetchDateAndTimeFieldSetup(
    DATE_AND_TIME_FIELD_MODES.availableDate,
  ).formFields
  const dueDateFields = fetchDateAndTimeFieldSetup(DATE_AND_TIME_FIELD_MODES.dueDate).formFields

  const initialValues = Object.assign({}, formInitialValues)
  const formValues = Object.assign({}, values)

  formValues.availableDate = consolidateDateTime({
    date: formValues[availableDateFields.date.name],
    time: formValues[availableDateFields.time.name],
    timezone: userTimezone,
  })

  formValues.availableDate = formValues.availableDate
    ? formValues.availableDate.toISOString()
    : null

  formValues.dueDate = consolidateDateTime({
    date: formValues[dueDateFields.date.name],
    time: formValues[dueDateFields.time.name],
    timezone: userTimezone,
  })
  formValues.dueDate = formValues.dueDate ? formValues.dueDate.toISOString() : null

  if (!(ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.timeLimit in formValues)) {
    // Add missing timeLimit field to formValues as all fields must be submitted
    formValues[ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.timeLimit] = null
  }

  if (!(ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.maxAttempts in formValues)) {
    // Add missing maxAttempts field to formValues as all fields must be submitted
    formValues[ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES.maxAttempts] = null
  }

  initialValues.availableDate = initialValues[availableDateFields.date.name]
    ? initialValues[availableDateFields.date.name].toISOString()
    : null

  initialValues.dueDate = initialValues[dueDateFields.date.name]
    ? initialValues[dueDateFields.date.name].toISOString()
    : null

  const assignmentDefaultValues = extractDefaultValuesFromAssignment(assignment)

  const exceptionIsEmpty = isExceptionEmpty(formValues, assignmentDefaultValues)

  if (exceptionIsEmpty && formValues.uuid) {
    // If exception is empty after edition, open delete on Empty dialog.
    // If there's no uuid exception has not been saved yet.
    const applyChangesFn = async () => {
      await deleteAssignmentException({
        dispatch,
        courseKey,
        data: {
          uuid: formValues.uuid,
        },
        returnRejectedPromise: true,
      })
      await getAssignmentsExceptions({
        dispatch,
        courseKey,
        returnRejectedPromise: true,
      }).catch(() => handleErrorsGettingExceptions(dispatchSettingsAction))
      closeConfirmationDialog({ dispatchSettingsAction })
      closeEditExceptionsSettingsDrawer({ dispatchSettingsAction })
      await getAssignmentsUnitsAndModules({
        dispatch,
        courseKey,
        purposeTypes: assignmentTypes ? Object.keys(assignmentTypes).join() : null,
        returnRejectedPromise: true,
      })
    }

    const applyFnWrapper = () =>
      applyChangesFn().catch(() => handleErrorsDeletingExceptions(dispatchSettingsAction))

    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'deleteOnEmpty',
        callback: applyFnWrapper,
      },
    })
    return
  }

  const assessmentIdentifier = assignment.identifier

  // Delete moment objects
  delete initialValues[availableDateFields.date.name]
  delete initialValues[availableDateFields.time.name]
  delete initialValues[dueDateFields.date.name]
  delete initialValues[dueDateFields.time.name]
  delete formValues[availableDateFields.date.name]
  delete formValues[availableDateFields.time.name]
  delete formValues[dueDateFields.date.name]
  delete formValues[dueDateFields.time.name]

  let updatedValues = Object.keys(assignmentDefaultValues).reduce((acc, field) => {
    const initialValue = initialValues[field]
    const newValue = formValues[field]

    acc[field] = newValue !== initialValue ? newValue : initialValue
    return acc
  }, {})

  const exceptionIsUnmodified = Object.keys(assignmentDefaultValues).reduce(
    (acc, fieldName) => acc && updatedValues[fieldName] === initialValues[fieldName],
    true,
  )

  if (!exceptionIsEmpty && !exceptionIsUnmodified) {
    if (formValues.uuid) {
      updatedValues.uuid = formValues.uuid
    }
    updatedValues.userId = formValues.userId

    /**
     * applyChangesFn
     * -> Create/Update Exception
     *    |-> Refresh/get  exceptions
     *        |-> Close dialog and drawer
     */
    const applyChangesFn = async () => {
      await createOrUpdateAssignmentException({
        dispatch,
        courseKey,
        data: {
          assessmentIdentifier,
          ...updatedValues,
        },
        returnRejectedPromise: true,
      })
      await getAssignmentsExceptions({
        dispatch,
        courseKey,
        returnRejectedPromise: true,
      })
      closeConfirmationDialog({ dispatchSettingsAction })
      closeEditExceptionsSettingsDrawer({ dispatchSettingsAction })
      await getAssignmentsUnitsAndModules({
        dispatch,
        courseKey,
        purposeTypes: assignmentTypes ? Object.keys(assignmentTypes).join() : null,
        returnRejectedPromise: true,
      })
    }

    const applyFnWrapper = () =>
      applyChangesFn().catch(() => handleErrorsCreatingOrUpdatingExceptions(dispatchSettingsAction))

    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'apply',
        callback: applyFnWrapper,
      },
    })
  } else {
    closeDrawer({ dispatchSettingsAction, drawerId: EDIT_EXCEPTIONS_DRAWER_ID, isFormDirty: false })
  }
}

export const formSubmitUnitOrModuleFunction = (
  values,
  formInitialValues,
  selection,
  userTimezone,
  courseKey,
  dispatch,
  dispatchSettingsAction,
  assignmentTypes,
  isAssignmentSplashPage,
) => {
  testOnSubmit(values)
  const availableDateFields = fetchDateAndTimeFieldSetup(
    DATE_AND_TIME_FIELD_MODES.availableDate,
  ).formFields

  const initialValues = Object.assign({}, formInitialValues)
  const formValues = Object.assign({}, values)

  formValues.availableDate = consolidateDateTime({
    date: formValues[availableDateFields.date.name],
    time: formValues[availableDateFields.time.name],
    timezone: userTimezone,
  })

  formValues.availableDate = formValues.availableDate
    ? formValues.availableDate.toISOString()
    : null

  initialValues.availableDate = initialValues[availableDateFields.date.name]
    ? initialValues[availableDateFields.date.name].toISOString()
    : null

  // Delete moment objects
  delete initialValues[availableDateFields.date.name]
  delete initialValues[availableDateFields.time.name]
  delete formValues[availableDateFields.date.name]
  delete formValues[availableDateFields.time.name]

  const updatedValues = Object.keys(initialValues).reduce((acc, field) => {
    const initialValue = initialValues[field]
    const newValue = formValues[field]
    // log('newValue: ', newValue, 'initialValue', initialValue, 'field', field)
    if (newValue !== initialValue) {
      acc[field] = newValue
    }
    return acc
  }, {})

  if (Object.keys(updatedValues).includes(availableDateFields.select.name)) {
    if (formValues.availableDateSelect === DATE_AND_TIME_SELECT_VALUES.anyTime) {
      updatedValues.availableDate = null
    }
  }

  if (!isEmptyObject(updatedValues)) {
    /**
     * applyChangesFn
     * -> Update Assignment
     *    |-> Refresh assignments table
     *        |-> Close dialog and drawer
     */
    const applyChangesFn = async () => {
      await updateSegmentSchedules({
        dispatch,
        courseKey,
        data: {
          segmentIds: [selection.identifier],
          ...updatedValues,
        },
        returnRejectedPromise: true,
      })
      if (isAssignmentSplashPage) {
        closeConfirmationDialog({ dispatchSettingsAction })
        closeUnitsAndModulesSettingsDrawer({ dispatchSettingsAction })
      } else {
        await getAssignmentsUnitsAndModules({
          dispatch,
          courseKey,
          purposeTypes: assignmentTypes ? Object.keys(assignmentTypes).join() : null,
          returnRejectedPromise: true,
        })
        closeConfirmationDialog({ dispatchSettingsAction })
        closeUnitsAndModulesSettingsDrawer({ dispatchSettingsAction })
      }
    }

    const applyFnWrapper = () =>
      applyChangesFn().catch((e) =>
        handleErrorsUpdatingSegments(dispatchSettingsAction, e, UNITS_AND_MODULES_DRAWER_ID),
      )

    setConfirmationDialogMode({
      dispatchSettingsAction,
      mode: {
        mode: 'applyUnitOrModule',
        callback: applyFnWrapper,
      },
    })
  } else {
    closeDrawer({
      dispatchSettingsAction,
      drawerId: UNITS_AND_MODULES_DRAWER_ID,
      isFormDirty: false,
    })
  }
}
