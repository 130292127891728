import { actions } from 'store/actions'
import { initialState } from 'store'

const {
  PROFILE_GET_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
  PROFILE_CHANGE_PASSWORD_REQUEST,
  PROFILE_CHANGE_PASSWORD_SUCCESS,
  PROFILE_CHANGE_PASSWORD_FAILURE
} = actions

const profileReducer = (state = initialState.profile, action) => {
  switch (action.type) {
    case PROFILE_GET_SUCCESS:
      return action.payload
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isUpdated: false
      }
    case PROFILE_UPDATE_SUCCESS:
    case PROFILE_UPDATE_FAILURE:
      return {
        ...state,
        isUpdated: true
      }
    case actions.PROFILE_UNSET:
      return initialState.profile
    case PROFILE_CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isPasswordChangedReady: false,
        success: false,
        hasErrors: false
      }
    case PROFILE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isPasswordChangedReady: true,
        success: true,
        hasErrors: false
      }
    case PROFILE_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        data: action.payload,
        isPasswordChangedReady: true,
        success: false,
        hasErrors: true
      }
    default:
      return state
  }
}

export default profileReducer
