/* eslint-disable no-unused-vars */
let id = 0

const devTools = ({ initialState }, self) => {
  // eslint-disable-next-line no-underscore-dangle
  const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__

  const instanceID = id
  id += 1

  const name = `acro-learn__${instanceID}`
  const features = {
    jump: false,
  }

  const devTools = reduxDevTools.connect({ name, features })

  return (action, state) => {
    devTools.send({ type: action.type, ...action }, state, {}, instanceID)
  }
}

export default devTools
