import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Trans, useTranslation } from 'react-i18next'
import { Button } from '@vitalsource/vstui'

const ContentNotAvailable = ({
  buttonLabel,
  contentIsPage = false,
  contentLabel,
  formattedDate,
  handleButtonOnClick,
  hasNextPage = false,
  willBeAvailable = false,
}) => {
  const { t } = useTranslation()

  return (
    <section
      className={classNames('content-not-available', {
        'content-not-available--page': contentIsPage,
      })}
    >
      <div className="content-not-available__message">
        {willBeAvailable ? (
          <h1 className="content-not-available__title">
            <Trans i18nKey="page.contentWillBeAvailable" formattedDate={formattedDate}>
              This content will be available <br />
              {{ formattedDate }}.
            </Trans>
          </h1>
        ) : (
          <>
            <h1 className="content-not-available__title">{t('page.contentNotAvailableTitle')}</h1>
            <p className="content-not-available__details">
              <Trans i18nKey="page.contentNotAvailableDetails" contentLabel={contentLabel}>
                <strong>{{ contentLabel }}</strong> has been made unavailable by your instructor.
              </Trans>
            </p>
          </>
        )}
        {hasNextPage && (
          <Button
            className="content-not-available__button"
            size="large"
            type="button"
            onClick={handleButtonOnClick}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </section>
  )
}

ContentNotAvailable.propTypes = {
  buttonLabel: PropTypes.string,
  contentIsPage: PropTypes.bool,
  contentLabel: PropTypes.string,
  formattedDate: PropTypes.string,
  handleButtonOnClick: PropTypes.func,
  hasNextPage: PropTypes.bool,
  willBeAvailable: PropTypes.bool,
}

export default ContentNotAvailable
