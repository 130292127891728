import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { useTranslation } from 'react-i18next'

import Oops from 'images/oops.svg'
import { StoreContext } from 'store'

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`

const ConfigurationError = () => {
  const navigate = useNavigate()
  const { customer } = useContext(StoreContext)
  const { t } = useTranslation()

  return (
    <Center>
      <img alt="Oops!" src={Oops} />
      <p style={{ margin: '8px 0 0' }}>
        {t('courses.errors.configurationError', { customer: customer?.name })}
      </p>
      <p style={{ margin: '0 0 18px' }}>{`${t(
        'courses.errors.configurationErrorApiKeyRequired',
      )} ${t('courses.errors.configurationErrorContact')}`}</p>
      <Button onClick={() => navigate('/')}>{t('nav.back')}</Button>
    </Center>
  )
}

export default ConfigurationError
