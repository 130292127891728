import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const getAqUser = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [
      actions.USER_AQ_POST_REQUEST,
      actions.USER_AQ_POST_SUCCESS,
      actions.USER_AQ_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().user(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })

const unsetUserAq = ({ dispatch }) => dispatch({ type: actions.USER_AQ_UNSET })

const userHasAccessToContent = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.USER_AQ_HAS_ACCESS_TO_CONTENT_REQUEST,
      actions.USER_AQ_HAS_ACCESS_TO_CONTENT_SUCCESS,
      actions.USER_AQ_HAS_ACCESS_TO_CONTENT_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().userHasAccess(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
  })

const unsetUserHasAccessToContent = ({ dispatch }) =>
  dispatch({ type: actions.USER_AQ_HAS_ACCESS_TO_CONTENT_UNSET })

export { getAqUser, userHasAccessToContent, unsetUserAq, unsetUserHasAccessToContent }
