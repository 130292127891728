import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Newrelic from 'utils/newrelic'
import ErrorPage from 'components/ErrorPage'

/**
 * @class this component is written as class because it makes use of the lifecycle
 *  `componentDidCatch`, which is not replaceable with useEffect.
 *  If someone wishes to change it to functional component, a new library called
 *  `react-pure-lifecycle` shall be added. {@link https://www.npmjs.com/package/react-pure-lifecycle}
 */
class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error) {
    Newrelic.noticeError(error)
  }

  render() {
    const { hasError } = this.state

    if (hasError) {
      return <ErrorPage />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
}

export default ErrorBoundary
