import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { coursesReducer } from './coursesReducer'

const CoursesContext = React.createContext()

export const coursesInitialState = {
  closeDrawer: null,
  isDrawerOpen: {},
  confirmationDialogMode: {},
  isFormDirty: false,
  selectedCourse: {},
}

export const CoursesContextProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(coursesReducer, {
    ...coursesInitialState,
    ...initialState,
  })

  return (
    <CoursesContext.Provider value={{ ...state, dispatchCoursesAction: dispatch }}>
      {children}
    </CoursesContext.Provider>
  )
}

CoursesContextProvider.propTypes = {
  initialState: PropTypes.shape({}),
  children: PropTypes.element,
}

CoursesContextProvider.defaultProps = { initialState: coursesInitialState }

export const useCoursesContext = () => React.useContext(CoursesContext) || {}
