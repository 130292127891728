// export const StoreKey = 'apiPolling'
// /**
//  * This method creates ApiPolling action types based on the store key.
//  * @param {StoreKey} StoreKey
//  * @returns {ApiPollingBaseActionTypes}
//  */
// export const createApiPollingActionTypes = (StoreKey) => ({
//   init: `${StoreKey}/ReduxApiPolling/Init`,
//   start: `${StoreKey}/ReduxApiPolling/Running`,
//   success: `${StoreKey}/ReduxApiPolling/Success`,
//   failure: `${StoreKey}/ReduxApiPolling/Failure`,
//   reset: `${StoreKey}/ReduxApiPolling/Reset`,
// })

// /**
//  * This method returns base Api polling Actions
//  * @param {ApiPollingBaseActionTypes} Types
//  * @returns {ApiPollingBaseActionHandlers}
//  */
// export const createBaseApiPollingActions = (Types) => ({
//   init: (payload) => (dispatch) => dispatch({ type: Types.init, payload }),
//   start: (payload) => (dispatch) => dispatch({ type: Types.start, payload }),
//   resolve: (payload) => (dispatch) => dispatch({ type: Types.success, payload }),
//   fail: (payload) => (dispatch) => dispatch({ type: Types.failure, payload }),
//   reset: (payload) => (dispatch) => dispatch({ type: Types.reset, payload }),
// })

import apiEndpoints from 'apiEndpoints'
import { actions } from 'store/actions'
import platformApi from 'store/api/platformApi'

const initStatusPolling = ({
  dispatch,
  key,
  pollingRequest,
  acceptCondition,
  onSuccess,
  onFailure,
}) => {
  const payload = { key, pollingRequest, acceptCondition, onSuccess, onFailure }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_INIT, payload })
}

const startStatusPolling = ({ dispatch, key }) => {
  const payload = { key }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_START, payload })
}

const resetStatusPolling = ({ dispatch, key }) => {
  const payload = { key }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_RESET, payload })
}

const successStatusPolling = ({ dispatch, key }) => {
  const payload = { key }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_SUCCESS, payload })
}

const failStatusPolling = ({ dispatch, key }) => {
  const payload = { key }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_FAIL, payload })
}

const resolveStatusPolling = ({ dispatch, key }) => {
  const payload = { key }
  dispatch({ type: actions.COURSE_COPY_STATUS_POLLING_RESOLVE, payload })
}

const courseCopyStatus = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_COPY_STATUS_GET_REQUEST,
      actions.COURSE_COPY_STATUS_GET_SUCCESS,
      actions.COURSE_COPY_STATUS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseCopyStatus(courseKey),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

export {
  initStatusPolling,
  startStatusPolling,
  resetStatusPolling,
  successStatusPolling,
  failStatusPolling,
  resolveStatusPolling,
  courseCopyStatus,
}
