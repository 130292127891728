import React, { memo } from 'react'
import PropTypes from 'prop-types'
import MUITooltip from '@mui/material/Tooltip'

const Tooltip = ({ title, children }) => {
  return (
    <MUITooltip title={title} arrow>
      {children}
    </MUITooltip>
  )
}

export default memo(Tooltip)

Tooltip.propTypes = {
  /**
   * Theme object used for style customization.
   */
  theme: PropTypes.object,
  /**
   * Tooltip content
   */
  title: PropTypes.string,
  children: PropTypes.element,
}

Tooltip.defaultProps = {
  theme: {},
  title: '',
}
