import React, { useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Dialog } from '@vitalsource/vstui'
import { StoreContext } from 'store'
import classnames from 'classnames'
import Completion from './Completion'
import TOCListElement from './TOCListElement'
import { AssignmentIcon, MenuAssignmentIcon } from 'components/LXIcons/LXIcons'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { getAssignmentTypeKey, getIsInstructorOrAdmin } from 'store/selectors'
import ScheduleInfo from './ScheduleInfo'

const TOCElement = (props) => {
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const [isOpen, setIsOpen] = useState(false)
  const [contentNotYetAvailableModal, setContentNotYetAvailableModal] = useState(false)
  const { course, currentLocation, userAq } = useContext(StoreContext)
  const isInstructorOrAdmin = getIsInstructorOrAdmin(course, userAq)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const { obj, itemType, courseKey, unitId, tocLevel, theme, showCompletion } = props

  const themeColor = theme.vstui.color.primary.getShade(6)

  useEffect(() => {
    currentLocation[itemType] && currentLocation[itemType] === obj.identifier
      ? setIsOpen(true)
      : setIsOpen(false)
  }, [currentLocation, itemType, obj.identifier])

  // temp, until we get completion from API
  const [completion, setCompletion] = useState(0)
  useEffect(() => {
    const randomCompletionValues = [0, 100, Math.floor(Math.random() * 100)]
    setCompletion(randomCompletionValues[Math.floor(Math.random() * 3)])
  }, [])

  const assignments = useMemo(
    () =>
      (itemType === 'page' || itemType === 'module') && obj.assessments
        ? obj.assessments.map((assessment) => ({
            ...assessment,
            type: getAssignmentTypeKey(assessment.assessmentType),
            isNotAvailable: assessment.schedule && !assessment.schedule.studentAvailability,
          }))
        : [],
    [itemType, obj],
  )

  const isActive = currentLocation[itemType] && currentLocation[itemType] === obj.identifier

  // highlight where the user is, in the left menu
  const shouldBeHighlighted = tocLevel === 'nav' && isActive && currentLocation.viewing === itemType
  const highlightStyle = `inset 5px 0 0 0 ${theme.vstui.color.primary.getShade(5)}`

  // Unit and Modules scheduling
  const isNotAvailable = obj && obj.schedule && !obj.schedule.studentAvailability
  const contentAvailableDate =
    obj?.schedule?.availableDate && moment.tz(obj.schedule.availableDate, userAq.time_zone)
  const shouldShowScheduleData =
    !isNotAvailable &&
    !!contentAvailableDate &&
    moment().tz(userAq.time_zone).isSameOrBefore(contentAvailableDate)

  const shouldTriggerContentNotYetAvailableModal = (tocNode) =>
    !isInstructorOrAdmin &&
    tocNode?.schedule?.studentAvailability &&
    tocNode?.schedule?.availableDate &&
    moment()
      .tz(userAq.time_zone)
      .isSameOrBefore(moment.tz(tocNode.schedule.availableDate, userAq.time_zone))

  const dateToDisplayInContentNotYetAvailableModal =
    contentNotYetAvailableModal &&
    contentNotYetAvailableModal?.schedule?.availableDate &&
    moment.tz(contentNotYetAvailableModal.schedule.availableDate, userAq.time_zone)
  const formattedDateToDisplayInContentNotYetAvailableModal =
    !!dateToDisplayInContentNotYetAvailableModal &&
    dateToDisplayInContentNotYetAvailableModal.format(t('toc.contentAvailabilityDateFormat'))

  const shouldShowAssessmentScheduleData = (tocNode) => {
    const { isNotAvailable } = tocNode
    const { availableDate, dueDate } = tocNode.schedule || {}
    const assignmentAvailableDate = availableDate && moment.tz(availableDate, userAq.time_zone)
    const hasAvailableDateInTheFuture =
      assignmentAvailableDate &&
      moment().tz(userAq.time_zone).isSameOrBefore(assignmentAvailableDate)

    return !isNotAvailable && (dueDate || hasAvailableDateInTheFuture)
  }

  const assignmentShouldBeHighlighted = (assignment) =>
    currentLocation['assignment'] &&
    currentLocation['assignment'] === assignment.identifier &&
    currentLocation.viewing === 'assignment' &&
    tocLevel === 'nav' &&
    highlightStyle

  return (
    <>
      {itemType === 'unit' && (
        <TOCListElement
          obj={obj}
          isOpen={isOpen}
          onClick={toggle}
          childListKey="modules"
          childName="module"
          itemType={itemType}
          courseKey={courseKey}
          unitId={unitId}
          isActive={isActive}
          tocLevel={tocLevel}
          shouldBeHighlighted={shouldBeHighlighted}
          showCompletion={showCompletion}
        />
      )}
      {itemType === 'module' && (
        <TOCListElement
          obj={obj}
          isOpen={isOpen}
          onClick={toggle}
          childListKey="pages"
          childName="page"
          itemType={itemType}
          courseKey={courseKey}
          unitId={unitId}
          isActive={isActive}
          tocLevel={tocLevel}
          assignmentsLength={assignments.length}
          shouldBeHighlighted={shouldBeHighlighted}
          showCompletion={showCompletion}
        />
      )}
      {/* Pages (a.k.a. Activities) are not displayed in Course Overview */}
      {itemType === 'page' &&
        tocLevel !== 'course' &&
        (!isNotAvailable || (isNotAvailable && isInstructorOrAdmin)) && (
          <>
            <li
              data-toc-element-type={itemType}
              data-toc-element-identifier={obj.identifier}
              className={classnames('toc__list-item--page', {
                active: isActive,
                'toc__list-item--not-available': isNotAvailable,
              })}
              style={{
                boxShadow: shouldBeHighlighted && highlightStyle,
              }}
            >
              {showCompletion && (
                <Completion itemType="page" tocLevel={tocLevel} completion={completion} />
              )}

              {!isNotAvailable || (isNotAvailable && isInstructorOrAdmin) ? (
                <Link
                  to={bpe.contentPageUrl({ courseKey, pageIdentifier: obj.identifier })}
                  className="toc__link toc__link--page"
                  title={obj.title}
                  onClick={(e) => {
                    if (shouldTriggerContentNotYetAvailableModal(obj)) {
                      e.preventDefault()
                      setContentNotYetAvailableModal(obj)
                    }
                  }}
                >
                  <span>{obj.title}</span>

                  {shouldShowScheduleData && (
                    <div className="toc__metadata">
                      <div className="toc__metadata-item">
                        <ScheduleInfo tocNode={obj} userAq={userAq} />
                      </div>
                    </div>
                  )}
                </Link>
              ) : (
                <span className="toc__link toc__link--page" title={obj.title}>
                  <span>{obj.title}</span>
                </span>
              )}

              {isNotAvailable && <span className="sr-only">{t('toc.notAvailable')}</span>}
            </li>
            {/* Add a new list item for each Assignment */}
            {assignments.map((assignment) => (
              <li
                key={assignment.identifier}
                className={classnames('toc__list-item--assignment', {
                  'toc__list-item--not-available': assignment.isNotAvailable,
                  'toc__list-item--not-yet-available': shouldShowAssessmentScheduleData(assignment),
                })}
                style={{
                  boxShadow: assignmentShouldBeHighlighted(assignment),
                }}
              >
                {showCompletion && (
                  <Completion itemType="page" tocLevel={tocLevel} completion={completion} />
                )}

                {!assignment.isNotAvailable ||
                (assignment.isNotAvailable && isInstructorOrAdmin) ? (
                  <Link
                    to={bpe.assignmentPageUrl({
                      courseKey,
                      assignmentType: assignment.type,
                      assignmentIdentifier: assignment.identifier,
                    })}
                    className="toc__link toc__link--assignment"
                    title={`${assignment.columnTitle}: ${assignment.title}`}
                    onClick={(e) => {
                      if (shouldTriggerContentNotYetAvailableModal(assignment)) {
                        e.preventDefault()
                        setContentNotYetAvailableModal(assignment)
                      }
                    }}
                  >
                    <div>
                      <span>
                        {tocLevel === 'nav' && (
                          <MenuAssignmentIcon
                            aria-hidden="true"
                            className="toc__assignment-icon"
                            color={themeColor}
                            title={`${assignment.columnTitle}: ${assignment.title}`}
                          />
                        )}
                        {tocLevel !== 'nav' && (
                          <AssignmentIcon
                            aria-hidden="true"
                            className={classnames({
                              'toc__assignment-icon': true,
                              'toc__assignment-icon_mt':
                                shouldShowAssessmentScheduleData(assignment),
                            })}
                            color={themeColor}
                            title={`${assignment.columnTitle}: ${assignment.title}`}
                          />
                        )}
                        {`${assignment.columnTitle}: ${assignment.title}`}
                      </span>
                      {shouldShowAssessmentScheduleData(assignment) && (
                        <div className="toc__metadata">
                          <div className="toc__metadata-item">
                            <ScheduleInfo tocNode={assignment} userAq={userAq} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Link>
                ) : (
                  <span
                    className="toc__link toc__link--assignment"
                    title={`${assignment.columnTitle}: ${assignment.title}`}
                  >
                    <span>
                      {tocLevel !== 'nav' && (
                        <AssignmentIcon
                          aria-hidden="true"
                          className={classnames({
                            'toc__assignment-icon': true,
                            'toc__assignment-icon_mt': shouldShowAssessmentScheduleData(assignment),
                          })}
                          color={themeColor}
                          title={`${assignment.columnTitle}: ${assignment.title}`}
                        />
                      )}
                      {`${assignment.columnTitle}: ${assignment.title}`}
                    </span>
                  </span>
                )}

                {assignment.isNotAvailable && (
                  <span className="sr-only">{t('toc.notAvailable')}</span>
                )}
              </li>
            ))}
          </>
        )}

      {contentNotYetAvailableModal &&
        ReactDOM.createPortal(
          <Dialog
            closeButtonLabel={t('modal.modalCloseButton')}
            onRequestClose={() => setContentNotYetAvailableModal(false)}
            open
            primaryActionButton={{
              label: t('toc.contentNotYetAvailableModalButton'),
              onClick: () => setContentNotYetAvailableModal(false),
            }}
            rootSelector="#main"
            size="small"
            title={t('toc.contentNotYetAvailableModalTitle')}
          >
            {t('toc.contentAvailabilityStatement', {
              formattedContentAvailableDate: formattedDateToDisplayInContentNotYetAvailableModal,
            })}
          </Dialog>,
          document.getElementById('root'),
        )}
    </>
  )
}

TOCElement.propTypes = {
  courseKey: PropTypes.string,
  itemType: PropTypes.string,
  obj: PropTypes.object,
  showCompletion: PropTypes.bool,
  theme: PropTypes.object,
  tocLevel: PropTypes.string,
  unitId: PropTypes.string,
}

export default React.memo(withTheme(TOCElement))
