import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const {
  LOGIN_POST_REQUEST,
  LOGIN_POST_SUCCESS,
  LOGIN_POST_FAILURE,
  SET_USER_IS_AUTHENTICATED,
  SIGNUP_FROM_MANAGE_POST_REQUEST,
  SIGNUP_FROM_MANAGE_POST_SUCCESS,
  SIGNUP_FROM_MANAGE_POST_FAILURE,
} = actions

export const login = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [LOGIN_POST_REQUEST, LOGIN_POST_SUCCESS, LOGIN_POST_FAILURE],
    fetchParams: {
      url: apiEndpoints().login(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })

export const loginAndEnroll = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [LOGIN_POST_REQUEST, LOGIN_POST_SUCCESS, LOGIN_POST_FAILURE],
    fetchParams: {
      url: apiEndpoints().loginAndEnroll(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })

export const setUserIsAuthenticated = ({ dispatch }) =>
  dispatch({ type: SET_USER_IS_AUTHENTICATED })

export const signupFromManage = ({ dispatch, data }) =>
  platformApi({
    dispatch,
    types: [
      SIGNUP_FROM_MANAGE_POST_REQUEST,
      SIGNUP_FROM_MANAGE_POST_SUCCESS,
      SIGNUP_FROM_MANAGE_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().signupFromManage(),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })
