import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import Button from '@vitalsource/vstui/Button'
import { useTranslation } from 'react-i18next'
import { useSettingsContext } from 'pages/Settings/store/settingsContext'
import { setFormDirtyStatus } from 'pages/Settings/store/actions'
import { closeDrawer } from 'components/Forms/smartFormHelpers'
import { DEFAULT_DRAWER_ID } from 'utils/constants'

const BottomActionsField = ({ drawerId }) => {
  const { t } = useTranslation()
  const { dispatchSettingsAction, isFormDirty } = useSettingsContext()
  const { dirty } = useFormikContext()

  useEffect(() => {
    if (dirty !== isFormDirty) {
      setFormDirtyStatus({ dispatchSettingsAction, isFormDirty: dirty })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty])

  const onClose = () => {
    closeDrawer({ dispatchSettingsAction, drawerId, isFormDirty })
  }

  return (
    <div className="bottom-actions">
      <Button fullWidth type="submit">
        {t('settingsForm.applyChanges')}
      </Button>
      <Button fullWidth color="neutral" onClick={onClose} type="button">
        {t('settingsForm.cancel')}
      </Button>
    </div>
  )
}

export default memo(BottomActionsField)

BottomActionsField.propTypes = {
  /**
   * Drawer id in order to close on cancel
   */
  drawerId: PropTypes.string,
}

BottomActionsField.defaultProps = {
  drawerId: DEFAULT_DRAWER_ID,
}
