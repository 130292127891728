import { devTools } from 'utils'
import { AssignmentSettingsActions } from './actions/settingsActions'
import { settingsInitialState } from './settingsInitialState'

const useDevTools =
  process.env.NODE_ENV === 'development' &&
  typeof window !== 'undefined' &&
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__

const devToolsSendAction = useDevTools ? devTools({ settingsInitialState }) : null

export const assignmentSettingsReducer = (state = settingsInitialState, action) => {
  let newState = {}
  switch (action.type) {
    case AssignmentSettingsActions.ASSIGNMENT_TAGS.SET_ACTIONS: {
      newState = {
        ...state,
        assignmentTagsActions: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.ASSIGNMENT_TAGS.SET_STATE: {
      newState = {
        ...state,
        assignmentTagsState: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.SET_ASSIGNMENT_TYPES: {
      newState = {
        ...state,
        assignmentTypes: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.SETTINGS_DRAWER.CLOSE: {
      newState = {
        ...state,
        isSettingsDrawerOpen: { ...state.isSettingsDrawerOpen, [action.payload]: false },
        isFormDirty: false,
      }
      break
    }
    case AssignmentSettingsActions.SETTINGS_DRAWER.CLOSE_ALL: {
      newState = {
        ...state,
        isSettingsDrawerOpen: settingsInitialState.isSettingsDrawerOpen,
        isFormDirty: false,
      }
      break
    }
    case AssignmentSettingsActions.SETTINGS_DRAWER.OPEN: {
      newState = {
        ...state,
        isSettingsDrawerOpen: { ...state.isSettingsDrawerOpen, [action.payload]: true },
      }
      break
    }
    case AssignmentSettingsActions.CONFIRMATION_DIALOG.CLOSE: {
      newState = {
        ...state,
        confirmationDialogMode: settingsInitialState.confirmationDialogMode,
      }
      break
    }
    case AssignmentSettingsActions.CONFIRMATION_DIALOG.SET_MODE: {
      newState = {
        ...state,
        confirmationDialogMode: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.SET_SELECTION: {
      newState = {
        ...state,
        assignmentSelection: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.UNSET_SELECTION: {
      newState = {
        ...state,
        assignmentSelection: settingsInitialState.assignmentSelection,
      }
      break
    }
    case AssignmentSettingsActions.SET_UNIT_OR_MODULE: {
      newState = {
        ...state,
        unitOrModuleSelection: action.payload,
      }
      break
    }
    case AssignmentSettingsActions.UNSET_UNIT_OR_MODULE: {
      newState = {
        ...state,
        unitOrModuleSelection: null,
      }
      break
    }
    case AssignmentSettingsActions.SET_FORM_DIRTY: {
      newState = {
        ...state,
        isFormDirty: action.payload,
      }
      break
    }
    default:
      newState = state
  }

  if (useDevTools) devToolsSendAction(action, newState)
  return newState
}
