import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Container } from 'reactstrap'
import Footer from 'components/Footer'
import TrialAlert from 'components/TrialAlert'

const ExternalModule = ({ module, courseKey }) => {
  const { t } = useTranslation()
  //window.IframeResizer.targetOrigin = 'http://studyguide-dev.vitalbook.com'
  return (
    <>
      <main
        id="main"
        role="main"
        aria-hidden="false"
        aria-label={t('nav.mainContent')}
        tabIndex="-1"
        className="px-0 external-module"
      >
        <Container className="external-module-container">
          {/* Trial alert banner */}
          <TrialAlert courseKey={courseKey} />
          <iframe
            src={module.externalUrl}
            className="external-module-iframe"
            title={module.title}
          />
        </Container>
      </main>
      <Footer />
    </>
  )
}

ExternalModule.propTypes = {
  courseKey: PropTypes.string,
  module: PropTypes.object,
}

export default ExternalModule
