import React, { useRef } from 'react'
import FocusLock from 'react-focus-lock'
import { Modal as M, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import classnames from 'classnames'
import { ReactComponent as CloseIcon } from 'images/close-btn.svg'
import { useTranslation } from 'react-i18next'

const Modal = ({
  className,
  closeBtn,
  fullHeight = false,
  fullScreen = false,
  fullWidth = false,
  modalBody,
  modalFooter,
  modalHeader,
  onClose,
  open,
  theme,
  toggle,
  withTheme = false,
  ariaLabel,
  ...props
}) => {
  const color = theme.vstui.color.primary
  const { t } = useTranslation()
  const modalRef = useRef()
  const headerThemeStyle = withTheme
    ? {
        backgroundColor: color.getShade(7),
      }
    : null

  const root = document.getElementById('root')

  const onModalOpened = () => {
    // Make content behind modal hidden to screen readers
    root?.setAttribute('aria-hidden', 'true')
    // Disable scrollbars behind modal
    document.documentElement.classList.add('overflow-hidden')
    // Set modal aria label
    modalRef?.current?.setAttribute('aria-label', ariaLabel)
  }

  const onModalClose = () => {
    // Make content behind modal visible to screen readers
    root?.removeAttribute('aria-hidden')
    // Enable scrollbars behind modal
    document.documentElement.classList.remove('overflow-hidden')
    // onClose callback
    if (onClose) onClose()
  }

  return (
    <FocusLock disabled={!open} className="focus-lock">
      <M
        className={classnames(className, {
          'with-theme': withTheme,
          'full-height': fullHeight,
          'full-width': fullWidth,
          'full-screen': fullScreen,
        })}
        isOpen={open}
        toggle={toggle}
        onOpened={onModalOpened}
        onClosed={onModalClose}
        onExit={onModalClose}
        innerRef={modalRef}
        {...props}
      >
        {(modalHeader || withTheme) && (
          <ModalHeader style={headerThemeStyle} toggle={toggle} tag="h1">
            {modalHeader}
          </ModalHeader>
        )}
        {modalBody && (
          <ModalBody>
            {closeBtn && (
              <Button
                className="lx-close-btn"
                aria-label={t('modal.modalCloseButton')}
                onClick={toggle}
              >
                <CloseIcon aria-hidden="true" />
              </Button>
            )}
            {modalBody}
          </ModalBody>
        )}
        {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
      </M>
    </FocusLock>
  )
}

Modal.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  closeBtn: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  modalBody: PropTypes.node,
  modalFooter: PropTypes.node,
  modalHeader: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  theme: PropTypes.object,
  toggle: PropTypes.func,
  withTheme: PropTypes.bool,
}

export default withTheme(Modal)
