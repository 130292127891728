import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'store'
import { actions, getAqUser, setUserIsAuthenticated } from 'store/actions'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'
import { DEFAULT_CUSTOMER } from 'utils/constants'
import { useAuthentication, useStateWithLocalStorage } from 'utils/customHooks'
import Spinner from 'components/Spinner'
import ErrorPage from 'components/ErrorPage'

const ProtectedRouter = ({ component: Component, ...props }) => {
  const { i18n } = useTranslation()
  const { dispatch, protectedRouterCurrentPath, userAq } = useContext(StoreContext)
  const { signInPageUrl, termsPageUrl } = buildPagesEndpoints(i18n)
  let { pathname } = useLocation()
  const { customerSlug, vbid } = useParams()
  const [shouldShowAnError, shouldRedirectToLogin, shouldRedirectToTerms, canShowComponent] =
    useAuthentication({
      getAqUser,
      setUserIsAuthenticated,
      pathname,
      customerSlug,
      vbid,
    })
  const [brandedSignInCustomer] = useStateWithLocalStorage('customerInLocalStorage')
  const customerForBranding =
    brandedSignInCustomer && brandedSignInCustomer !== DEFAULT_CUSTOMER
      ? brandedSignInCustomer
      : null

  const isTermsPage = pathname.includes('/tos')
  const nextPageParam = protectedRouterCurrentPath
    ? { customerSlug: customerForBranding || null, next: `?next=${protectedRouterCurrentPath}` }
    : { customerSlug: customerForBranding || null, next: '' }
  const termsUrl = termsPageUrl(nextPageParam)
  const signInUrl = signInPageUrl(nextPageParam)

  const shouldRenderSpinner =
    !isTermsPage &&
    !canShowComponent &&
    !shouldRedirectToLogin &&
    !shouldRedirectToTerms &&
    !shouldShowAnError

  useEffect(() => {
    if (!isTermsPage) {
      dispatch({
        type: actions.PROTECTED_ROUTER_CURRENT_PATH_SET,
        payload: encodeURIComponent(pathname),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isTermsPage, window.location])

  if (shouldRenderSpinner) {
    return <Spinner />
  }

  return (
    <>
      {(canShowComponent || isTermsPage) && <Component {...props} />}
      {shouldShowAnError && <ErrorPage />}
      {shouldRedirectToLogin &&
        (process.env.REACT_APP_ALOHOMORA_ENABLED === 'true' ? (
          window.location.replace(userAq.alohomoraUrl)
        ) : (
          <Navigate to={signInUrl} />
        ))}
      {shouldRedirectToTerms && !isTermsPage && <Navigate to={termsUrl} />}
    </>
  )
}

ProtectedRouter.propTypes = {
  component: PropTypes.elementType,
}

export default ProtectedRouter
