import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

const getCourses = ({ dispatch }) =>
  platformApi({
    dispatch,
    types: [actions.COURSES_GET_REQUEST, actions.COURSES_GET_SUCCESS, actions.COURSES_GET_FAILURE],
    fetchParams: {
      url: apiEndpoints().courses(),
      params: { method: 'get', credentials: 'include' },
    },
  })

const unsetCourses = ({ dispatch }) => dispatch({ type: actions.COURSES_UNSET })

const getCourse = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [actions.COURSE_GET_REQUEST, actions.COURSE_GET_SUCCESS, actions.COURSE_GET_FAILURE],
    fetchParams: {
      url: apiEndpoints().navigation(courseKey),
      params: { method: 'get', credentials: 'include' },
    },
  })

const unloadCourse = ({ dispatch }) => dispatch({ type: actions.COURSE_UNLOAD })

const enroll = ({ dispatch, courseKey, data }) =>
  platformApi({
    dispatch,
    types: [actions.ENROLL_POST_REQUEST, actions.ENROLL_POST_SUCCESS, actions.ENROLL_POST_FAILURE],
    fetchParams: {
      url: apiEndpoints().enroll(courseKey),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })

const getCourseAccessRequirements = ({ dispatch, courseKey = '' }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_ACCESS_REQUIREMENTS_GET_REQUEST,
      actions.COURSE_ACCESS_REQUIREMENTS_GET_SUCCESS,
      actions.COURSE_ACCESS_REQUIREMENTS_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseAccessRequirements(courseKey.trim()),
      params: {
        method: 'get',
        credentials: 'include',
      },
    },
  })

const unsetCourseAccessRequirements = ({ dispatch }) =>
  dispatch({ type: actions.COURSE_ACCESS_REQUIREMENTS_UNSET })

const getCartUrl = ({ dispatch, courseKey, redirectUrl }) =>
  platformApi({
    dispatch,
    types: [
      actions.CART_URL_GET_REQUEST,
      actions.CART_URL_GET_SUCCESS,
      actions.CART_URL_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().getCartUrl(courseKey, redirectUrl),
      params: { method: 'get', credentials: 'include' },
    },
  })

const resetAssessments = ({ dispatch, courseKey, data }) =>
  platformApi({
    dispatch,
    types: [
      actions.RESET_ASSESSMENTS_REQUEST,
      actions.RESET_ASSESSMENTS_SUCCESS,
      actions.RESET_ASSESSMENTS_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().resetAssessments(courseKey),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
  })

const courseCopy = ({ dispatch, courseKey, data }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_COPY_POST_REQUEST,
      actions.COURSE_COPY_POST_SUCCESS,
      actions.COURSE_COPY_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseCopy(courseKey),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      }),
    },
  })

export {
  getCourse,
  getCourses,
  unloadCourse,
  enroll,
  getCourseAccessRequirements,
  unsetCourses,
  getCartUrl,
  unsetCourseAccessRequirements,
  resetAssessments,
  courseCopy,
}
