/**
 * Form Definitions
 */

/**
 * FormField Structure:
 * fieldName:{
 *   name: string
 *   i18n: string
 *   options?: [SelectOptions]
 * }
 *
 * SelectOptions Structure:
 * {label: string, value: any, i18n: string}
 */

//---------- Global form definitions

/**
 * Multiple Values, option definition.
 * This is the option used as 'Multiple values' in several fields.
 */
export const MULTIPLE_VALUES_OPTION = {
  label: 'Multiple values',
  i18n: 'settingsForm.multipleValues',
  value: '',
}

//---------- AttemptsAndTimeLimitField Definitions
/**
 * Max attempts options
 */
export const ATTEMPTS_OPTIONS = [
  { label: 'Unlimited', value: '-1', i18n: 'settingsForm.unlimited' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
]

/**
 * Time limit options
 */
export const TIME_LIMIT_OPTIONS = [
  { label: 'None', value: '0', i18n: 'settingsForm.none' },
  { label: '10 min', value: '10' },
  { label: '20 min', value: '20' },
  { label: '30 min', value: '30' },
  { label: '40 min', value: '40' },
  { label: '50 min', value: '50' },
  { label: '1 hr', value: '60' },
  { label: '1 hr 10 min', value: '70' },
  { label: '1 hr 20 min', value: '80' },
  { label: '1 hr 30 min', value: '90' },
  { label: '2 hr', value: '120' },
  { label: '2 hr 30 min', value: '150' },
  { label: '3 hr', value: '180' },
  { label: '3 hr 30 min', value: '210' },
  { label: '4 hr', value: '240' },
  { label: '4 hr 30 min', value: '270' },
  { label: '5 hr', value: '300' },
  { label: '6 hr', value: '360' },
]

export const ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS = {
  maxAttempts: {
    name: 'maxAttempts',
    i18n: 'settingsForm.attemptsLabel',
    options: ATTEMPTS_OPTIONS,
  },
  timeLimit: {
    name: 'timeLimit',
    i18n: 'settingsForm.timeLimitLabel',
    options: TIME_LIMIT_OPTIONS,
  },
}

/**
 * Attempts and time limit generic field names as used in form submission
 */
export const ATTEMPTS_AND_TIME_LIMIT_FIELD_NAMES = {
  maxAttempts: ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.maxAttempts.name,
  timeLimit: ATTEMPTS_AND_TIME_LIMIT_FORM_FIELDS.timeLimit.name,
}

//---------- DateAndTimeField Definitions

/**
 * Modes of DateAndTimeField module.
 */
export const DATE_AND_TIME_FIELD_MODES = {
  availableDate: 'availableDate',
  dueDate: 'dueDate',
}

/**
 * Date and time generic field names as used in form submission
 */
export const DATE_AND_TIME_FIELD_NAMES = DATE_AND_TIME_FIELD_MODES

/**
 * Types of each field applied after mode:
 * ex. of actual field name: availableDateDatePicker
 */
export const DATE_AND_TIME_FIELD_TYPES = {
  select: 'Select',
  date: 'DatePicker',
  time: 'TimePicker',
}

/**
 * Date and time specific values, used in availableDate and dueDate
 * variations of the module.
 */
export const DATE_AND_TIME_SELECT_VALUES = {
  anyTime: 'anyTime',
  definedDate: 'definedDate',
}

/**
 * Start date / availableDate specific options.
 * Values are consistent but label and localization varies
 */
export const START_DATE_TIME_SELECT_OPTIONS = [
  {
    value: DATE_AND_TIME_SELECT_VALUES.definedDate,
    i18n: 'settingsForm.specificStartDate',
  },
  {
    value: DATE_AND_TIME_SELECT_VALUES.anyTime,
    i18n: 'settingsForm.anyTime',
  },
]

/**
 * Due date specific options.
 * Values are consistent but label and localization varies
 */
export const DUE_DATE_TIME_SELECT_OPTIONS = [
  {
    value: DATE_AND_TIME_SELECT_VALUES.definedDate,
    i18n: 'settingsForm.specificDueDate',
  },
  {
    value: DATE_AND_TIME_SELECT_VALUES.anyTime,
    i18n: 'settingsForm.anyTime',
  },
]

export const AVAILABLE_DATE_TIME_FORM_FIELDS = {
  select: {
    name: `${DATE_AND_TIME_FIELD_MODES.availableDate}${DATE_AND_TIME_FIELD_TYPES.select}`,
    i18n: 'settingsForm.availableBeginningAt',
    options: START_DATE_TIME_SELECT_OPTIONS,
  },
  date: {
    name: `${DATE_AND_TIME_FIELD_MODES.availableDate}${DATE_AND_TIME_FIELD_TYPES.date}`,
    i18n: 'settingsForm.startDate',
  },
  time: {
    name: `${DATE_AND_TIME_FIELD_MODES.availableDate}${DATE_AND_TIME_FIELD_TYPES.time}`,
    i18n: 'settingsForm.startTime',
  },
}

export const DUE_DATE_TIME_FORM_FIELDS = {
  select: {
    name: `${DATE_AND_TIME_FIELD_MODES.dueDate}${DATE_AND_TIME_FIELD_TYPES.select}`,
    i18n: 'settingsForm.availableUntil',
    options: DUE_DATE_TIME_SELECT_OPTIONS,
  },
  date: {
    name: `${DATE_AND_TIME_FIELD_MODES.dueDate}${DATE_AND_TIME_FIELD_TYPES.date}`,
    i18n: 'settingsForm.dueDate',
  },
  time: {
    name: `${DATE_AND_TIME_FIELD_MODES.dueDate}${DATE_AND_TIME_FIELD_TYPES.time}`,
    i18n: 'settingsForm.dueTime',
  },
}

export const STUDENT_AVAILABILITY_CHECK_FORM_FIELDS = {
  available: {
    name: 'studentAvailability',
    i18n: 'settingsForm.studentAvailability',
  },
}

export const CLONED_COURSE_DATE_FIELDS = {
  startDate: {
    name: 'startDate',
    i18n: 'courses.courseCloneForm.startDate',
  },
  endDate: {
    name: 'endDate',
    i18n: 'courses.courseCloneForm.endDate',
  },
}

export const PRESERVE_INSTRUCTORS_CHECK_FIELDS = {
  name: 'preserveInstructors',
  i18n: 'courses.courseCloneForm.preserveInstructorsCheckbox',
}
