import { actions } from 'store/actions'
import { initialState } from 'store'

const accountCenterUrlReducer = (state = initialState.accountCenterUrl, action) => {
  switch (action.type) {
    case actions.ACCOUNT_CENTER_URL_GET_SUCCESS:
      return action.payload.url
    default:
      return state
  }
}

export default accountCenterUrlReducer
