import { DEFAULT_THEME } from 'utils/constants'

const initialState = {
  api: {
    error: {},
    loading: {},
    loaded: {},
    isReauthRequired: false,
  },
  assignments: null, // Contains assignments, units and modules.
  assignmentsExceptions: null,
  assignmentPurposeTypes: null,
  assignmentTypes: null,
  auth: {
    isAuthenticated: false,
    failedCount: 0,
  },
  authoringUserActions: null,
  authUserAccess: null,
  brandedSigninCustomerData: {},
  cartUrl: null,
  checkUserExist: null,
  configuration: {},
  course: null,
  courses: null,
  courseAccessRequirements: null,
  courseMessages: {
    course: null, // courseKey,
    message: null,
    shown: false,
  },
  courseSettings: {},
  currentLocation: {
    course: null, // courseKey
    unit: null, // identifier
    module: null, // identifier
    page: null, // identifier
    viewing: null, // unit/module/page/assignment
  },
  customer: null,
  enrollment: {
    courseKeyExist: false,
  },
  enrollments: null,
  theme: DEFAULT_THEME,
  userAq: null,
  userAqHasAccess: null,
  userLk: null,
  profile: null,
  terms: {},
  protectedRouterCurrentPath: null,
  schedule: null,
  externalModules: null,
  resetAssessmentsMessage: null,
  courseSchedule: null,
  courseCopy: null,
  courseCopyStatus: {},
  userPublisherCustomers: null,
  userPublisherBlueprints: null,
  customerInstructors: null,
}

export default initialState
