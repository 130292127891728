import React from 'react'
import i18n from 'i18n'
import { isEmptyObject, isObject } from 'utils/functional'
import { FormFeedback } from 'reactstrap'

export const safelyGetErrors = (formErrors) => {
  if (!formErrors || isEmptyObject(formErrors)) return ''
  let { errors } = formErrors

  // temp - fix no errors key
  if (!errors && formErrors.status) {
    const { status, ...rest } = formErrors
    errors = { ...rest }
  }
  // ... end fix

  if (!errors) return ''
  const errorsKeys = Object.keys(errors)

  // temp - fix nested errors
  errorsKeys.map((fieldName) => {
    if (isObject(errors[fieldName])) {
      return (errors[fieldName] = errors[fieldName][fieldName])
    }
    return errors[fieldName]
  })
  // ... end fix

  return errors
}

export const getErrorMessageForAlert = (formErrors) => {
  const errors = safelyGetErrors(formErrors)
  if (!errors) return ''

  const errorsKeys = Object.keys(errors)

  const getLiError = (field) =>
    Array.isArray(errors[field]) ? (
      errors[field].map((error, index) => <li key={index}>{error}</li>)
    ) : (
      <li key={field}>{errors[field]}</li>
    )
  return <ul className="list-unstyled mb-0">{errorsKeys.map(getLiError)}</ul>
}

export const renderFormFeedback = (messages, feedbackFor) => {
  if (!messages) {
    return ''
  }
  if (Array.isArray(messages)) {
    return messages.map((message, index) => (
      <FormFeedback key={`form_feedback_${feedbackFor}_${index}`}>{message}</FormFeedback>
    ))
  } else {
    return <FormFeedback>{messages}</FormFeedback>
  }
}

export const validateEmptyField = (name, value, setError) => {
  if (!value) {
    const error = i18n.t('login.emptyError')
    setError(error)
    return false
  }

  setError('')
  return true
}
