import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ActivityDueClock } from 'images/activity-due-clock.svg'

const ScheduleInfo = (props) => {
  const { tocNode, userAq } = props
  const { availableDate, dueDate, isUpcomingDue } = tocNode.schedule
  const { t } = useTranslation()

  const hasAvailableDateInTheFuture =
    availableDate && moment().tz(userAq.time_zone).isSameOrBefore(availableDate)

  const shouldBeInDangerState = isUpcomingDue

  return (
    <span className={shouldBeInDangerState ? 'danger' : ''}>
      <ActivityDueClock aria-hidden="true" className="toc__metadata-icon" />
      {hasAvailableDateInTheFuture && !dueDate && (
        <>
          <span className="sr-only">{t('toc.notAvailable')}</span>
          {`${t('assignment.availableDate')}: `}
          <time>
            {moment
              .tz(availableDate, userAq.time_zone)
              .format(t('toc.contentAvailabilityDateFormat'))}
          </time>
        </>
      )}
      {(!availableDate || (availableDate && !hasAvailableDateInTheFuture)) && dueDate && (
        <>
          {`${t('assignment.alertPanel.dueDate')}: `}
          <time>
            {moment.tz(dueDate, userAq.time_zone).format(t('toc.contentAvailabilityDateFormat'))}
          </time>
        </>
      )}
      {availableDate && hasAvailableDateInTheFuture && dueDate && (
        <>
          <span className="sr-only">{t('toc.notAvailable')}</span>
          {`${t('assignment.availableDate')}: `}
          <time>
            {moment
              .tz(availableDate, userAq.time_zone)
              .format(t('toc.contentAvailabilityDateFormat'))}
          </time>
          &nbsp;-&nbsp;
          <time>
            {moment.tz(dueDate, userAq.time_zone).format(t('toc.contentAvailabilityDateFormat'))}
          </time>
        </>
      )}
    </span>
  )
}

ScheduleInfo.propTypes = {
  tocNode: PropTypes.object,
  userAq: PropTypes.object,
}

export default React.memo(ScheduleInfo)
