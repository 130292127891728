import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import { createTheme, ThemeProvider } from '@vitalsource/vstui/themes'
import * as colors from '@vitalsource/vstui/colors'

const getTheme = (theme) => {
  const { primaryColor: primary, secondaryColor: secondary, locale } = theme

  const primaryColor = colors[primary.toLowerCase()]
  const secondaryColor = colors[secondary.toLowerCase()]

  return createTheme({
    color: {
      primary: primaryColor,
      secondary: secondaryColor,
    },
    i18n: locale,
    typography: {
      fontSize: 16,
    },
    size: {
      large: 48,
      medium: 38,
      small: 32,
    },
  })
}

const ThemeProviderComponent = ({ children }) => {
  const { theme: themeConfig } = useContext(StoreContext)

  if (!themeConfig) return null

  const theme = getTheme(themeConfig)

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

ThemeProviderComponent.propTypes = {
  children: PropTypes.node,
}

export { getTheme, ThemeProviderComponent }
