import { actions } from 'store/actions'
import { initialState } from 'store'

const protectedRouterCurrentPathReducer = (
  state = initialState.protectedRouterCurrentPath,
  action
) => {
  switch (action.type) {
    case actions.PROTECTED_ROUTER_CURRENT_PATH_SET:
      return action.payload
    default:
      return state
  }
}

export default protectedRouterCurrentPathReducer
