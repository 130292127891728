import React from 'react'
import { SettingsContextProvider } from 'pages/Settings/store/settingsContext'

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

const withAssignmentsSettingsContext = (WrappedComponent) => {
  const Component = (props) => {
    return (
      <SettingsContextProvider>
        <WrappedComponent {...props} />
      </SettingsContextProvider>
    )
  }
  Component.displayName = `withAssignmentsSettingsContext(${getDisplayName(WrappedComponent)})`
  return Component
}

export default withAssignmentsSettingsContext
