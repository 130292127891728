import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

const MastWaves = ({ theme }) => (
  <div className="mast__waves" style={{ backgroundColor: theme.vstui.color.primary.getShade(7) }}>
    <svg
      className="mast__waves-svg"
      viewBox="0 0 1440 123"
      preserveAspectRatio="xMidYMin slice"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#F2F4F9" fillRule="evenodd">
        <path
          d="M1002.2 63.4c-41.7 7.7-83.8 15.6-126.1 22.5 108.9-13.3 217.9-26.2 328-30.3 59-2.2 118.5-2.1 177.6 1.9 19.5 1.3 38.8 2.6 58.3 4.5V0c-182.3 0-437.8 63.4-437.8 63.4zM361.5 120.8c124 5.8 246.3-3.8 368.1-17.4-6.2.4-12.4.7-18.5 1-126.4 5.8-255.9-23.1-379.2-48.7 0 0-179-49.9-331.9-50.2v69.3c117.8 20.4 257.4 41.1 361.5 46z"
          opacity=".1"
        />
        <path
          d="M729.5 103.4c48.9-5.4 97.7-11.5 146.6-17.5 76.3-9.3 152.6-18.4 229.3-24.5l-27.3-5.7S889.1 3 733.5 5.6C577.9 8.1 308.5 75 308.5 75S253 87.2 177.8 102.5c65.5 8.8 129.4 15.7 183.6 18.2 124.1 5.8 246.3-3.8 368.1-17.3z"
          opacity=".15"
        />
        <path
          d="M1381.6 57.4c-59.1-4-118.6-4.1-177.6-1.9-33 1.2-65.8 3.3-98.6 5.9-76.7 6-153 15.1-229.3 24.5-48.8 6-97.7 12.1-146.6 17.5-121.8 13.6-244 23.2-368.1 17.4-54.2-2.5-118.2-9.4-183.6-18.2-60-8.2-121.4-18-177.8-27.7V123h1440V62c-19.5-1.8-38.9-3.3-58.4-4.6z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
)

MastWaves.propTypes = {
  theme: PropTypes.object,
}

export default withTheme(MastWaves)
