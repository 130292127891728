import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import classnames from 'classnames'
import { LXPagination } from '../PageElements/PageElements'
import { Link } from 'react-router-dom'
import { FooterHome } from '../LXIcons/LXIcons'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const Footer = ({ withNavigation, next, prev, theme, courseKey }) => {
  const { i18n, t } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const dt = new Date()
  const homeLink = bpe.coursePageUrl({ courseKey })
  return (
    <footer className={classnames({ 'lx-footer': true, 'with-navigation': withNavigation })}>
      {withNavigation && (
        <Container className="lx-footer_navigation">
          <Row className="justify-content-start">
            <Col md="4" className="d-none d-md-block">
              <Link to={homeLink} className="page-link home" aria-label={t('nav.home')}>
                <FooterHome color={theme.vstui.color.primary.getShade(7)} aria-hidden="true" />
              </Link>
            </Col>
            <Col md="4">
              {(next || prev) && (
                <LXPagination
                  prev={prev}
                  next={next}
                  color={theme.vstui.color.primary.getShade(7)}
                  bottom
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
      <Container className="d-md-flex text-sm-center text-md-left">
        <div className="lx-footer__copy mr-auto">
          &copy; {dt.getFullYear()} Vitalsource Technologies, LLC
        </div>
        <div className="lx-footer__misc">
          <a href="https://support.vitalsource.com/hc/en-us/articles/201646123-VitalSource-Technologies-LLC-Privacy-Policy">
            {t('footer.privacyPolicyLink')}
          </a>
          <a href="https://support.vitalsource.com/hc/en-us/articles/204612518-VitalSource-Technologies-LLC-Terms-and-Conditions-of-Use">
            {t('footer.termsOfUseLink')}
          </a>
        </div>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  courseKey: PropTypes.string,
  next: PropTypes.string,
  prev: PropTypes.string,
  theme: PropTypes.object,
  withNavigation: PropTypes.bool,
}

export default withTheme(Footer)
