import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { StoreContext } from 'store'
import Toggler from './Toggler'
import { Collapse } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ActivityAssignment } from 'images/activity-assignment.svg'
import { ReactComponent as ActivityDueClock } from 'images/activity-due-clock.svg'
import moment from 'moment-timezone'
import buildPagesEndpoints from 'utils/buildPagesEndpoints'

const AlertsPanel = (props) => {
  const collapseId = 'alertsPanelCollapsible'
  const [isOpen, setIsOpen] = useState(false)
  const { userAq } = useContext(StoreContext)
  const { t, i18n } = useTranslation()
  const bpe = buildPagesEndpoints(i18n)
  const { courseKey } = props

  const upcomingAssignments = Array.isArray(props.upcomingAssignments)
    ? props.upcomingAssignments
    : []

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const generateLink = (element) => {
    if (element.type === 'unit') {
      return bpe.courseUnitUrl({ courseKey: courseKey, unitIdentifier: element.unitIdentifier })
    } else if (element.type === 'module' || element.type === 'external_module') {
      return bpe.modulePageUrl({
        courseKey: courseKey,
        unitIdentifier: element.unitIdentifier,
        moduleIdentifier: element.identifier,
      })
    }

    return bpe.assignmentPageUrl({
      courseKey,
      assignmentType: element.assessmentType,
      assignmentIdentifier: element.identifier,
    })
  }

  return (
    <div className="alerts-panel alerts-panel__danger">
      <div className="toc__element-wrapper">
        <div className="alerts-panel__title toc__link">{t('assignment.alertPanel.title')}</div>
        <ul className="toc__metrics-list">
          <li className="toc__metrics-list-item">
            <span className="toc__metrics-value">{upcomingAssignments.length}</span>
            {upcomingAssignments.length === 1 && <>{t('assignment.name')}</>}
            {(upcomingAssignments.length > 1 || upcomingAssignments.length === 0) && (
              <>{t('assignment.namePlural')}</>
            )}
          </li>
        </ul>
        {!!upcomingAssignments.length && (
          <Toggler isOpen={isOpen} onClick={toggle} collapseId={collapseId} />
        )}
      </div>

      <Collapse isOpen={isOpen} id={collapseId}>
        <ul className="alerts-panel__assignment-list">
          {upcomingAssignments.map((assignment) => {
            return (
              <li key={assignment.identifier}>
                <h5 className="unit-module">
                  {assignment?.unitModuleLabel?.unit}
                  {assignment?.unitModuleLabel?.module && (
                    <>{` | ${assignment?.unitModuleLabel?.module}`}</>
                  )}
                </h5>
                <h4 className="assignment-title">
                  <Link
                    to={generateLink(assignment)}
                    title={`${assignment.label || assignment.title}`}
                  >
                    <ActivityAssignment
                      aria-hidden="true"
                      className="alerts-panel__assignment-icon"
                      title={`${assignment.label || assignment.title}`}
                    />
                    {`${assignment.label || assignment.title}`}
                  </Link>
                </h4>
                <h6 className="assignment-schedule danger">
                  <ActivityDueClock aria-hidden="true" className="toc__metadata-icon" />
                  {`${t('assignment.alertPanel.dueDate')}: `}
                  <time>{`${moment
                    .tz(assignment?.schedule?.dueDate, userAq.time_zone)
                    .format(t('toc.contentAvailabilityDateFormat'))}`}</time>
                </h6>
              </li>
            )
          })}
        </ul>
      </Collapse>
    </div>
  )
}

AlertsPanel.propTypes = {
  courseKey: PropTypes.string,
  upcomingAssignments: PropTypes.array,
}

AlertsPanel.defaultProps = {
  upcomingAssignments: [],
}

export default AlertsPanel
