import platformApi, { withCredentials } from 'store/api'
import { actions } from 'store/actions'
import apiEndpoints from 'apiEndpoints'

export const externalModuleScheduleGet = ({ dispatch, courseKey, moduleId }) =>
  platformApi({
    dispatch,
    types: [
      actions.SCHEDULE_GET_REQUEST,
      actions.SCHEDULE_GET_SUCCESS,
      actions.SCHEDULE_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().scheduleExternalModule(courseKey, moduleId),
      params: withCredentials({ method: 'get', credentials: 'include' }),
    },
  })

export const unsetSchedule = ({ dispatch }) => dispatch({ type: actions.SCHEDULE_UNSET })

export const externalModuleSchedulePost = ({ dispatch, courseKey, moduleId, data }) =>
  platformApi({
    dispatch,
    types: [
      actions.SCHEDULE_POST_REQUEST,
      actions.SCHEDULE_POST_SUCCESS,
      actions.SCHEDULE_POST_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().scheduleExternalModule(courseKey, moduleId),
      params: withCredentials({
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    },
  })

export const externalModuleScheduleRemove = ({ dispatch, courseKey, moduleId }) =>
  platformApi({
    dispatch,
    types: [
      actions.SCHEDULE_DELETE_REQUEST,
      actions.SCHEDULE_DELETE_SUCCESS,
      actions.SCHEDULE_DELETE_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().scheduleExternalModule(courseKey, moduleId),
      params: withCredentials({ method: 'delete', credentials: 'include' }),
    },
  })

export const getCourseSchedule = ({ dispatch, courseKey }) =>
  platformApi({
    dispatch,
    types: [
      actions.COURSE_SCHEDULE_GET_REQUEST,
      actions.COURSE_SCHEDULE_GET_SUCCESS,
      actions.COURSE_SCHEDULE_GET_FAILURE,
    ],
    fetchParams: {
      url: apiEndpoints().courseSchedule(courseKey),
      params: withCredentials({ method: 'get', credentials: 'include' }),
    },
  })

export const unsetCourseSchedule = ({ dispatch }) =>
  dispatch({ type: actions.COURSE_SCHEDULE_UNSET })
