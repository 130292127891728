import PropTypes from 'prop-types'

const PageType = PropTypes.shape({
  identifier: PropTypes.string,
  pageId: PropTypes.string,
  pageNumber: PropTypes.number,
  prevNextPages: PropTypes.arrayOf(PropTypes.string),
  quickstartUrl: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
})

export default PageType
