import PropTypes from 'prop-types'

const CoursesType = PropTypes.arrayOf(
  PropTypes.shape({
    courseContentsUrl: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    title: PropTypes.string,
  })
)

export default CoursesType
